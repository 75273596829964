const remoteHostRegex = /^(?<maybeHost>[a-zA-Z0-9_.-]+)(?::(?<hostPort>\d+))?$/;
const isIp = (value) => {
    const ipv4 = /^(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}$/;
    return ipv4.test(value);
};
const isUrl = (value) => {
    try {
        return !!new URL(`https://${value}`);
    }
    catch (_a) {
        return false;
    }
};
export const parseRemoteServer = (value) => {
    if (!value || !isValid(value)) {
        return {};
    }
    const matches = value.trim().match(remoteHostRegex);
    if (!matches || !matches.groups) {
        return {};
    }
    const { groups: { maybeHost, hostPort }, } = matches;
    let port;
    if (hostPort) {
        port = {
            secure: [parseInt(hostPort, 10)],
        };
    }
    return {
        host: maybeHost,
        port,
        usingSecure: true,
    };
};
export const isValid = (value) => {
    if (!value || !value.trim()) {
        return false;
    }
    const matches = value.trim().match(remoteHostRegex);
    if (!matches || !matches.groups) {
        return false;
    }
    const { groups: { maybeHost }, } = matches;
    return isIp(maybeHost) || isUrl(maybeHost);
};
export const stringifyRemoteServer = (value) => {
    var _a;
    if (!value || !value.host) {
        return '';
    }
    let remoteServer = '';
    remoteServer = `${remoteServer}${value.host}`;
    if ((_a = value.port) === null || _a === void 0 ? void 0 : _a.secure) {
        remoteServer = `${remoteServer}:${value.port.secure[0]}`;
    }
    return remoteServer;
};
