import { CART_HAS_EXPIRED_INVENTORY_MASK, INVENTORY_ERROR, STATUS } from '../constants';
export const isOpen = (status) => status === STATUS.OPEN || status === STATUS.DIST_OPEN || status === STATUS.HOLD;
export const isSeatNotSold = (status) => !(status === STATUS.SOLD);
export const isEventPath = (path) => /\/event.*/.test(path);
export const isCustomerPath = (path) => /\/customers.*/.test(path);
export const isReportPath = (path) => /\/reports.*/.test(path);
export const isCartExpired = (data) => {
    var _a;
    if ((_a = data === null || data === void 0 ? void 0 : data.errors) === null || _a === void 0 ? void 0 : _a.length) {
        return (data.errors.find((err) => { var _a; return (err === null || err === void 0 ? void 0 : err.code) === INVENTORY_ERROR && ((_a = err === null || err === void 0 ? void 0 : err.details) === null || _a === void 0 ? void 0 : _a.inventorySpecificCode) === CART_HAS_EXPIRED_INVENTORY_MASK; }) !== undefined);
    }
    return false;
};
