import { jsx as _jsx } from "react/jsx-runtime";
import { Component } from 'react';
import { RootDocumentContext } from '../../../context/root-document';
export default (WrappedComponent) => { var _a; return _a = class extends Component {
        constructor() {
            super();
            this.setNode = (node) => {
                this.node = node;
            };
            this.openDrop = (bool) => {
                const { rootDocument } = this.context;
                if (bool && !this.isListening) {
                    rootDocument.addEventListener('mouseup', this.handleClickOutside, true);
                }
                else if (!bool) {
                    rootDocument.removeEventListener('mouseup', this.handleClickOutside, true);
                }
                this.state.isListening = bool;
                this.setState({ clickedOutside: false });
            };
            this.isClickedOutside = (event) => {
                const domNode = this.node;
                return !domNode || !event.target || (domNode !== event.target && !domNode.contains(event.target));
            };
            this.handleClickOutside = (event) => {
                this.setState({ clickedOutside: this.isClickedOutside(event) });
            };
            this.state = {
                clickedOutside: false,
                isListening: false,
            };
            this.handleClickOutside = this.handleClickOutside.bind(this);
        }
        componentDidMount() {
            this.state.isListening = false;
            if (this.state && this.state.isOpen) {
                const { rootDocument } = this.context;
                rootDocument.addEventListener('mouseup', this.handleClickOutside, true);
                this.state.isListening = true;
            }
        }
        componentWillUnmount() {
            const { rootDocument } = this.context;
            rootDocument.removeEventListener('mouseup', this.handleClickOutside, true);
            this.state.isListening = false;
        }
        render() {
            return (_jsx("div", { ref: this.setNode, children: _jsx(WrappedComponent, { openDrop: this.openDrop, clickedOutside: this.state.clickedOutside, ...this.props }) }));
        }
    },
    _a.contextType = RootDocumentContext,
    _a; };
