import { SYSTEM_ID } from '../../constants';
import { formatDatesAndTimes } from '../../utils/dateUtils';
export const dateEntriesModifier = (item, locale) => {
    let city = '';
    let state = '';
    let mapId;
    const venue = { name: '', type: '' };
    const { id, name = '', inventoryIds, hostName, eventCode, rotatingEntryTokenEnabled, terminalId = '', endDate, } = item;
    if (item.location) {
        city = item.location.city || '';
        state = item.location.state || '';
        if (item.location.venue) {
            venue.name = item.location.venue.name || '';
            mapId = item.location.venue.mapId;
        }
    }
    if (venue.name) {
        city = city && `, ${city}`;
    }
    if (venue.name || city) {
        state = state && `, ${state}`;
    }
    let modifiedItem = {
        id,
        inventoryIds,
        hostName,
        eventCode,
        mapId,
        name,
        venue,
        rotatingEntryTokenEnabled,
        url: `/event/${id}`,
        location: `${venue.name}${city}${state}`,
        time: '',
        dateNumb: '',
        dateMonth: '',
        weekday: '',
        fullDateShortMonth: '',
        terminalId,
        endDate,
    };
    if (item.date && item.location) {
        const dateFormatted = formatDatesAndTimes(item.date, item.location.localTimeUTCOffsetMinutes, locale);
        modifiedItem = {
            ...modifiedItem,
            ...dateFormatted,
        };
    }
    return modifiedItem;
};
export const getInventoryTypeByEventId = (eventId) => {
    const splitEventId = eventId.toUpperCase().split('-');
    const arxId = splitEventId[splitEventId.indexOf('ARX') + 1];
    return arxId ? SYSTEM_ID.ARCHTICS : SYSTEM_ID.HOST;
};
export const getInventoryEventId = (eventId) => {
    const inventoryType = getInventoryTypeByEventId(eventId);
    const splitEventId = eventId.toUpperCase().split('-');
    return splitEventId[splitEventId.indexOf(inventoryType) + 1];
};
