import IconADA from '@ticketmaster/tm1pos-web-shared/components/icons/IconAda';
import IconDelete from '@ticketmaster/tm1pos-web-shared/components/icons/IconDelete';
import ToggleArrow from '@ticketmaster/tm1pos-web-shared/components/ToggleArrow';
import { currencyFormatOptions } from '@ticketmaster/tm1pos-web-shared/constants';
import { formatFeeName } from '@ticketmaster/tm1pos-web-shared/fees/fees-taxes-messages';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { feePricing } from '../../../../proptypes-constants';
import messages, { getAdaTypeMessageDescriptor } from '../../messages';

import './styles.scss';

class ShoppingBagItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFee: false,
    };
  }

  toggleFee = () => {
    this.setState((prevState) => ({
      showFee: !prevState.showFee,
    }));
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  render() {
    let adaNotes = null;
    const {
      adaType,
      isDeselectingSeat,
      placeId,
      removingSeatPlacedId,
      GA,
      promotion,
      tickettype,
      row,
      seat,
      refCb,
      section,
      pricing,
      deSelectSeatAction,
      allowDelete,
      isBestAvailable,
      isOrderUnpaid,
      sellClass = { name: '' },
    } = this.props;

    if (adaType) {
      const adaMessage = getAdaTypeMessageDescriptor(adaType.name);
      adaNotes = adaMessage ? (
        <div className="sdr-shopping-bag-item__details--ada">
          <IconADA />
          <FormattedMessage {...adaMessage} />
        </div>
      ) : null;
    }
    const price = pricing.total;
    const { feesAndTaxes, feesAndTaxesBreakdown, faceValue } = pricing;
    const ifShow = isDeselectingSeat || placeId !== removingSeatPlacedId;
    const showFeeTitle = !!feesAndTaxesBreakdown.length || !!feesAndTaxes.amount;
    const errorMessage = classNames('sdr-shopping-bag-item__error', {
      'sdr-shopping-bag-item__hidden': ifShow,
    });
    const ticketType = classNames('sdr-shopping-bag-item__ticketType', {
      'sdr-shopping-bag-item__ticketType_GA': GA,
    });
    let ticketTypeCombine = promotion
      ? `${tickettype} ${GA ? '- GA' : ''}(${promotion})`
      : `${tickettype}${GA ? ' - GA' : ''}`;

    ticketTypeCombine = sellClass.name ? `${ticketTypeCombine} (${sellClass.name})` : ticketTypeCombine;
    const rowDOM = (
      <div className="sdr-shopping-bag-item__row">
        <FormattedMessage
          {...messages.bagItemRow}
          values={{ rowName: <div className="sdr-shopping-bag-item__row--numb">{row}</div> }}
        />
      </div>
    );
    const seatDOM = (
      <div className="sdr-shopping-bag-item__seat">
        <FormattedMessage
          {...messages.bagItemSeat}
          values={{ seatName: <div className="sdr-shopping-bag-item__seat--numb">{seat}</div> }}
        />
      </div>
    );
    const feeTitleClassName = classNames('sdr-shopping-bag-item__fees__title', {
      'sdr-shopping-bag-item__fees__title--pointer': !!feesAndTaxesBreakdown.length,
    });

    const deleteClassName = classNames('sdr-shopping-bag-item__delete', {
      'sdr-shopping-bag-item__delete--hidden': isOrderUnpaid,
    });
    return (
      <div className="sdr-shopping-bag-item-wrap">
        <div className="sdr-shopping-bag-item" ref={refCb || null}>
          <div className="sdr-shopping-bag-item__details--seat">
            <div className="sdr-shopping-bag-item__section">
              <FormattedMessage
                {...messages.bagItemSection}
                values={{ sectionName: <div className="sdr-shopping-bag-item__section--numb">{section}</div> }}
              />
            </div>
            {!GA ? rowDOM : null}
            {!GA ? seatDOM : null}
            {allowDelete ? (
              <a
                tabIndex={-1}
                className={deleteClassName}
                onClick={() => deSelectSeatAction(placeId, GA, section, isBestAvailable)}
              >
                <IconDelete width="14px" height="14px" />
              </a>
            ) : null}
          </div>
          <div className="sdr-shopping-bag-item__details--pricing">
            <div className="sdr-shopping-bag-item__total">
              <div className={ticketType}>{ticketTypeCombine}</div>
              <div className="sdr-shopping-bag-item__price">
                <FormattedNumber value={faceValue.amount} currency={faceValue.currency} {...currencyFormatOptions} />
              </div>
            </div>
            {showFeeTitle ? (
              <div className="sdr-shopping-bag-item__fees">
                <div className={feeTitleClassName} onClick={feesAndTaxesBreakdown.length ? this.toggleFee : null}>
                  <FormattedMessage {...messages.feesAndTaxes} />
                  {feesAndTaxesBreakdown.length ? (
                    <ToggleArrow open={this.state.showFee} width="8px" height="8px" />
                  ) : null}
                </div>
                <div className="sdr-shopping-bag-item__fees__price">
                  <FormattedNumber
                    value={feesAndTaxes.amount}
                    currency={feesAndTaxes.currency}
                    {...currencyFormatOptions}
                  />
                </div>
              </div>
            ) : null}
            {this.state.showFee ? (
              <div className="sdr-shopping-bag-item__fees__list">
                {feesAndTaxesBreakdown.map((fee) => (
                  <div className="sdr-shopping-bag-item__fees__item">
                    <div>
                      <FormattedMessage {...formatFeeName(fee.name)} />
                    </div>
                    <div>
                      <FormattedNumber
                        value={fee.amount.amount}
                        currency={fee.amount.currency}
                        {...currencyFormatOptions}
                      />
                    </div>
                  </div>
                ))}
                <div className="sdr-shopping-bag-item__fees__total">
                  <div className="sdr-shopping-bag-item__fees__item">
                    <FormattedMessage {...messages.bagTotal} />
                    <FormattedNumber value={price.amount} currency={price.currency} {...currencyFormatOptions} />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          {adaNotes}
        </div>
        <div className={errorMessage}>
          <FormattedMessage {...messages.failToDeselect} />
        </div>
      </div>
    );
  }
}

ShoppingBagItem.propTypes = {
  adaType: PropTypes.object,
  section: PropTypes.string,
  row: PropTypes.string,
  seat: PropTypes.string,
  tickettype: PropTypes.string,
  refCb: PropTypes.func,
  GA: PropTypes.bool,
  promotion: PropTypes.string,
  deSelectSeatAction: PropTypes.func,
  placeId: PropTypes.string,
  removingSeatPlacedId: PropTypes.string,
  isDeselectingSeat: PropTypes.bool,
  pricing: feePricing,
  allowDelete: PropTypes.bool,
  isBestAvailable: PropTypes.bool,
  isOrderUnpaid: PropTypes.bool,
  sellClass: PropTypes.object,
};

export default ShoppingBagItem;
