/*
 * Errors Messages
 *
 * This contains all the human text for the error messages visible on the page.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  archticsIdFailMessage: {
    id: 'sales.errors.archticsIdFailMessage',
    defaultMessage: 'You have failed to retrieve an Archtics Id.',
  },
  contactAdministratorTip: {
    id: 'sales.errors.contactAdministratorTip',
    defaultMessage: 'Contact an administrator to check your permissions if you feel this is an error.',
  },
  addSeatToCartErr: {
    id: 'sales.errors.addSeatToCartErr',
    defaultMessage: 'Could not add seats to cart. Try selecting the seats again.',
  },
  addSeatToCartExpired: {
    id: 'sales.errors.addSeatToCartExpired',
    defaultMessage: 'Your seat selections have expired and were removed from the cart.',
  },
  noSeatsAvailableMessage: {
    id: 'sales.errors.noSeatsAvailableMessage',
    defaultMessage: 'No seats available.',
  },
  noSeatsAvailableTip: {
    id: 'sales.errors.noSeatsAvailableTip',
    defaultMessage: 'Contact an administrator to check your permissions if you feel this is an error.',
  },
  noTicketTypesAvailableMessage: {
    id: 'sales.errors.noTicketTypesAvailableMessage',
    defaultMessage: 'No ticket types available.',
  },
  noTicketTypesAvailableTip: {
    id: 'sales.errors.noTicketTypesAvailableTip',
    defaultMessage: 'This event is not on sale or you do not have enough permissions to sell this event.',
  },
  unsupportedEventMessage: {
    id: 'sales.errors.unsupportedEventMessage',
    defaultMessage: 'Unsupported or invalid event',
  },
  unsupportedEventTip: {
    id: 'sales.errors.unsupportedEventTip',
    defaultMessage:
      'Unsupported or invalid event ({specificMessage}). Contact your Ticketmaster representative for support.',
  },
  unsupportedEventDuplicateQualifierTipDetails: {
    id: 'sales.errors.unsupportedEventDuplicateQualifierTipDetails',
    defaultMessage: 'duplicate qualifier states',
  },
  unsupportedEventGranularPricingTipDetails: {
    id: 'sales.errors.unsupportedEventGranularPricingTipDetails',
    defaultMessage: 'granular pricing',
  },
  unsupportedEventTrackingEventTypeTipDetails: {
    id: 'sales.errors.unsupportedEventTrackingEventTypeTipDetails',
    defaultMessage: 'tracking event type',
  },
  ticketTypeNotAvailableMessage: {
    id: 'sales.errors.ticketTypeNotAvailableMessage',
    defaultMessage: 'Invalid combination.',
  },
  ticketTypeNotAvailableTip: {
    id: 'sales.errors.ticketTypeNotAvailableTip',
    defaultMessage:
      'This combination of the inventory and ticket type is currently not available. Change your selection or enable the relevant combination on the ticketing system.',
  },
  edpErrorLoadingEventTitle: {
    id: 'sales.errors.edpErrorLoadingEventTitle',
    defaultMessage: 'The event did not load properly.',
  },
  edpErrorLoadingEventTip: {
    id: 'sales.errors.edpErrorLoadingEventTip',
    defaultMessage: 'Reload the page, or try again later.',
  },
  edpErrorContinueAction: {
    id: 'sales.errors.edpErrorContinueAction',
    defaultMessage: 'Continue',
  },
  edpErrorReloadAction: {
    id: 'sales.errors.edpErrorReloadAction',
    defaultMessage: 'Reload',
  },
  edpErrorBackAction: {
    id: 'sales.errors.edpErrorBackAction',
    defaultMessage: 'Back',
  },
  edpErrorBackToEventsAction: {
    id: 'sales.errors.edpErrorBackToEventsAction',
    defaultMessage: 'Back to Events',
  },
  cardCheckoutFailMainMessage: {
    id: 'sales.errors.cardCheckoutFailMainMessage',
    defaultMessage: 'Credit card is declined.',
  },
  signOutAction: {
    id: 'sales.errors.signOutAction',
    defaultMessage: 'Sign Out',
  },
  manualCardCheckoutFailTipMessage: {
    id: 'sales.errors.manualCardCheckoutFailMessage',
    defaultMessage: 'Try re-entering or using a different credit card or payment method.',
  },
  swipedCardCheckoutFailTipMessage: {
    id: 'sales.errors.swipedCardCheckoutFailMessage',
    defaultMessage: 'Try using a different credit card or payment method.',
  },
  okMessage: {
    id: 'sales.errors.okMessage',
    defaultMessage: 'Ok',
  },
  ismMapUpdateFail: {
    id: 'sales.errors.ismMapLoadFail',
    defaultMessage: 'The event map is not updated. Try refreshing or use "Best Available".',
  },
  swipeCardErrorMessage: {
    id: 'sales.errors.swipeCardErrorMessage',
    defaultMessage: 'Could not read the credit card.',
  },
  swipeCardErrorTip: {
    id: 'sales.errors.swipeCardErrorTip',
    defaultMessage: 'Try swiping the card again, enter the card manually or use another card or payment method.',
  },
  wrongPaymentTypeErrorMessage: {
    id: 'sales.errors.wrongPaymentTypeErrorMessage',
    defaultMessage: 'Payment type not allowed.',
  },
  wrongPaymentTypeErrorTip: {
    id: 'sales.errors.wrongPaymentTypeErrorTip',
    defaultMessage: 'Contact an administrator if you feel this is an error.',
  },
  cartWrongStateErrorMessage: {
    id: 'sales.errors.cartWrongStateErrorMessage',
    defaultMessage: 'Cart error',
  },
  cartWrongStateErrorTip: {
    id: 'sales.errors.cartWrongStateErrorTip',
    defaultMessage: 'The seats you selected could not be retrieved. Try selecting the seats again.',
  },
  cartSplitOrderNotSupportedErrorMessage: {
    id: 'sales.errors.cartSplitOrderNotSupportedErrorMessage',
    defaultMessage: 'Cart error',
  },
  cartSplitOrderNotSupportedErrorTip: {
    id: 'sales.errors.cartSplitOrderNotSupportedErrorTip',
    defaultMessage:
      'Unable to find {ticketQuantity} consecutive seats (max. available is {reservedTicketQuantity}). Try again with fewer tickets.',
  },
  defaultCheckoutErrorMessage: {
    id: 'sales.errors.defaultCheckoutErrorMessage',
    defaultMessage: 'Unable to complete checkout.',
  },
  smsDeliveryFailMainMessage: {
    id: 'sales.errors.smsDeliveryFailMainMessage',
    defaultMessage: 'Unable to deliver via SMS',
  },
  printingFailMainMessage: {
    id: 'sales.errors.printingFailMainMessage',
    defaultMessage: 'Unable to print',
  },
  emailFailMainMessage: {
    id: 'sales.errors.emailFailMainMessage',
    defaultMessage: 'Unable to send email',
  },
  genericDeliveryFailTipMessage: {
    id: 'sales.errors.genericDeliveryFailTipMessage',
    defaultMessage: 'Please redeliver the tickets from the ticketing system.',
  },
  noBarcodeDeliveryFailTipMessage: {
    id: 'sales.errors.noBarcodeDeliveryFailTipMessage',
    defaultMessage:
      'The tickets could not be delivered because the event has not been barcoded. Please check the event configuration.',
  },
  invalidCartIdMessage: {
    id: 'sales.errors.invalidCartIdMessage',
    defaultMessage: 'The seats you selected are no longer available.',
  },
  companionErrorTitle: {
    id: 'sales.errors.companionErrorTitle',
    defaultMessage: 'Can’t add to cart.',
  },
  companionErrorContent: {
    id: 'sales.errors.companionErrorContent',
    defaultMessage: 'Please select another type of Accessible seat before selecting a companion seat.',
  },
  confirmDeleteOrderMessage: {
    id: 'sales.errors.confirmDeleteOrderMessage',
    defaultMessage: 'In order to cancel the order #{orderId}, seats must be assigned back to a single inventory type.',
  },
  backToCheckout: {
    id: 'sales.errors.backToCheckout',
    defaultMessage: 'Back to Checkout',
  },
  loseAllPaymentDataTitle: {
    id: 'sales.errors.loseAllPaymentDataTitle',
    defaultMessage: 'Lose All Payment Data',
  },
  loseAllPaymentDataContent: {
    id: 'sales.errors.loseAllPaymentDataContent',
    defaultMessage:
      'By switching back to single payment method, you will lose all your payment data. Do you want to continue to single payment?',
  },
  permissionsFailMessage: {
    id: 'sales.errors.permissionsFailMessage',
    defaultMessage: 'Unable to fetch user permissions',
  },
  permissionsFailTipMessage: {
    id: 'sales.errors.permissionsFailTipMessage',
    defaultMessage:
      'Please try reloading this page. If the problem persists, contact your Ticketmaster representative.',
  },
  multipleDataSourcesPermissionFailMessage: {
    id: 'sales.errors.multipleDataSourcesPermissionFailMessage',
    defaultMessage: 'User Configuration Error',
  },
  multipleDataSourcesPermissionFailTipMessage: {
    id: 'sales.errors.multipleDataSourcesPermissionFailTipMessage',
    defaultMessage:
      'Your user account is assigned to multiple regions, resulting in an error. Please contact your Ticketmaster representative to verify the region assigned to your user account.',
  },
  invalidCardTypeMessage: {
    id: 'sales.errors.invalidCardTypeMessage',
    defaultMessage:
      'Card type could not be recognized. Please ensure that you have entered the card number correctly and that this type of card is supported',
  },
});
