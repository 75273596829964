import { AnyAction, createSelector } from '@reduxjs/toolkit';
import SharedErrorPage from '@ticketmaster/tm1pos-web-shared/containers/ErrorPage';
import { selectIsUserUnauthorized } from '@ticketmaster/tm1pos-web-shared/store/selectors/user-selector';
import { connect } from 'react-redux';
import { USER_TIMEOUT } from '../../actions-constants';

type Props = {
  dispatch: (action: AnyAction) => void;
  isUserUnauthorized?: boolean;
};

export const ErrorPage = ({ dispatch, isUserUnauthorized = false }: Props) => {
  const handleLogoutButtonClick = () => {
    dispatch({ type: USER_TIMEOUT });
  };

  return (
    <SharedErrorPage logoutClickHandler={handleLogoutButtonClick} isUserUnauthorized={isUserUnauthorized ?? false} />
  );
};

const mapStateToProps = createSelector(selectIsUserUnauthorized, (isUserUnauthorized) => ({
  isUserUnauthorized,
}));
const mapDispatchToProps = (dispatch: any) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(ErrorPage);
