export class ForageUtilsBase {
    constructor(store) {
        this.store = store;
    }
    getItem(key) {
        return this.store.getItem(key);
    }
    setItem(key, value) {
        return this.store.setItem(key, value);
    }
    removeItem(key) {
        return this.store.removeItem(key);
    }
    clearDb() {
        return this.store.clear();
    }
}
