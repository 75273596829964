import { defineMessages } from 'react-intl';
export default defineMessages({
    default_header: {
        id: 'shared.ErrorPage.header',
        defaultMessage: 'Something went wrong!',
    },
    default_tip: {
        id: 'shared.ErrorPage.tip',
        defaultMessage: 'Try signing in again.',
    },
    unauthorized_header: {
        id: 'shared.ErrorPage.header.unauthorized',
        defaultMessage: 'Unauthorized Access',
    },
    unauthorized_tip: {
        id: 'shared.ErrorPage.tip.unauthorized',
        defaultMessage: 'You do not have the necessary permissions to access this application.\nIf you require access, contact your administrator.',
    },
    reload: {
        id: 'shared.ErrorPage.reload',
        defaultMessage: 'Sign In Again',
    },
});
