import { createSelector } from '@reduxjs/toolkit';
import {
  selectEmvPaymentInProgress,
  selectEmvPaymentModuleState,
  selectEmvPaymentStartInProgress,
} from '@ticketmaster/tm1pos-web-shared/payment/emvPaymentModule-selectors';
import emvPaymentModuleMessages from '@ticketmaster/tm1pos-web-shared/payment/payment-messages';
import emvmessages from '../App/messages';
import { selectCurrentEvent } from '../EventDetailPage/selectors/main';
import { CARD, PIX } from './components/Form/constants';
import { selectCheckoutMutationInProgress, selectFormToggles, selectTerminalId } from './components/Form/selectors';

export const selectIsCardPayment = createSelector(selectFormToggles, (formToggles) =>
  formToggles ? formToggles.paymentMethod === CARD || formToggles.paymentMethod === PIX : false,
);

export const selectIfShowEMVLoading = createSelector(
  selectIsCardPayment,
  selectCheckoutMutationInProgress,
  selectEmvPaymentInProgress,
  selectEmvPaymentStartInProgress,
  (isCardPayment, checkoutMutationInProgress, isTransactionInProgress, isTransactionStartInProgress) =>
    (isTransactionInProgress || isTransactionStartInProgress) && isCardPayment && !checkoutMutationInProgress,
);

export const selectEMVSetupError = createSelector(
  selectCurrentEvent,
  selectTerminalId,
  selectEmvPaymentModuleState,
  (currentEvent, terminalId, emvPaymentModuleState) => {
    if (emvPaymentModuleState.isEmvSupported) {
      if (!currentEvent.isHost && !terminalId) {
        return { message: emvmessages.EMVTerminalIdMissingMsg, variant: 'alert' };
      }
      if (emvPaymentModuleState.rules.shouldSelectDeviceFromList && !emvPaymentModuleState.devices.length) {
        return { message: emvPaymentModuleMessages.paymentDeviceListEmptyError, variant: 'alert' };
      }
    }
    return null;
  },
);

export const selectIsEmvPayment = createSelector(
  selectEmvPaymentModuleState,
  selectIsCardPayment,
  (emvPaymentModuleState, isCardPayment) => emvPaymentModuleState.isEmvSupported && isCardPayment,
);
