import TmdsClient from '@ticketmaster/tm1pos-tmds-client';
import { call, put } from 'redux-saga/effects';
import { clearTransactionState, setEmvMode, setEmvSupported } from './emvPaymentModule-slice';
import { EMVService } from './EMVService';
import { tmdsPaymentActor, TmdsPaymentsMachineEvents } from './tmds/tmdsPaymentService';
class TmdsPaymentModule {
    *isAvailable(permissions) {
        yield call(TmdsClient.getTmdsDeviceDetails);
        return permissions.canUseTmdsPayment && TmdsClient.hasPaymentDevice;
    }
    *initialize() {
        yield put(setEmvMode(EMVService.Tmds));
        yield put(setEmvSupported(true));
    }
    acknowledgeTransaction() {
        tmdsPaymentActor.send({ type: TmdsPaymentsMachineEvents.ACKNOWLEDGE });
    }
    reverseTransaction() {
        tmdsPaymentActor.send({ type: TmdsPaymentsMachineEvents.ROLLBACK_INIT });
    }
    processTransaction(action) {
        tmdsPaymentActor.send({ type: TmdsPaymentsMachineEvents.PAY_INIT, ...action });
    }
    *clearTransaction() {
        yield call(clearTmdsTransaction);
    }
    fetchPaymentDevices() { }
    pollTransactionStatus() { }
    cancelTransaction() {
        tmdsPaymentActor.send({ type: TmdsPaymentsMachineEvents.CANCEL });
    }
}
export function* clearTmdsTransaction() {
    yield call(TmdsClient.clearPaymentData);
    yield put(clearTransactionState());
}
export default new TmdsPaymentModule();
