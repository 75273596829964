import { ADAType } from '../model/ADAType';
export const mapADATypeFromString = (value) => {
    switch (value === null || value === void 0 ? void 0 : value.toUpperCase()) {
        case 'COMPANION':
        case 'DOQ-COMPANION':
        case 'D1Q-COMPAN':
        case 'DDQ-COMPAN':
        case '2 WHEELCHAIR COMPAN':
        case 'DHQ-COMPAN':
        case 'COMPANION SEAT':
            return ADAType.COMPANION;
        case 'MOBILITY':
        case 'MOBILITY IMPAIRED SEAT':
        case 'LIMITED MOBILITY':
        case 'DOQ-LIMITED MOBILITY':
        case '2 TRANSFER WC':
            return ADAType.MOBILITY;
        case 'SIGHT/HEARING':
        case 'SIGHT/HEARING IMPAIRED SEAT':
        case 'SIGHT HEARING IM':
        case 'SIGHT IMPAIRED':
        case 'DOQ-SIGHT/HEARING':
        case '2 DISABLED':
        case '2 SIGHT IMPAIRED':
        case '2 HEARING IMPAIRED':
            return ADAType.SIGHT_HEARING;
        case 'SIGHT':
            return ADAType.SIGHT;
        case 'HEARING':
            return ADAType.HEARING;
        case 'WHEELCHAIR':
        case '2 WCHAIR KILLED':
        case 'ACCESSIBLE':
        case 'DOQ-WHEELCHAIR':
        case 'SCOOTER':
        case 'D1Q-WCACC':
        case 'DDQ-WCACC':
        case 'DHQ-WCACC':
        case 'D2Q-WCACC':
        case 'WHEELCHAIR ACCESSIBLE SEAT':
            return ADAType.WHEELCHAIR;
        case 'NOT-SPECIFIED':
            return ADAType.UNSPECIFIED;
        case 'AMBULANT':
            return ADAType.AMBULANT;
        default:
            return null;
    }
};
