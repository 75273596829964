import { delay } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { featureFlagsLoaded, saveFeatureFlags } from '../../feature-flags/feature-flags-slice';
import { getUserInformation } from '../../services/sales-api-client/sales-api-system';
import { fetchHostPermissionsError, fetchUserArchticsIdFailed, fetchUserInformationFailed, fetchUserInformationLoading, fetchUserInformationSuccess, } from './user.slice';
const hasArchticsFetchError = (errors) => errors.map((err) => err.code).includes('AAPI_USER_FETCH_ERROR');
const hasMultiDataSourceError = (errors) => errors.map((err) => err.code).includes('MULTIPLE_DATASOURCE_FROM_OPCODES');
const hasPermissionsFetchError = (response) => {
    const unknownPermissions = Object.keys(response.permissions).length === 0;
    const emptyHostPermissions = (Object.keys(response.permissions.host || {}).length || 0) === 0;
    const emptyArchticsPermissions = !response.permissions.archtics;
    return unknownPermissions || (emptyHostPermissions && emptyArchticsPermissions);
};
const hasUnauthorizedError = (errors) => !!errors.find((error) => error.code === 'UNAUTHORIZED_ACCESS');
export function* startFetchingUserInformation(applicationName, cacheUserInfo = true) {
    let errors = [];
    const onError = (salesApiErrors) => {
        errors = salesApiErrors;
    };
    yield put(fetchUserInformationLoading());
    try {
        const response = yield call(getUserInformation, applicationName, cacheUserInfo, onError);
        yield put(saveFeatureFlags(response.enabledFeatureFlags));
        yield put(featureFlagsLoaded());
        yield put(fetchUserInformationSuccess(response));
        yield call(handleErrorsIfAny, response, errors);
    }
    catch (_error) {
        yield put(fetchUserInformationFailed({ isUserUnauthorized: hasUnauthorizedError(errors) }));
    }
}
export function* handleErrorsIfAny(response, errors) {
    yield call(delay, 0);
    if (hasArchticsFetchError(errors)) {
        yield put(fetchUserArchticsIdFailed());
    }
    if (hasPermissionsFetchError(response)) {
        if (hasMultiDataSourceError(errors)) {
            yield put({ type: 'MULTIPLE_DATASOURCE_FROM_OPCODES' });
        }
        else {
            yield put(fetchHostPermissionsError());
        }
    }
}
