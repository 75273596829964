import { Banner } from '@ticketmaster/aurora';
import { PaymentDevices } from '@ticketmaster/tm1pos-web-shared/payment/emvPaymentModule-slice';
import { InjectedIntl, injectIntl } from 'react-intl';
import './EMVPaymentForm.scss';
import ManualCardEntry, { Props as ManualCardEntryProps } from '../RenderPaymentForm/ManualCardEntry';
import PaymentDeviceList from './PaymentDeviceList';
import MessageDescriptor = ReactIntl.FormattedMessage.MessageDescriptor;

type InjectedProps = {
  intl: InjectedIntl;
};
export type Props = {
  paymentDevices: PaymentDevices;
  selectedDeviceId?: string | null;
  setupError: { message: MessageDescriptor; variant: string } | null;
  showDeviceList: boolean;
  onRefresh: () => void;
  onPaymentDeviceSelected: (deviceId: string) => void;
  manualCardEntry: ManualCardEntryProps;
  showManualCardEntry: boolean;
};
const EMVPaymentForm = ({
  paymentDevices,
  onPaymentDeviceSelected,
  selectedDeviceId,
  setupError,
  showDeviceList,
  onRefresh,
  manualCardEntry,
  showManualCardEntry,
  intl,
}: InjectedProps & Props) => (
  <div className="card-entry__wrapper">
    <div>
      {setupError && (
        <div className="sdr-checkout__payment-emv-fail" role="alert">
          <Banner
            heading={intl.formatMessage(setupError.message)}
            isOpen
            variant={setupError.variant ? setupError.variant : 'error'}
          />
        </div>
      )}
      {showDeviceList && (
        <PaymentDeviceList
          selectedDeviceId={selectedDeviceId}
          paymentDevices={paymentDevices}
          onPaymentDeviceSelected={onPaymentDeviceSelected}
          onRefresh={onRefresh}
        />
      )}
    </div>
    {showManualCardEntry ? <ManualCardEntry {...manualCardEntry} /> : undefined}
  </div>
);
export default injectIntl(EMVPaymentForm);
