import {
  ADA_FILTER_LIST_CHECKED_CHANGE,
  ADA_FILTER_LIST_TOGGLE,
  ADD_GA_WITH_SECTIONS,
  ARCHTICS_EVENT_SEAT_STATUS,
  ARCHTICS_EVENT_SEAT_STATUS_FETCH,
  ARCHTICS_EVENT_SEAT_STATUS_UPDATE,
  ARCHTICS_SECTION_SEAT_SUMMARY_FETCH,
  BEST_AVAILABLE_PRICE_MODAL_TOGGLE,
  BEST_AVAILABLE_SEATS,
  ERROR_EVENT_DETAIL,
  EVENT_AVAILABILITY,
  EVENT_DETAILS,
  EVENT_ID_SET,
  FETCH_BEST_AVAILABLE_SEATS,
  FETCH_EVENT_AVAILABILITY,
  FETCH_EVENT_DETAILS,
  INITIAL_ARCHTICS_EVENT_SEAT_STATUS_FETCH,
  ISM_TOKEN,
  ISM_TOKEN_FETCH,
  PLACE_TO_RESERVE_DESELECT,
  PLACE_TO_RESERVE_FETCH,
  PLACE_TO_RESERVE_SELECT,
  PLACE_TO_UNRESERVE_FETCH,
  PLACE_TOTALS_SET,
  SEARCH_TICKET_TYPES_AND_OFFERS,
  SEAT_DATA_LOAD,
  SEAT_DATA_LOAD_FETCH,
  UPDATE_ACTIVE_PRICE_LEVELS,
  UPDATE_ACTIVE_PRICE_RANGE,
  UPDATE_ACTIVE_TICKET_TYPE,
  UPDATE_TICKETS_QUANTITY,
} from './constants';

export function setEventId(eventId) {
  return {
    type: EVENT_ID_SET,
    eventId,
  };
}

export function modifyADAList(name, remove = false) {
  return {
    type: ADA_FILTER_LIST_CHECKED_CHANGE,
    name,
    remove,
  };
}

export function toggleADAFilter(bool) {
  return {
    type: ADA_FILTER_LIST_TOGGLE,
    isActive: bool,
  };
}

export function toggleBestAvailPrice() {
  return {
    type: BEST_AVAILABLE_PRICE_MODAL_TOGGLE,
  };
}

export function loadSeatData() {
  return {
    type: SEAT_DATA_LOAD,
    ...SEAT_DATA_LOAD_FETCH,
  };
}

export function loadArchticsSeatStatus(eventId, user = {}) {
  return {
    type: ARCHTICS_EVENT_SEAT_STATUS,
    eventId,
    user,
    ARCHTICS_EVENT_SEAT_STATUS_FETCH,
    ARCHTICS_SECTION_SEAT_SUMMARY_FETCH,
  };
}

export function deselectPlaceToUnReserve({ place }) {
  return {
    type: PLACE_TO_RESERVE_DESELECT,
    place,
    ...PLACE_TO_UNRESERVE_FETCH,
  };
}

export function selectPlaceToReserve({ place }) {
  return {
    type: PLACE_TO_RESERVE_SELECT,
    place,
    // selected,
    ...PLACE_TO_RESERVE_FETCH,
  };
}

export function selectedGASectionADDToList({ sec }) {
  return {
    type: ADD_GA_WITH_SECTIONS,
    sec,
  };
}

export function setTotalIsmPlaces(total) {
  return {
    type: PLACE_TOTALS_SET,
    total,
  };
}

export function updateArchticsSeatStatus(eventId, isInitial) {
  return {
    type: ARCHTICS_EVENT_SEAT_STATUS_UPDATE,
    eventId,
    isInitial,
    ARCHTICS_EVENT_SEAT_STATUS_FETCH: isInitial
      ? INITIAL_ARCHTICS_EVENT_SEAT_STATUS_FETCH
      : ARCHTICS_EVENT_SEAT_STATUS_FETCH,
  };
}

export function getIsmApiToken(eventId, user = {}) {
  return {
    type: ISM_TOKEN,
    eventId,
    user,
    ...ISM_TOKEN_FETCH,
  };
}

export function errorEventDetail(error) {
  return {
    type: ERROR_EVENT_DETAIL,
    error,
  };
}

export function getAvailability() {
  return {
    type: EVENT_AVAILABILITY,
    ...FETCH_EVENT_AVAILABILITY,
  };
}

export function getEventDetail() {
  return {
    type: EVENT_DETAILS,
    ...FETCH_EVENT_DETAILS,
  };
}

export function updateActivePriceRange(rangesArr, deSelect) {
  return {
    deSelect,
    type: UPDATE_ACTIVE_PRICE_RANGE,
    ranges: rangesArr,
  };
}

export function updateActivePriceLevels(levels) {
  return {
    type: UPDATE_ACTIVE_PRICE_LEVELS,
    levels,
  };
}

export function updateActiveTicketType(currentTicketType) {
  return {
    type: UPDATE_ACTIVE_TICKET_TYPE,
    currentTicketType,
  };
}

export function findBestAvailableSeats() {
  return {
    type: BEST_AVAILABLE_SEATS,
    ...FETCH_BEST_AVAILABLE_SEATS,
  };
}

export function updateTicketQty(ticketQty) {
  return {
    type: UPDATE_TICKETS_QUANTITY,
    value: ticketQty,
  };
}

export function searchTicketTypeOffer(value) {
  return {
    type: SEARCH_TICKET_TYPES_AND_OFFERS,
    value,
  };
}
