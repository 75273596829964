import { errorCodes } from '../constants';
export const configureRetryAttempts = (attempts, condition = () => false) => (data, tries) => {
    if (tries > attempts) {
        return false;
    }
    return condition(data);
};
export const opcodeCondition = (data) => {
    if (data && data.errors && data.errors.length) {
        return data.errors.find((err) => err.code === errorCodes.OPCODE_LOCK) !== undefined;
    }
    return false;
};
export const faultyConnectionCondition = (data) => { var _a; return (_a = data === null || data === void 0 ? void 0 : data.errors) === null || _a === void 0 ? void 0 : _a.some((err) => err.extensions.errorCode === errorCodes.PDG_FAULTY_CONNECTION); };
const faultTolerance = {
    retryIfOpcodeLock: configureRetryAttempts(3, opcodeCondition),
    retryPaymentIfFaultyConnection: configureRetryAttempts(3, faultyConnectionCondition),
};
export default faultTolerance;
