import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { PrinterService } from '../../../PrinterService';
import DropdownPrinterDrivers from '../Inputs/DropdownPrinterDrivers';
import DropdownPrinterList from '../Inputs/DropdownPrinterList';
import '../EditPrinterConfiguration.scss';
export const LocalQzForm = ({ printers, defaultPrinter, onChangePrinter, onRefreshPrinter, connectionError, loading, printerServiceRefreshed, readOnly, showValidationMessage, }) => {
    const [printer, setPrinter] = useState();
    useEffect(() => {
        if (defaultPrinter && printerServiceRefreshed) {
            setPrinter(defaultPrinter);
            onChangePrinter(defaultPrinter);
        }
    }, [defaultPrinter, printerServiceRefreshed, setPrinter, onChangePrinter]);
    const handlePrinterChange = useCallback((newPrinter) => {
        setPrinter(newPrinter);
        onChangePrinter(null);
    }, [onChangePrinter]);
    const handleDriverChange = (newDriver) => {
        if (printer) {
            const newPrinter = {
                ...printer,
                ...newDriver,
                printerService: PrinterService.QzTrayLocal,
            };
            setPrinter(newPrinter);
            onChangePrinter(newPrinter);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { children: _jsx(DropdownPrinterList, { hasConnectionError: !!connectionError, disabled: loading || !!connectionError, defaultPrinter: printer, printers: printers, onChange: handlePrinterChange, onRefresh: () => onRefreshPrinter(PrinterService.QzTrayLocal), readOnly: readOnly, showValidationMessage: showValidationMessage }) }), _jsx("div", { children: _jsx(DropdownPrinterDrivers, { defaultDriver: { method: printer === null || printer === void 0 ? void 0 : printer.method, dpi: printer === null || printer === void 0 ? void 0 : printer.dpi }, disabled: !printer, onChange: handleDriverChange, readOnly: readOnly, showValidationMessage: showValidationMessage }) })] }));
};
export default LocalQzForm;
