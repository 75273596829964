import dynamicAction from '@ticketmaster/tm1pos-web-shared/components/utils/DynamicNameHelper';

export const GET_REPORT_DATA = 'GET_REPORT_DATA';
export const UPDATE_REPORT_DATETIME = 'UPDATE_REPORT';
export const SEND_EMAIL_REPORT = 'SEND_EMAIL_REPORT';
export const RESET_REPORT_FORM = 'RESET_REPORT_FORM';
export const CLEAR_FORM_ERROR_STATE = 'CLEAR_FORM_ERROR_STATE';
export const FETCH_EMAIL_REPORT = dynamicAction(SEND_EMAIL_REPORT);
export const FETCH_REPORT = dynamicAction(GET_REPORT_DATA);
export const FETCH_UPDATED_REPORT = dynamicAction(UPDATE_REPORT_DATETIME);
