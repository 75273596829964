import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ButtonComponent from '../components/ButtonComponent';
import ErrorModal from '../components/ErrorModal';
import { IconClose } from '../components/icons';
const errorModalHoc = ({ actionButtons = [], closeButtons = [], ...props }) => ({ dispatch }) => {
    const buttons = actionButtons.map((btn) => {
        const clickHandler = () => {
            if (btn.actionsToDispatchOnClick) {
                btn.actionsToDispatchOnClick.map((action) => dispatch(action));
            }
            if (btn.clickHandler) {
                btn.clickHandler();
            }
        };
        return (_jsx(ButtonComponent, { className: btn.className, onButtonClick: clickHandler, children: _jsx(FormattedMessage, { ...btn.message }) }, btn.message.id));
    });
    const close = closeButtons.map((btn) => {
        const clickHandler = () => {
            if (btn.actionsToDispatchOnClick) {
                btn.actionsToDispatchOnClick.map((action) => dispatch(action));
            }
        };
        return (_jsx("a", { role: "button", onClick: clickHandler, tabIndex: -1, className: "oc-modal__close", children: _jsx(IconClose, {}) }));
    });
    return _jsx(ErrorModal, { ...props, close: close, buttons: buttons });
};
const intlMessagePropType = PropTypes.shape({
    id: PropTypes.string,
    defaultMessage: PropTypes.string,
});
const actionToDispatchPropType = PropTypes.shape({
    type: PropTypes.string.isRequired,
});
errorModalHoc.propTypes = {
    mainMessage: intlMessagePropType,
    tipMessages: PropTypes.arrayOf(intlMessagePropType),
    children: PropTypes.node,
    actionButtons: PropTypes.arrayOf(PropTypes.shape({
        className: PropTypes.string,
        message: intlMessagePropType,
        actionsToDispatchOnClick: PropTypes.arrayOf(actionToDispatchPropType),
        clickHandler: PropTypes.func,
    })),
};
export { errorModalHoc };
