import { LogComponent, LogStatus } from '../../errorHandling/constants';
import { logClientToCloud } from '../../store/actions';
import { emvPaymentError, emvPaymentSuccessful, emvRefundError, emvRefundSuccessful, setError, setFailedReverseTransaction, setReverseTransactionStatus, setStatus, setTransaction, } from '../emvPaymentModule-slice';
import { ReverseTransactionStatus, TMDSError, TransactionStatus } from '../emvPaymentModule-types';
import messages from '../payment-messages';
import { TmdsPaymentsMachineEvents } from './tmdsPaymentMachine';
import { mapPaymentDataFromTMDS } from './tmdsPaymentMapper';
export const handlePaymentResponse = (result, isRefund, dispatch, send) => {
    const isTransactionSuccessful = result.success;
    const paymentDeviceData = result.data;
    if (isTransactionSuccessful) {
        send({ type: TmdsPaymentsMachineEvents.PAY_SUCCESS });
        const successAction = isRefund ? emvRefundSuccessful() : emvPaymentSuccessful();
        dispatch(setTransaction(mapPaymentDataFromTMDS(paymentDeviceData)));
        dispatch(successAction);
    }
    else if (!isTransactionSuccessful) {
        const errorAction = isRefund ? emvRefundError() : emvPaymentError();
        const error = {};
        let status = TransactionStatus.FAILURE;
        let eventType = TmdsPaymentsMachineEvents.PAY_FAILURE;
        switch (paymentDeviceData.error.errorCode) {
            case TMDSError.CANCEL:
                error.errorMessage = isRefund ? messages.cancelEMVRefundWarningMsg : messages.cancelEMVPaymentWarningMsg;
                error.isWarning = true;
                status = TransactionStatus.CANCELLED;
                eventType = TmdsPaymentsMachineEvents.CANCELED;
                break;
            case TMDSError.BAD_SWIPE:
                error.errorMessage = messages.checkoutEMVPaymentBadSwipeErrMsg;
                break;
            case TMDSError.NAK:
                error.errorMessage = messages.nakEMVPaymentWarningMsg;
                break;
            default:
                error.errorMessage = isRefund ? messages.refundEMVRefundErrMsg : messages.checkoutEMVPaymentErrMsg;
                break;
        }
        error.terminalError = paymentDeviceData.error.message;
        dispatch(setStatus(status));
        dispatch(setError(error));
        dispatch(errorAction);
        send({ type: eventType });
    }
};
export const handleRollbackResponse = (result, dispatch, send) => {
    if (result.success) {
        dispatch(setReverseTransactionStatus(ReverseTransactionStatus.SUCCESS));
        send({ type: 'ROLLBACK_SUCCESS' });
    }
    else {
        dispatch(logClientToCloud({
            type: LogComponent.EMV,
            status: LogStatus.ERROR,
            data: { detail: 'failed to rollback TMDS transaction', error: result.error },
        }));
        dispatch(setFailedReverseTransaction(result.error || null));
        send({ type: TmdsPaymentsMachineEvents.ROLLBACK_FAILURE });
    }
};
