import { ErrorInfo } from '@ticketmaster/tm1pos-web-shared/model/graphql';
import { put } from 'redux-saga/effects';
import { CANCEL_CHECKOUT, CHECKOUT_PAYMENT_ERROR } from '../actions-constants';
import { fetchCheckoutError } from './checkout-error-actions';
import { messages } from './checkout-error-messages';
import { checkoutError } from './checkoutError';

export function* handleCheckoutError(errorInfo: ErrorInfo, orderCreatedId?: string) {
  const error = checkoutError(errorInfo.code);

  if (errorInfo.details?.recoveryAction === 'RETRY_PAYMENT_OR_CANCEL_ORDER') {
    yield put({ type: CHECKOUT_PAYMENT_ERROR, orderIdToRetry: orderCreatedId });
    yield put({ type: CANCEL_CHECKOUT });
    error.retryPayment = true;
  }
  if (errorInfo.details?.recoveryAction === 'START_NEW_CART') {
    error.clearCart = true;
  }
  if (errorInfo.details?.backendRecovery === 'INITIATED_REFUND') {
    error.notes = [messages.pendingTransactionWarning, messages.chargeReleaseWarning];
  }
  if (errorInfo.cid) {
    error.cid = errorInfo.cid;
  }

  yield put(fetchCheckoutError(error));
}
