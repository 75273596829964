import TmdsClient, { type PrintDevice } from '@ticketmaster/tm1pos-tmds-client';
import { type Map } from 'immutable';

export const handleTmdsPrinterChange = (values: Map<string, any>, previousValues: Map<string, any>): void => {
  const previousPrinter: PrintDevice | null = previousValues.get('printer')?.toJS
    ? previousValues.get('printer')?.toJS()
    : previousValues.get('printer');
  const newPrinter: PrintDevice | null = values.get('printer')?.toJS
    ? values.get('printer').toJS()
    : values.get('printer');

  if (newPrinter?.id !== previousPrinter?.id) {
    TmdsClient.selectPrinter(newPrinter ?? null);
  }
};
