var _a, _b;
import { createAction, createSlice } from '@reduxjs/toolkit';
import { getUserFromStorage } from '../utils/getUserFromStorage';
import { DEFAULT_LANGUAGE } from './translations-constants';
const initialState = {
    language: ((_b = (_a = getUserFromStorage()) === null || _a === void 0 ? void 0 : _a.profile) === null || _b === void 0 ? void 0 : _b.language) || DEFAULT_LANGUAGE,
    messages: {},
    translationsLoaded: false,
};
const translationsSlice = createSlice({
    name: 'translations',
    initialState,
    reducers: {
        setLanguage: (state, action) => ({
            ...state,
            language: action.payload,
        }),
        setMessages: (state, { payload }) => ({
            ...state,
            translationsLoaded: true,
            messages: payload,
        }),
    },
});
export const loadMessages = createAction(`${translationsSlice.name}/loadMessages`);
export const { setLanguage, setMessages } = translationsSlice.actions;
export const translationsReducer = translationsSlice.reducer;
