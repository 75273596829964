import { jsx as _jsx } from "react/jsx-runtime";
import { DownIcon } from '@ticketmaster/aurora';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './styles.scss';
const defaultWidth = '8px';
const defaultHeight = '8px';
const ToggleArrow = (props) => {
    const toggleClassName = classNames(props.className, {
        'sdr-arrow-toggle': true,
        'sdr-arrow-toggle--open': props.open,
    });
    return (_jsx("div", { className: toggleClassName, "data-testid": "dropdown-icon", children: _jsx(DownIcon, { style: {
                width: props.width || defaultWidth,
                height: props.height || defaultHeight,
            } }) }));
};
ToggleArrow.propTypes = {
    open: PropTypes.bool,
    width: PropTypes.string,
    height: PropTypes.string,
    className: PropTypes.string,
};
export default ToggleArrow;
