export const toUserTrackingInformation = (userProfileInformation) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    return ({
        $email: (_a = userProfileInformation.userTrackingInformation) === null || _a === void 0 ? void 0 : _a.email,
        $last_login: new Date(),
        $name: userProfileInformation.username,
        CDD: ((_b = userProfileInformation.userTrackingInformation) === null || _b === void 0 ? void 0 : _b.cdd) || 'NO_CDD',
        CSM: ((_c = userProfileInformation.userTrackingInformation) === null || _c === void 0 ? void 0 : _c.csm) || 'NO_CSM',
        CSS: ((_d = userProfileInformation.userTrackingInformation) === null || _d === void 0 ? void 0 : _d.css) || 'NO_CSS',
        Lang: userProfileInformation.language,
        Org: userProfileInformation.organizationName,
        Segment: (_e = userProfileInformation.userTrackingInformation) === null || _e === void 0 ? void 0 : _e.segment,
        UserId: userProfileInformation.profileId,
        domainType: (_f = userProfileInformation.userTrackingInformation) === null || _f === void 0 ? void 0 : _f.organizationType,
        orgCountry: userProfileInformation.countryCode,
        orgSubType: (_g = userProfileInformation.userTrackingInformation) === null || _g === void 0 ? void 0 : _g.organizationSubType,
        salesforceOrgId: userProfileInformation.salesforceOrgId || 'NO_salesforceOrgId',
        salesforceUserId: ((_h = userProfileInformation.userTrackingInformation) === null || _h === void 0 ? void 0 : _h.salesforceUserId) || 'NO_salesforceUserId',
    });
};
