import { registerLightstepTracing } from '@ticketmaster/tm1pos-web-shared/middleware/OpenTelemetry/lightstep';
import { selectCurrentEventDetails } from '@ticketmaster/tm1pos-web-shared/store/selectors';
import { selectUserInformation } from '@ticketmaster/tm1pos-web-shared/store/selectors/user-selector';
import { useState } from 'react';
import { useAppSelector } from '../../../../store/hooks';

export const OpenTelemetry = () => {
  const userInformation = useAppSelector(selectUserInformation);
  const eventDetails = useAppSelector(selectCurrentEventDetails);
  const [lightstepTracer, setLightstepTracer] = useState<ReturnType<typeof registerLightstepTracing>>();
  let currentLightstepTracer = lightstepTracer;

  if (!currentLightstepTracer) {
    currentLightstepTracer = registerLightstepTracing();
    setLightstepTracer(currentLightstepTracer);
  }

  if (userInformation) {
    currentLightstepTracer.setUserInformation(userInformation);
  }
  currentLightstepTracer.setEventDetails(eventDetails);

  return null;
};
