/*
 *
 * Form reducers
 *
 */

import { EMAIL, PRINT, SMS } from '@ticketmaster/tm1pos-web-shared/constants';
import { emvPaymentError } from '@ticketmaster/tm1pos-web-shared/payment/emvPaymentModule-slice';
import { fromJS } from 'immutable';
import { actionTypes } from 'redux-form';

import { CANCEL_EMV_CHECKOUT } from '../../../App/actions-constants';
import { checkoutMutationInProgress } from './actions';
import {
  CANCEL_CHECKOUT,
  CHECKOUT_PAYMENT_ERROR,
  CREDIT_CARD_PREPOPULATE_MANUAL,
  FETCH_CHECKOUT,
} from './actions-constants';
import PrintReceipt from './components/PrintReceipt';
import { CARD, CASH, OTHER, PIX, PRINT_RECEIPT_UPDATE_SETTINGS } from './constants';
import { clearCheckoutError } from './errors/checkout-error-actions';

export const initialPaymentValues = {
  accountNumber: '',
  cardHolderName: '',
  expDate: '',
  manualCardEntry: false,
  paymentMethod: CARD,
  isOpen: false,
  attemptedToBePaid: false,
  method: 'AMEX',
  amount: {
    amount: 11.23,
    currency: 'CAD',
  },
  acceptedStatus: false,
  otherPaymentMethod: '',
  paymentInfo: '',
  cashTendered: '',
  changeDue: '',
  type: CARD,
};
const initialState = fromJS({
  values: {
    checkoutInProgress: false,
    checkoutMutationInProgress: false,
    numberToValidate: '',
    deliveryMethod: SMS,
    // Any changes in this object must be followed by an increased version in PRINT_RECEIPT_KEY
    printReceipts: {
      [CARD]: {
        [SMS]: { active: false, quantity: PrintReceipt.printerQuantityProvider[1] },
        [EMAIL]: { active: false, quantity: PrintReceipt.printerQuantityProvider[1] },
        [PRINT]: { active: false, quantity: PrintReceipt.printerQuantityProvider[1] },
      },
      [CASH]: {
        [SMS]: { active: false, quantity: PrintReceipt.printerQuantityProvider[1] },
        [EMAIL]: { active: false, quantity: PrintReceipt.printerQuantityProvider[1] },
        [PRINT]: { active: false, quantity: PrintReceipt.printerQuantityProvider[1] },
      },
      [OTHER]: {
        [SMS]: { active: false, quantity: PrintReceipt.printerQuantityProvider[1] },
        [EMAIL]: { active: false, quantity: PrintReceipt.printerQuantityProvider[1] },
        [PRINT]: { active: false, quantity: PrintReceipt.printerQuantityProvider[1] },
      },
      [PIX]: {
        [SMS]: { active: false, quantity: PrintReceipt.printerQuantityProvider[1] },
        [EMAIL]: { active: false, quantity: PrintReceipt.printerQuantityProvider[1] },
        [PRINT]: { active: false, quantity: PrintReceipt.printerQuantityProvider[1] },
      },
    },
    fullName: '',
    phoneNumber: '',
    email: '',
    linkGlobalAccount: false,
    ...initialPaymentValues,
  },
});
const clearCardData = (state) =>
  state.withMutations((map) => {
    map.setIn(['values', 'accountNumber'], '').setIn(['values', 'cardHolderName'], '').setIn(['values', 'expDate'], '');
  });

const onChange = (state, action) => {
  if (action.meta.form === 'checkout') {
    switch (action.meta.field) {
      case 'linkGlobalAccount':
        return state.setIn(['values', 'linkGlobalAccount'], action.payload);
      case 'manualCardEntry':
        return clearCardData(state);
      case 'printReceiptToggle':
        return state.setIn(
          [
            'values',
            'printReceipts',
            state.getIn(['values', 'paymentMethod']),
            state.getIn(['values', 'deliveryMethod']),
            'active',
          ],
          action.payload,
        );
      case 'printReceiptCount':
        return state.setIn(
          [
            'values',
            'printReceipts',
            state.getIn(['values', 'paymentMethod']),
            state.getIn(['values', 'deliveryMethod']),
            'quantity',
          ],
          action.payload,
        );
      case 'paymentMethod':
        return state.setIn(['values', 'paymentMethod'], action.payload);
      default:
        return state;
    }
  }
  return state;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
function FormReducer(state = initialState, action) {
  switch (action.type) {
    case CREDIT_CARD_PREPOPULATE_MANUAL: // also called during swipe
      return state.withMutations((map) => {
        map
          .setIn(['values', 'accountNumber'], action.accountNumber)
          .setIn(['values', 'cardHolderName'], action.cardHolderName)
          .setIn(['values', 'expDate'], action.expDate);
        if (action.email) {
          map.setIn(['values', 'email'], action.email);
        }
      });
    case actionTypes.CHANGE:
      return onChange(state, action);
    case CHECKOUT_PAYMENT_ERROR:
      return clearCardData(state);
    case FETCH_CHECKOUT.LOADING:
      return state.setIn(['values', 'checkoutInProgress'], true);
    case CANCEL_CHECKOUT:
    case CANCEL_EMV_CHECKOUT:
    case emvPaymentError.type:
      return state.setIn(['values', 'checkoutInProgress'], false);
    case FETCH_CHECKOUT.ERROR:
      return state.setIn(['values', 'checkoutInProgress'], false).setIn(['values', 'error'], action.payload);
    case clearCheckoutError.type:
      return state.deleteIn(['values', 'error']);
    case FETCH_CHECKOUT.SUCCESS:
      return state
        .setIn(['values', 'checkoutInProgress'], false)
        .setIn(['values', 'changeDue'], '')
        .setIn(['values', 'cashTendered'], '');
    case PRINT_RECEIPT_UPDATE_SETTINGS: {
      let newState = state;
      if (action.data) {
        newState = state.setIn(['values', 'printReceipts'], fromJS(action.data));
      }
      return newState;
    }
    case checkoutMutationInProgress.type:
      return state.setIn(['values', 'checkoutMutationInProgress'], action.inProgress);
    default:
      return state;
  }
}

export default FormReducer;
