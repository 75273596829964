import availableLocales from 'cldr-core/availableLocales.json';
import countryPhoneCode from './countryPhoneCode.json';
export const getClosestLocale = (locale) => {
    if (availableLocales.availableLocales.full.includes(locale)) {
        return locale;
    }
    const lastDash = locale.lastIndexOf('-');
    if (lastDash === -1) {
        return 'en';
    }
    return getClosestLocale(locale.substring(0, lastDash));
};
const getLocalizedTerritories = async (locale) => {
    try {
        const closestLocale = getClosestLocale(locale);
        const territoriesForLocale = await import(`cldr-localenames-full/main/${closestLocale}/territories.json`);
        return territoriesForLocale.main[closestLocale].localeDisplayNames.territories;
    }
    catch (_a) {
        const territoriesForLocale = await import('cldr-localenames-full/main/en/territories.json');
        return territoriesForLocale.main.en.localeDisplayNames.territories;
    }
};
export const getCountryData = async (locale) => {
    const territories = await getLocalizedTerritories(locale);
    return countryPhoneCode.map((it) => ({
        ...it,
        name: territories[it.key],
    }));
};
