import mixpanel from 'mixpanel-browser';
import { getEventPlatform } from '../utils';
import { toUserTrackingInformation } from '../utils/converters';
const ANALYTICS_NAME = 'TM1';
export default class MixpanelAnalytics {
    static create(token) {
        return new MixpanelAnalytics(token);
    }
    constructor(token) {
        var _a;
        this.token = token;
        this.getEventProperties = (event) => {
            var _a;
            if ((event === null || event === void 0 ? void 0 : event.id) == null) {
                return {};
            }
            return {
                'TM Event ID': event.id,
                'TM Event Name': event.name,
                'Venue Name': (_a = event.venue) === null || _a === void 0 ? void 0 : _a.name,
                Platform: getEventPlatform(event.id),
            };
        };
        this.getOrderProperties = (order) => {
            if ((order === null || order === void 0 ? void 0 : order.orderId) == null) {
                return {};
            }
            return {
                'Order ID': order.orderId,
            };
        };
        this.analytics = mixpanel.init(this.token, {}, ANALYTICS_NAME);
        (_a = this.analytics) === null || _a === void 0 ? void 0 : _a.reset();
    }
    extendWithBaseData(properties) {
        return {
            ...this.baseData,
            ...properties,
        };
    }
    eventTrack(action, properties, eventDetails, orderDetails) {
        if (this.analytics && action) {
            const extendedProperties = {
                ...this.extendWithBaseData(properties),
                ...this.getEventProperties(eventDetails),
                ...this.getOrderProperties(orderDetails),
            };
            this.analytics.track(action, extendedProperties);
        }
    }
    timeEvent(action) {
        if (this.analytics && action) {
            this.analytics.time_event(action);
        }
    }
    setBaseData(baseData) {
        this.baseData = baseData;
    }
    setUserProperties(userProperties) {
        this.analytics.identify(userProperties.profileId);
        this.analytics.people.set(toUserTrackingInformation(userProperties));
    }
}
