import { defineMessages } from 'react-intl';
const messages = defineMessages({
    printerService_Tmds: {
        id: 'shared.PrintingConfiguration.printerService_Tmds',
        defaultMessage: 'TMDS Printer',
    },
    printerService_QzTrayLocal: {
        id: 'shared.PrintingConfiguration.printerService_QzTrayLocal',
        defaultMessage: 'QZ Tray Local Server',
    },
    printerService_QzTrayRemote: {
        id: 'shared.PrintingConfiguration.printerService_QzTrayRemote',
        defaultMessage: 'QZ Tray Remote Server',
    },
    printerDriver_Boca200: {
        id: 'shared.PrintingConfiguration.printerDriver_Boca200',
        defaultMessage: 'BOCA 200 DPI',
    },
    printerDriver_Boca300: {
        id: 'shared.PrintingConfiguration.printerDriver_Boca300',
        defaultMessage: 'BOCA 300 DPI',
    },
    printerDriver_Png200: {
        id: 'shared.PrintingConfiguration.printerDriver_Png200',
        defaultMessage: 'PNG 200 DPI',
    },
    printerDriver_Png300: {
        id: 'shared.PrintingConfiguration.printerDriver_Png300',
        defaultMessage: 'PNG 300 DPI',
    },
    printerDriver_Fgl: {
        id: 'shared.PrintingConfiguration.printerDriver_Fgl',
        defaultMessage: 'FGL',
    },
    printerDriver_Png: {
        id: 'shared.PrintingConfiguration.printerDriver_Png',
        defaultMessage: 'PNG',
    },
    printerDriver_Pdf: {
        id: 'shared.PrintingConfiguration.printerDriver_Pdf',
        defaultMessage: 'PDF',
    },
    printerDriver_Postscript: {
        id: 'shared.PrintingConfiguration.printerDriver_Postscript',
        defaultMessage: 'Postscript',
    },
    printingServiceLabel: {
        id: 'shared.PrintingConfiguration.printingServiceLabel',
        defaultMessage: 'Print Service',
    },
    printingServicePlaceholder: {
        id: 'shared.PrintingConfiguration.printingServicePlaceholder',
        defaultMessage: 'Select a service',
    },
    printerListLabel: {
        id: 'shared.PrintingConfiguration.printerListLabel',
        defaultMessage: 'Printer Name',
    },
    printerListPlaceholder: {
        id: 'shared.PrintingConfiguration.printerListPlaceholder',
        defaultMessage: 'Select a printer',
    },
    noPrinterFoundError: {
        id: 'shared.PrintingConfiguration.noPrinterFoundError',
        defaultMessage: 'No printer detected on the device.',
    },
    printerDriverLabel: {
        id: 'shared.PrintingConfiguration.printerDriverLabel',
        defaultMessage: 'Printer Format',
    },
    printerDriverPlaceholder: {
        id: 'shared.PrintingConfiguration.printerDriverPlaceholder',
        defaultMessage: 'Select a format',
    },
    printerRemoteServerLabel: {
        id: 'shared.PrintingConfiguration.printerRemoteServerLabel',
        defaultMessage: 'Remote Server (IP Address or Hostname)',
    },
    printerRemoteServerPlaceholder: {
        id: 'shared.PrintingConfiguration.printerRemoteServerPlaceholder',
        defaultMessage: '123.45.67.89:8181',
    },
    printerRemoteServerCheckConnection: {
        id: 'shared.PrintingConfiguration.printerRemoteServerCheckConnection',
        defaultMessage: 'Check Connection',
    },
    printerRemoteServerError: {
        id: 'shared.PrintingConfiguration.printerRemoteServerError',
        defaultMessage: 'Invalid remote server, please use the format IPAddress:port or Hostname:port.',
    },
    noPrinterNameMessage: {
        id: 'shared.PrintingConfiguration.noPrinterNameMessage',
        defaultMessage: 'No printer set yet',
    },
    printerNameLabel: {
        id: 'shared.PrintingConfiguration.printerNameLabel',
        defaultMessage: 'Printing Configuration',
    },
    editPrinterConfigurationButton: {
        id: 'shared.PrintingConfiguration.editPrinterConfigurationButton',
        defaultMessage: 'Edit',
    },
    noPrinterConfiguredMessage: {
        id: 'shared.PrintingConfiguration.noPrinterConfiguredMessage',
        defaultMessage: 'There is no printer configured yet. If you need to print tickets, please set up a printing configuration or choose a different delivery method.',
    },
    savePrinterConfiguration: {
        id: 'shared.PrintingConfiguration.savePrinterConfiguration',
        defaultMessage: 'Save',
    },
    cancelPrinterConfiguration: {
        id: 'shared.PrintingConfiguration.cancelPrinterConfiguration',
        defaultMessage: 'Cancel',
    },
    printerConfigurationModalTitle: {
        id: 'shared.PrintingConfiguration.printerConfigurationModalTitle',
        defaultMessage: 'Printing Configuration',
    },
    cannotFindPrinter: {
        id: 'shared.PrintingConfiguration.cannotFindPrinter',
        defaultMessage: 'Cannot find printer "", please be sure it is on and reachable',
    },
    notSupportedMethod: {
        id: 'shared.PrintingConfiguration.notSupportedMethod',
        defaultMessage: 'Printing method not supported',
    },
    testPrinter: {
        id: 'shared.PrintingConfiguration.testPrinter',
        defaultMessage: 'Test Printer',
    },
    aSelectionIsRequired: {
        id: 'shared.PrintingConfiguration.aSelectionIsRequired',
        defaultMessage: 'A selection is required.',
    },
    aValueIsRequired: {
        id: 'shared.PrintingConfiguration.aValueIsRequired',
        defaultMessage: 'A value is required.',
    },
});
const isValidKey = (key) => key in messages;
export const getLabelForPrinterService = (printerService) => {
    const key = `printerService_${printerService}`;
    return isValidKey(key) ? messages[key] : { id: key, defaultMessage: printerService };
};
export const getLabelForPrinterDriver = (printerDriver) => {
    const key = `printerDriver_${printerDriver}`;
    return isValidKey(key) ? messages[key] : { id: key, defaultMessage: printerDriver };
};
export default messages;
