import { jsx as _jsx } from "react/jsx-runtime";
import { PrinterService } from '../../../PrinterService';
import LocalQzForm from './LocalQzForm';
import RemoteQzForm from './RemoteQzForm';
import TmdsForm from './TmdsForm';
import '../EditPrinterConfiguration.scss';
export const PrinterServiceFormSelector = ({ printerService, ...props }) => {
    let Component;
    switch (printerService) {
        case PrinterService.Tmds:
            Component = TmdsForm;
            break;
        case PrinterService.QzTrayLocal:
            Component = LocalQzForm;
            break;
        case PrinterService.QzTrayRemote:
            Component = RemoteQzForm;
            break;
        default:
            return null;
    }
    return _jsx(Component, { ...props });
};
