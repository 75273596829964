import dynamicAction from '../components/utils/DynamicNameHelper';
export const JWT_TOKEN_EXPIRED = 'JWT_TOKEN_EXPIRED';
export const USER_TIMEOUT = 'USER_TIMEOUT';
export const START_TIMEOUT = 'START_TIMEOUT';
export const START_USER_DATA_RETRIEVAL = 'START_USER_DATA_RETRIEVAL';
export const FINISHED_USER_DATA_RETRIEVAL = 'FINISHED_USER_DATA_RETRIEVAL';
export const UPDATE_USER_TRACKING_INFORMATION = 'UPDATE_USER_TRACKING_INFORMATION';
export const SET_INVENTORY_TYPE = 'SET_INVENTORY_TYPE';
export const CLIENT_CLOUD_LOG = 'CLIENT_CLOUD_LOG';
export const REDELIVER_TICKETS = 'REDELIVER_TICKETS';
export const START_CUSTOMER_SEARCH = 'START_CUSTOMER_SEARCH';
export const FETCH_REDELIVERY = dynamicAction(REDELIVER_TICKETS);
export const FETCH_SEARCH_CUSTOMER = dynamicAction(START_CUSTOMER_SEARCH);
