import PropTypes from 'prop-types';
export const intlMessageShape = PropTypes.shape({
    id: PropTypes.string,
    defaultMessage: PropTypes.string,
});
export const eventItemShape = PropTypes.shape({
    className: PropTypes.string,
    clickHandler: PropTypes.func,
    date: PropTypes.string,
    dateMonth: PropTypes.string,
    dateNumb: PropTypes.string,
    displayAsLink: PropTypes.bool,
    fullDateShortMonth: PropTypes.string,
    fullDateLongMonth: PropTypes.string,
    id: PropTypes.string,
    inventoryIds: PropTypes.shape({
        arx: PropTypes.string,
        host: PropTypes.string,
    }),
    isToday: PropTypes.bool,
    localTime: PropTypes.string,
    location: PropTypes.string,
    mapId: PropTypes.string,
    name: PropTypes.string,
    time: PropTypes.string,
    url: PropTypes.string,
    utcOffset: PropTypes.number,
    venue: PropTypes.shape({
        name: PropTypes.string,
        type: PropTypes.string,
    }),
    weekday: PropTypes.string,
});
export const orderItemShape = PropTypes.shape({
    eventDetails: eventItemShape,
    eventId: PropTypes.string,
    id: PropTypes.string,
    orderId: PropTypes.string,
    rawOrderId: PropTypes.string,
    orderingDate: PropTypes.shape({
        dateTime: PropTypes.string,
        offsetMinutes: PropTypes.number,
    }),
    offsetMinutes: PropTypes.number,
    ticketCount: PropTypes.number,
});
export const hostPermissionsShape = PropTypes.shape({
    canRefund: PropTypes.bool,
    canRefundPrinted: PropTypes.bool,
    mustAuthorizeCards: PropTypes.bool,
});
export const orderGroupShape = PropTypes.shape({
    closestDate: PropTypes.number,
    customerId: PropTypes.string,
    email: PropTypes.string,
    fullName: PropTypes.string,
    name: PropTypes.shape({
        first: PropTypes.string,
        last: PropTypes.string,
    }),
    phoneNumber: PropTypes.string,
    items: PropTypes.arrayOf(orderItemShape),
});
export const userItemShape = PropTypes.shape({
    accountId: PropTypes.string,
    address: PropTypes.string,
    email: PropTypes.string,
    fullName: PropTypes.string,
    phone: PropTypes.string,
});
