import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import { printerNameMapper } from '../../../mapper';
import messages from '../../../printing-message';
import './PrinterName.scss';
export const PrinterName = ({ printer, intl }) => {
    const getPrinterName = () => {
        if (!printer) {
            return intl.formatMessage(messages.noPrinterNameMessage);
        }
        return printerNameMapper(printer);
    };
    return (_jsxs("div", { className: "printer-name", children: [_jsx("label", { className: "printer-name__label", children: _jsx(FormattedMessage, { ...messages.printerNameLabel }) }), _jsx("p", { className: classNames('printer-name__name', {
                    'printer-name__name--none': !printer,
                }), children: getPrinterName() })] }));
};
const PrinterNameWithIntl = injectIntl(PrinterName);
PrinterNameWithIntl.displayName = 'PrinterName';
export default PrinterNameWithIntl;
