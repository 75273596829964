import { currencyFormatOptions } from '@ticketmaster/tm1pos-web-shared/constants';
import { Tm1DataGridCell, Tm1DataGridRow } from '@tm1/design-system-react';
import Big from 'big.js';
import { FormattedNumber, InjectedIntl, injectIntl } from 'react-intl';
import './styles.scss';
import messages from '../messages';
import { OtherCharge } from './model/other-charge';

type Props = {
  otherCharges: OtherCharge[];
};

type InjectedProps = {
  intl: InjectedIntl;
};

const EditOtherChargesGridFooter = ({ otherCharges, intl }: Props & InjectedProps) => {
  const calculateGrandTotal = () => ({
    amount: otherCharges
      .reduce((sum, item) => sum.add(new Big(item.price.amount).mul(item.quantity || 0)), new Big(0))
      .toNumber(),
    currency: otherCharges[0].price.currency,
  });

  const grandTotal = calculateGrandTotal();

  return (
    <Tm1DataGridRow className="edit-other-charges-grid__footer" data-testid="other-charges-footer">
      <Tm1DataGridCell gridColumn="1/5">{intl.formatMessage(messages.otherChargesTotal)}</Tm1DataGridCell>
      <Tm1DataGridCell gridColumn="5">
        <FormattedNumber value={grandTotal.amount} currency={grandTotal.currency} {...currencyFormatOptions} />
      </Tm1DataGridCell>
    </Tm1DataGridRow>
  );
};

export default injectIntl(EditOtherChargesGridFooter);
