import { PaymentType } from '@ticketmaster/tm1pos-web-shared/model/payment';
import { defineMessages, FormattedMessage } from 'react-intl';

type PaymentMethodMessages = Partial<Record<PaymentType, FormattedMessage.MessageDescriptor>>;

export const paymentMethodMessagesByPaymentType: PaymentMethodMessages = defineMessages({
  [PaymentType.VOU]: {
    id: 'sales.paymentMethod.VOU',
    defaultMessage: 'Voucher',
  },
  [PaymentType.CK]: {
    id: 'sales.paymentMethod.CK',
    defaultMessage: 'Check',
  },
  [PaymentType.PPRT]: {
    id: 'sales.paymentMethod.PPRT',
    defaultMessage: 'Pre-print',
  },
  [PaymentType.CHRG]: {
    id: 'sales.paymentMethod.CHRG',
    defaultMessage: 'Charge',
  },
  [PaymentType.CASH]: {
    id: 'sales.paymentMethod.CASH',
    defaultMessage: 'Cash',
  },
  [PaymentType.AMX]: {
    id: 'sales.paymentMethod.AMX',
    defaultMessage: 'American Express',
  },
  [PaymentType.VISA]: {
    id: 'sales.paymentMethod.VISA',
    defaultMessage: 'Visa',
  },
  [PaymentType.DSC]: {
    id: 'sales.paymentMethod.DSC',
    defaultMessage: 'Discover',
  },
  [PaymentType.MC]: {
    id: 'sales.paymentMethod.MC',
    defaultMessage: 'MasterCard',
  },
  [PaymentType.COMP]: {
    id: 'sales.paymentMethod.COMP',
    defaultMessage: 'Complimentary',
  },
  [PaymentType.CHECK]: {
    id: 'sales.paymentMethod.CHECK',
    defaultMessage: 'Check',
  },
  [PaymentType.GC]: {
    id: 'sales.paymentMethod.GC',
    defaultMessage: 'Gift Card',
  },
  [PaymentType.PO]: {
    id: 'sales.paymentMethod.PO',
    defaultMessage: 'Purchase Order',
  },
  [PaymentType.CONSIN]: {
    id: 'sales.paymentMethod.CONSIN',
    defaultMessage: 'Consignment',
  },
  [PaymentType.PSS]: {
    id: 'sales.paymentMethod.PSS',
    defaultMessage: 'Premium Seat Services',
  },
  [PaymentType.BB]: {
    id: 'sales.paymentMethod.BB',
    defaultMessage: 'Bill Back',
  },
  [PaymentType.CDNDBT]: {
    id: 'sales.paymentMethod.CDNDBT',
    defaultMessage: 'Interac',
  },
  [PaymentType.GAMEX]: {
    id: 'sales.paymentMethod.GAMEX',
    defaultMessage: 'GAMEX',
  },
  [PaymentType.CO]: {
    id: 'sales.paymentMethod.CO',
    defaultMessage: 'Comp',
  },
  [PaymentType.GD]: {
    id: 'sales.paymentMethod.GD',
    defaultMessage: 'GD',
  },
  [PaymentType.TD]: {
    id: 'sales.paymentMethod.TD',
    defaultMessage: 'TD',
  },
  [PaymentType.EX]: {
    id: 'sales.paymentMethod.EX',
    defaultMessage: 'EX',
  },
  [PaymentType.GP]: {
    id: 'sales.paymentMethod.GP',
    defaultMessage: 'GP',
  },
  [PaymentType.MO]: {
    id: 'sales.paymentMethod.MO',
    defaultMessage: 'Money Order',
  },
  [PaymentType.TMGC]: {
    id: 'sales.paymentMethod.TMGC',
    defaultMessage: 'TM Gift Card',
  },
  [PaymentType.AME]: {
    id: 'sales.paymentMethod.AME',
    defaultMessage: 'Broker - Americana',
  },
  [PaymentType.APP]: {
    id: 'sales.paymentMethod.APP',
    defaultMessage: 'Broker - Applause',
  },
  [PaymentType.BDH]: {
    id: 'sales.paymentMethod.BDH',
    defaultMessage: 'Broadway Direct (House Seats)',
  },
  [PaymentType.GMCV]: {
    id: 'sales.paymentMethod.GMCV',
    defaultMessage: 'GMCV',
  },
  [PaymentType.CPSC]: {
    id: 'sales.paymentMethod.CPSC',
    defaultMessage: 'CPSC',
  },
  [PaymentType.GRP]: {
    id: 'sales.paymentMethod.GRP',
    defaultMessage: 'Broadway Direct (Group)',
  },
  [PaymentType.PEN]: {
    id: 'sales.paymentMethod.PEN',
    defaultMessage: 'Broker - Penn',
  },
  [PaymentType.TDI]: {
    id: 'sales.paymentMethod.TDI',
    defaultMessage: 'Broker - Broadway.com',
  },
  [PaymentType.SBD]: {
    id: 'sales.paymentMethod.SBD',
    defaultMessage: 'Broadway Direct (Web)',
  },
  [PaymentType.LOT]: {
    id: 'sales.paymentMethod.LOT',
    defaultMessage: 'Broadway Direct (Lottery)',
  },
  [PaymentType.LIB]: {
    id: 'sales.paymentMethod.LIB',
    defaultMessage: 'Charge',
  },
  [PaymentType.VCH]: {
    id: 'sales.paymentMethod.VCH',
    defaultMessage: 'Voucher',
  },
  [PaymentType.INV]: {
    id: 'sales.paymentMethod.INV',
    defaultMessage: 'Invoice',
  },
  [PaymentType.CHEQUE]: {
    id: 'sales.paymentMethod.CHEQUE',
    defaultMessage: 'Cheque',
  },
  [PaymentType.PIX]: {
    id: 'sales.paymentMethod.PIX',
    defaultMessage: 'Pix',
  },
});

export const getPaymentMethodMessageByKey = (key: PaymentType): FormattedMessage.MessageDescriptor | undefined =>
  paymentMethodMessagesByPaymentType[key] ?? undefined;
