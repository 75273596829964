import Fuse from 'fuse.js';
import moment from 'moment';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { findEvents } from '../../services/sales-api-client/sales-api-events';
import { selectLanguage } from '../../translations';
import { CHAR_LENGTH, DEFAULT_WEIGHTED_SEARCH_KEYS } from './constants';
import { selectEvents, selectReadyToLoadAllEvents, selectSearchQuery } from './events-selector';
import { fetchAllEventsError, fetchAllEventsLoading, fetchAllEventsSuccess, fetchInitialEventsError, fetchInitialEventsLoading, fetchInitialEventsSuccess, loadEvents, receiveSearchResults, startSearch, } from './events.slice';
export function getSearchEventKeys(query, locale) {
    const defaultKeys = DEFAULT_WEIGHTED_SEARCH_KEYS;
    let qArray = query.split(/\s+/g);
    const finder = (re) => (elem) => elem.match(re);
    qArray = qArray.map((item) => {
        const str = String(item.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'))
            .trim()
            .toLowerCase();
        const re = new RegExp(`^${str}`, 'i');
        const couldBeAWeekday = moment.localeData(locale)._weekdays.find(finder(re));
        const couldBeMonth = moment.localeData(locale)._months.find(finder(re));
        const couldBeTime = /^[0-2]?\d:([0-5]\d?)?$/.test(str);
        return { couldBeAWeekday, couldBeMonth, couldBeTime };
    });
    const determineReWeight = qArray.reduce((acc, { couldBeAWeekday, couldBeMonth, couldBeTime }) => {
        acc.weekday += couldBeAWeekday ? 1 : 0;
        acc.fullDateShortMonth += couldBeMonth ? 1 : 0;
        acc.time += couldBeTime ? 1 : 0;
        if (acc.time || acc.fullDateShortMonth || acc.weekday) {
            acc.reWeight = true;
        }
        return acc;
    }, { name: 1, weekday: 0, fullDateShortMonth: 0, time: 0, location: 0, reWeight: false });
    if (determineReWeight.reWeight) {
        const totalKeys = defaultKeys.length;
        const heavilyWeightedKeys = Object.keys(determineReWeight).filter((item) => item !== 'reWeight' && parseInt(determineReWeight[item], 10) > 0);
        let heavyWeightedKeysValueTotal = 0.8;
        const heavyWeightedRatio = heavilyWeightedKeys.length / totalKeys;
        heavyWeightedKeysValueTotal += 0.2 * heavyWeightedRatio;
        const restOfWeightedKeysTotal = 1 - heavyWeightedKeysValueTotal;
        const heavyWeightedKeysValue = heavyWeightedKeysValueTotal / heavilyWeightedKeys.length;
        const restTotal = totalKeys - heavilyWeightedKeys.length;
        const restOfWeightedKeysValue = restOfWeightedKeysTotal / restTotal;
        delete determineReWeight.reWeight;
        const getWeightedKeys = () => Object.keys(determineReWeight).reduce((acc, cur) => {
            const isHeavy = heavilyWeightedKeys.find(finder(new RegExp(cur)));
            acc.push({
                name: cur,
                weight: isHeavy ? heavyWeightedKeysValue : restOfWeightedKeysValue,
            });
            return acc;
        }, []);
        return getWeightedKeys();
    }
    return defaultKeys;
}
export function* searchEvents({ payload }) {
    if (payload.searchArr.length === 0) {
        yield put(receiveSearchResults({ searchResults: [], searchQuery: payload.searchQuery }));
        return;
    }
    const locale = yield select(selectLanguage);
    const keys = yield call(getSearchEventKeys, payload.searchQuery, locale);
    const options = {
        includeMatches: true,
        includeScore: true,
        minMatchCharLength: CHAR_LENGTH,
        findAllMatches: true,
        location: 0,
        distance: 200,
        threshold: 0.3,
        tokenSeparator: /\s+/g,
        keys,
    };
    const fuse = new Fuse(payload.searchArr, options);
    const results = fuse.search(String(payload.searchQuery).trim());
    const readyToLoadAll = yield select(selectReadyToLoadAllEvents);
    if (readyToLoadAll) {
        yield put(loadAllEvents());
    }
    yield put(receiveSearchResults({ searchQuery: payload.searchQuery, searchResults: results }));
}
export const fetchEventsSuccess = (isInitial, payload) => isInitial ? fetchInitialEventsSuccess(payload) : fetchAllEventsSuccess(payload);
export const fetchEventsLoading = (isInitial) => (isInitial ? fetchInitialEventsLoading() : fetchAllEventsLoading());
export const fetchEventsError = (isInitial) => (isInitial ? fetchInitialEventsError() : fetchAllEventsError());
export const loadInitialEvents = () => loadEvents(true);
export const loadAllEvents = () => loadEvents(false);
export function* getEventsData({ payload }) {
    const isInitial = payload;
    const startDate = moment().subtract(1, 'days').hours(0).minute(0).seconds(0).utc().format();
    const endDate = isInitial ? moment().add(6, 'month').hours(0).minute(0).seconds(0).utc().format() : null;
    try {
        yield put(fetchEventsLoading(isInitial));
        const data = yield call(findEvents, startDate, endDate);
        yield put(fetchEventsSuccess(isInitial, data));
        const searchQuery = yield select(selectSearchQuery);
        const eventsArr = yield select(selectEvents);
        if (searchQuery.length) {
            yield put(startSearch({ searchArr: eventsArr, searchQuery }));
        }
        return eventsArr;
    }
    catch (error) {
        yield put(fetchEventsError(isInitial));
    }
    return null;
}
export function* watchDataLoad() {
    yield takeEvery(loadEvents.type, getEventsData);
}
export function* watchStartSearch() {
    yield takeEvery(startSearch.type, searchEvents);
}
export default [watchDataLoad, watchStartSearch];
