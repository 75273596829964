import { IntlProvider } from 'react-intl';
export default class IntlProviderWrapper extends IntlProvider {
    getChildContext() {
        const context = super.getChildContext();
        const originalFormatMessage = context.intl.formatMessage;
        context.intl.formatMessage = (messageDescriptor, values) => {
            if (!messageDescriptor) {
                return 'unknown';
            }
            return originalFormatMessage(messageDescriptor, values);
        };
        return context;
    }
}
