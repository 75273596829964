import { createAction, createSlice } from '@reduxjs/toolkit';
import { locationChange } from '../../router-action';
import { isEventPath } from '../../utils/conditionals';
import storage from '../../utils/storage/storageService';
export const initialState = {
    allEventsAreLoaded: false,
    currentEvent: storage.getItem('currentEventDetails') || undefined,
    items: [],
    isLoadingAll: false,
    isLoadingInitial: false,
    isLoadingInitialFailed: false,
    searchQuery: '',
    searchResults: [],
};
const actionNamespace = 'event';
const eventsSlice = createSlice({
    name: actionNamespace,
    initialState,
    reducers: {
        fetchInitialEventsSuccess: (state, { payload }) => ({
            ...state,
            isLoadingInitial: false,
            items: payload,
        }),
        fetchAllEventsSuccess: (state, { payload }) => ({
            ...state,
            allEventsAreLoaded: true,
            isLoadingAll: false,
            items: payload,
        }),
        fetchInitialEventsLoading: (state) => ({
            ...state,
            isLoadingInitial: true,
        }),
        fetchAllEventsLoading: (state) => ({
            ...state,
            isLoadingAll: true,
        }),
        fetchInitialEventsError: (state) => ({
            ...state,
            isLoadingInitial: false,
            isLoadingInitialFailed: true,
            items: [],
            searchResults: [],
        }),
        fetchAllEventsError: (state) => ({
            ...state,
            allEventsAreLoaded: false,
            isLoadingAll: false,
            items: [],
            searchResults: [],
        }),
        receiveSearchResults: (state, { payload }) => ({
            ...state,
            searchResults: payload.searchResults,
            searchQuery: payload.searchQuery,
        }),
        selectEvent: (state, { payload }) => {
            storage.setItem('currentEventDetails', payload);
            return {
                ...state,
                currentEvent: payload,
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(locationChange, (state, { payload }) => {
            if (!isEventPath(payload.pathname)) {
                storage.setItem('currentEventDetails', null);
                return {
                    ...state,
                    currentEvent: undefined,
                };
            }
            return state;
        });
    },
});
export const loadEvents = createAction(`${actionNamespace}/loadEvents`);
export const startSearch = createAction(`${actionNamespace}/startSearch`);
export const { receiveSearchResults, fetchInitialEventsLoading, fetchInitialEventsError, fetchInitialEventsSuccess, fetchAllEventsLoading, fetchAllEventsError, fetchAllEventsSuccess, selectEvent, } = eventsSlice.actions;
export const eventsReducer = eventsSlice.reducer;
