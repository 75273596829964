import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { ERROR_MESSAGE_DELAY } from '../../constants';
import { HIDE_ERROR_NOTIFICATION } from '../../errorHandling/actions';
import ErrorNotification from '../ErrorNotification';
import wrapModal from '../utils/ModalWrap';
import './styles.scss';
export class ErrorContainer extends PureComponent {
    constructor() {
        super(...arguments);
        this.removeNotification = (notification) => () => {
            this.props.dispatch({ type: HIDE_ERROR_NOTIFICATION, notification });
        };
    }
    renderModal() {
        const ErrorModal = wrapModal(this.props.modalContent);
        return _jsx(ErrorModal, { dispatch: this.props.dispatch });
    }
    renderErrorNotifications() {
        const notifications = this.props.errorNotifications.map((notification) => {
            const removeNotification = this.removeNotification(notification);
            return (_jsx(ErrorNotification, { delay: ERROR_MESSAGE_DELAY, message: notification.message, tip: notification.tip, clickHandler: removeNotification }, `error_notify_${notification.failedActionName}`));
        });
        return _jsx("div", { className: "sdr-error__notifications", children: notifications });
    }
    render() {
        const className = classNames('sdr-error-container', this.props.className);
        return (_jsxs("div", { className: className, children: [this.props.isModalActive && this.props.modalContent ? this.renderModal() : null, this.props.errorNotifications && this.props.errorNotifications.length ? this.renderErrorNotifications() : null] }));
    }
}
ErrorContainer.propTypes = {
    modalContent: PropTypes.func,
    isModalActive: PropTypes.bool,
    errorNotifications: PropTypes.array,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};
const mapDispatchToProps = (dispatch) => ({ dispatch });
export default connect(null, mapDispatchToProps)(ErrorContainer);
