const certificate = `-----BEGIN CERTIFICATE-----
MIIFWjCCA0KgAwIBAgIQNzkyMDIzMDUwMjE4MzQzMTANBgkqhkiG9w0BAQsFADCB
mDELMAkGA1UEBhMCVVMxCzAJBgNVBAgMAk5ZMRswGQYDVQQKDBJRWiBJbmR1c3Ry
aWVzLCBMTEMxGzAZBgNVBAsMElFaIEluZHVzdHJpZXMsIExMQzEZMBcGA1UEAwwQ
cXppbmR1c3RyaWVzLmNvbTEnMCUGCSqGSIb3DQEJARYYc3VwcG9ydEBxemluZHVz
dHJpZXMuY29tMB4XDTIzMDUwMjE4MzQzMVoXDTI1MDUyMzIwMjYzMlowggEUMQsw
CQYDVQQGDAJDQTELMAkGA1UECAwCUUMxDzANBgNVBAcMBlF1ZWJlYzEoMCYGA1UE
CgwfTWljcm9mbGV4IDIwMDEgTExDL1RpY2tldG1hc3RlcjEoMCYGA1UECwwfTWlj
cm9mbGV4IDIwMDEgTExDL1RpY2tldG1hc3RlcjEoMCYGA1UEAwwfTWljcm9mbGV4
IDIwMDEgTExDL1RpY2tldG1hc3RlcjErMCkGCSqGSIb3DQEJAQwccXVlYmVjYWRt
aW5AdGlja2V0bWFzdGVyLmNvbTE8MDoGA1UEDQwzcmVuZXdhbC1vZi1hOTgyZDU2
ZjdmNGFiODI0ZGViOWNjM2U2ZjJlNWQ2NmE3NjZiZmMxMIIBIDALBgkqhkiG9w0B
AQEDggEPADCCAQoCggEBAOSdGJn815PTqyX4aPWiuqv63/1h/TIJPUETCvdfI04d
UMWFHg42XfZnQUQ5AuyRSjgPyr7GGWFOKP2aWxFao3HLJUg88KiiFDxzUXUaULAF
345Gn8faGS6UqUb2M4cu8G+CJtU0D2r/yKBrgi1dOWmJqVL8WbOzHcPGtHQ2PWdT
wyvxtzZ8ioQiHEPJ4zyaFg6wKFhytfGal1ZIkgw77Z8HDMEUDBOHbGav3NdZpZOO
SvXr8P4LpNhwLoHYIeJpwMFYlCGxVSqFVT0MTOf2oNUK3Nobplfrs8gOe3ngmFBL
YVVClTf3+qtLxYt7vQlY7BrnSbx2krdKHV/eYee4VIUCAwEAAaMjMCEwHwYDVR0j
BBgwFoAUkKZQt4TUuepf8gWEE3hF6Kl1VFwwDQYJKoZIhvcNAQELBQADggIBACdH
w1J1oI5akEiETixY3/su89KcB9jeHsNZx3JeGA1BEiBL2z0K2NqrDJICdsCHGsox
Q5Cfgt/ggnNpjNWzUkyztkHxj/myBfLVkU0KFed/caeYJangTZKamhjW9HF32QUa
iD/8Bnb3yxF25UKHWIHZ8Eu6FdF5qCPXVoKO4l0bFkbFdRoZsVcIIm4rTMQYKJQv
2yg6wOZ9CKphnK4xXfX/Q2XuMBWX63VDevC6/CrrGHmG+a/5KBdyMiTv5cmthp7G
p8hNre20JAv+pPmWm+8wbqV3QX4OCMRt6LziGP50oHQG1BBHKzC7jUivxJErABYP
b1mfKZUzdLne9D2OIxpP45S4VqiSU1nghI1MNLXnDJy8yy2lMI4pE9X228IIBFoo
yGoAdVKh3pQCYql858z1JBLQTsg6aA8mJ6w33uRagysVX7IGIe57Z+NjJTnRRoYT
Njp2y02H/cH8kan3H+DGnBKB174pjP8Ep+1hKKO8ODgwz+RgYVsNDilf6/CXg3DG
l/SFIk3OL3pYu6jOJZrU6qxQxZT8NuvlqkS74xdEuO4cdgFE9Wwm58Twu50LzDWo
6Hz8kKDBYwaDrjWJuTu4xNEFopwXN7WLRLwnGduUDkLpgWQ21rGHudKw7n7YNAgh
Sg5W+Hv6Le9+okOwA84zaQ6n1U4txh3CXY3yFgjq
-----END CERTIFICATE-----
--START INTERMEDIATE CERT--
-----BEGIN CERTIFICATE-----
MIIFEjCCA/qgAwIBAgICEAAwDQYJKoZIhvcNAQELBQAwgawxCzAJBgNVBAYTAlVT
MQswCQYDVQQIDAJOWTESMBAGA1UEBwwJQ2FuYXN0b3RhMRswGQYDVQQKDBJRWiBJ
bmR1c3RyaWVzLCBMTEMxGzAZBgNVBAsMElFaIEluZHVzdHJpZXMsIExMQzEZMBcG
A1UEAwwQcXppbmR1c3RyaWVzLmNvbTEnMCUGCSqGSIb3DQEJARYYc3VwcG9ydEBx
emluZHVzdHJpZXMuY29tMB4XDTE1MDMwMjAwNTAxOFoXDTM1MDMwMjAwNTAxOFow
gZgxCzAJBgNVBAYTAlVTMQswCQYDVQQIDAJOWTEbMBkGA1UECgwSUVogSW5kdXN0
cmllcywgTExDMRswGQYDVQQLDBJRWiBJbmR1c3RyaWVzLCBMTEMxGTAXBgNVBAMM
EHF6aW5kdXN0cmllcy5jb20xJzAlBgkqhkiG9w0BCQEWGHN1cHBvcnRAcXppbmR1
c3RyaWVzLmNvbTCCAiIwDQYJKoZIhvcNAQEBBQADggIPADCCAgoCggIBANTDgNLU
iohl/rQoZ2bTMHVEk1mA020LYhgfWjO0+GsLlbg5SvWVFWkv4ZgffuVRXLHrwz1H
YpMyo+Zh8ksJF9ssJWCwQGO5ciM6dmoryyB0VZHGY1blewdMuxieXP7Kr6XD3GRM
GAhEwTxjUzI3ksuRunX4IcnRXKYkg5pjs4nLEhXtIZWDLiXPUsyUAEq1U1qdL1AH
EtdK/L3zLATnhPB6ZiM+HzNG4aAPynSA38fpeeZ4R0tINMpFThwNgGUsxYKsP9kh
0gxGl8YHL6ZzC7BC8FXIB/0Wteng0+XLAVto56Pyxt7BdxtNVuVNNXgkCi9tMqVX
xOk3oIvODDt0UoQUZ/umUuoMuOLekYUpZVk4utCqXXlB4mVfS5/zWB6nVxFX8Io1
9FOiDLTwZVtBmzmeikzb6o1QLp9F2TAvlf8+DIGDOo0DpPQUtOUyLPCh5hBaDGFE
ZhE56qPCBiQIc4T2klWX/80C5NZnd/tJNxjyUyk7bjdDzhzT10CGRAsqxAnsjvMD
2KcMf3oXN4PNgyfpbfq2ipxJ1u777Gpbzyf0xoKwH9FYigmqfRH2N2pEdiYawKrX
6pyXzGM4cvQ5X1Yxf2x/+xdTLdVaLnZgwrdqwFYmDejGAldXlYDl3jbBHVM1v+uY
5ItGTjk+3vLrxmvGy5XFVG+8fF/xaVfo5TW5AgMBAAGjUDBOMB0GA1UdDgQWBBSQ
plC3hNS56l/yBYQTeEXoqXVUXDAfBgNVHSMEGDAWgBQDRcZNwPqOqQvagw9BpW0S
BkOpXjAMBgNVHRMEBTADAQH/MA0GCSqGSIb3DQEBCwUAA4IBAQAJIO8SiNr9jpLQ
eUsFUmbueoxyI5L+P5eV92ceVOJ2tAlBA13vzF1NWlpSlrMmQcVUE/K4D01qtr0k
gDs6LUHvj2XXLpyEogitbBgipkQpwCTJVfC9bWYBwEotC7Y8mVjjEV7uXAT71GKT
x8XlB9maf+BTZGgyoulA5pTYJ++7s/xX9gzSWCa+eXGcjguBtYYXaAjjAqFGRAvu
pz1yrDWcA6H94HeErJKUXBakS0Jm/V33JDuVXY+aZ8EQi2kV82aZbNdXll/R6iGw
2ur4rDErnHsiphBgZB71C5FD4cdfSONTsYxmPmyUb5T+KLUouxZ9B0Wh28ucc1Lp
rbO7BnjW
-----END CERTIFICATE-----
`;
export default certificate;
