import { isReportPath } from '@ticketmaster/tm1pos-web-shared/utils/conditionals';
import { LOCATION_CHANGE } from 'connected-react-router';
import { fromJS } from 'immutable';
import moment from 'moment';
import { sendEmailReport } from './actions';
import {
  CLEAR_FORM_ERROR_STATE,
  FETCH_EMAIL_REPORT,
  FETCH_REPORT,
  FETCH_UPDATED_REPORT,
  GET_REPORT_DATA,
  RESET_REPORT_FORM,
  UPDATE_REPORT_DATETIME,
} from './actions-constants';

const reportDateTimeFormat = 'YYYY-MM-DDTHH:mm:ss';
const todayStartDateTime = moment().hours(0).minutes(0).seconds(0).format(reportDateTimeFormat);
const todayEndDateTime = moment().hours(23).minutes(59).seconds(59).format(reportDateTimeFormat);

const initialState = fromJS({
  isLoadingReportData: false,
  isLoadingReportUpdated: false,
  reportDataLoadFailed: false,
  reportData: null,
  reportOpcode: '',
  isSendingEmailReport: false,
  wasReportSent: false,
  reportSentSuccessStatus: false,
  noPermissionsForReport: false,
  reportStartDateTime: todayStartDateTime,
  reportEndDateTime: todayEndDateTime,
});

function ReportReducer(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE: {
      if (action.payload && !isReportPath(action.payload.pathname)) {
        return initialState;
      }
      return state;
    }
    case GET_REPORT_DATA:
      return state.set('reportDataLoadFailed', false);
    case sendEmailReport.type:
    case CLEAR_FORM_ERROR_STATE:
    case RESET_REPORT_FORM:
      return state.set('wasReportSent', false).set('reportSentSuccessStatus', false);
    case FETCH_UPDATED_REPORT.LOADING:
      return state.set('isLoadingReportUpdated', true);
    case FETCH_REPORT.LOADING:
      return state.set('noPermissionsForReport', false).set('isLoadingReportData', true);
    case FETCH_EMAIL_REPORT.LOADING:
      return state.set('isSendingEmailReport', true);
    case FETCH_EMAIL_REPORT.ERROR:
      return state.set('wasReportSent', true).set('reportSentSuccessStatus', false).set('isSendingEmailReport', false);
    case FETCH_EMAIL_REPORT.SUCCESS:
      return state.set('wasReportSent', true).set('reportSentSuccessStatus', true).set('isSendingEmailReport', false);
    case FETCH_UPDATED_REPORT.ERROR:
    case FETCH_REPORT.ERROR:
      return state
        .set('isLoadingReportData', false)
        .set('isLoadingReportUpdated', false)
        .set('reportDataLoadFailed', true)
        .set('noPermissionsForReport', action.noPermissions)
        .set('reportData', null);
    case FETCH_UPDATED_REPORT.SUCCESS:
    case FETCH_REPORT.SUCCESS:
      return state
        .set('isLoadingReportUpdated', false)
        .set('isLoadingReportData', false)
        .set('reportStartDateTime', action.data.startDateTime)
        .set('reportEndDateTime', action.data.endDateTime)
        .set('reportData', action.data);
    case UPDATE_REPORT_DATETIME:
      return state
        .set(
          'reportStartDateTime',
          moment(action.startDate).hours(0).minutes(0).seconds(0).format(reportDateTimeFormat),
        )
        .set(
          'reportEndDateTime',
          moment(action.endDate).hours(23).minutes(59).seconds(59).format(reportDateTimeFormat),
        );
    default:
      return state;
  }
}

export function emailFormReducer(state, action) {
  // ? Disabling the `sonarjs/no-small-switch` rule to comply with the Reducer pattern
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (action.type) {
    case RESET_REPORT_FORM:
      return state.setIn(['values', 'reportEmail'], '');
    default:
      return state;
  }
}

export default ReportReducer;
