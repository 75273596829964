import moment from 'moment';
import { DEFAULT_INVENTORY_TYPE_SELECTED, HOLD_INVENTORY_TYPE, SYSTEM_ID } from '../constants';
export const reconstructObj = (arr, obj, func) => arr.reduce((acc, cur) => typeof obj[cur] === 'object'
    ? Object.assign(acc, { [cur]: func(obj[cur]) })
    : Object.assign(acc, { [cur]: obj[cur] }), {});
export const cleanObjStrict = (obj = {}) => {
    const arr = Object.keys(obj).filter((k) => obj[k] !== null && obj[k] !== undefined && obj[k] !== '');
    return reconstructObj(arr, obj, cleanObjStrict);
};
export const cleanObj = (obj = {}) => {
    const arr = Object.keys(obj).filter((k) => obj[k] !== null && obj[k] !== undefined);
    return reconstructObj(arr, obj, cleanObj);
};
export const testArray = (arr) => {
    if (!(arr instanceof Array)) {
        throw new Error('checkAllFn(Expected Array)');
    }
    return arr.concat();
};
export const checkAllFn = (fn) => (arr) => {
    const checks = testArray(arr);
    let bol = true;
    while (bol && checks.length) {
        bol = fn(...checks.shift());
    }
    return bol;
};
export const walkObjectFn = (fn) => (obj, arr) => {
    const props = testArray(arr);
    let cur = obj;
    while (cur && props.length) {
        cur = cur[props.shift()];
    }
    return fn(cur);
};
export const checkDefined = walkObjectFn((cur) => cur === 0 || Boolean(cur));
export const walkObjectForValue = walkObjectFn((cur) => cur);
export const checkNestedStrictEquality = (obj, arr = [], test) => {
    const val = walkObjectForValue(obj, arr);
    return val === test;
};
export const checkAllDefined = checkAllFn(checkDefined);
export const checkAllNestedStrictEquality = checkAllFn(checkNestedStrictEquality);
export const maskProp = (field) => {
    const isDefined = field !== undefined && field !== null;
    if (isDefined && !isNaN(field)) {
        return 0;
    }
    if (isDefined) {
        return field.substr(0, 6).replace(/./g, 'x');
    }
    return field;
};
export const maskFields = (pobj, arr) => {
    const obj = pobj instanceof Array ? pobj.concat() : { ...pobj };
    return Object.keys(obj).reduce((acc, cur) => {
        if (obj[cur] instanceof Object) {
            acc[cur] = maskFields(obj[cur], arr);
        }
        else if (arr.indexOf(cur) > -1) {
            acc[cur] = maskProp(obj[cur]);
        }
        return acc;
    }, obj);
};
export const isInt = (num) => !isNaN(parseInt(num, 10));
export const validMonth = (num, defaultMonth) => {
    const n = parseInt(num, 10);
    if (isInt(n) && n > 0 && n < 13) {
        return num;
    }
    return defaultMonth;
};
export const validYear = (num, defaultYear) => {
    const n = parseInt(num, 10);
    if (isInt(n) && n > 0 && n < Number.MAX_SAFE_INTEGER) {
        return num;
    }
    return defaultYear;
};
export const eventType = (eventId) => {
    let archtics;
    let host;
    const matched = eventId === null || eventId === void 0 ? void 0 : eventId.match(/arx-(.*)-hst-(.*)/i);
    if (matched && matched.length) {
        [, archtics, host] = matched;
    }
    return { archtics, host };
};
export const orderType = (orderId) => {
    const str = orderId || '';
    const matchHOST = str.toUpperCase().indexOf(SYSTEM_ID.HOST) > -1;
    const matchARX = str.toUpperCase().indexOf(SYSTEM_ID.ARCHTICS) > -1;
    return { arx: matchARX, host: matchHOST };
};
export const getEventPlatform = (eventId) => (eventType(eventId).archtics ? 'Archtics' : 'Host');
export const getOrderPlatform = (orderId) => (orderId === SYSTEM_ID.ARCHTICS ? 'Archtics' : 'Host');
export const orderHostType = (orderId) => {
    try {
        return orderId.split('/')[1] || '';
    }
    catch (e) {
        console.error(`Failed to get host name of order with id ${orderId}.`);
        return '';
    }
};
export const defaultUTCOffset = 0;
export const applyOffset = (d = {}, offset) => {
    let md = d;
    if (offset !== undefined && offset !== null && d.utcOffset) {
        md = d.utcOffset(offset);
    }
    else if (d.utcOffset) {
        md = d.utcOffset(defaultUTCOffset);
    }
    return md;
};
export const getNowLocalTime = (offset) => applyOffset(moment(), offset);
export const getEventLocalTime = (event) => applyOffset(moment(event.date), event.utcOffset);
export const checkIfEventIsToday = (nowLocalTime, eventLocalTime) => moment(eventLocalTime).isSame(nowLocalTime, 'day');
export const checkIfEventIsPast = (nowLocalTime, eventLocalTime) => moment(eventLocalTime).isBefore(nowLocalTime, 'day');
export const getTimeDiffInHours = (eventLocalTime, nowLocalTime) => moment.duration(eventLocalTime.diff(nowLocalTime)).as('hours');
export const getEventTimeDetails = (event) => {
    if (event.date) {
        const offset = event.utcOffset || 0;
        const nowLocalTime = getNowLocalTime(offset);
        const eventLocalTime = getEventLocalTime(event);
        const isToday = checkIfEventIsToday(nowLocalTime, eventLocalTime);
        const isPast = checkIfEventIsPast(nowLocalTime, eventLocalTime);
        const diffFromNow = getTimeDiffInHours(eventLocalTime, nowLocalTime);
        const lessThanFourHoursAgo = diffFromNow >= -4;
        return {
            isToday,
            isTodayAndLessThanFourHoursAgo: lessThanFourHoursAgo && isToday,
            diffFromNow,
            isPast,
        };
    }
    return {
        isToday: false,
        isTodayAndLessThanFourHoursAgo: false,
        diffFromNow: null,
        isPast: false,
    };
};
export const sortInventory = (inventoryArr) => inventoryArr
    .concat()
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .sort(makeFirstInList(HOLD_INVENTORY_TYPE))
    .sort(makeFirstInList(DEFAULT_INVENTORY_TYPE_SELECTED));
export const makeFirstInList = (firstToBe) => (a, b) => {
    if (a.name === firstToBe) {
        return -1;
    }
    if (b.name === firstToBe) {
        return 1;
    }
    return 0;
};
export const mapDispatchToProps = (dispatch) => ({ dispatch });
