import moment from 'moment';
export class PerformanceDevSpanProcessor {
    forceFlush() {
        return Promise.resolve(undefined);
    }
    onEnd(span) {
        const spanName = this.getSpanName(span);
        try {
            const options = this.getTimings(span);
            this.log(`${spanName}\n  traceId: ${span.spanContext().traceId}\n  duration: ${this.convertTimestamp(span.duration)}`, 'blue');
            performance.measure(spanName, options);
        }
        catch (_e) {
            this.log(`Error occurred while processing "${spanName}" performance mark`, 'orange');
        }
    }
    log(message, color) {
        console.debug(`%c[OTLP]%c ${moment().format('hh:mm:ss.SSS')}  ${message}`, `font-weight: bold; color: ${color}`, `font-weight: normal; color: ${color}`);
    }
    getTimings(span) {
        const start = this.convertTimestamp(span.startTime) - performance.timeOrigin;
        const end = start + this.convertTimestamp(span.duration);
        return { start, end };
    }
    getSpanName(span) {
        if (span.attributes['http.url']) {
            return `${span.name} (${span.attributes['http.url']})`;
        }
        return `${span.name}`;
    }
    onStart(span, parentContext) { }
    shutdown() {
        return Promise.resolve(undefined);
    }
    convertTimestamp(oltpTimestamp) {
        const numberString = oltpTimestamp.map((n) => n.toString().padStart(9, '0')).join('');
        return Math.ceil(Number(numberString) / 1000000);
    }
}
