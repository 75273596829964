import * as React from 'react';
import { connect } from 'react-redux';
import { LogComponent } from '../../errorHandling/constants';
import { dispatchError } from '../../services/errors/error-dispatcher';
const isPromiseRejectionEvent = (event) => event && 'promise' in event;
const isICEPromiseRejectionEvent = (event) => event && 'detail' in event && isPromiseRejectionEvent(event.detail);
export class ErrorBoundary extends React.Component {
    static getDerivedStateFromError() {
        return { hasError: true };
    }
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    componentDidMount() {
        window.addEventListener('error', this.errorHandler.bind(this));
        window.addEventListener('unhandledrejection', this.unhandledRejectionHandler.bind(this));
    }
    componentDidCatch(error) {
        const { dispatch } = this.props;
        dispatchError(error, LogComponent.UNHANDLED_COMPONENT_ERROR, { dispatch });
    }
    componentWillUnmount() {
        window.removeEventListener('error', this.errorHandler.bind(this));
        window.removeEventListener('unhandledrejection', this.unhandledRejectionHandler.bind(this));
    }
    errorHandler({ error }) {
        const { dispatch } = this.props;
        dispatchError(error, LogComponent.UNHANDLED_WINDOW_ERROR, { dispatch });
    }
    async unhandledRejectionHandler(promiseRejectionEvent) {
        const { dispatch } = this.props;
        promiseRejectionEvent.preventDefault();
        let promise = null;
        if (isPromiseRejectionEvent(promiseRejectionEvent)) {
            promise = promiseRejectionEvent.promise;
        }
        else if (isICEPromiseRejectionEvent(promiseRejectionEvent)) {
            promise = promiseRejectionEvent.detail.promise;
        }
        if (promise && dispatch) {
            promise.catch((error) => {
                dispatchError(error, LogComponent.UNHANDLED_REJECTION_ERROR, { dispatch });
            });
        }
        else {
            console.error(promiseRejectionEvent);
        }
    }
    render() {
        const { children, errorPage } = this.props;
        const { hasError } = this.state;
        if (hasError) {
            return errorPage;
        }
        return children;
    }
}
export const mapDispatchToProps = (dispatch) => ({ dispatch });
export default connect(null, mapDispatchToProps)(ErrorBoundary);
