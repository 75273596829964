export const CHECKOUT_FORM_TITLE = 'checkout';

export const NO_PAYMENT_INFO_CATEGORY = ['OCA', 'ONC'];
export const CHECK_PAYMENT_INFO_CATEGORY = ['OCH'];
export const CASH_PAYMENT_INFO_CATEGORY = ['CSH'];
export const CREDIT_PAYMENT_INFO_CATEGORY = ['OCC', 'OGC'];
export const DEFAULT_PRINT_RECEIPT_VALUE = {
  active: true,
  quantity: {
    id: '1',
    name: '1',
    value: '1',
    label: '1',
    disabled: false,
    hasError: false,
  },
};
// cc types
export const AMERICANEXPRESS = 'AMERICANEXPRESS';
export const MASTERCARD = 'MASTERCARD';
export const DISCOVER = 'DISCOVER';
export const VISA = 'VISA';
export const CREDIT_CARD_TYPE = ['American Express', 'Discover', 'MasterCard', 'Visa'];

// deliveries and payments
export const CARD = 'Card';
export const CASH = 'Cash';
export const PIX = 'Pix';
export const OTHER = 'Other';

// payment processes
export const EMV_PROCESS = 'EMV';
export const MAGNETIC_STRIPE_PROCESS = 'MagStripe';

// payment statuses
export const REJECTED = 'REJECTED';

// checkout errors
export const CARD_REJECT = 'CARD_REJECT';
export const CARD_NO_FUNDS = 'CARD_NO_FUNDS';
export const CARD_BANK_PROBLEM = 'CARD_BANK_PROBLEM';
export const CART_IN_WRONG_STATE = 'CART_IN_WRONG_STATE';
export const TICKET_TYPE_NOT_AVAILABLE = 'TICKET_TYPE_NOT_AVAILABLE';
export const CART_SPLIT_ORDER_NOT_SUPPORTED = 'CART_SPLIT_ORDER_NOT_SUPPORTED';
export const INVALID_CART_ID = 'INVALID_CART_ID';
export const PAYMENT_TYPE_NOT_PERMITTED = 'PAYMENT_TYPE_NOT_PERMITTED';
export const UNSUPPORTED_CARD_TYPE = 'CARD_UNSUPPORTED_TYPE';
export const INVALID_CARD_NUMBER = 'INVALID_CARD_NUMBER';
export const CARD_EXPIRED = 'CARD_EXPIRED';
export const INVALID_CARD_EXPIRATION_DATE = 'INVALID_CARD_EXPIRATION_DATE';

// checkout error context
export const CHECKOUT_CONTEXT = 'CHECKOUT';
export const CHECKOUT_NONCES = 'CHECKOUT NONCES';
export const CHECKOUT_PAYMENT_TOKEN = 'CHECKOUT PAYMENT TOKEN';

// print receipt storage
export const PRINT_RECEIPT_KEY = 'print-receipt-v2';
export const PRINT_RECEIPT_UPDATE_SETTINGS = 'print-receipt';

// failed payment checkout errors

const CARD_REJECT_GENERAL_DECLINE = 'CARD_REJECT_GENERAL_DECLINE';
const CARD_REJECT_CALL_FOR_AUTH = 'CARD_REJECT_CALL_FOR_AUTH';
const CARD_REJECT_LOST_OR_STOLEN = 'CARD_REJECT_LOST_OR_STOLEN';
const CARD_REJECT_EXPIRED_CARD = 'CARD_REJECT_EXPIRED_CARD';
const CARD_REJECT_INVALID_CARD_ACCOUNT = 'CARD_REJECT_INVALID-CARD_ACCOUNT';
const CARD_REJECT_TRY_AGAIN = 'CARD_REJECT_TRY_AGAIN';
const CARD_REJECT_TIMEOUT = 'CARD_REJECT_TIMEOUT';
const CARD_REJECT_COMMUNICATION_ERROR = 'CARD_REJECT_COMMUNICATION_ERROR';
const CARD_REJECT_ACCESS_NOT_ALLOWED = 'CARD_REJECT_ACCESS_NOT_ALLOWED';
const CARD_REJECT_REFUND_NOT_ALLOWED = 'CARD_REJECT_REFUND_NOT_ALLOWED';
const CARD_REJECT_AMOUNT_NOT_ALLOWED = 'CARD_REJECT_AMOUNT_NOT_ALLOWED';
const CARD_REJECT_MOP_NOT_ALLOWED = 'CARD_REJECT_MOP_NOT_ALLOWED';
const CARD_REJECT_INVALID_MOP = 'CARD_REJECT_INVALID_MOP';
const CARD_REJECT_INCORRECT_CID = 'CARD_REJECT_INCORRECT_CID';
const CARD_REJECT_CART_STRIPE_ERROR = 'CARD_REJECT_CART_STRIPE_ERROR';
const CARD_REJECT_SYSTEM_ERROR = 'CARD_REJECT_SYSTEM_ERROR';
const CARD_REJECT_INTERNAL_ERROR = 'CARD_REJECT_INTERNAL_ERROR';
const CARD_REJECT_INVALID_TRANSACTION_TYPE = 'CARD_REJECT_INVALID_TRANSACTION_TYPE';
const CARD_REJECT_INVALID_MERCHANT_MOP = 'CARD_REJECT_INVALID_MERCHANT_MOP';
const CARD_REJECT_INVALID_MERCHANT_NUMBER = 'CARD_REJECT_INVALID_MERCHANT_NUMBER';
const CARD_REJECT_FRAUD = 'CARD_REJECT_FRAUD';
const CARD_REJECT_UNKNOWN_BANK = 'CARD_REJECT_UNKNOWN_BANK';
const CARD_REJECT_UNKNOWN_RESPONSE = 'CARD_REJECT_UNKNOWN_RESPONSE';
const CARD_REJECT_BAD_DATA = 'CARD_REJECT_BAD_DATA';

export const CARD_REJECT_ERRORS = [
  CARD_REJECT_GENERAL_DECLINE,
  CARD_REJECT_CALL_FOR_AUTH,
  CARD_REJECT_LOST_OR_STOLEN,
  CARD_REJECT_EXPIRED_CARD,
  CARD_REJECT_INVALID_CARD_ACCOUNT,
  CARD_REJECT_TRY_AGAIN,
  CARD_REJECT_TIMEOUT,
  CARD_REJECT_COMMUNICATION_ERROR,
  CARD_REJECT_ACCESS_NOT_ALLOWED,
  CARD_REJECT_REFUND_NOT_ALLOWED,
  CARD_REJECT_AMOUNT_NOT_ALLOWED,
  CARD_REJECT_MOP_NOT_ALLOWED,
  CARD_REJECT_INVALID_MOP,
  CARD_REJECT_INCORRECT_CID,
  CARD_REJECT_CART_STRIPE_ERROR,
  CARD_REJECT_SYSTEM_ERROR,
  CARD_REJECT_INTERNAL_ERROR,
  CARD_REJECT_INVALID_TRANSACTION_TYPE,
  CARD_REJECT_INVALID_MERCHANT_MOP,
  CARD_REJECT_INVALID_MERCHANT_NUMBER,
  CARD_REJECT_FRAUD,
  CARD_REJECT_UNKNOWN_BANK,
  CARD_REJECT_UNKNOWN_RESPONSE,
  CARD_REJECT_BAD_DATA,
];

// delivery errors
export const ERROR_NO_BARCODE_TICKETS = 'ERROR_NO_BARCODE_TICKETS';
