import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CheckBox, Toggle } from '@ticketmaster/aurora';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Children, Component } from 'react';
import { IconOK } from '../icons';
import './styles.scss';
const Checkbox = ({ id, className, disabled, indeterminate, onClick, text, value, toggle, children, checked }) => {
    const defaultClassNames = {
        labelClassName: 'oc-checkbox',
        inputClassName: 'oc-checkbox__checkbox',
        buttonClassName: 'oc-checkbox__button',
        textClassName: 'oc-checkbox__label-text',
    };
    if (toggle) {
        Object.entries(defaultClassNames).forEach(([key, valuer]) => {
            defaultClassNames[key] = valuer.replace(/oc-checkbox/, 'oc-toggle-switch');
        });
    }
    return (_jsxs("label", { htmlFor: id, className: classNames(defaultClassNames.labelClassName, className), children: [_jsx("input", { className: defaultClassNames.inputClassName, disabled: disabled, checked: checked, onChange: onClick, type: "checkbox", id: id, value: value, ref: (input) => {
                    const inputRef = input;
                    if (input && indeterminate) {
                        inputRef.indeterminate = indeterminate;
                    }
                } }), _jsx("span", { className: defaultClassNames.buttonClassName, children: !toggle ? _jsx(IconOK, {}) : '' }), _jsx("span", { className: defaultClassNames.textClassName, children: text }), children || null] }));
};
const CheckboxList = (props) => {
    const items = Children.map(props.children, (item) => (_jsx("li", { className: "oc-checkbox-list__item", children: item }, item.props.value)));
    return _jsx("ul", { className: classNames('oc-checkbox-list', props.className), children: items });
};
class AuroraToggle extends Component {
    constructor(props) {
        super(props);
        this.onClick = () => {
            const newValue = !this.state.value;
            this.setState((prevState) => ({
                value: !prevState.value,
            }));
            if (this.props.input && this.props.input.onChange) {
                this.props.input.onChange(newValue);
            }
        };
        const initValue = props.input ? props.input.value : false;
        this.state = {
            value: initValue,
        };
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!nextProps.isFrozen && this.props.isFrozen) {
            this.setState({
                value: nextProps.input ? nextProps.input.value : false,
            });
        }
    }
    render() {
        const onClickCallback = this.props.isFrozen ? undefined : this.onClick;
        return (_jsx(Toggle, { ...this.props, onClick: onClickCallback, value: this.state.value, type: "button" }));
    }
}
CheckboxList.propTypes = {
    children: PropTypes.arrayOf(Checkbox).isRequired,
    className: PropTypes.string,
};
Checkbox.propTypes = {
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    children: PropTypes.node,
    disabled: PropTypes.bool,
    indeterminate: PropTypes.bool,
    onClick: PropTypes.func,
    toggle: PropTypes.bool,
    checked: PropTypes.bool,
};
AuroraToggle.propTypes = {
    isFrozen: PropTypes.bool,
    input: PropTypes.shape({
        onChange: PropTypes.func,
        value: PropTypes.bool,
    }),
};
const renderCheckboxComponent = (field) => (_jsx(CheckBox, { ...field, value: field.input.value, name: field.input.name, onClick: field.input.onChange, isChecked: field.checked, children: field.text }));
export { Checkbox, CheckboxList, AuroraToggle, renderCheckboxComponent };
