import { LogStatus } from '../../errorHandling/constants';
import { logClientToCloud } from '../../store/actions';
import * as ErrorHandler from './error-handler';
export function dispatchError(error, logComponent, { dispatch }) {
    if (ErrorHandler.isAlreadyHandled(error) || ErrorHandler.isErrorInvalid(error)) {
        return;
    }
    ErrorHandler.setAsHandled(error);
    dispatch(logClientToCloud({
        data: { error: ErrorHandler.toLogError(error) },
        status: LogStatus.ERROR,
        type: logComponent,
    }));
}
