import TmdsClient, { tmdsFragmentMessages } from '@ticketmaster/tm1pos-tmds-client';
import IconAlert24 from '@ticketmaster/tm1pos-web-shared/components/icons/IconAlert24';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { checkPrinterOnlineStatus } from '../../actions';

const printerDropdownStatus = (Wrapped) => {
  class DropdownWrap extends PureComponent {
    static propTypes = {
      dataProvider: PropTypes.array,
      printerList: PropTypes.array,
      errorMessage: PropTypes.string,
      invalid: PropTypes.bool,
      selection: PropTypes.object,
      selectedPrinter: PropTypes.object,
    };

    componentDidMount() {
      this.props.dispatch(checkPrinterOnlineStatus(true));
      TmdsClient.clearPrinterErrorNotification();
    }

    componentWillUnmount() {
      this.props.dispatch(checkPrinterOnlineStatus(false));
    }

    get invalidSelection() {
      const selected = this.props.selection || this.props.selectedPrinter;
      if (selected && selected.hasError) {
        const dp = this.props.dataProvider || this.props.printerList || [];
        return dp.find(({ id }) => id === selected.value);
      }
      return null;
    }

    get invalid() {
      return this.props.invalid || Boolean(this.invalidSelection);
    }

    get errorMessage() {
      if (!this.props.errorMessage && this.invalidSelection) {
        const { title } = TmdsClient.printerStatusNotify(this.invalidSelection.status);
        const titleValues = Object.assign(title.values || {}, {
          printer: <FormattedMessage {...tmdsFragmentMessages.printer} />,
          suggestion: '',
        });
        return <FormattedMessage {...title} values={titleValues} />;
      }
      return this.props.errorMessage;
    }

    /*
     *   optionsContentRenderer
     *   params (dataLabel, item, props)
     *   returns options content
     */
    optionsContentRenderer = (dataLabel, item = {}) => {
      if (item && !item.hasError) {
        return dataLabel;
      }
      return (
        <span>
          <IconAlert24 />
          {dataLabel}
        </span>
      );
    };

    render() {
      return (
        <Wrapped
          {...this.props}
          optionsContentRenderer={this.optionsContentRenderer}
          invalid={this.invalid}
          errorMessage={this.errorMessage}
        />
      );
    }
  }

  const mapDispatchToProps = (dispatch) => ({ dispatch });

  return connect(null, mapDispatchToProps)(DropdownWrap);
};

export default printerDropdownStatus;
