import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    collapseSection: {
        id: 'shared.ExpandableSection.collapseSection',
        defaultMessage: 'Collapse section',
    },
    expandSection: {
        id: 'shared.ExpandableSection.expandSection',
        defaultMessage: 'Expand section',
    },
});
