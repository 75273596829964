import { LogComponent } from '../../errorHandling/constants';
import { dispatchError } from '../../services/errors/error-dispatcher';
export function createUnhandledErrorsMiddleware() {
    return (store) => (next) => (action) => {
        try {
            return next(action);
        }
        catch (error) {
            dispatchError(error, LogComponent.UNHANDLED_MIDDLEWARE_ERROR, store);
            throw error;
        }
    };
}
