export const CHAR_LENGTH = 2;
export const EVENT_HEIGHT = 76;
export const DEFAULT_WEIGHTED_SEARCH_KEYS = [
    {
        name: 'name',
        weight: 0.8,
    },
    {
        name: 'time',
        weight: 0.05,
    },
    {
        name: 'location',
        weight: 0.05,
    },
    {
        name: 'weekday',
        weight: 0.05,
    },
    {
        name: 'fullDateShortMonth',
        weight: 0.05,
    },
];
