import { LogComponent, LogStatus } from '../errorHandling/constants';
import { actionHistory } from '../middleware/actionHistory/debugging';
import { CLIENT_CLOUD_LOG, SET_INVENTORY_TYPE } from './actions-constants';
export function setInventoryType(type) {
    return {
        type: SET_INVENTORY_TYPE,
        inventoryType: type,
    };
}
export const logClientToCloud = (log = { data: {}, status: LogStatus.UNKNOWN, type: LogComponent.APP }) => ({
    type: CLIENT_CLOUD_LOG,
    log: {
        ...log,
        data: {
            ...log.data,
            latestActions: actionHistory === null || actionHistory === void 0 ? void 0 : actionHistory.getLastActions(),
            currentUrl: window.location.href,
        },
    },
});
