export const mixpanelConsts = {
  ARCHTICS: 'Archtics',
  HOST: 'Host',
  OPCODE: 'OPCODE',
  EVENT_SEARCH_PAGE: 'Event Search Page',
  EVENT_DETAIL_PAGE: 'Event Details Page',
  CHECKOUT_PAGE: 'Checkout Page',
  CONFIRMATION_PAGE: 'Confirmation Page',
  APP_LOAD: 'To: App Load',
  EVENT_LOAD_TIME: 'Sdr: Load Time Event Info',
  EVENT_DETAIL_PAGE_LOAD_TIME: 'Sdr: Full Event Page Load Time',
  GEOMETRY_LOAD_TIME: 'Sdr: Load Time Geometry',
  SELECT_EVENT_FROM_LIST: 'Sdr: Event Selected From List On Search',
  TICKET_TYPE_CHANGE: 'Sdr: Ticket Type Selector Change From Default',
  EVENT_DETAIL_PAGE_PAGE_VISIT: 'Sdr: Event Detail Page Visit',
  QUANTITY_SELECTOR: 'Sdr: Quantity Selector Changed From Default',
  DELETE_INVENTORY_IN_BAG: 'Sdr: Delete Inventory In Bag',
  DELETE_INVENTORY_IN_CART: 'Sdr: Delete Inventory In Cart',
  PRICE_RANGE: 'Sdr: Price Range EDP Selected',
  PRICE_RANGE_MODEL_TOGGLE: 'Sdr: Price Range Modal Selected',
  SHOPPING_BAG_CHECKOUT_TOGGLE: 'Sdr: Shopping Bag Proceed To Checkout',
  NAV_BAR_BAG_CHECKOUT_TOGGLE: 'Sdr: Nav Bar Proceed To Checkout',
  FIND_BEST_AVAILABLE_SEATS: 'Sdr: Best Available Seats Search',
  PAYMENT_PROCESSING: 'Sdr: Processing Payment',
  CASH_PAYMENT_PROCESSING: 'Sdr: Processing Payment With Cash',
  CARD_PAYMENT_PROCESSING_VIA_SWIPE: 'Sdr: Processing Payment With Card Via Swipe',
  CARD_PAYMENT_PROCESSING_VIA_MANUAL_INPUT: 'Sdr: Processing Payment With Card Via Manual Input',
  GROSS_PRICE_TICKETS: 'Sdr: Gross Price Ticket',
  CONTINUE_SHOPPING_AFTER_CHECKOUT: 'Sdr: Continue Shopping After Checkout',
  CUSTOMER_INFO: 'Sdr: Entering Customer Info',
  SUCCESS_PLACE_TO_RESERVE: 'Sdr: Time To Reserve ISM Seats',
  SUCCESS_BEST_AVAILABLE_SEATS: 'Sdr: Time To Reserve BA Seats',
  CAPTURE_BUYER_INFO: 'Sdr: Capture Buyer Info',
  SUCCESS_COMPLETED_ORDER: 'Sdr: Complete Order',
};

export const checkoutToggleLocation = {
  NAV: 'NAV',
  SHOPPING_BAG: 'SHOPPING_BAG',
  CONTINUE_SHOPPING: 'CONTINUE_SHOPPING',
};
export const clearCartLocation = {
  SHOPPING_BAG: 'BAG',
  CART: 'CART',
  HOMEPAGE: 'HOMEPAGE',
  CANCEL_BUTTON: 'CANCEL_BUTTON',
  LOCATION_CHANGED: 'LOCATION_CHANGED',
};
