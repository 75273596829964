import localForage from 'localforage';
import { ForageUtilsBase } from './ForageUtilsBase';
class PersistLocalForage extends ForageUtilsBase {
    constructor() {
        const store = localForage.createInstance({
            name: 'TM1 Sales P',
        });
        super(store);
    }
}
export const persistLocalForageUtils = new PersistLocalForage();
