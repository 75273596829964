import { GraphQLResult } from '@ticketmaster/tm1pos-web-shared/model/graphql';
import { fetchFromSalesApi } from '@ticketmaster/tm1pos-web-shared/services/sales-api-client/sales-api';
import { call, Effect } from 'redux-saga/effects';
import { GENERATE_ISM_TOKEN_QUERY } from 'services/sales-api-client/api-queries';
import { GenerateIsmApiTokenResult } from 'services/sales-api-client/model/generate-ism-api-token-result';
import { UTILS_SERVICE_URL } from '../../constants';

export function* generateIsmApiToken(
  eventId: string,
): Generator<Effect, GraphQLResult<GenerateIsmApiTokenResult>, GraphQLResult<GenerateIsmApiTokenResult>> {
  const defaultDataResponse: GraphQLResult<GenerateIsmApiTokenResult> = { data: { token: '' } };
  const response = yield call(fetchFromSalesApi, {
    url: UTILS_SERVICE_URL,
    query: GENERATE_ISM_TOKEN_QUERY,
    options: {
      variables: {
        eventId,
      },
    },
  });

  if (!response.data?.token) {
    return { ...response, ...defaultDataResponse };
  }

  return response;
}
