import { createSelector } from '@reduxjs/toolkit';
import IconFeedback from '@ticketmaster/tm1pos-web-shared/components/icons/IconFeedback';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { selectFeedbackContacts } from '../../../../selectors';

import Modal from './components/Modal';

import './styles.scss';

export class Feedback extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  toggle = () => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
  };

  render() {
    const { email, phone, link } = this.props.feedbackContacts;
    const feedbackInfoAvailable = email || phone || link;
    const allowShowModal = this.state.showModal && feedbackInfoAvailable;
    return (
      <div className="header__profile text-decoration-none header__feedback">
        <a tabIndex={-1} onClick={this.toggle} role="button" className="oc-feedback-icon-container">
          <IconFeedback />
        </a>
        {allowShowModal ? <Modal onClose={this.toggle} {...this.props.feedbackContacts} /> : null}
      </div>
    );
  }
}

Feedback.propTypes = {
  feedbackContacts: PropTypes.shape({
    email: PropTypes.string,
    phone: PropTypes.string,
    link: PropTypes.string,
  }),
};

const mapStateToProps = createSelector(selectFeedbackContacts, (feedbackContacts) => ({ feedbackContacts }));

export default connect(mapStateToProps, null)(Feedback);
