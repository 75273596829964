import { renderInputComponent } from '@ticketmaster/tm1pos-web-shared/components/InputComponent';
import { intlShape } from '@ticketmaster/tm1pos-web-shared/typings/react-intl-types';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Field } from 'redux-form/immutable';
import messages from '../../../../messages';

export class CreditCardForm extends Component {
  validateAccountNumberInput = (value) =>
    /^\d{12,}$/.exec(value) ? undefined : this.props.intl.formatMessage(messages.accountNumberValidationError);

  validateCardHolderInput = (value) => (value ? undefined : this.props.intl.formatMessage(messages.required));

  validateExpDateInput = (value) => {
    if (value) {
      const match = value.match(/^(\d\d)\/\d\d(\d\d)?$/);
      if (match && match[0]) {
        const month = parseInt(match[1], 10);
        if (month > 0 && month <= 12) {
          return undefined;
        }
      }
    }

    return this.props.intl.formatMessage(messages.expDateValidationError);
  };

  normalizeOnlyDigits = (value) => {
    if (!value) {
      return value;
    }
    return value.replace(/[^\d]/g, '');
  };

  normalizeExpDateInput = (value) => {
    const onlyNums = this.normalizeOnlyDigits(value);

    if (onlyNums.length <= 2) {
      return onlyNums;
    }
    return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 6)}`;
  };

  render() {
    return (
      <div className="card-entry__form">
        <Field
          name={this.props.fieldNames.cardHolderName}
          className="sdr-checkout__input"
          component={renderInputComponent}
          placeholder={this.props.cardHolderLabel}
          validate={this.validateCardHolderInput}
          autoComplete="off"
        />
        <Field
          name={this.props.fieldNames.accountNumber}
          className="sdr-checkout__input"
          component={renderInputComponent}
          placeholder={this.props.cardNumberLabel}
          validate={this.validateAccountNumberInput}
          normalize={this.normalizeOnlyDigits}
          autoComplete="cc-csc"
        />
        <div className="card-entry__fields">
          <Field
            name={this.props.fieldNames.expDate}
            className="sdr-checkout__input"
            component={renderInputComponent}
            placeholder={this.props.cardExpDateLabel}
            validate={this.validateExpDateInput}
            normalize={this.normalizeExpDateInput}
            autoComplete="cc-csc"
          />
        </div>
      </div>
    );
  }
}

CreditCardForm.propTypes = {
  cardHolderLabel: PropTypes.string,
  cardNumberLabel: PropTypes.string,
  cardExpDateLabel: PropTypes.string,
  fieldNames: PropTypes.object,
  intl: intlShape.isRequired,
};

export default injectIntl(CreditCardForm);
