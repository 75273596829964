import { selectHasActivePrinter } from '@ticketmaster/tm1pos-web-shared/printing/printingModule-selectors';
import { useSelector } from 'react-redux';
import PrintControl from './PrintControl';

const PrintControlConnected = () => {
  const hasActivePrinter = useSelector(selectHasActivePrinter);
  return <PrintControl hasActivePrinter={hasActivePrinter} />;
};
PrintControlConnected.displayName = 'PrintControlConnected';
export default PrintControlConnected;
