import { SYSTEM_ID } from '../constants';
import { getInventoryTypeByEventId } from '../store/events/utils';
export function getCheckoutTarget(userInformation, eventId) {
    var _a, _b;
    if (!userInformation || !eventId) {
        return undefined;
    }
    const isUserHostOnly = ((_a = userInformation.host) === null || _a === void 0 ? void 0 : _a.opCodes) && !userInformation.archtics;
    const isUserArchticsOnly = !((_b = userInformation.host) === null || _b === void 0 ? void 0 : _b.opCodes) && userInformation.archtics;
    if (isUserHostOnly) {
        return SYSTEM_ID.HOST;
    }
    if (isUserArchticsOnly) {
        return SYSTEM_ID.ARCHTICS;
    }
    return getInventoryTypeByEventId(eventId);
}
