import { currencyFormatOptions } from '@ticketmaster/tm1pos-web-shared/constants';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';

export const getLocaleFormats = (locale: string, options: any | undefined) => {
  const format = new Intl.NumberFormat(locale);
  const numberToFormat = 12345.6789;

  const parts = format.formatToParts(numberToFormat);
  const numerals = Array.from({ length: 10 }).map((_, i) => format.format(i));
  const groupingSeparator = parts.find((part) => part.type === 'group')?.value;
  const decimalSeparator = parts.find((part) => part.type === 'decimal')?.value;

  let currency;
  if (options?.currency) {
    const formatCurrency = new Intl.NumberFormat(locale, options);
    const partsCurrency = formatCurrency.formatToParts(numberToFormat);
    const symbol = partsCurrency.find((part) => part.type === 'currency')?.value;
    const fractionDigits = partsCurrency.find((part) => part.type === 'fraction')?.value.length || 0;

    currency = {
      code: options.currency,
      symbol,
      fractionDigits,
    };
  }

  return {
    numerals,
    groupingSeparator,
    decimalSeparator,
    currency,
  };
};

export type Props = {
  cashTenderedLabel: string;
  meta: any;
  locale: string;
  currency: string;
  cashTenderedValue: string | number;
  onValueChange: (value: number) => void;
};

export const CurrencyInput = ({
  cashTenderedLabel,
  meta,
  locale,
  currency,
  cashTenderedValue,
  onValueChange,
}: Props) => {
  const localeFormats = getLocaleFormats(locale, { ...currencyFormatOptions, currency });

  const handleChange = (values: any) => {
    const cashTendered = values?.floatValue || 0;
    onValueChange(cashTendered);
  };

  return (
    <div
      className={classNames('sdr-checkout__cash-tendered-input', {
        'sdr-checkout__cash-tendered-input--error': meta?.error,
      })}
    >
      <label className="oc-label">{cashTenderedLabel}</label>
      <NumberFormat
        className="oc-textfield__input"
        thousandSeparator={localeFormats?.groupingSeparator}
        decimalSeparator={localeFormats?.decimalSeparator}
        prefix={`${localeFormats?.currency?.symbol} `}
        decimalScale={localeFormats?.currency?.fractionDigits}
        // localeFormats.numerals will be an array of 10 strings
        customNumerals={
          localeFormats?.numerals as [string, string, string, string, string, string, string, string, string, string]
        }
        placeholder={`${localeFormats?.currency?.symbol} 0`}
        value={cashTenderedValue}
        allowLeadingZeros={false}
        inputMode="numeric"
        onValueChange={handleChange}
      />
      {meta.error && <div className="sdr-checkout__cash-tendered-input__message">{meta.error}</div>}
    </div>
  );
};
