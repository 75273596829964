import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectActivePrinter, selectPrinterServices, selectPrintingModuleState } from '../../printingModule-selectors';
import { clearErrorQzConnect, printTestTicket, refreshPrinters, setActivePrinter, setPrinterServiceRefreshed, } from '../../printingModule-slice';
import EditPrinterConfiguration from './EditPrinterConfiguration';
const EditPrinterConfigurationConnected = forwardRef(({ onPrinterValidChange, className, readOnly = false, showTestPrinter = false, showValidationMessage, }, ref) => {
    const allowedServices = useSelector(selectPrinterServices);
    const { printers, loading, connectionError, printerServiceRefreshed } = useSelector(selectPrintingModuleState);
    const activePrinter = useSelector(selectActivePrinter);
    const dispatch = useDispatch();
    const onSave = useCallback((printer) => {
        dispatch(setActivePrinter(printer));
    }, [dispatch]);
    const onCancel = useCallback(() => {
        dispatch(setPrinterServiceRefreshed(false));
        dispatch(clearErrorQzConnect());
    }, [dispatch]);
    const onRefreshPrinter = useCallback((printerService, remoteHost) => {
        dispatch(refreshPrinters({ printerService, remoteHost }));
    }, [dispatch]);
    const onTestPrinter = (printer) => {
        dispatch(printTestTicket(printer));
    };
    const forceInitialRefresh = useRef(true);
    useEffect(() => {
        if ((activePrinter === null || activePrinter === void 0 ? void 0 : activePrinter.printerService) && !printerServiceRefreshed && forceInitialRefresh.current) {
            onRefreshPrinter(activePrinter.printerService, activePrinter.remoteHost);
        }
        forceInitialRefresh.current = false;
    }, [activePrinter, printerServiceRefreshed, forceInitialRefresh, onRefreshPrinter]);
    return (_jsx(EditPrinterConfiguration, { className: className, allowedServices: allowedServices, loading: loading, printers: printers, activePrinter: activePrinter, onSave: onSave, onCancel: onCancel, onRefreshPrinter: onRefreshPrinter, connectionError: connectionError, printerServiceRefreshed: printerServiceRefreshed, readOnly: readOnly, onPrintTestTicket: onTestPrinter, showTestPrinter: showTestPrinter, onPrinterValidChange: onPrinterValidChange, ref: ref, showValidationMessage: showValidationMessage }));
});
EditPrinterConfigurationConnected.displayName = 'EditPrinterConfigurationConnected';
export default EditPrinterConfigurationConnected;
