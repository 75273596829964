import { call, put } from 'redux-saga/effects';
import { JWT_TOKEN_EXPIRED } from '../../store/actions-constants';
import { executeQuery, executeQueryWithCacheManagement, handleErrors } from './sales-api-functions';
export const INVALID_JWT_TOKEN_MESSAGES = ['Jwt is expired'];
export function* fetchFromSalesApi(fetchArgs) {
    const { cacheKey, onError } = fetchArgs;
    let response;
    try {
        if (cacheKey) {
            response = yield* executeQueryWithCacheManagement(fetchArgs, cacheKey);
        }
        else {
            response = yield* executeQuery(fetchArgs);
        }
        yield call(handleErrors, response, onError);
        return response;
    }
    catch (err) {
        if (INVALID_JWT_TOKEN_MESSAGES.includes(err.message)) {
            yield put({ type: JWT_TOKEN_EXPIRED, content: err });
        }
        else {
            throw err;
        }
    }
    return {};
}
