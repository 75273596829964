import { createSelector } from 'reselect';
import { CartResponse } from '../../../../../services/sales-api-client/model/cart-response';
import { EventFee } from '../../../../../services/sales-api-client/model/order-fee';
import { selectCurrentEventFees } from '../../../../EventDetailPage/selectors/main';
import { selectCart } from '../../../selectors';
import { OtherCharge } from './model/other-charge';

export const selectOtherCharges = createSelector(
  selectCurrentEventFees,
  selectCart,
  (allEventFees: EventFee[], cart: CartResponse) => {
    const otherCharges: OtherCharge[] = allEventFees.map((eventFee) => ({
      ...eventFee,
      quantity: getCartedFeeQuantity(eventFee, cart),
    }));

    return otherCharges;
  },
);

const getCartedFeeQuantity = (eventFee: EventFee, cart: CartResponse) =>
  (cart.eventFees || []).find((cartEventFee) => cartEventFee.id === eventFee.id)?.quantity;
