import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './styles.scss';
const ButtonComponent = (props) => {
    const styleClasses = classNames(props.className);
    return (_jsx("div", { className: "btn-wrap", children: _jsx("button", { id: props.id, type: props.type, className: styleClasses, onClick: props.onButtonClick, disabled: props.disabled, children: props.children }) }));
};
ButtonComponent.propTypes = {
    children: PropTypes.node.isRequired,
    onButtonClick: PropTypes.func,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    disabled: PropTypes.bool,
    type: PropTypes.string,
    id: PropTypes.string,
};
export default ButtonComponent;
