import { PRINT } from '@ticketmaster/tm1pos-web-shared/constants';
import { ErrorInfo } from '@ticketmaster/tm1pos-web-shared/model/graphql';
import { Buffer } from 'buffer';
import cryptoBrowserify from 'crypto-browserify';
import { toNumber } from 'lodash';
import { CARD, CARD_REJECT_ERRORS } from './constants';
import { getRandomSalt } from './utils';

export function decodeReceiptMessage(encodedData = '', defaults: any = {}) {
  try {
    const decoded = atob(encodedData);
    return {
      approvalCode: getDecodedValue(decoded, /^(Auth #|# d'Autor\.)\s*:\s*([^\s]*)\s*$/gm, 2, defaults.approvalCode),
      cardEntryMode: getDecodedValue(
        decoded,
        /^(Card Entry|Entr.e de Carte)\s*:\s*([^\s]*)\s*$/gm,
        2,
        defaults.cardEntryMode,
      ),
      terminalVerificationResult: getDecodedValue(
        decoded,
        /^TVR\s*:\s*([^\s]*)\s*$/gm,
        1,
        defaults.terminalVerificationResult,
      ),
      transactionType: getDecodedValue(
        decoded,
        /^(Trans Type|Type de Trans\.)\s*:\s*([^\s]*)\s*$/gm,
        2,
        defaults.transactionType,
      ),
      terminalId: getDecodedValue(decoded, /^Term ID\s*:\s*([^\s]*)\s*$/gm, 1, defaults.terminalId),
      applicationId: getDecodedValue(decoded, /^AID\s*:\s*([^\s]*)\s*$/gm, 1, defaults.applicationIdentifier),
    };
  } catch (e) {
    // TODO add CLIENT_LOG action
    // eslint-disable-next-line no-console
    console.error(e);
  }
  return {};
}

export const getDecodedValue = (decoded: string, regex: RegExp, index: number, defaultValue: any) => {
  const regExResult = regex.exec(decoded);

  if (regExResult && regExResult[index]) {
    return regExResult[index];
  }
  return defaultValue;
};

export function encryptCardData(number: string | null, paymentCertificate: any) {
  try {
    const RSA_PKCS1_PADDING = 1;
    const { body: cert } = paymentCertificate;
    const adjustedCert = cert.replace(/\s{2,10}/g, '\n');
    const toEncrypt = `${getRandomSalt()}${number}`;

    return cryptoBrowserify
      .publicEncrypt(
        {
          key: adjustedCert,
          padding: RSA_PKCS1_PADDING,
        },
        Buffer.from(toEncrypt),
      )
      .toString('base64');
  } catch (error) {
    console.log('failed to encrypt card number', error); // eslint-disable-line no-console
    return null;
    // TODO add CLIENT_LOG action
  }
}

export function hashCreditCardNumber(creditCardValue: string | null) {
  if (creditCardValue === null) {
    return null;
  }
  let cardNumBeforeHash;
  let hashedCardNumber;
  const seed = 'MsPiD';
  try {
    const cardString = creditCardValue.toString();
    if (toNumber(creditCardValue) % 10 !== 1) {
      cardNumBeforeHash = seed + cardString;
    } else {
      cardNumBeforeHash = cardString + seed;
    }
    hashedCardNumber = cryptoBrowserify.createHash('sha1').update(cardNumBeforeHash).digest('hex').toUpperCase();
    return hashedCardNumber;
  } catch (error) {
    return null;
    // TODO add CLIENT_LOG action
  }
}

export const getKnownError = (errors: ErrorInfo[]) =>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  errors.find((error) => CARD_REJECT_ERRORS.indexOf(error.code!) !== -1);

export const isRequiredFieldsValid = (
  isEmvSupported: boolean,
  manualCardEntry: boolean,
  isCheckoutFormValid: boolean,
  fullName: string,
): boolean => {
  if (isEmvSupported && manualCardEntry) {
    return isCheckoutFormValid || !!fullName;
  }
  return isCheckoutFormValid;
};

export const isPrinterValid = (
  deliveryMethod: string,
  hasPrintReceipt: boolean,
  hasActivePrinter: boolean,
): boolean => {
  if (deliveryMethod !== PRINT && !hasPrintReceipt) {
    return true;
  }
  return hasActivePrinter;
};

export const isCardPaymentValid = (
  isEmvSupported: boolean,
  cardPaymentEnabled: boolean,
  hasActivePaymentDevice: boolean,
) => cardPaymentEnabled && (!isEmvSupported || hasActivePaymentDevice);

export const isCheckoutFormComplete = (
  isEmvSupported: boolean,
  manualCardEntry: boolean,
  isCheckoutFormValid: boolean,
  fullName: string,
  deliveryMethod: string,
  hasPrintReceipt: boolean,
  hasActivePrinter: boolean,
  hasActivePaymentDevice: boolean,
  paymentMethod: string,
  cardPaymentEnabled: boolean,
) =>
  isRequiredFieldsValid(isEmvSupported, manualCardEntry, isCheckoutFormValid, fullName) &&
  isPrinterValid(deliveryMethod, hasPrintReceipt, hasActivePrinter) &&
  (paymentMethod !== CARD || isCardPaymentValid(isEmvSupported, cardPaymentEnabled, hasActivePaymentDevice));
