import { withMatcher } from '@ticketmaster/tm1pos-web-shared/utils/withMatcher';

import {
  APP_LOADED,
  CANCEL_EMV_CHECKOUT,
  CLEAR_CART,
  CLOSE_FEEDBACK_MODAL,
  FETCH_FEEDBACK_CONTACTS,
  FETCH_SUBMIT_FEEDBACK,
  GET_FEEDBACK_CONTACTS,
  PRINTER_DROPDOWN_VISIBLE_ONLINE_CHECK,
  PROMPT_ORDER_DELETE,
  QUALIFIED_HOLDS_TOGGLE,
  SET_DEFAULT_INVENTORY_TYPE,
  START_LOGIN,
  SUBMIT_FEEDBACK,
  TOGGLE_CHECKOUT,
  USER_INACTIVITY,
} from './actions-constants';

export const startLogin = () => ({
  type: START_LOGIN,
});

export const appLoaded = () => ({
  type: APP_LOADED,
});
export const submitFeedback = ({ feedbackText }) => ({
  type: SUBMIT_FEEDBACK,
  feedbackText,
  ...FETCH_SUBMIT_FEEDBACK,
});
export const getFeedbackContacts = () => ({
  type: GET_FEEDBACK_CONTACTS,
  ...FETCH_FEEDBACK_CONTACTS,
});

export const clearCart = (clearPlace) => ({
  type: CLEAR_CART,
  clearPlace,
});
export const toggleCheckout = ({ location, showCart }) => ({
  type: TOGGLE_CHECKOUT,
  location,
  showCart,
});
export const promptOrderDelete = () => ({
  type: PROMPT_ORDER_DELETE,
});

export const closeFeedbackModal = () => ({
  type: CLOSE_FEEDBACK_MODAL,
});

export const checkPrinterOnlineStatus = (onScreen) => ({
  type: PRINTER_DROPDOWN_VISIBLE_ONLINE_CHECK,
  isOnScreen: onScreen,
});

export function setDefaultInventoryType(type) {
  return {
    type: SET_DEFAULT_INVENTORY_TYPE,
    inventoryType: type,
  };
}

export function setQualifiedHoldsToggle(toggleValue) {
  return {
    type: QUALIFIED_HOLDS_TOGGLE,
    isActive: toggleValue,
  };
}

export function cancelEMVCheckout() {
  return {
    type: CANCEL_EMV_CHECKOUT,
  };
}

export const userInactivity = () => ({
  type: USER_INACTIVITY,
});

const USER_SIGNOUT = 'USER_SIGNOUT';
export const userSignout = withMatcher(() => ({ type: USER_SIGNOUT }));
