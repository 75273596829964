import { createSelector } from 'reselect';
export const selectFeatureFlags = createSelector((state) => state.featureFlags.resolvedFlags, (flags = {}) => overrideWithLocalFlags(flags));
const overrideWithLocalFlags = (flags) => {
    let flagsWithLocalOverrides = flags;
    try {
        const localFlags = localStorage.getItem('sales-feature-flags');
        if (localFlags) {
            flagsWithLocalOverrides = {
                ...flags,
                ...(localFlags ? JSON.parse(localFlags) : {}),
            };
        }
    }
    catch (ex) {
        console.warn('Invalid feature flags in localStorage.');
    }
    return flagsWithLocalOverrides;
};
