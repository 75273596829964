import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Component, createRef } from 'react';
import './styles.scss';
export default class InputComponent extends Component {
    constructor(props) {
        super(props);
        this.onKeyPress = (event) => {
            if (event.key === 'Enter' && this.props.onEnter) {
                this.props.onEnter();
            }
        };
        this.state = {
            value: props.value || '',
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.input = createRef();
    }
    componentDidMount() {
        if (this.props.autoSelect) {
            this.input.select();
        }
        if (this.props.setRefName) {
            this.props.setRefName(this.input);
        }
    }
    UNSAFE_componentWillReceiveProps(props) {
        if (this.state.value !== props.value) {
            this.setState({ value: props.value });
        }
    }
    handleFocus(e) {
        if (this.props.autoSelect) {
            this.input.select();
        }
        if (this.props.handleOnFocus) {
            this.props.handleOnFocus(e);
        }
    }
    handleChange(event) {
        let value = event.target.value;
        if (this.props.onInputChange) {
            value = this.props.onInputChange(value) || value;
        }
        if (!this.props.input || !this.props.input.onChange) {
            this.setState({ value });
        }
        return true;
    }
    render() {
        const hasErrorTest = this.props.meta && this.props.meta.invalid && this.props.meta.dirty && !this.props.focusOn;
        const wrapClass = classNames('oc-textfield', this.props.className, {
            'oc-textfield--error': hasErrorTest,
            'oc-textfield--error__tooltip': hasErrorTest,
        });
        const toolTipClassName = classNames('oc-textfield__input', {
            'sdr-inputBox--error': hasErrorTest,
        });
        let tips = null;
        if (hasErrorTest) {
            if (Array.isArray(this.props.meta.error)) {
                tips = (_jsxs("div", { className: "sdr-inputBox--error-tips_container", children: [_jsxs("div", { className: "sdr-inputBox--error-tips_left", children: [this.props.meta.error[0], " "] }), _jsxs("div", { className: "sdr-inputBox--error-tips_right", children: [this.props.meta.error[1], " "] })] }));
            }
            else {
                tips = _jsxs("div", { className: "sdr-inputBox--error-tips", children: [this.props.meta.error, " "] });
            }
        }
        return (_jsxs("div", { className: wrapClass, children: [_jsx("label", { className: "oc-label", htmlFor: this.props.id, children: this.props.labelText }), _jsx("input", { ...this.props.input, type: this.props.type, value: this.state.value, onBlur: this.props.handleOnBlur, onChange: this.handleChange, onFocus: this.handleFocus, placeholder: this.props.placeholder, className: toolTipClassName, id: this.props.id, disabled: this.props.disabled, ref: (input) => {
                        this.input = input;
                    }, onKeyPress: this.onKeyPress, autoFocus: this.props.autofocus, autoComplete: this.props.autoComplete }), tips] }));
    }
}
InputComponent.propTypes = {
    autoSelect: PropTypes.bool,
    focusOn: PropTypes.bool,
    handleOnBlur: PropTypes.func,
    handleOnFocus: PropTypes.func,
    onEnter: PropTypes.func,
    id: PropTypes.string,
    autofocus: PropTypes.bool,
    input: PropTypes.object,
    meta: PropTypes.object,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    labelText: PropTypes.string,
    onInputChange: PropTypes.func,
    setRefName: PropTypes.func,
    autoComplete: PropTypes.string,
};
export const onBlockSwipeInput = ({ input }) => (event) => {
    let fchange = event;
    if (/%/g.test(event)) {
        fchange = input.value;
        document.activeElement.blur();
    }
    if (input && input.onChange) {
        input.onChange.apply(input, [fchange]);
    }
    return fchange;
};
export const renderInputComponent = (field) => (_jsx(InputComponent, { ...field, value: field.input.value, onInputChange: onBlockSwipeInput(field) }));
export const renderInputComponentRedelivery = (field) => (_jsx(InputComponent, { ...field, value: field.input.value, onInputChange: field.input.onChange }));
