import { context } from '@opentelemetry/api';
import { setSpanContext } from '@opentelemetry/api/build/src/trace/context-utils';
import _ from 'lodash';
import { call, put, select } from 'redux-saga/effects';
import { v1 as uuid } from 'uuid';
import { UI_VERSION } from '../constants';
import { selectFeatureFlags } from '../feature-flags';
import tracerService from '../services/tracer/tracer.service';
import { selectAuthToken, selectUserInformation } from '../store/selectors/user-selector';
import { getUserFromStorage } from './getUserFromStorage';
export const CORRELATION_ID_HEADER_NAME = 'TMPS-Correlation-Id';
export function delay(millis) {
    return new Promise((resolve) => {
        setTimeout(() => resolve(true), millis);
    });
}
export const fetchJson = async (url, options) => {
    const response = await fetch(url, options);
    if (response.type !== 'opaque' && response.type !== 'opaqueredirect' && !response.ok) {
        throw new Error(await response.text());
    }
    const cid = (options === null || options === void 0 ? void 0 : options.headers) ? options.headers[CORRELATION_ID_HEADER_NAME] : undefined;
    return response.body ? response.json().then((payload) => addCorrelationIdIfErrorPresent(payload, cid)) : null;
};
const addCorrelationIdIfErrorPresent = (payload, cid) => {
    if (!_.isEmpty(payload.errors) && cid) {
        return {
            ...payload,
            errors: payload.errors.map((err) => ({ cid, ...err })),
        };
    }
    return payload;
};
export const fetchExternalData = (request = {}) => {
    const parentSpan = request.parentTracedOperation && tracerService.getSpan(request.parentTracedOperation);
    if (parentSpan) {
        const contextHavingParent = setSpanContext(context.active(), parentSpan.spanContext());
        return context.with(contextHavingParent, () => getFetchResult(request));
    }
    return getFetchResult(request);
};
const getFetchResult = ({ url = '', options = {}, qualifier = () => true, defaultDataResponse = [], modifier = (data) => data, }) => fetchJson(url, options).then((data) => {
    let modifiedData;
    try {
        modifiedData = modifier(data);
    }
    catch (e) {
        console.log('fetch modifier error ', e);
        modifiedData = data;
    }
    if (data && qualifier(data)) {
        return modifiedData;
    }
    if (defaultDataResponse instanceof Array) {
        return modifiedData || defaultDataResponse;
    }
    return { ...modifiedData, ...defaultDataResponse };
});
export function* handleResponse(action, response, skipSuccess = false) {
    var _a;
    try {
        if (!response || ((_a = response === null || response === void 0 ? void 0 : response.errors) === null || _a === void 0 ? void 0 : _a.length) > 0 || !(response === null || response === void 0 ? void 0 : response.data)) {
            const errors = (response === null || response === void 0 ? void 0 : response.errors) || [new Error('Could not fetch.')];
            yield put({
                type: action.ERROR,
                errors,
            });
        }
        else if (!skipSuccess) {
            yield put({
                type: action.SUCCESS,
                data: response,
            });
        }
    }
    catch (caughtError) {
        yield put({
            type: action.ERROR,
            errors: [caughtError],
        });
    }
    return response;
}
export function* getUserIdentityData() {
    var _a, _b, _c;
    const userInformation = yield select(selectUserInformation);
    if (userInformation != null) {
        return {
            archticsDsn: (_a = userInformation === null || userInformation === void 0 ? void 0 : userInformation.archtics) === null || _a === void 0 ? void 0 : _a.dsn,
            archticsUserId: (_b = userInformation === null || userInformation === void 0 ? void 0 : userInformation.archtics) === null || _b === void 0 ? void 0 : _b.userId,
            opCodes: (_c = userInformation === null || userInformation === void 0 ? void 0 : userInformation.host) === null || _c === void 0 ? void 0 : _c.opCodes,
        };
    }
    return null;
}
export function* generateReqArgsWithToken(args) {
    let token = '';
    const correlationId = yield call(uuid);
    const featureFlags = yield select(selectFeatureFlags);
    const authToken = yield select(selectAuthToken);
    try {
        const userInfo = yield call(getUserFromStorage);
        if (userInfo === null || userInfo === void 0 ? void 0 : userInfo.access_token) {
            token = userInfo === null || userInfo === void 0 ? void 0 : userInfo.access_token;
        }
    }
    catch (err) {
        console.error('No access token token available!');
    }
    return {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Token': token,
            [CORRELATION_ID_HEADER_NAME]: correlationId,
            'TMPS-Client-App-Version': UI_VERSION,
            'Feature-Flags': JSON.stringify(featureFlags),
            ...injectAuthTokenHeader(authToken),
        },
        body: JSON.stringify(args),
    };
}
export const getExponentialBackoff = (startingDelay, numAttempts, maxDelay) => Math.min(startingDelay * 2 ** numAttempts, maxDelay);
const injectAuthTokenHeader = (authToken) => {
    if (authToken) {
        return { 'Tm1Pos-AuthToken': authToken };
    }
    return {};
};
