import { EMVService } from '@ticketmaster/tm1pos-web-shared/payment/EMVService';
import { checkDefined } from '@ticketmaster/tm1pos-web-shared/utils';
import { cryptoRandom } from '@ticketmaster/tm1pos-web-shared/utils/random';
import { CARD, CASH, MAGNETIC_STRIPE_PROCESS, OTHER, PIX } from './constants';

export class Confirmation {
  constructor(order) {
    this.orderData = order.orderData.checkout.order;
    this.filesToPrint = [];
    this.applyId(order);
    this.orderTime = new Date(Date.now());
    this.orderTimeUtcOffset = -this.orderTime.getTimezoneOffset();
    this.ticketsData = order.ticketsData;
    this.customerName = order.customerName;
    const orderPath = order.orderData.checkout;
    this.payments = order.payments.map((payment) => ({
      name: typeof payment.paymentOrder === 'number' ? payment.paymentOrder + 1 : '',
      data: this.paymentDetails(payment),
      amount: null,
      type: payment.paymentMethod,
    }));
    this.totals = checkDefined(orderPath, ['order', 'totals']) ? orderPath.order.totals : null;
  }

  applyId(order) {
    const orderData = order.orderData.checkout.order;
    this.orderId = orderData.accountId || orderData.id;
  }

  paymentDetails(payment) {
    switch (payment.paymentMethod) {
      case CARD:
        return payment.cardData;
      case CASH:
        return CASH;
      case PIX:
        return PIX;
      case OTHER:
        return payment.otherPaymentMethod.label;
      default:
        return '';
    }
  }

  addDocuments(deliveryResult) {
    this.pdfResults = deliveryResult.pdfResults;
    const documents =
      checkDefined(deliveryResult, ['documents']) && deliveryResult.documents.length ? deliveryResult.documents : [];
    this.filesToPrint = documents.map((item) => (item.body ? item.body.base64 : null)).filter((d) => !!d);
    this.rfidTags = documents.map((item) => (item.rfidTags ? item.rfidTags : null)).filter((t) => !!t);
  }
}

export const transformCardType = (ccType = '') => {
  try {
    return ccType.toUpperCase().replace(/[^A-Z]/, '');
  } catch (e) {
    console.log('transform card type: ', e); // eslint-disable-line no-console
  }

  return '';
};

export function getRandomSalt() {
  return cryptoRandom().toFixed(20).slice(2, 18);
}

export function getPaymentCategoryForType(type, permissions) {
  const mopType = permissions.find((mop) => mop.type === type);
  return mopType ? mopType.category : null;
}

export const getPaymentProcess = (payments, isEmvOn, emvMode) => {
  const isEmv = (p) => p.type === CARD && isEmvOn;
  const isMagStripe = (p) => p.type === CARD && !isEmvOn && !p.manualCardEntry;

  const isPaymentProcessEmv = payments.some(isEmv);
  const isPaymentProcessMagStripe = payments.some(isMagStripe);
  let paymentProcess;

  if (isPaymentProcessEmv) {
    paymentProcess = emvMode ?? EMVService.None;
  } else if (isPaymentProcessMagStripe) {
    paymentProcess = MAGNETIC_STRIPE_PROCESS;
  }
  return paymentProcess;
};

export function confirmationDataFactory(orderObj) {
  return new Confirmation(orderObj);
}

export const findFirstAndLastNames = (fullName) => {
  // regex captures everything after last space and everything before
  const result = /(.*)\s(?!\s)(.*)$/.exec(fullName.trim()) || [];
  // anonymous deconstruction assignment with default values
  const [, firstName = '', lastName = fullName] = result;
  return { firstName, lastName };
};

export const normalizeFn = (value) => value === true || value === 'true';
