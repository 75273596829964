export var PrinterType;
(function (PrinterType) {
    PrinterType["QzTray"] = "QzTray";
    PrinterType["Tmds"] = "Tmds";
})(PrinterType || (PrinterType = {}));
export var PrintingMethod;
(function (PrintingMethod) {
    PrintingMethod["BOCA"] = "BOCA";
    PrintingMethod["FGL"] = "FGL";
    PrintingMethod["PDF"] = "PDF";
    PrintingMethod["PNG"] = "PNG";
    PrintingMethod["PS"] = "POSTSCRIPT";
})(PrintingMethod || (PrintingMethod = {}));
export var PrintingDpi;
(function (PrintingDpi) {
    PrintingDpi["Dpi200"] = "DPI200";
    PrintingDpi["Dpi300"] = "DPI300";
})(PrintingDpi || (PrintingDpi = {}));
export const isQzPrinter = (printer) => printer.type === PrinterType.QzTray;
export const isTmdsPrinter = (printer) => printer.type === PrinterType.Tmds;
