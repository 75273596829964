import { Tm1Button, Tm1Dialog, Tm1DialogFooter } from '@ticketmaster/tm1pos-web-shared/components/v2';
import sharedMessages from '@ticketmaster/tm1pos-web-shared/messages';
import { useState } from 'react';
import { FormattedMessage, InjectedIntl, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { CartEventFee } from '../../../../services/sales-api-client/model/cart-event-fee';
import { selectIsLoadingEventFees } from '../../app-selectors';
import { setEventFees, toggleEditOtherChargesModal } from '../../event-fees-actions';
import { selectOtherCharges } from './EditOtherChargesGrid/edit-other-charges-grid-selectors';
import EditOtherChargesGrid from './EditOtherChargesGrid/EditOtherChargesGrid';
import messages from './messages';

type InjectedProps = {
  intl: InjectedIntl;
};

const EditOtherChargesModal = ({ intl }: InjectedProps) => {
  const isLoadingOtherCharges = useSelector(selectIsLoadingEventFees);
  const initialOtherCharges = useSelector(selectOtherCharges);
  const [otherCharges, setOtherCharges] = useState([...initialOtherCharges]);

  const dispatch = useDispatch();

  const handleApply = () => {
    const eventFees: CartEventFee[] = otherCharges.map((otherCharge) => ({
      id: otherCharge.id,
      quantity: otherCharge.quantity || 0,
    }));

    dispatch(setEventFees({ eventFees }));
  };

  const handleCancel = () => {
    dispatch(toggleEditOtherChargesModal());
  };

  return (
    <div role="dialog">
      <Tm1Dialog
        className="edit-charges-modal"
        open
        size="extra-large"
        backgroundOverlay
        scrollable
        data-testid="edit-charges-dialog"
      >
        <div className="edit-charges-modal-header" slot="dialog-header">
          <FormattedMessage {...messages.editOtherCharges} />
        </div>
        <div className="edit-charges-modal-content" slot="dialog-content">
          <EditOtherChargesGrid otherCharges={otherCharges} onOtherChargesChange={setOtherCharges} />
        </div>
        <Tm1DialogFooter slot="footer">
          <Tm1Button slot="actions" variant="lightweight" onClick={handleCancel} disabled={isLoadingOtherCharges}>
            {intl.formatMessage(sharedMessages.Cancel)}
          </Tm1Button>
          <Tm1Button
            slot="actions"
            onClick={handleApply}
            disabled={isLoadingOtherCharges}
            is-loading={isLoadingOtherCharges}
          >
            {intl.formatMessage(sharedMessages.Apply)}
          </Tm1Button>
        </Tm1DialogFooter>
      </Tm1Dialog>
    </div>
  );
};

export default injectIntl(EditOtherChargesModal);
