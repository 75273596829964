import TmdsClient from '@ticketmaster/tm1pos-tmds-client';
import { tmdsPrinterToPrinter } from './mapper';
import { selectActivePrinter as selectActivePrinterFromModule, selectPrinterError as selectPrinterErrorFromModule, selectPrintingModuleEnabled, } from './printingModule-selectors';
export const selectPrinterError = (state) => {
    const printingModuleEnabled = selectPrintingModuleEnabled(state);
    return printingModuleEnabled ? selectPrinterErrorFromModule(state) : TmdsClient.localPrinterError;
};
export const selectActivePrinter = (state) => {
    const printingModuleEnabled = selectPrintingModuleEnabled(state);
    return printingModuleEnabled
        ? selectActivePrinterFromModule(state)
        : tmdsPrinterToPrinter(TmdsClient.selectedPrinterData);
};
