import { assign, setup } from 'xstate';
export var TmdsPaymentsMachineEvents;
(function (TmdsPaymentsMachineEvents) {
    TmdsPaymentsMachineEvents["INIT"] = "INIT";
    TmdsPaymentsMachineEvents["PAY_INIT"] = "PAY_INIT";
    TmdsPaymentsMachineEvents["PAY_SUCCESS"] = "PAY_SUCCESS";
    TmdsPaymentsMachineEvents["PAY_FAILURE"] = "PAY_FAILURE";
    TmdsPaymentsMachineEvents["CANCELED"] = "CANCELED";
    TmdsPaymentsMachineEvents["CANCEL"] = "CANCEL";
    TmdsPaymentsMachineEvents["ACKNOWLEDGE"] = "ACKNOWLEDGE";
    TmdsPaymentsMachineEvents["ACKNOWLEDGED"] = "ACKNOWLEDGED";
    TmdsPaymentsMachineEvents["ROLLBACK_INIT"] = "ROLLBACK_INIT";
    TmdsPaymentsMachineEvents["ROLLBACK_FAILURE"] = "ROLLBACK_FAILURE";
    TmdsPaymentsMachineEvents["ROLLBACK_SUCCESS"] = "ROLLBACK_SUCCESS";
})(TmdsPaymentsMachineEvents || (TmdsPaymentsMachineEvents = {}));
export const initialContext = { isRefund: false };
export var TmdsPaymentsMachineStates;
(function (TmdsPaymentsMachineStates) {
    TmdsPaymentsMachineStates["READY"] = "ready";
    TmdsPaymentsMachineStates["OFF"] = "off";
    TmdsPaymentsMachineStates["PAYING"] = "paying";
    TmdsPaymentsMachineStates["STOPPING"] = "stopping";
    TmdsPaymentsMachineStates["PENDING"] = "pending";
    TmdsPaymentsMachineStates["ROLLBACKING"] = "rollbacking";
    TmdsPaymentsMachineStates["ACKNOWLEDGING"] = "acknowledging";
})(TmdsPaymentsMachineStates || (TmdsPaymentsMachineStates = {}));
export const tmdsPaymentMachine = setup({
    types: {},
}).createMachine({
    id: 'TMDS payment',
    initial: TmdsPaymentsMachineStates.OFF,
    context: initialContext,
    states: {
        [TmdsPaymentsMachineStates.OFF]: {
            on: {
                [TmdsPaymentsMachineEvents.INIT]: {
                    target: TmdsPaymentsMachineStates.READY,
                    actions: assign({
                        dispatch: ({ event }) => event.dispatch,
                    }),
                },
            },
        },
        [TmdsPaymentsMachineStates.READY]: {
            on: {
                [TmdsPaymentsMachineEvents.PAY_INIT]: {
                    target: TmdsPaymentsMachineStates.PAYING,
                    actions: assign({
                        paymentData: ({ event }) => event.data,
                        isRefund: ({ event }) => event.isRefund,
                    }),
                },
            },
            entry: assign({
                paymentData: () => undefined,
                isRefund: () => false,
            }),
        },
        [TmdsPaymentsMachineStates.PAYING]: {
            on: {
                [TmdsPaymentsMachineEvents.PAY_SUCCESS]: TmdsPaymentsMachineStates.PENDING,
                [TmdsPaymentsMachineEvents.PAY_FAILURE]: TmdsPaymentsMachineStates.READY,
                [TmdsPaymentsMachineEvents.CANCEL]: TmdsPaymentsMachineStates.STOPPING,
                [TmdsPaymentsMachineEvents.CANCELED]: TmdsPaymentsMachineStates.READY,
            },
        },
        [TmdsPaymentsMachineStates.STOPPING]: {
            on: {
                [TmdsPaymentsMachineEvents.PAY_SUCCESS]: TmdsPaymentsMachineStates.PENDING,
                [TmdsPaymentsMachineEvents.PAY_FAILURE]: TmdsPaymentsMachineStates.READY,
                [TmdsPaymentsMachineEvents.CANCELED]: TmdsPaymentsMachineStates.READY,
            },
        },
        [TmdsPaymentsMachineStates.PENDING]: {
            on: {
                [TmdsPaymentsMachineEvents.ACKNOWLEDGE]: TmdsPaymentsMachineStates.ACKNOWLEDGING,
                [TmdsPaymentsMachineEvents.ROLLBACK_INIT]: TmdsPaymentsMachineStates.ROLLBACKING,
            },
        },
        [TmdsPaymentsMachineStates.ROLLBACKING]: {
            on: {
                [TmdsPaymentsMachineEvents.ROLLBACK_SUCCESS]: TmdsPaymentsMachineStates.READY,
                [TmdsPaymentsMachineEvents.ROLLBACK_FAILURE]: TmdsPaymentsMachineStates.READY,
            },
        },
        [TmdsPaymentsMachineStates.ACKNOWLEDGING]: {
            on: {
                [TmdsPaymentsMachineEvents.ACKNOWLEDGED]: TmdsPaymentsMachineStates.READY,
            },
        },
    },
});
