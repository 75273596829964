import { defineMessages } from 'react-intl';
const UNABLE_ESTABLISH_CONNECTION = 'Unable to establish connection with QZ';
const NO_PORT_SPECIFIED = 'No ports have been specified to connect over';
const UNABLE_CREATE_WEBSOCKET_CONNECTION = 'Unable to create a websocket connection';
const CONNECTION_CLOSED_BEFORE_RESPONSE_RECEIVED = 'Connection closed before response received';
const CONNECTION_NOT_ESTABLISHED_YET = 'A connection to QZ has not been established yet';
const CONNECTION_ALREADY_EXISTS = 'An open connection with QZ Tray already exists';
const CONNECTION_ATTEMPT_NOT_RETURNED_YET = 'The current connection attempt has not returned yet';
const WEBSOCKET_NOT_SUPPORTED = 'WebSocket not supported by this browser';
const UNSUPPORTED_WEBSOCKET_VERSION = 'Unsupported WebSocket version detected: HyBi-00/Hixie-76';
const NO_OPEN_CONNECTION = 'No open connection with QZ Tray';
const DATA_FORMAT_NOT_SUPPORTED = 'Data format is not supported with connected QZ Tray version';
export const qzMessages = defineMessages({
    unableEstablishConnection: {
        id: 'qztrayMessages.unableEstablishConnection',
        defaultMessage: 'Unable to establish a connection with QZ Tray.',
    },
    noPortSpecified: {
        id: 'qztrayMessages.noPortSpecified',
        defaultMessage: 'No ports have been specified to connect over.',
    },
    unableCreateWebsocketConnection: {
        id: 'qztrayMessages.unableCreateWebsocketConnection',
        defaultMessage: 'Unable to create a websocket connection',
    },
    connectionClosedBeforeResponseReceived: {
        id: 'qztrayMessages.connectionClosedBeforeResponseReceived',
        defaultMessage: 'The connection closed before a response was received.',
    },
    connectionNotEstablishedYet: {
        id: 'qztrayMessages.connectionNotEstablishedYet',
        defaultMessage: 'A connection to QZ has not been established yet',
    },
    connectionAlreadyExists: {
        id: 'qztrayMessages.connectionAlreadyExists',
        defaultMessage: 'An open connection with QZ Tray already exists',
    },
    connectionAttemptNotReturnedYet: {
        id: 'qztrayMessages.connectionAttemptNotReturnedYet',
        defaultMessage: 'The current connection attempt has not returned yet',
    },
    websocketNotSupported: {
        id: 'qztrayMessages.websocketNotSupported',
        defaultMessage: 'This webSocket is not supported by this browser.',
    },
    unsupportedWebsocketVersion: {
        id: 'qztrayMessages.unsupportedWebsocketVersion',
        defaultMessage: 'An unsupported WebSocket version has been detected: HyBi-00/Hixie-76.',
    },
    noOpenConnection: {
        id: 'qztrayMessages.noOpenConnection',
        defaultMessage: 'There is no open connection with QZ Tray.',
    },
    dataFormatNotSupported: {
        id: 'qztrayMessages.dataFormatNotSupported',
        defaultMessage: 'The data format is not supported with the current connected QZ Tray version.',
    },
});
export const getMessageFromQZError = (error) => {
    let message;
    const exactMessage = Object.values(qzMessages).find((it) => it.defaultMessage === mapQZErrorMessage(error));
    if (exactMessage) {
        message = exactMessage;
    }
    else if (error.includes(DATA_FORMAT_NOT_SUPPORTED)) {
        message = qzMessages.dataFormatNotSupported;
    }
    else {
        message = {
            id: 'qzerror_default',
            defaultMessage: error,
        };
    }
    return message;
};
const mapQZErrorMessage = (error) => {
    switch (error) {
        case UNABLE_ESTABLISH_CONNECTION:
            return qzMessages.unableEstablishConnection.defaultMessage;
        case NO_PORT_SPECIFIED:
            return qzMessages.noPortSpecified.defaultMessage;
        case UNABLE_CREATE_WEBSOCKET_CONNECTION:
            return qzMessages.unableCreateWebsocketConnection.defaultMessage;
        case CONNECTION_CLOSED_BEFORE_RESPONSE_RECEIVED:
            return qzMessages.connectionClosedBeforeResponseReceived.defaultMessage;
        case CONNECTION_NOT_ESTABLISHED_YET:
            return qzMessages.connectionNotEstablishedYet.defaultMessage;
        case CONNECTION_ALREADY_EXISTS:
            return qzMessages.connectionAlreadyExists.defaultMessage;
        case CONNECTION_ATTEMPT_NOT_RETURNED_YET:
            return qzMessages.connectionAttemptNotReturnedYet.defaultMessage;
        case WEBSOCKET_NOT_SUPPORTED:
            return qzMessages.websocketNotSupported.defaultMessage;
        case UNSUPPORTED_WEBSOCKET_VERSION:
            return qzMessages.unsupportedWebsocketVersion.defaultMessage;
        case NO_OPEN_CONNECTION:
            return qzMessages.noOpenConnection.defaultMessage;
        default:
            return error;
    }
};
export default qzMessages;
