import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { cloneElement, useRef, useState } from 'react';
import { Tm1DropdownMenu, Tm1Select } from '../DesignSystem';
import './DropdownSearch.scss';
const ensureArray = (origChildren) => {
    let children;
    if (!(origChildren instanceof Array)) {
        children = [origChildren];
    }
    else {
        children = origChildren;
    }
    return children;
};
const formatDisplayName = (info) => {
    if (info.description) {
        return `${info.description} · ${info.label}`;
    }
    return info.label;
};
const waitForClosePopupAnimation = () => new Promise((resolve) => {
    setTimeout(() => {
        resolve(true);
    }, 25);
});
const tryCloneElement = (element, props) => {
    try {
        return cloneElement(element, props);
    }
    catch (_) {
        return element;
    }
};
export const DropdownSearch = ({ searchPlaceHolder, selectedValue, onChange, error, testId = 'dropdown-search', children, }) => {
    const [displayName, setDisplayName] = useState('');
    const dropdownMenuRef = useRef(null);
    const updateChildren = (origChildren) => {
        if (!origChildren) {
            throw new Error('At least one Tm1DropdownMenuOptionGroup must be included.');
        }
        return cloneGroups(ensureArray(origChildren));
    };
    const cloneGroups = (origChildren) => origChildren
        .filter((child) => child)
        .map((child) => tryCloneElement(child, { children: cloneOptions(ensureArray(child.props.children)) }));
    const cloneOptions = (origChildren) => origChildren.map((child) => tryCloneElement(child, {
        selected: (child === null || child === void 0 ? void 0 : child.props.value) === selectedValue,
        onClick: createOnClickProxy(child),
    }));
    const findSelectedItem = (origChildren) => ensureArray(origChildren)
        .flatMap((child) => child === null || child === void 0 ? void 0 : child.props.children)
        .filter((child) => child)
        .find((child) => child.props.value === selectedValue);
    const createOnClickProxy = (child) => () => {
        const selectedItemInfo = {
            value: child.props.value,
            label: child.props.children,
            description: child.props.description,
        };
        if (selectedItemInfo.value === selectedValue) {
            return;
        }
        setDisplayName(formatDisplayName(selectedItemInfo));
        waitForClosePopupAnimation().then(() => {
            if (onChange) {
                onChange(selectedItemInfo);
            }
            if (child.props.onClick) {
                child.props.onClick(selectedItemInfo);
            }
        });
    };
    const selectedItem = findSelectedItem(children);
    if (!displayName && (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.props.children)) {
        setDisplayName(selectedItem.props.children);
    }
    const renderTrigger = () => {
        const selectComponentProps = {
            key: `trigger${error ? '-with-error' : ''}`,
            slot: 'trigger',
            className: 'dropdown-search__trigger',
            placeholder: displayName,
            'data-testid': `trigger-${testId}`,
        };
        if (error) {
            selectComponentProps.error = error;
        }
        return _jsx(Tm1Select, { ...selectComponentProps });
    };
    return (_jsxs(Tm1DropdownMenu, { alignment: "start", searchable: true, className: "dropdown-search", closeOnClick: true, searchPlaceholder: searchPlaceHolder, ref: dropdownMenuRef, "data-testid": testId, children: [renderTrigger(), updateChildren(children)] }));
};
