import { SMS } from '@ticketmaster/tm1pos-web-shared/constants';
import { GraphQLResult } from '@ticketmaster/tm1pos-web-shared/model/graphql';
import { fetchFromSalesApi } from '@ticketmaster/tm1pos-web-shared/services/sales-api-client/sales-api';
import { call, Effect } from 'redux-saga/effects';
import { POS_GRAPHQL_URL, SHOPPING_SERVICE_URL } from '../../constants';
import { DeliveryConfig } from '../../models/delivery-config';
import {
  BEST_AVAILABLE_MUTATION,
  CANCEL_ORDER_MUTATION,
  CHECKOUT_MUTATION,
  CLEAR_CART_MUTATION,
  deliverMutation,
  ISM_TICKET_RESERVE_MUTATION,
  UNRESERVE_CART_ITEM_MUTATION,
} from './api-queries';
import { CancelOrderParams } from './model/cancel-order-params';
import { CartResponse } from './model/cart-response';
import { CheckoutParams } from './model/checkout-params';
import { CheckoutResult } from './model/checkout-result';
import { DeliverTicketsParams } from './model/deliver-tickets-params';
import { DeliveryResult } from './model/delivery-result';
import { ReserveBestAvailableParams } from './model/reserve-best-available-params';
import { ReserveSeatParams } from './model/reserve-seat-params';
import { UnreserveCartItemParams } from './model/unreserve-cart-item-params';

export type CancelOrderGraphQLResult = GraphQLResult | { errorMessage: string };
export type ClearCartGraphQLResult = GraphQLResult | { errorMessage: string };
export type UnReserveSeatGraphQLResult = GraphQLResult | { errorMessage: string };

export function* cancelOrder(
  params: CancelOrderParams,
): Generator<Effect, CancelOrderGraphQLResult, CancelOrderGraphQLResult> {
  return yield call(fetchFromSalesApi, {
    url: SHOPPING_SERVICE_URL,
    query: CANCEL_ORDER_MUTATION,
    options: {
      variables: {
        cartId: params.cartId,
        hostName: params.hostName,
        orderId: params.orderId,
        defaultSellClass: {
          id: params.defaultSellClass.id,
          name: params.defaultSellClass.name,
        },
        sellClass: {
          id: params.sellClass.id,
          name: params.sellClass.name,
        },
      },
    },
  });
}

export function* emptyCart(cartId: string): Generator<Effect, ClearCartGraphQLResult, ClearCartGraphQLResult> {
  return yield call(fetchFromSalesApi, {
    url: SHOPPING_SERVICE_URL,
    query: CLEAR_CART_MUTATION,
    options: {
      variables: {
        cartId,
      },
    },
  });
}

export function* unReserveCartItem(
  params: UnreserveCartItemParams,
): Generator<Effect, UnReserveSeatGraphQLResult, UnReserveSeatGraphQLResult> {
  return yield call(fetchFromSalesApi, {
    url: SHOPPING_SERVICE_URL,
    query: UNRESERVE_CART_ITEM_MUTATION,
    options: {
      variables: {
        eventId: params.eventId,
        cart: params.cart,
        cartItemId: params.cartItem.id,
        hostName: params.hostName,
        seatName: params.cartItem.seatName,
        generalAdmissionPlace: params.cartItem.generalAdmission,
      },
    },
  });
}

export function* deliverTickets(
  params: DeliverTicketsParams,
): Generator<Effect, GraphQLResult<DeliveryResult>, GraphQLResult<DeliveryResult>> {
  const args = {
    url: POS_GRAPHQL_URL,
    query: deliverMutation,
    options: {
      variables: {
        request: params.deliveryRequest,
      },
    },
  };

  return yield call(fetchFromSalesApi, args);
}

export const getRecipient = (deliveryConfig: DeliveryConfig) => {
  if (deliveryConfig.method === SMS) {
    return deliveryConfig.phoneNumber;
  }
  return deliveryConfig.email;
};

export function* checkout(
  params: CheckoutParams,
): Generator<Effect, GraphQLResult<CheckoutResult>, GraphQLResult<CheckoutResult>> {
  return yield call(fetchFromSalesApi, {
    url: SHOPPING_SERVICE_URL,
    query: CHECKOUT_MUTATION,
    options: {
      variables: {
        ...params,
      },
    },
  });
}

export function* reserveSeat(
  params: ReserveSeatParams,
): Generator<Effect, GraphQLResult<CartResponse>, GraphQLResult<CartResponse>> {
  return yield call(fetchFromSalesApi, {
    url: SHOPPING_SERVICE_URL,
    query: ISM_TICKET_RESERVE_MUTATION,
    options: {
      variables: {
        eventId: params.eventId,
        place: {
          id: params.place.placeId,
          section: params.place.section,
          row: params.place.row,
          name: params.place.seat,
          rowId: params.place.rowId,
          sectionId: params.place.sectionId,
        },
        hostName: params.hostName,
        eventCode: params.eventCode,
        ticketType: params.ticketType,
        sellClass: {
          id: params.sellClass.id,
          name: params.sellClass.name,
        },
        useCart: params.useCart,
        adaRequired: params.adaRequired,
      },
    },
  });
}

export function* reserveBestAvailable(
  params: ReserveBestAvailableParams,
): Generator<Effect, GraphQLResult<CartResponse>, GraphQLResult<CartResponse>> {
  return yield call(fetchFromSalesApi, {
    url: SHOPPING_SERVICE_URL,
    query: BEST_AVAILABLE_MUTATION,
    options: {
      variables: {
        eventId: params.eventId,
        priceLevelGroups: params.priceLevelGroups,
        quantity: params.quantity,
        ticketType: params.ticketType,
        sellClass: {
          id: params.sellClass.id,
          name: params.sellClass.name,
        },
        hostName: params.hostName,
        eventCode: params.eventCode,
        useCart: params.useCart,
      },
    },
  });
}
