export const webSocketWithTimeoutFactory = (timeoutMs) => new Proxy(WebSocket, {
    construct(Target, args) {
        const ws = new Target(...args);
        const cancelOnTimeout = setTimeout(() => {
            if (ws.readyState !== WebSocket.OPEN) {
                ws.close();
            }
        }, timeoutMs);
        ws.addEventListener('open', () => clearTimeout(cancelOnTimeout), {
            once: true,
        });
        return ws;
    },
});
