import { DEFAULT_LANGUAGE, SHARED_NAMESPACE_TRANSIFEX, TMDS_NAMESPACE_TRANSIFEX } from './translations-constants';
export const fetchTranslations = (translationUrl) => async (language, namespace) => {
    const languages = [DEFAULT_LANGUAGE];
    if (language) {
        languages.push(language);
    }
    const namespaces = [SHARED_NAMESPACE_TRANSIFEX, TMDS_NAMESPACE_TRANSIFEX];
    if (namespace) {
        namespaces.push(namespace);
    }
    const response = await fetch(`${translationUrl}?lng=${languages.map(encodeURIComponent).join('+')}&ns=${namespaces
        .map(encodeURIComponent)
        .join('+')}`);
    if (!response.ok) {
        throw new Error('Unable to fetch translations');
    }
    return response.json();
};
const flattenNamespace = (translations, language) => Object.entries(translations.resources[language]).reduce((messages, [, namespaceMessages]) => {
    Object.entries(namespaceMessages).forEach(([key, message]) => {
        messages[key] = message;
    });
    return messages;
}, {});
export const getMessagesForLanguage = (translations, language) => {
    const defaultMessages = flattenNamespace(translations, DEFAULT_LANGUAGE);
    if (!language) {
        return defaultMessages;
    }
    const languageMessages = flattenNamespace(translations, language);
    for (const [key, value] of Object.entries(defaultMessages)) {
        if (!languageMessages[key]) {
            languageMessages[key] = value;
        }
    }
    return languageMessages;
};
export const getTranslations = async (translationUrl, language, namespace) => {
    try {
        const translations = await fetchTranslations(translationUrl)(language, namespace);
        return getMessagesForLanguage(translations, language);
    }
    catch (e) {
        return {};
    }
};
