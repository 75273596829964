import dynamicAction from '@ticketmaster/tm1pos-web-shared/components/utils/DynamicNameHelper';

export const CHECKOUT_NOW = 'CHECKOUT_NOW';
export const CANCEL_CHECKOUT = 'CANCEL_CHECKOUT';
export const FETCH_CHECKOUT = dynamicAction(CHECKOUT_NOW);
export const CREDIT_CARD_SWIPE_ERROR = 'CREDIT_CARD_SWIPE_ERROR';
export const CREDIT_CARD_PREPOPULATE_MANUAL = 'CREDIT_CARD_PREPOPULATE_MANUAL';
export const CHECKOUT_PAYMENT_ERROR = 'CHECKOUT_PAYMENT_ERROR';
export const SHOW_MODAL_PAYMENT_NOT_COMPLETE = 'SHOW_MODAL_PAYMENT_NOT_COMPLETE';

export const DO_DELIVERY = 'DO_DELIVERY';
export const DO_DELIVERY_END = 'DO_DELIVERY_END';
export const DELIVERY_ERROR = 'DELIVERY_ERROR';
export const DELIVERY_ERROR_TYPES = {
  SMS_ERROR: 'SMS_ERROR',
  EMAIL_ERROR: 'EMAIL_ERROR',
  PRINT_ERROR: 'PRINT_ERROR',
  NO_BARCODE_TICKETS_ERROR: 'NO_BARCODE_TICKETS_ERROR',
};
