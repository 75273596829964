import { defineMessages } from 'react-intl';

export default defineMessages({
  continueSelling: {
    id: 'sales.OrderConfirmation.continueSelling',
    defaultMessage: 'Continue Selling',
  },
  orderId: {
    id: 'sales.OrderConfirmation.orderId',
    defaultMessage: 'Account/Order #',
  },
  orderTime: {
    id: 'sales.OrderConfirmation.orderTime',
    defaultMessage: 'Date',
  },
  payment: {
    id: 'sales.OrderConfirmation.payment',
    defaultMessage: 'Payment',
  },
  paymentNotProcessed: {
    id: 'sales.OrderConfirmation.paymentNotProcessed',
    defaultMessage: 'Not processed through TM1 Sales ({paymentData})',
  },
  delivery: {
    id: 'sales.OrderConfirmation.delivery',
    defaultMessage: 'Delivery',
  },
  failStatusMessage: {
    id: 'sales.OrderConfirmation.failStatusMessage',
    defaultMessage: 'Failed',
  },
  cash: {
    id: 'sales.OrderConfirmation.cash',
    defaultMessage: 'Cash',
  },
  name: {
    id: 'sales.OrderConfirmation.name',
    defaultMessage: 'Name',
  },
  clickToViewOrder: {
    id: 'sales.OrderConfirmation.clickToViewOrder',
    defaultMessage: 'Click to view order',
  },
  printDelivery: {
    id: 'sales.OrderConfirmation.printDelivery',
    defaultMessage: 'Print',
  },
  smsDelivery: {
    id: 'sales.OrderConfirmation.smsDelivery',
    defaultMessage: 'SMS {number}',
  },
  directionAfterPrintError: {
    id: 'sales.OrderConfirmation.directionAfterPrintError',
    defaultMessage: 'Then go to the order page and try printing again.',
  },
});
