import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import CopyCorrelationId from '../CopyCorrelationId/CopyCorrelationId';
import { IconAlert24, IconAlertYellow } from '../icons';
import './styles.scss';
const ErrorModal = ({ mainMessage, tipMessages, children, close, isYellowAlert, buttons, messageParameters, cid }) => (_jsxs("div", { className: "oc-modal__content-wrap", children: [_jsxs("div", { className: "oc-modal__title-wrap", children: [isYellowAlert ? _jsx(IconAlertYellow, {}) : _jsx(IconAlert24, {}), _jsx("div", { className: "oc-modal__title", children: _jsx(FormattedMessage, { ...mainMessage, values: messageParameters }) }), close && close[0] ? close[0] : null] }), tipMessages && tipMessages.length
            ? tipMessages.map((msg) => (_jsx("div", { className: "oc-modal__title-tip", children: _jsx(FormattedMessage, { ...msg, values: messageParameters }) }, msg.id)))
            : null, cid ? _jsx(CopyCorrelationId, { cid: cid }) : undefined, children, _jsx("div", { className: "oc-modal__buttons", children: buttons })] }));
ErrorModal.propTypes = {
    mainMessage: PropTypes.object.isRequired,
    tipMessages: PropTypes.array,
    children: PropTypes.node,
    buttons: PropTypes.node,
    isYellowAlert: PropTypes.bool,
    close: PropTypes.node,
    messageParameters: PropTypes.object,
    cid: PropTypes.string,
};
export default ErrorModal;
