import { createAction } from '@reduxjs/toolkit';

export type MetricsPayload = {
  loadType: string;
  duration: number;
  startTime: string;
  endTime: string;
};

export const iceGeometryLoadStarted = createAction('ism/geometryLoadStarted');
export const iceGeometryLoadCompleted = createAction('ism/geometryLoadComplete');

export const iceMetricsEventReport = createAction<MetricsPayload>('ism/metricsReport');
