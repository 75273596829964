export const ARX_CC_TYPES_MAP = {
    AMERICANEXPRESS: 'AE',
    MASTERCARD: 'MC',
    DISCOVER: 'DI',
    VISA: 'VI',
    DEBIT: 'IC',
};
export const HOST_CC_TYPES_MAP = {
    AMERICANEXPRESS: 'AMX',
    MASTERCARD: 'MC',
    DISCOVER: 'DSC',
    VISA: 'VISA',
    DEBIT: 'CDNDBT',
};
