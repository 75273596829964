import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './styles.scss';
import { IconInfo } from '../icons';
const TooltipComponent = (props) => {
    const styleClasses = classNames('oc-tooltip', props.className);
    return (_jsxs("div", { className: styleClasses, children: [_jsx("div", { className: "oc-tooltip__content", children: props.text }), props.children] }));
};
const InfoTooltipComponent = (props) => {
    const styleClass = classNames('oc-info', props.className);
    const icon = props.children || _jsx(IconInfo, {});
    return (_jsxs("div", { className: styleClass, children: [_jsx("span", { className: "oc-icon-tooltip", children: icon }), _jsx(TooltipComponent, { text: props.text, className: props.tooltipClassName })] }));
};
TooltipComponent.propTypes = {
    className: PropTypes.string,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.element]),
    children: PropTypes.element,
};
InfoTooltipComponent.propTypes = {
    className: PropTypes.oneOf([
        'oc-info--left',
        'oc-info--right',
        'oc-info--top',
        'oc-info--bottom',
        'order-details__body__event-info-button',
    ]).isRequired,
    tooltipClassName: PropTypes.string,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.element]),
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
};
export { TooltipComponent, InfoTooltipComponent };
