import moment from 'moment';
import { createSelector } from 'reselect';
export const selectSearchResultsRaw = (state) => state.events.searchResults;
export const selectSearchQuery = (state) => state.events.searchQuery;
export const selectLoadingInitialState = (state) => state.events.isLoadingInitial;
export const selectLoadingAllState = (state) => state.events.isLoadingAll;
export const selectAllEventsLoadedState = (state) => state.events.allEventsAreLoaded;
export const selectEventItems = (state) => state.events.items;
export const selectReadyToLoadAllEvents = createSelector(selectAllEventsLoadedState, selectLoadingAllState, selectLoadingInitialState, (allAreLoaded, isLoadingAll, isLoadingInitial) => !allAreLoaded && !isLoadingAll && !isLoadingInitial);
export const selectEvents = createSelector(selectEventItems, (events) => events.filter(({ date, utcOffset, endDate }) => {
    if (utcOffset !== null && utcOffset !== undefined && (date || endDate)) {
        const eventDate = endDate !== null && endDate !== void 0 ? endDate : date;
        const fourAmNextDay = moment.utc(eventDate).utcOffset(utcOffset).add(1, 'days').hours(4).minutes(0);
        const now = moment().utc().utcOffset(utcOffset);
        return now.unix() < fourAmNextDay.unix();
    }
    return true;
}));
export const selectSearchResults = createSelector(selectSearchResultsRaw, (results) => {
    if (results === null || results === void 0 ? void 0 : results.length) {
        let filtered = results.filter((item) => ((item === null || item === void 0 ? void 0 : item.score) || 0) < 0.15);
        if (filtered.length < 1) {
            filtered = results.slice(0, 3);
        }
        return filtered.sort((a, b) => {
            const aDate = a.item.date || '';
            const bDate = b.item.date || '';
            return +moment(aDate).utc() - +moment(bDate).utc();
        });
    }
    return results;
});
