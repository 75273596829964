import qz from 'qz-tray';
import { call, put, select } from 'redux-saga/effects';
import { v1 as uuid } from 'uuid';
import { SIGNER_URL } from '../constants';
import { LogComponent, LogStatus } from '../errorHandling/constants';
import { toLogError } from '../services/errors/error-handler';
import { logClientToCloud } from '../store/actions';
import { fetchJson, generateReqArgsWithToken } from '../utils/sagas-utils';
import { webSocketWithTimeoutFactory } from '../websocket/webSocketWithTimeoutFactory';
import { selectHasQzTrayEnabled, selectIsQzConnecting } from './printingModule-selectors';
import { clearErrorQzConnect, errorQzConnect, errorQzPrinters, printingFailed, setActivePrinter, setLoading, setPrinters, setQzConnecting, } from './printingModule-slice';
import { isQzPrinter, PrinterType, PrintingMethod, } from './printingModule-types';
import localCertificate from './qzTray/digital-certificate';
import { getPrinterData, getPrinterError } from './qzTray/qz-mappers';
class QzPrintingModule {
    *initialize(printer) {
        const hasQzTrayEnabled = yield select(selectHasQzTrayEnabled);
        if (hasQzTrayEnabled) {
            qz.api.setWebSocketType(webSocketWithTimeoutFactory(3000));
            const baseFetchOptions = yield call(generateReqArgsWithToken);
            yield call(initCertificate, baseFetchOptions);
            if (printer && isQzPrinter(printer)) {
                yield put(setActivePrinter(printer));
                yield call(connect, printer.remoteHost);
            }
        }
    }
    *print(printer, filesToPrint) {
        if (isQzPrinter(printer)) {
            yield call(doPrint, printer, filesToPrint);
        }
    }
    *refreshPrinters(remoteHost) {
        yield call(refreshPrinters, remoteHost);
    }
}
export function* refreshPrinters(remoteHost) {
    yield put(clearErrorQzConnect());
    yield put(setLoading(true));
    yield call(connect, remoteHost);
    yield call(doRefreshPrinters);
    yield put(setLoading(false));
}
const resolveQzConfigOptionsForPrinter = (printer) => {
    if (printer.method === PrintingMethod.PDF) {
        return {
            size: {
                width: 2,
                height: 5.5,
            },
            units: 'in',
        };
    }
    return {};
};
export function* doPrint(printer, filesToPrint) {
    const qzConfigOptions = resolveQzConfigOptionsForPrinter(printer);
    const config = qz.configs.create(printer.name, qzConfigOptions);
    try {
        const data = filesToPrint.map((content) => getPrinterData(printer, content));
        yield call(qz.print, config, data);
    }
    catch (error) {
        yield put(printingFailed(getPrinterError(error)));
    }
}
const getLocalCertificate = async () => Promise.resolve(localCertificate);
export const getSignature = (baseFetchOptions) => async (messageToSign) => {
    var _a;
    baseFetchOptions.body = JSON.stringify({
        query: 'query sign($message: String) {signForQzTray(message: $message)}',
        variables: { message: messageToSign },
    });
    baseFetchOptions.headers['TMPS-Correlation-Id'] = uuid();
    const response = await fetchJson(SIGNER_URL, baseFetchOptions);
    if ((_a = response.errors) === null || _a === void 0 ? void 0 : _a.length) {
        return '';
    }
    return response.data.signForQzTray;
};
export const initCertificate = (baseFetchOptions) => {
    qz.security.setCertificatePromise(getLocalCertificate);
    qz.security.setSignatureAlgorithm('SHA512');
    qz.security.setSignaturePromise(getSignature(baseFetchOptions));
};
const QZ_DEFAULT_SECURE_PORTS = [8181, 8282, 8383, 8484];
export const isReconnectionNeeded = (server) => {
    if (!qz.websocket.isActive()) {
        return false;
    }
    const currentConnection = qz.websocket.getConnectionInfo();
    if (!server.ports.includes(currentConnection.port)) {
        return true;
    }
    return server.host !== currentConnection.host;
};
export const getServerRequest = (options) => {
    var _a;
    if (!options) {
        return { host: 'localhost', ports: QZ_DEFAULT_SECURE_PORTS };
    }
    return {
        host: options.host || 'localhost',
        ports: ((_a = options.port) === null || _a === void 0 ? void 0 : _a.secure) || QZ_DEFAULT_SECURE_PORTS,
    };
};
export function* connect(options) {
    const safeServer = yield call(getServerRequest, options);
    const reconnectionNeeded = yield call(isReconnectionNeeded, safeServer);
    try {
        if (reconnectionNeeded) {
            yield call(qz.websocket.disconnect);
        }
    }
    catch (error) {
        yield put(logClientToCloud({
            type: LogComponent.QZ_PRINTING,
            status: LogStatus.ERROR,
            data: { detail: 'error disconnecting from QZ websocket ', error: toLogError(error) },
        }));
    }
    if (!qz.websocket.isActive()) {
        const isAlreadyConnecting = yield select(selectIsQzConnecting);
        if (!isAlreadyConnecting) {
            yield put(setQzConnecting(true));
            try {
                yield call(qz.websocket.connect, { ...options });
            }
            catch (error) {
                yield put(errorQzConnect(error.message));
            }
            finally {
                yield put(setQzConnecting(false));
            }
        }
    }
}
export function* doRefreshPrinters() {
    if (qz.websocket.isActive()) {
        try {
            const qzPrinters = yield call(qz.printers.find);
            yield put(setPrinters(qzPrinters.map((printer) => ({ name: printer, type: PrinterType.QzTray }))));
        }
        catch (error) {
            yield put(errorQzPrinters(error.message));
        }
    }
}
export default new QzPrintingModule();
