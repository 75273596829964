import classNames from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';

import messages from '../../messages';

import './styles.scss';

export class TabMenuComponent extends PureComponent {
  render() {
    return Object.keys(this.props.paths).map((pathName) => {
      const options = this.props.paths[pathName];
      const { path, pattern } = options;
      const key = path;
      const isActiveTab = !!this.props.currentLocation.match(pattern);
      const tabClassName = classNames('text-decoration-none', 'header_tab', {
        'header_tab--active': isActiveTab,
      });
      if (isActiveTab) {
        return (
          <span key={key} className={tabClassName}>
            <FormattedMessage {...messages[`${pathName.toLowerCase()}MenuTabName`]} />
          </span>
        );
      }
      return (
        <Link to={path} className={tabClassName} key={key}>
          <FormattedMessage {...messages[`${pathName.toLowerCase()}MenuTabName`]} />
        </Link>
      );
    });
  }
}

TabMenuComponent.propTypes = {
  paths: PropTypes.object.isRequired,
  currentLocation: PropTypes.string,
};

export default TabMenuComponent;
