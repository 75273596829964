import { call, put, select, takeLatest } from 'redux-saga/effects';
import { persistLocalForageUtils } from '../utils/storage/persistLocalForageUtils';
import { selectEmvPaymentModule } from './emvPaymentModule-selectors';
import { acknowledgeEmvTransaction, beginEmvTransaction, cancelEmvTransaction, clearEmvTransaction, fetchPaymentDevices, initializeEmvPaymentModule, pollTransactionStatus, reverseEmvTransaction, setDeviceId, } from './emvPaymentModule-slice';
import noopPaymentModule from './NoopPaymentModule';
import pdgPaymentModule from './PdgPaymentModule';
import tmdsPaymentModule from './TmdsPaymentModule';
export function* doInitializeEmvPaymentModule(action) {
    const permissions = action.payload;
    if (!permissions) {
        yield call(noopPaymentModule.initialize);
        return;
    }
    const pdgIsAvailable = yield call(pdgPaymentModule.isAvailable, permissions);
    const tmdsIsAvailable = yield call(tmdsPaymentModule.isAvailable, permissions);
    if (pdgIsAvailable) {
        yield call(pdgPaymentModule.initialize);
    }
    else if (tmdsIsAvailable) {
        yield call(tmdsPaymentModule.initialize);
    }
    else {
        yield call(noopPaymentModule.initialize);
    }
}
export function* startTransaction(action) {
    const emvPaymentModule = yield select(selectEmvPaymentModule);
    yield call(emvPaymentModule.processTransaction, action.payload);
}
export function* cancelTransaction() {
    const emvPaymentModule = yield select(selectEmvPaymentModule);
    yield call(emvPaymentModule.cancelTransaction);
}
export function* acknowledgeTransaction() {
    const emvPaymentModule = yield select(selectEmvPaymentModule);
    yield call(emvPaymentModule.acknowledgeTransaction);
}
export function* reverseTransaction() {
    const emvPaymentModule = yield select(selectEmvPaymentModule);
    yield call(emvPaymentModule.reverseTransaction);
}
export function* doFetchPaymentDevices() {
    const emvPaymentModule = yield select(selectEmvPaymentModule);
    yield call(emvPaymentModule.fetchPaymentDevices);
}
export function* doPollTransactionStatus() {
    const emvPaymentModule = yield select(selectEmvPaymentModule);
    yield call(emvPaymentModule.pollTransactionStatus);
}
export function* clearTransaction() {
    const emvPaymentModule = yield select(selectEmvPaymentModule);
    yield call(emvPaymentModule.clearTransaction);
}
export function savePaymentDevice(action) {
    persistLocalForageUtils.setItem('activePaymentDevice', action.payload);
}
export function* loadPersistedPaymentDevice() {
    const deviceId = yield persistLocalForageUtils.getItem('activePaymentDevice');
    if (deviceId) {
        yield put(setDeviceId(deviceId));
    }
}
export function* watchInitializeEmvPaymentModule() {
    yield takeLatest(initializeEmvPaymentModule.type, doInitializeEmvPaymentModule);
    yield takeLatest(initializeEmvPaymentModule.type, loadPersistedPaymentDevice);
}
export function* watchForStartEmvTransaction() {
    yield takeLatest(beginEmvTransaction.type, startTransaction);
}
export function* watchForAcknowledgeEmvTransaction() {
    yield takeLatest(acknowledgeEmvTransaction.type, acknowledgeTransaction);
}
export function* watchForReverseEmvTransaction() {
    yield takeLatest(reverseEmvTransaction.type, reverseTransaction);
}
export function* watchForCancelEmvTransaction() {
    yield takeLatest(cancelEmvTransaction.type, cancelTransaction);
}
export function* watchForFetchPaymentDevices() {
    yield takeLatest(fetchPaymentDevices.type, doFetchPaymentDevices);
}
export function* watchForPollTransactionStatus() {
    yield takeLatest(pollTransactionStatus.type, doPollTransactionStatus);
}
export function* watchForSetDeviceId() {
    yield takeLatest(setDeviceId.type, savePaymentDevice);
}
export function* watchForClearTransaction() {
    yield takeLatest(clearEmvTransaction.type, clearTransaction);
}
export default [
    watchInitializeEmvPaymentModule,
    watchForStartEmvTransaction,
    watchForAcknowledgeEmvTransaction,
    watchForReverseEmvTransaction,
    watchForCancelEmvTransaction,
    watchForFetchPaymentDevices,
    watchForPollTransactionStatus,
    watchForSetDeviceId,
    watchForClearTransaction,
];
