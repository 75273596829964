import { ZoneContextManager } from '@opentelemetry/context-zone';
import { CompositePropagator, W3CBaggagePropagator, W3CTraceContextPropagator } from '@opentelemetry/core';
import { OTLPTraceExporter } from '@opentelemetry/exporter-otlp-http';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch';
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request';
import { OTTracePropagator } from '@opentelemetry/propagator-ot-trace';
import { Resource } from '@opentelemetry/resources';
import { BatchSpanProcessor } from '@opentelemetry/sdk-trace-base';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';
import { LIGHTSTEP_URL, SALES_API_URL } from '../../constants';
import { EventDetailsSpanProcessor } from './event-details-span-processor';
import { PerformanceDevSpanProcessor } from './performance-dev-span-processor';
import { UserInformationSpanProcessor } from './user-information-span-processor';
const createExporter = () => new OTLPTraceExporter({
    headers: {},
    url: LIGHTSTEP_URL,
    attributes: {
        hardwareConcurrency: navigator.hardwareConcurrency,
        deviceMemory: navigator.deviceMemory,
    },
});
const createProvider = () => {
    const provider = new WebTracerProvider({
        resource: new Resource({
            [SemanticResourceAttributes.SERVICE_NAME]: 'sales.ui',
        }),
    });
    provider.register({
        contextManager: new ZoneContextManager(),
        propagator: new CompositePropagator({
            propagators: [new W3CTraceContextPropagator(), new W3CBaggagePropagator(), new OTTracePropagator()],
        }),
    });
    return provider;
};
const createSpanProcessors = (providerWithZone, exporter) => {
    const eventDetailsSpanProcessor = new EventDetailsSpanProcessor();
    const userInformationSpanProcessor = new UserInformationSpanProcessor();
    providerWithZone.addSpanProcessor(new BatchSpanProcessor(exporter));
    providerWithZone.addSpanProcessor(userInformationSpanProcessor);
    providerWithZone.addSpanProcessor(eventDetailsSpanProcessor);
    providerWithZone.addSpanProcessor(new PerformanceDevSpanProcessor());
    return { userInformationSpanProcessor, eventDetailsSpanProcessor };
};
const configureIntrumentation = () => {
    const propagateTraceHeaderCorsUrls = [new RegExp(`^${SALES_API_URL}.*`)];
    const ignoreUrls = [/api-js\.mixpanel\.com/, /www\.google-analytics\.com/];
    registerInstrumentations({
        instrumentations: [
            new FetchInstrumentation({
                propagateTraceHeaderCorsUrls,
                applyCustomAttributesOnSpan,
            }),
            new XMLHttpRequestInstrumentation({
                clearTimingResources: true,
                ignoreUrls,
            }),
        ],
    });
};
export const applyCustomAttributesOnSpan = (span, request) => {
    var _a, _b;
    const header = (request === null || request === void 0 ? void 0 : request.headers) ? new Headers(request.headers) : new Headers();
    const attributes = {
        'tm.correlation_id': (_a = header.get('tmps-correlation-id')) !== null && _a !== void 0 ? _a : 'unknown',
    };
    try {
        const body = JSON.parse(((_b = request === null || request === void 0 ? void 0 : request.body) === null || _b === void 0 ? void 0 : _b.toString()) || '{}');
        if (body.query) {
            const [, queryType, graphqlOperation] = body.query.match(/(mutation|query) (\w+)/);
            if (graphqlOperation) {
                const spanName = `GraphQL ${queryType} ${graphqlOperation}`;
                attributes['tm.gql_operation'] = graphqlOperation;
                span.updateName(spanName);
            }
        }
    }
    catch (_) {
    }
    finally {
        span.setAttributes(attributes);
    }
};
const createLightstepProcessorsFacade = ({ userInformationSpanProcessor, eventDetailsSpanProcessor, }) => ({
    setUserInformation: (userInformation) => userInformationSpanProcessor.setUserInformation(userInformation),
    setEventDetails: (eventDetails) => eventDetailsSpanProcessor.setEventDetails(eventDetails),
});
export const registerLightstepTracing = () => {
    configureIntrumentation();
    const exporter = createExporter();
    const provider = createProvider();
    return createLightstepProcessorsFacade(createSpanProcessors(provider, exporter));
};
