import { withMatcher } from '@ticketmaster/tm1pos-web-shared/utils/withMatcher';
import {
  CLEAR_FORM_ERROR_STATE,
  FETCH_EMAIL_REPORT,
  FETCH_REPORT,
  FETCH_UPDATED_REPORT,
  GET_REPORT_DATA,
  RESET_REPORT_FORM,
  SEND_EMAIL_REPORT,
  UPDATE_REPORT_DATETIME,
} from './actions-constants';

export const startLoadingReport = () => ({
  type: GET_REPORT_DATA,
  ...FETCH_REPORT,
});

export const updateReportDateTime = (startDate: Date, endDate: Date) => ({
  type: UPDATE_REPORT_DATETIME,
  startDate,
  endDate,
  ...FETCH_UPDATED_REPORT,
});

export type PaymentGroupsLabels = {
  CASH: string;
  CHECK: string;
  CARD: string;
  OTHER: string;
};
export type SendEmailReport = {
  paymentGroupsLabels: PaymentGroupsLabels;
};
export const sendEmailReport = withMatcher((args: SendEmailReport) => ({
  type: SEND_EMAIL_REPORT,
  ...FETCH_EMAIL_REPORT,
  ...args,
}));

export const resetReportForm = () => ({
  type: RESET_REPORT_FORM,
});
export const clearFormErrorState = () => ({
  type: CLEAR_FORM_ERROR_STATE,
});
