import { LEVELS_COLOR_MAP } from '../../../PriceLevels/constants';

const OPTIMIZED_COLOR_ID = {
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  10: '10',
  11: '11',
  12: '12',
  13: '13',
  14: '14',
  15: '15',
  16: '16',
  17: '17',
  18: '18',
  19: '19',
  20: '20',
  21: '21',
  22: '22',
  23: '23',
  24: '24',
  25: '25',
  26: '26',
  27: '27',
  28: '28',
  29: '29',
  30: '30',
  31: '31',
  32: '32',
  A: '1',
  B: '2',
  C: '3',
  D: '4',
  E: '5',
  F: '6',
  G: '7',
  H: '8',
  I: '9',
  J: '10',
  K: '11',
  L: '12',
  M: '13',
  N: '14',
  O: '15',
  P: '16',
  Q: '17',
  R: '18',
  S: '19',
  T: '20',
  U: '21',
  V: '22',
  W: '23',
  X: '24',
  Y: '25',
  Z: '26',
};

export function convertToOptimizedColorId(colorId) {
  const maxColors = getMaxPriceLevelColorCount();
  const optimizedColorId = OPTIMIZED_COLOR_ID[colorId] % maxColors;
  if (Number.isNaN(optimizedColorId)) {
    return colorId;
  }
  return optimizedColorId || maxColors;
}

function toISMColor(value) {
  return value.replace('#', '');
}

const MAX_PRICE_LEVEL_COLOR_COUNT = 10;

export const getMaxPriceLevelColorCount = () => MAX_PRICE_LEVEL_COLOR_COUNT;

export const getIsmPriceLevelColors = () =>
  Array.from({ length: getMaxPriceLevelColorCount() }).map((value, index) => ({
    colorId: `${index + 1}`,
    color: toISMColor(LEVELS_COLOR_MAP[index + 1]),
  }));
