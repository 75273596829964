import { GraphQLResult } from '@ticketmaster/tm1pos-web-shared/model/graphql';
import { isCartExpired } from '@ticketmaster/tm1pos-web-shared/utils/conditionals';
import { call, Effect, put } from 'redux-saga/effects';
import { CLEAR_CART } from '../App/actions-constants';
import { clearCart } from '../App/sagas';
import {
  CART_IN_WRONG_STATE,
  CART_SPLIT_ORDER_NOT_SUPPORTED,
  TICKET_TYPE_NOT_AVAILABLE,
} from '../Checkout/components/Form/constants';
import {
  BEST_AVAILABLE_PRICE_MODAL_TOGGLE,
  CART_HAS_EXPIRED_INVENTORY_MASK,
  CART_IN_WRONG_STATE_ERROR,
  CART_SPLIT_ORDER_NOT_SUPPORTED_ERROR,
  FETCH_BEST_AVAILABLE_SEATS,
  PLACE_TO_RESERVE_FETCH,
  TICKET_TYPE_NOT_AVAILABLE_ERROR,
} from './constants';
import { BestAvailableResult, ISMSelectionResult } from './model/graphql';

export const getErrorEffectsFromBestAvailableResponse = (
  response: GraphQLResult<BestAvailableResult>,
  ticketQty: number,
): Effect[] => {
  const effects: Effect[] = [];
  if (response.errors && response.errors.length > 0 && response.errors[0].code) {
    const errorCode = response.errors[0].code;
    if (errorCode === CART_IN_WRONG_STATE) {
      effects.push(put({ type: BEST_AVAILABLE_PRICE_MODAL_TOGGLE }));
      effects.push(put({ type: CART_IN_WRONG_STATE_ERROR }));
    } else if (errorCode === CART_SPLIT_ORDER_NOT_SUPPORTED) {
      const cart = response.data!.reserveBestAvailable; // eslint-disable-line @typescript-eslint/no-non-null-assertion
      const reservedTicketQuantity = cart.items[cart.items.length - 1].places.length;
      effects.push(call(clearCart, { type: CLEAR_CART, cartId: cart.id, trigger: 'Split order' }));
      effects.push(
        put({ type: CART_SPLIT_ORDER_NOT_SUPPORTED_ERROR, ticketQuantity: ticketQty, reservedTicketQuantity }),
      );

      return effects;
    } else if (errorCode === TICKET_TYPE_NOT_AVAILABLE) {
      effects.push(put({ type: TICKET_TYPE_NOT_AVAILABLE_ERROR }));

      return effects;
    }

    if (isCartExpired(response)) {
      effects.push(put({ type: BEST_AVAILABLE_PRICE_MODAL_TOGGLE }));
      effects.push(
        put({
          type: PLACE_TO_RESERVE_FETCH.ERROR,
          errorType: CART_HAS_EXPIRED_INVENTORY_MASK,
          place: {},
        }),
      );
      effects.push(put({ type: CLEAR_CART, trigger: 'Cart expired', origin: 'Best Avail' }));
    } else {
      effects.push(put({ type: FETCH_BEST_AVAILABLE_SEATS.ERROR, errorType: errorCode, quantity: ticketQty }));
    }
  }

  return effects;
};

export const getErrorEffectsFromISMSelectionResponse = (
  response: GraphQLResult<ISMSelectionResult>,
  action: any,
): Effect[] => {
  const effects: Effect[] = [];
  const hasErrors = response.errors && response.errors.length > 0;
  if (hasErrors && response.errors?.[0].code) {
    const errorCode = response.errors[0].code;
    if (errorCode === CART_IN_WRONG_STATE) {
      effects.push(
        put({
          type: CART_IN_WRONG_STATE_ERROR,
          place: action.place,
        }),
      );
    } else if (errorCode === TICKET_TYPE_NOT_AVAILABLE) {
      effects.push(
        put({
          type: TICKET_TYPE_NOT_AVAILABLE_ERROR,
          place: action.place,
        }),
      );
    } else if (isCartExpired(response)) {
      effects.push(
        put({
          type: action.ERROR,
          errorType: CART_HAS_EXPIRED_INVENTORY_MASK,
          place: action.place,
        }),
      );
      effects.push(put({ type: CLEAR_CART, trigger: 'Cart expired', origin: 'ISM Selection' }));
    } else {
      effects.push(
        put({
          type: action.ERROR,
          errorType: errorCode,
          place: action.place,
        }),
      );
    }
  } else if (hasErrors && !response.data?.reserveSinglePlace) {
    effects.push(
      put({
        type: action.ERROR,
        place: action.place,
      }),
    );
    effects.push(put({ type: CLEAR_CART, trigger: 'Generic error', origin: 'ISM Selection' }));
  }

  return effects;
};
