import { call } from 'redux-saga/effects';
import { POS_GRAPHQL_URL } from '../../constants';
import { PRINTABLE_TEST_TICKET_QUERY } from './api-queries';
import { fetchFromSalesApi } from './sales-api';
export function* getPrintableTestTicket(printableTestTicketRequest) {
    var _a;
    const response = yield call(fetchFromSalesApi, {
        url: POS_GRAPHQL_URL,
        query: PRINTABLE_TEST_TICKET_QUERY,
        options: {
            variables: {
                printableTestTicketRequest,
            },
        },
    });
    return ((_a = response.data) === null || _a === void 0 ? void 0 : _a.printableTestTicket) || [];
}
