import ErrorContainer from '@ticketmaster/tm1pos-web-shared/components/ErrorContainer';
import { ToastContainerProvider } from '@ticketmaster/tm1pos-web-shared/context/toast-container';
import { selectErrorModalContent, selectIsModalActive } from '@ticketmaster/tm1pos-web-shared/errorHandling/selectors';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CheckoutErrorModal from 'containers/Checkout/components/Form/errors/CheckoutErrorModal';
import { selectMainErrorNotifications } from '../../../../middleware/errorsHandling/selectors';
import { checkoutToggleLocation } from '../../../../middleware/mixpanel-constants';
import { useAppSelector } from '../../../../store/hooks';
import Checkout from '../../../Checkout';
import { selectOrderIdToRetry } from '../../../EventDetailPage/selectors/main';
import { promptOrderDelete, toggleCheckout } from '../../actions';
import CartExpiredErrorModal from '../../components/CartExpiredErrorModal/CartExpiredErrorModal';
import EditOtherChargesModal from '../../components/EditOtherChargesModal';
import { selectShowCart } from '../../selectors';
import CartCover from '../CartCover';
import Header from '../Header';

import './styles.scss';

export const MainContainer = ({
  children,
  location,
}: {
  children: JSX.Element;
  location: Pick<Location, 'pathname'>;
}) => {
  const dispatch = useDispatch();
  const showCart = useAppSelector(selectShowCart);
  const modalContent = useAppSelector(selectErrorModalContent);
  const isModalActive = useAppSelector(selectIsModalActive);
  const errorNotifications = useAppSelector(selectMainErrorNotifications);
  const isOrderUnpaid = !!useAppSelector(selectOrderIdToRetry);

  const [initCartComponent, setInitCartComponent] = useState(!!showCart);

  const handleClickCart = () => {
    const toggleLocation = checkoutToggleLocation.NAV;
    if (isOrderUnpaid) {
      dispatch(promptOrderDelete());
    } else {
      dispatch(toggleCheckout({ location: toggleLocation, showCart }));
    }
  };

  const continueShopping = () => {
    const toggleLocation = checkoutToggleLocation.CONTINUE_SHOPPING;
    dispatch(toggleCheckout({ location: toggleLocation, showCart }));
  };

  useEffect(() => {
    if (showCart) {
      setInitCartComponent(true);
    }
  }, [showCart]);

  return (
    <ToastContainerProvider>
      <div>
        <Header handleClickCart={handleClickCart} locationPath={location.pathname} />
        {children}
        <CartCover showCart={showCart} handleClick={handleClickCart} />
        <Checkout
          initCartComponent={initCartComponent}
          showCart={showCart}
          handleClickCart={handleClickCart}
          continueShopping={continueShopping}
        />
        <EditOtherChargesModal />
        <CheckoutErrorModal />
        <CartExpiredErrorModal />
        <ErrorContainer
          modalContent={modalContent}
          isModalActive={isModalActive}
          errorNotifications={errorNotifications}
          className="sdr-error-container--main"
        />
      </div>
    </ToastContainerProvider>
  );
};
