import { combineReducers } from '@reduxjs/toolkit';
import errorsReducer from '../errorHandling/reducer';
import { featureFlagsReducer } from '../feature-flags/feature-flags-slice';
import { emvPaymentModuleReducer } from '../payment/emvPaymentModule-slice';
import { printingModuleReducer } from '../printing/printingModule-slice';
import { translationsReducer } from '../translations/translations-slice';
import { eventsReducer } from './events/events.slice';
import { userReducer } from './user-information/user.slice';
export const reducers = {
    errors: errorsReducer,
    events: eventsReducer,
    featureFlags: featureFlagsReducer,
    language: translationsReducer,
    printingModule: printingModuleReducer,
    emvPaymentModule: emvPaymentModuleReducer,
    user: userReducer,
};
const rootReducer = combineReducers(reducers);
export const initLazyLoadingStructure = (store, createReducer, runSaga) => {
    store.lazyLoadedReducers = {};
    store.injectReducer = (key, reducer) => {
        if (!store.lazyLoadedReducers[key]) {
            store.lazyLoadedReducers[key] = reducer;
            store.replaceReducer(createReducer(store.lazyLoadedReducers));
        }
    };
    store.runSaga = runSaga;
    store.lazyLoadedSagas = {};
    store.injectSagas = (sagas, fallbackName = '') => {
        sagas
            .filter((saga) => !store.lazyLoadedSagas[saga.name || fallbackName])
            .map((saga) => {
            if (!saga.name && !fallbackName) {
                throw new Error('[store.injectSagas] To avoid duplicates, a saga function cannot be injected without a `name` property. Use `fallbackName` if importing a saga function from another package');
            }
            store.lazyLoadedSagas[saga.name] = saga;
            return saga;
        })
            .forEach(store.runSaga);
    };
    return store;
};
export const enhancers = () => {
    const enhancerList = [];
    const enhancerFn = window.__TM1POS_DEVTOOLS_EXTENSION__;
    if (enhancerFn) {
        enhancerList.push(enhancerFn());
    }
    return enhancerList;
};
