import { createSelector } from '@reduxjs/toolkit';
import { CartIcon } from '@ticketmaster/aurora';
import IconClose from '@ticketmaster/tm1pos-web-shared/components/icons/IconClose';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { selectCartItemsLength } from '../../../App/selectors';

export class CheckoutHeader extends Component {
  shouldComponentUpdate(nextProps) {
    const noUpdates = nextProps.cartItemsLength === this.props.cartItemsLength;
    return !noUpdates;
  }

  render() {
    return (
      <div className="sdr-checkout__header">
        <div className="sdr-checkout__cart">
          <CartIcon color="#fff" size={24} />
          <div className="sdr-checkout__cart-items">{this.props.cartItemsLength}</div>
        </div>
        <a role="button" onClick={this.props.handleClickCart} tabIndex={-1} className="sdr-checkout__close">
          <IconClose />
        </a>
      </div>
    );
  }
}

CheckoutHeader.propTypes = {
  handleClickCart: PropTypes.func,
  cartItemsLength: PropTypes.number,
};

const mapStateToProps = createSelector([selectCartItemsLength], (cartItemsLength) => ({ cartItemsLength }));
const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutHeader);
