export const LEVELS_MAX_QUANTITY = 32;

export const DEFAULT_PRICE_LEVEL_OBJ = {
  priceLevels: [],
  description: '',
  price: { amount: 0, currency: 'USD' },
  numericId: 0,
};

// The first "ism-colors.js:MAX_PRICE_LEVEL_COLOR_COUNT" colors are used only.
export const LEVELS_COLOR_MAP = {
  1: '#6BBEFD',
  2: '#FF8D21',
  3: '#b564e3',
  4: '#38c56a',
  5: '#f03848',
  6: '#3e4de2',
  7: '#f9cc01',
  8: '#e542d0',
  9: '#308c7e',
  10: '#a90119',
  11: '#7696fd',
  12: '#fdf527',
  13: '#c8aafe',
  14: '#64f2b7',
  15: '#fe92cd',
  16: '#037ca4',
  17: '#a58111',
  18: '#7b07de',
  19: '#209913',
  20: '#c70c7a',
  21: '#34d4cb',
  22: '#f55b4b',
  23: '#9b22b9',
  24: '#2fe31c',
  25: '#fc59ad',
  26: '#1e4477',
  27: '#67510d',
  28: '#edb4fd',
  29: '#8ed38e',
  30: '#fc027f',
  31: '#778699',
  32: '#c3ca07',
  A: '#6BBEFD',
  B: '#FF8D21',
  C: '#b564e3',
  D: '#38c56a',
  E: '#f03848',
  F: '#3e4de2',
  G: '#f9cc01',
  H: '#e542d0',
  I: '#308c7e',
  J: '#a90119',
  K: '#7696fd',
  L: '#fdf527',
  M: '#c8aafe',
  N: '#64f2b7',
  O: '#fe92cd',
  P: '#037ca4',
  Q: '#a58111',
  R: '#7b07de',
  S: '#209913',
  T: '#c70c7a',
  U: '#34d4cb',
  V: '#f55b4b',
  W: '#9b22b9',
  X: '#2fe31c',
  Y: '#fc59ad',
  Z: '#1e4477',
};
