import { selectEmvPaymentModuleState } from '@ticketmaster/tm1pos-web-shared/payment/emvPaymentModule-selectors';
import { ReverseTransactionStatus } from '@ticketmaster/tm1pos-web-shared/payment/emvPaymentModule-types';
import { useDispatch, useSelector } from 'react-redux';
import { promptOrderDelete } from 'containers/App/actions';
import { CLEAR_CART, REJECT_ORDER_DELETE } from 'containers/App/actions-constants';
import { selectOrderIdToRetry } from 'containers/EventDetailPage/selectors/main';
import { selectCheckoutError } from '../../selectors';
import { clearCheckoutError } from '../checkout-error-actions';
import { CheckoutError } from '../checkoutError';
import CheckoutErrorModal from './CheckoutErrorModal';

export const CheckoutErrorModalConnected = () => {
  const error: CheckoutError = useSelector(selectCheckoutError);
  const {
    transaction: { reverseStatus },
  } = useSelector(selectEmvPaymentModuleState);

  const orderIdToRetry = useSelector(selectOrderIdToRetry);

  const dispatch = useDispatch();

  const onClose = () => {
    if (error.clearCart) {
      dispatch({ type: CLEAR_CART });
    }
    if (error.retryPayment) {
      dispatch({
        type: REJECT_ORDER_DELETE,
      });
    }
    dispatch(clearCheckoutError());
  };

  const onCancel = () => {
    dispatch(clearCheckoutError());
    dispatch(promptOrderDelete());
  };

  if (error && reverseStatus !== ReverseTransactionStatus.PROCESSING) {
    return (
      <CheckoutErrorModal
        errorCode={error.code}
        paymentFailed={error.retryPayment}
        reversePaymentFailed={reverseStatus === ReverseTransactionStatus.FAILURE}
        orderId={orderIdToRetry}
        close={onClose}
        cancel={error.retryPayment ? onCancel : undefined}
        notes={error.notes}
        cid={error.cid}
      />
    );
  }
  return null;
};
