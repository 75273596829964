import { FormattedMessage } from 'react-intl';
import { DEFAULT_CHECKOUT_ERROR_CODE } from './checkout-error-messages';

export default class ThrowableCheckoutError extends Error {
  public readonly errorType: string;

  constructor(message: string, errorType: string) {
    super(message);
    this.errorType = errorType;
  }
}

export const checkoutError = (code?: string): CheckoutError => ({
  code: code ?? DEFAULT_CHECKOUT_ERROR_CODE,
  clearCart: false,
  retryPayment: false,
  notes: [],
});

export type CheckoutError = {
  code: string;
  retryPayment: boolean;
  clearCart: boolean;
  notes: FormattedMessage.MessageDescriptor[];
  cid?: string;
};
