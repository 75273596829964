import { addLocaleData } from 'react-intl';
import localeEn from 'react-intl/locale-data/en';
import { v4 as uuidV4 } from 'uuid';
export const notTranslatedMessage = (message) => ({
    id: uuidV4(),
    defaultMessage: message,
});
export const loadLocale = async (lang) => {
    try {
        const { default: locale } = await import(`react-intl/locale-data/${lang}`);
        addLocaleData(locale);
        return locale[0].locale;
    }
    catch (e) {
        console.error('Cannot set locale-data, defaulting to en', e);
        addLocaleData(localeEn);
    }
    return localeEn[0].locale;
};
