import { EMAIL, PRINT, SMS } from '@ticketmaster/tm1pos-web-shared/constants';
import { CLOSE_ERROR_MODAL } from '@ticketmaster/tm1pos-web-shared/errorHandling/actions';
import { errorModalHoc } from '@ticketmaster/tm1pos-web-shared/errorHandling/errorModalHoc';
import { push } from 'connected-react-router';
import { FormattedMessage } from 'react-intl';
import ReverseTransactionResult from 'containers/Checkout/components/ReverseTransactionResult';
import { userSignout } from '../../containers/App/actions';
import { CLEAR_CART, REJECT_ORDER_DELETE, START_ORDER_DELETE } from '../../containers/App/actions-constants';
import { messages as checkoutErrorMessages } from '../../containers/Checkout/components/Form/errors/checkout-error-messages';
import CancelOrder from '../../containers/EventDetailPage/components/CancelOrder';
import messages from './messages';

const unButtonClass = 'oc-unbutton';

const okCloseBtn = {
  className: 'oc-btn',
  message: messages.okMessage,
  actionsToDispatchOnClick: [{ type: CLOSE_ERROR_MODAL }],
};

const okCloseAndClearCart = {
  className: 'oc-btn',
  message: messages.okMessage,
  actionsToDispatchOnClick: [{ type: CLEAR_CART, trigger: 'Close and Clear cart modal' }, { type: CLOSE_ERROR_MODAL }],
};
const closeBtn = {
  className: 'oc-modal__close',
  actionsToDispatchOnClick: [{ type: CLOSE_ERROR_MODAL }],
};
const returnToEventsBtn = {
  className: unButtonClass,
  message: messages.edpErrorBackToEventsAction,
  actionsToDispatchOnClick: [{ type: CLOSE_ERROR_MODAL }, push('/')],
};
const reloadBtn = {
  className: 'oc-btn',
  message: messages.edpErrorReloadAction,
  clickHandler: () => {
    window.location.reload();
  },
};
const signOutBtn = {
  className: 'oc-btn',
  message: messages.signOutAction,
  actionsToDispatchOnClick: [userSignout()],
};

export const edpErrorContent = errorModalHoc({
  mainMessage: messages.edpErrorLoadingEventTitle,
  tipMessages: [messages.edpErrorLoadingEventTip],
  actionButtons: [returnToEventsBtn, reloadBtn],
});

export const checkoutFailDefaultErrorContent = errorModalHoc({
  mainMessage: messages.defaultCheckoutErrorMessage,
  children: <ReverseTransactionResult />,
  actionButtons: [okCloseBtn],
});

export const paymentTypePermittedErrorContent = errorModalHoc({
  mainMessage: messages.wrongPaymentTypeErrorMessage,
  tipMessages: [messages.wrongPaymentTypeErrorTip],
  actionButtons: [okCloseBtn],
  children: <ReverseTransactionResult />,
});

export const swipeCardFailContent = errorModalHoc({
  mainMessage: messages.swipeCardErrorMessage,
  tipMessages: [messages.swipeCardErrorTip],
  actionButtons: [okCloseBtn],
});
export const companionSeatAlone = errorModalHoc({
  mainMessage: messages.companionErrorTitle,
  tipMessages: [messages.companionErrorContent],
  actionButtons: [okCloseBtn],
  closeButtons: [closeBtn],
  isYellowAlert: true,
});
export const cartWrongStateErrorContent = errorModalHoc({
  mainMessage: messages.cartWrongStateErrorMessage,
  tipMessages: [messages.cartWrongStateErrorTip],
  actionButtons: [okCloseAndClearCart],
  children: <ReverseTransactionResult />,
});
export const cartSplitOrderNotSupportedErrorContent = (ticketQuantity, reservedTicketQuantity) => {
  const values = { ticketQuantity, reservedTicketQuantity };
  return errorModalHoc({
    mainMessage: messages.cartSplitOrderNotSupportedErrorMessage,
    tipMessages: [messages.cartSplitOrderNotSupportedErrorTip],
    actionButtons: [okCloseBtn],
    messageParameters: values,
  });
};

export const cartSeatsExpiredErrorContent = errorModalHoc({
  mainMessage: messages.cartWrongStateErrorMessage,
  tipMessages: [messages.addSeatToCartExpired],
  actionButtons: [okCloseAndClearCart],
  children: <ReverseTransactionResult />,
});
export const checkoutCardFailErrorContent = (manualCardEntry) =>
  errorModalHoc({
    mainMessage: messages.cardCheckoutFailMainMessage,
    tipMessages: [
      manualCardEntry ? messages.manualCardCheckoutFailTipMessage : messages.swipedCardCheckoutFailTipMessage,
    ],
    children: <ReverseTransactionResult />,
    actionButtons: [okCloseBtn],
  });

export const noTicketTypesAvailableErrorContent = errorModalHoc({
  mainMessage: messages.noTicketTypesAvailableMessage,
  tipMessages: [messages.noTicketTypesAvailableTip],
  actionButtons: [returnToEventsBtn],
});

export const unsupportedEventErrorContent = (tipMessage) =>
  errorModalHoc({
    mainMessage: messages.unsupportedEventMessage,
    children: (
      <div className="oc-modal__title-tip">
        <FormattedMessage
          {...messages.unsupportedEventTip}
          values={{ specificMessage: <FormattedMessage {...tipMessage} /> }}
        />
      </div>
    ),
    actionButtons: [returnToEventsBtn],
  });

export const ticketTypeNotAvailableErrorContent = errorModalHoc({
  mainMessage: messages.ticketTypeNotAvailableMessage,
  tipMessages: [messages.ticketTypeNotAvailableTip],
  actionButtons: [okCloseBtn],
});

export const noBarcodeDeliveryFailErrorContent = (deliveryMethod, cid) => {
  let mainMessage;
  switch (deliveryMethod) {
    case SMS:
      mainMessage = messages.smsDeliveryFailMainMessage;
      break;
    case EMAIL:
      mainMessage = messages.emailFailMainMessage;
      break;
    case PRINT:
    default:
      mainMessage = messages.printingFailMainMessage;
  }
  return errorModalHoc({
    mainMessage,
    tipMessages: [messages.noBarcodeDeliveryFailTipMessage],
    actionButtons: [okCloseBtn],
    cid,
  });
};
export const smsDeliveryFailErrorContent = (cid) =>
  errorModalHoc({
    mainMessage: messages.smsDeliveryFailMainMessage,
    tipMessages: [messages.genericDeliveryFailTipMessage],
    actionButtons: [okCloseBtn],
    cid,
  });
export const printingErrorContent = (cid) =>
  errorModalHoc({
    mainMessage: messages.printingFailMainMessage,
    tipMessages: [messages.genericDeliveryFailTipMessage],
    actionButtons: [okCloseBtn],
    cid,
  });
export const emailErrorContent = (cid) =>
  errorModalHoc({
    mainMessage: messages.emailFailMainMessage,
    tipMessages: [messages.genericDeliveryFailTipMessage],
    actionButtons: [okCloseBtn],
    cid,
  });

export const invalidCartIdErrorContent = errorModalHoc({
  mainMessage: messages.invalidCartIdMessage,
  actionButtons: [okCloseAndClearCart],
  children: <ReverseTransactionResult />,
});
export const invalidCardTypeErrorContent = errorModalHoc({
  mainMessage: messages.defaultCheckoutErrorMessage,
  children: (
    <div className="oc-modal__title-tip oc-modal__title-tip--semi-bold" key={messages.invalidCardTypeMessage.id}>
      <FormattedMessage {...messages.invalidCardTypeMessage} />
      <ReverseTransactionResult />
    </div>
  ),
  actionButtons: [okCloseBtn],
});

export const fetchRefundPermissionFailedContent = errorModalHoc({
  mainMessage: messages.permissionsFailMessage,
  tipMessages: [messages.permissionsFailTipMessage],
  actionButtons: [reloadBtn, signOutBtn],
});

export const multipleDataSourcesPermissionFailedContent = errorModalHoc({
  mainMessage: messages.multipleDataSourcesPermissionFailMessage,
  tipMessages: [messages.multipleDataSourcesPermissionFailTipMessage],
  actionButtons: [signOutBtn],
});

export const askDeleteCartContent = (orderId) => {
  const values = { orderId };
  return errorModalHoc({
    mainMessage: checkoutErrorMessages.paymentNotCompleteTitle,
    tipMessages: [messages.confirmDeleteOrderMessage],
    children: <CancelOrder />,
    actionButtons: [
      {
        className: unButtonClass,
        message: messages.backToCheckout,
        actionsToDispatchOnClick: [{ type: CLOSE_ERROR_MODAL }, { type: REJECT_ORDER_DELETE }],
      },
      {
        className: 'oc-btn',
        message: checkoutErrorMessages.cancelOrder,
        actionsToDispatchOnClick: [{ type: CLOSE_ERROR_MODAL }, { type: START_ORDER_DELETE }],
      },
    ],
    messageParameters: values,
  });
};
