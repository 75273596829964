import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { selectActivePrinter } from '../../../printingModule-selectors';
import PrinterName from './PrinterName';
const PrinterNameConnected = () => {
    const activePrinter = useSelector(selectActivePrinter);
    return _jsx(PrinterName, { printer: activePrinter });
};
PrinterNameConnected.displayName = 'PrinterNameConnected';
export default PrinterNameConnected;
