import storageService from '../../utils/storage/storageService';
import { fetchData, fetchDataWithFaultTolerance } from './sales-api-fetchers';
export const hasErrors = (response) => { var _a; return (((_a = response.errors) === null || _a === void 0 ? void 0 : _a.length) || 0) > 0; };
export const extractData = (query, graphQLResult) => {
    const operation = (query.match(/(query|mutation) (.*?)\s/) || [])[2];
    const payload = ((graphQLResult === null || graphQLResult === void 0 ? void 0 : graphQLResult.data) || {})[operation];
    if (!payload) {
        throw new Error(`Cannot fetch ${operation}`);
    }
    return payload;
};
export const handleErrors = (response, onError) => {
    if (onError && hasErrors(response)) {
        onError(response.errors);
    }
};
export function* executeQuery(fetchArgs) {
    var _a;
    if ((_a = fetchArgs.options) === null || _a === void 0 ? void 0 : _a.faultToleranceHandler) {
        return yield* fetchDataWithFaultTolerance(fetchArgs);
    }
    return yield* fetchData(fetchArgs);
}
export const isResponseValid = (response) => !!response.data && !hasErrors(response);
export function* executeQueryWithCacheManagement(fetchArgs, cacheKey) {
    let response;
    const cachedResponse = storageService.getItem(cacheKey);
    if (cachedResponse) {
        response = cachedResponse;
    }
    else {
        response = yield* executeQuery(fetchArgs);
        if (isResponseValid(response)) {
            storageService.setItem(cacheKey, { data: response.data });
        }
    }
    return response;
}
