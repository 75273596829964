import { createSelector } from 'reselect';
import { ReverseTransactionStatus, TransactionStatus } from './emvPaymentModule-types';
import { EMVService } from './EMVService';
import noopPaymentModule from './NoopPaymentModule';
import messages from './payment-messages';
import pdgPaymentModule from './PdgPaymentModule';
import tmdsPaymentModule from './TmdsPaymentModule';
export const selectEmvPaymentModuleState = (state) => state.emvPaymentModule;
export const selectEMVMode = createSelector(selectEmvPaymentModuleState, (moduleState) => moduleState.emvMode);
export const selectEmvPaymentModule = createSelector(selectEMVMode, (emvMode) => {
    switch (emvMode) {
        case EMVService.Tmds:
            return tmdsPaymentModule;
        case EMVService.Pdg:
            return pdgPaymentModule;
        case EMVService.None:
        default:
            return noopPaymentModule;
    }
});
export const selectEmvPaymentInProgress = createSelector(selectEmvPaymentModuleState, (state) => state.transaction.status === TransactionStatus.PROCESSING);
export const selectHasEmvTransaction = createSelector(selectEmvPaymentModuleState, (state) => state.transaction.status !== TransactionStatus.NONE);
export const selectTransactionIsInError = createSelector(selectEmvPaymentModuleState, (state) => !!state.transaction.error);
export const selectEmvPaymentCancelInProgress = createSelector(selectEmvPaymentModuleState, (state) => state.transaction.status === TransactionStatus.CANCELLING);
export const selectEmvPaymentReverseInProgress = createSelector(selectEmvPaymentModuleState, (state) => state.transaction.reverseStatus === ReverseTransactionStatus.PROCESSING);
export const selectEmvPaymentStartInProgress = createSelector(selectEmvPaymentModuleState, (state) => state.transaction.status === TransactionStatus.STARTING);
export const selectEmvOperationInProgress = createSelector(selectEmvPaymentCancelInProgress, selectEmvPaymentStartInProgress, (isCancelInProgress, isStartInProgress) => isCancelInProgress || isStartInProgress);
export const selectEMVRefundPageError = createSelector(selectEmvPaymentModuleState, (emvPaymentModuleState) => {
    const { error } = emvPaymentModuleState.transaction;
    return getEMVError(error, messages.refundEMVRefundErrMsg);
});
export const selectEMVCheckoutPageError = createSelector(selectEmvPaymentModuleState, (emvPaymentModuleState) => {
    const { error } = emvPaymentModuleState.transaction;
    return getEMVError(error, messages.checkoutEMVPaymentErrMsg);
});
export const getEMVError = (error, defaultErrorMessage) => {
    if (!error) {
        return null;
    }
    return {
        terminalMessage: error.terminalError,
        message: error.errorMessage || defaultErrorMessage,
        isWarning: error.isWarning,
    };
};
