import { context } from '@opentelemetry/api';
import { setSpanContext } from '@opentelemetry/api/build/src/trace/context-utils';
import { call } from 'redux-saga/effects';
import { fetchJson, generateReqArgsWithToken } from '../../utils/sagas-utils';
import tracerService from '../tracer/tracer.service';
export function* fetchData({ url, query, options, monitor }) {
    const args = yield call(generateReqArgsWithToken, { ...options, query });
    const span = tracerService.getSpanForQuery(query);
    if (span) {
        const contextWithSpan = setSpanContext(context.active(), span.spanContext());
        return yield context.with(contextWithSpan, () => fetchJson(url, args));
    }
    const pciResult = yield call(fetchJson, url, args);
    if (monitor) {
        args.body = JSON.stringify({ query: monitor.query, variables: monitor.variables });
        fetchJson(monitor.url, args);
    }
    return pciResult;
}
export function* fetchDataWithFaultTolerance(fetchArgs) {
    const { options } = fetchArgs;
    const faultToleranceHandler = (options === null || options === void 0 ? void 0 : options.faultToleranceHandler) || (() => false);
    let tries = 0;
    let response;
    do {
        try {
            response = yield call(fetchData, fetchArgs);
        }
        catch (e) {
            response = null;
        }
        tries += 1;
    } while (faultToleranceHandler(response, tries));
    return response;
}
