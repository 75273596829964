function getUserOpcodes(userInformation) {
    var _a, _b;
    if (!((_a = userInformation.host) === null || _a === void 0 ? void 0 : _a.opCodes)) {
        return null;
    }
    const opCodes = Object.entries((_b = userInformation === null || userInformation === void 0 ? void 0 : userInformation.host) === null || _b === void 0 ? void 0 : _b.opCodes)
        .map(([host, opcode]) => `${host}/${opcode}`)
        .join(', ');
    return {
        'tm.host_op_codes': opCodes,
    };
}
function getUserType(userInformation) {
    var _a, _b;
    const userType = [];
    if (!!(userInformation === null || userInformation === void 0 ? void 0 : userInformation.host) && Object.values((_a = userInformation === null || userInformation === void 0 ? void 0 : userInformation.host) === null || _a === void 0 ? void 0 : _a.opCodes)[0]) {
        userType.push('Host');
    }
    if (!!(userInformation === null || userInformation === void 0 ? void 0 : userInformation.archtics) && ((_b = userInformation === null || userInformation === void 0 ? void 0 : userInformation.archtics) === null || _b === void 0 ? void 0 : _b.userId)) {
        userType.push('Archtics');
    }
    return userType.length ? userType.join(' + ') : 'NONE';
}
function getUserProfileInformation(userInformation) {
    if (!userInformation.profile.profileId) {
        return null;
    }
    return {
        'tm.principal_id': userInformation.profile.profileId,
        'tm.user_type': getUserType(userInformation),
    };
}
function getArchticsInformation(userInformation) {
    var _a, _b, _c, _d;
    if (!((_a = userInformation.archtics) === null || _a === void 0 ? void 0 : _a.userId) && !((_b = userInformation.archtics) === null || _b === void 0 ? void 0 : _b.dsn)) {
        return null;
    }
    return {
        'tm.archtics_user_id': (_c = userInformation.archtics) === null || _c === void 0 ? void 0 : _c.userId,
        'tm.archtics_user_dsn': (_d = userInformation.archtics) === null || _d === void 0 ? void 0 : _d.dsn,
    };
}
export function getUserAttributes(userInformation) {
    return {
        ...getUserProfileInformation(userInformation),
        ...getUserOpcodes(userInformation),
        ...getArchticsInformation(userInformation),
    };
}
export function getCurrentEventAttributes(eventDetails) {
    return {
        'tm.event_id': eventDetails.id,
        'tm.event_code': eventDetails.eventCode,
        'tm.event_name': eventDetails.name,
        'tm.event_host_name': eventDetails.hostName,
        'tm.event_map_id': eventDetails.mapId,
    };
}
export function getIsmAttributes(seatAmount, priceLevelAmount) {
    return {
        'ism.seat_amount': seatAmount,
        'ism.price_level_amount': priceLevelAmount,
    };
}
