import classNames from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import './styles.scss';

export class Tab extends PureComponent {
  onClick = () => {
    this.props.onClick(this.props.label);
  };

  render() {
    const className = classNames({
      'tab-item': true,
      'tab-item--active': this.props.activeTabLabel === this.props.label,
    });
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    return (
      <li className={className} role="tab" onClick={this.onClick}>
        <a className="tab-link">
          {this.props.icon()}
          <FormattedMessage {...this.props.message} />
        </a>
      </li>
    );
    /* eslint-enable */
  }
}

Tab.propTypes = {
  label: PropTypes.string,
  activeTabLabel: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.func,
  message: PropTypes.object,
};

export default Tab;
