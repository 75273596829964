import { serializeError } from 'serialize-error';
import { actionHistory } from '../../middleware/actionHistory/debugging';
import { CLIENT_CLOUD_LOG } from '../../store/actions-constants';
export function toLogError(error) {
    const logError = serializeError(error);
    delete logError.isAlreadyHandled;
    renameSafeMaskedFields(logError);
    return logError;
}
export function isAlreadyHandled(error) {
    return error ? error.isAlreadyHandled : false;
}
export function setAsHandled(error) {
    if (error) {
        error.isAlreadyHandled = true;
    }
}
export const isErrorEmpty = (error) => !error || (!error.message && !error.stack);
export const isErrorInvalid = (error) => (actionHistory.getLastActions().length === 0 ||
    actionHistory.getLastActions().every((action) => action === CLIENT_CLOUD_LOG)) &&
    isErrorEmpty(error);
const renameSafeMaskedFields = (logError) => {
    logError.errorName = logError.name;
    delete logError.name;
};
