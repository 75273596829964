import { createSelector } from '@reduxjs/toolkit';
import { selectCurrentEventDetails } from '@ticketmaster/tm1pos-web-shared/store/selectors';
import { eventType } from '@ticketmaster/tm1pos-web-shared/utils';
import { ARCHTICS_EVENT_TYPE, HOST_EVENT_TYPE } from '../constants';

export const selectCompoundEventId = (state) => state.event.get('currentEventId');

export const selectEventIds = createSelector(
  selectCompoundEventId,
  selectCurrentEventDetails,
  (compoundEventId, currentEvent) => eventType(compoundEventId || currentEvent?.id || ''),
);

export const selectEventType = createSelector(selectEventIds, (ids) =>
  !ids.archtics && ids.host ? HOST_EVENT_TYPE : ARCHTICS_EVENT_TYPE,
);
