import { createSelector } from '@reduxjs/toolkit';
import { renderInputComponent } from '@ticketmaster/tm1pos-web-shared/components/InputComponent';
import { PaymentType } from '@ticketmaster/tm1pos-web-shared/model/payment';
import { intlShape } from '@ticketmaster/tm1pos-web-shared/typings/react-intl-types';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { change } from 'redux-form/immutable';
import { Field, formValueSelector } from 'redux-form/lib/immutable';
import {
  ARCHTICS_EVENT_TYPE,
  ARCHTICS_PAYMENT_INFO_LIMITATION,
  HOST_PAYMENT_INFO_LIMITATION,
} from '../../../../../../constants';
import { getPaymentMethodMessageByKey } from '../../../../../../models/payment-methods-messages';
import { renderDropdownComponent } from '../../../../../App/components/SearchFilterDropdown';
import { selectCurrentEvent } from '../../../../../EventDetailPage/selectors/main';
import messages from '../../../../messages';
import {
  CASH_PAYMENT_INFO_CATEGORY,
  CHECK_PAYMENT_INFO_CATEGORY,
  CHECKOUT_FORM_TITLE,
  CREDIT_PAYMENT_INFO_CATEGORY,
  NO_PAYMENT_INFO_CATEGORY,
} from '../../constants';
import { selectOtherPaymentMethods } from '../../selectors';

import './styles.scss';
import { CashForm } from '../CashForm';

export class OtherForm extends Component {
  constructor() {
    super();
    this.state = {
      activeCategory: '',
    };
  }

  componentDidMount() {
    if (!this.props.getFieldValue(this.props.fieldNames.otherPaymentMethods)) {
      const cashPaymentInfo = this.props.otherPaymentMethods.find(
        (method) => CASH_PAYMENT_INFO_CATEGORY.indexOf(method.category) !== -1,
      );
      if (cashPaymentInfo) {
        const initialValue = this.mapMethodToInput(cashPaymentInfo);
        this.setFieldDefaultInputValue(initialValue);
      }
    }
  }

  get paymentInfo() {
    const { fieldNames } = this.props;
    const { activeCategory } = this.state;
    if (NO_PAYMENT_INFO_CATEGORY.indexOf(activeCategory) !== -1 || !activeCategory) {
      return null;
    }

    if (CASH_PAYMENT_INFO_CATEGORY.indexOf(activeCategory) !== -1) {
      return (
        <CashForm
          fieldNames={fieldNames}
          cashToTender={this.props.cashToTender}
          shouldInitFocusOnCashTendered={this.props.shouldInitFocusOnCashTendered}
          intl={this.props.intl}
          dispatch={this.props.dispatch}
          getFieldValue={this.props.getFieldValue}
        />
      );
    }

    if (CHECK_PAYMENT_INFO_CATEGORY.indexOf(activeCategory) !== -1) {
      return (
        <Field
          name={fieldNames.paymentInfo}
          component={renderInputComponent}
          placeholder={this.props.paymentInfoLabel}
          validate={this.paymentInfoCheckValidation}
        />
      );
    }

    if (CREDIT_PAYMENT_INFO_CATEGORY.indexOf(activeCategory) !== -1) {
      return (
        <Field
          name={fieldNames.paymentInfo}
          component={renderInputComponent}
          placeholder={this.props.intl.formatMessage(messages.cardNumberPlaceholder)}
          validate={this.paymentInfoCreditCardValidation}
        />
      );
    }
    return (
      <Field
        name={fieldNames.paymentInfo}
        component={renderInputComponent}
        placeholder={this.props.paymentInfoLabel}
        validate={this.paymentInfoValidation}
      />
    );
  }

  setFieldDefaultInputValue(defaultInputValue) {
    const { fieldNames, dispatch } = this.props;
    dispatch(change(CHECKOUT_FORM_TITLE, fieldNames.otherPaymentMethod, defaultInputValue));
    this.handleChange({ value: defaultInputValue.value });
  }

  validatePaymentMethod = (value) => (value ? undefined : this.props.intl.formatMessage(messages.paymentInfoRequired));

  paymentInfoValidation = (value) => {
    const maxValue =
      this.props.currentEvent.type === ARCHTICS_EVENT_TYPE
        ? ARCHTICS_PAYMENT_INFO_LIMITATION
        : HOST_PAYMENT_INFO_LIMITATION;
    return value && value.length > maxValue
      ? [
          this.props.intl.formatMessage(messages.paymentInfoLimitMsg),
          this.props.intl.formatMessage(messages.paymentInfoMaxValue, { maxValue }),
        ]
      : undefined;
  };

  paymentInfoCheckValidation = (value) => {
    if (!value) {
      return undefined;
    }
    const letterNumber = /^[0-9a-zA-Z]+$/;
    if (value.length > 10) {
      return this.props.intl.formatMessage(messages.paymentInfoMax10Chars);
    }
    if (value.match(letterNumber)) {
      return undefined;
    }
    return this.props.intl.formatMessage(messages.paymentInfoMax10Chars);
  };

  handleChange = (method) => {
    const category = this.props.otherPaymentMethods.find((it) => it.type === method.value)?.category;

    this.setState({
      activeCategory: category,
    });
  };

  paymentInfoCreditCardValidation = (value) => {
    if (value === 0 || !value || value === '0') {
      return undefined;
    }
    return /\d{12}/.exec(parseInt(value, 10)) ? undefined : this.props.intl.formatMessage(messages.paymentInfo12Digits);
  };

  translatePaymentMethod = (type, fallback) =>
    this.props.intl.formatMessage(
      getPaymentMethodMessageByKey(type) || {
        id: 'fake id',
        defaultMessage: fallback,
      },
    );

  mapMethodToInput(method) {
    const translatedLabel = this.translatePaymentMethod(method.type, method.description);
    return {
      id: method.type,
      name: translatedLabel,
      value: method.type,
      label: translatedLabel,
      disabled: false,
      hasError: false,
    };
  }

  render() {
    const { fieldNames, otherPaymentMethods = [] } = this.props;

    const provider = otherPaymentMethods.map(({ description, type }) => ({
      label: this.translatePaymentMethod(type, description),
      value: type,
    }));

    return (
      <div className="other-entry__form">
        <Field
          name={fieldNames.otherPaymentMethod}
          component={renderDropdownComponent}
          dataProvider={provider}
          emptySelection={this.props.otherPaymentsLabel}
          minimumOptionsToDisplaySearch={10}
          searchPlaceholder={this.props.intl.formatMessage(messages.otherPaymentsSearchPlaceholder)}
          emptySearchMessage={messages.noPaymentMethodsFound}
          validate={this.validatePaymentMethod}
          onChange={this.handleChange}
        />

        {this.paymentInfo}
      </div>
    );
  }
}

OtherForm.propTypes = {
  otherPaymentMethods: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      category: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
  intl: intlShape.isRequired,
  paymentInfoLabel: PropTypes.string,
  otherPaymentsLabel: PropTypes.string,
  currentEvent: PropTypes.object,
  fieldNames: PropTypes.object,
  cashToTender: PropTypes.object,
  shouldInitFocusOnCashTendered: PropTypes.bool,
  getFieldValue: PropTypes.func.isRequired,
};

const filterNonApplicablePaymentMethods = (paymentMethods) =>
  paymentMethods.filter((method) => method.type !== PaymentType.PIX);

const mapStateToProps = (state) => {
  const selector = formValueSelector(CHECKOUT_FORM_TITLE);
  return createSelector([selectOtherPaymentMethods, selectCurrentEvent], (otherPaymentMethods, currentEvent) => ({
    otherPaymentMethods: filterNonApplicablePaymentMethods(otherPaymentMethods),
    currentEvent,
    getFieldValue: (fieldName) => selector(state, fieldName),
  }))(state);
};

const mapDispatchToProps = (dispatch) => ({ dispatch });
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OtherForm));
