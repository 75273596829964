/*
 *
 * App global and API constants
 * config holds environment specific constants
 * constants.js contains constants used on all envs
 *
 */
import { OidcUser } from '@ticketmaster/tm1pos-web-shared/utils/model/OidcUser';

export const TAB_MENU_PATHS = {
  EVENTS: {
    path: '/',
    pattern: /^\/$/,
  },
  CUSTOMERS: {
    path: '/customers',
    pattern: /^\/customers.*/,
  },
  REPORTS: {
    path: '/reports',
    pattern: /^\/reports/,
  },
  SETTINGS: {
    path: '/settings',
    pattern: /^\/settings/,
  },
};

export const STATUS = {
  OPEN: 'OPEN',
  DIST_OPEN: 'DIST-OPEN',
  HOLD: 'HOLD',
  INQUIRY: 'INQUIRY',
  SOLD: 'SOLD',
};

export const PAYMENT_TYPES = {
  americanexpress: 'AMEX',
  visa: 'VISA',
  mastercard: 'Mastercard',
  discover: 'Discover',
  dinersclub: 'Diners Club',
  interac: 'Interac',
};

export const CARD_REPORT_ORDER = {
  visa: 1,
  mastercard: 2,
  americanexpress: 3,
  discover: 4,
  dinersclub: 5,
  interac: 6,
};

/* add any other App constants */
export const TM1SALES_APP_NAME = 'Sales';
export const COMPONENT_ID = process.env.COMPONENT_ID;
export const ISM_ENV = process.env.ISM_ENV;
export const ISM_WEBSOCKET_UPDATES_THROTTLE_RATE = 10000;
export const ARCHTICS_EVENTS_ADMIN_INVENTORY_STATUS_REFRESH_RATE = 10000;
export const ARCHTICS_EVENTS_ADMIN_INVENTORY_STATUS_MAX_DELAY = 600000; // 10 mins
export const ARCHTICS_EVENTS_ADMIN_INVENTORY_STATUS_MAX_ATTEMPTS = 6;
export const ARCHTICS_EVENT_TYPE = 'ARCHTICS_EVENT_TYPE';
export const ARCHTICS_NOTE_LIMITATION = 255;
export const HOST_NOTE_LIMITATION = 320;
export const ARCHTICS_PAYMENT_INFO_LIMITATION = 40;
export const HOST_PAYMENT_INFO_LIMITATION = 20;
export const HOST_EVENT_TYPE = 'HOST_EVENT_TYPE';
const port = window.location.port ? `:${window.location.port}` : '';
const baseUrl = `${window.location.protocol}//${window.location.hostname}${port}`;
const LOGOUT_REDIRECT_URL = baseUrl;
export const userOauth = {
  AUTHORITY: `${process.env.USER_OAUTH_BASE_URL}/v1/authorize`,
  CLIENT_ID: '0oaauy0z20G3VCSSr1d7',
  METADATA: JSON.parse(`${process.env.IDENTITY_METADATA}`),
  USERINFO_ENDPOINT: `${process.env.USER_OAUTH_BASE_URL}/v1/userinfo`,
  AUTHORIZATION_ENDPOINT: `${process.env.USER_OAUTH_BASE_URL}/v1/authorize`,
  TOKEN_ENDPOINT: `${process.env.USER_OAUTH_BASE_URL}/v1/token`,
  SCOPE: 'openid profile',
  REDIRECT_URI: `${baseUrl}/authenticate`,
  SILENT_REDIRECT_URI: `${baseUrl}/silentrenew`,
  RESPONSE_TYPE: 'token id_token',
  JWKS_URI: `${process.env.USER_OAUTH_BASE_URL}/v1/keys`,
  getEndSessionEndpoint: (user: OidcUser) =>
    `${process.env.USER_OAUTH_BASE_URL}/v1/logout?id_token_hint=${user.id_token}&post_logout_redirect_uri=${LOGOUT_REDIRECT_URL}`,
};

export const MIXPANEL_TM1_TOKEN = process.env.MIXPANEL_TM1_TOKEN;

export const FEEDBACK_CONTACTS_URL = `${process.env.TM1_URL}/api/tm1/support-contact`;
export const FEEDBACK_SUBMIT_URL = `${process.env.TM1_URL}/api/tm1/feedback`;
export const TRANSLATIONS_URL = `${process.env.TM1_URL}/translations`;

export const ISM_HOST_SNAPSHOT_URL = `https://${process.env.ISMRT_URI}/snapshot/v1`;
export const ISMRT_PLACE_UPDATES_URL = `wss://${process.env.ISMRT_URI}/v1`;

export const TM1ORDERS_URL = `${process.env.TM1ORDERS_URL}`;

const SALES_API_URL = `${process.env.INBOUND_PCI_URL}/sales-api`;
const POS_API_URL = `${process.env.INBOUND_PCICONN_URL}/pos-api`;

export const SHOPPING_SERVICE_URL = `${SALES_API_URL}/shopping`;
export const AVAILABILITY_SERVICE_URL = `${SALES_API_URL}/availability`;
export const UTILS_SERVICE_URL = `${SALES_API_URL}/utils`;
export const REPORT_SERVICE_URL = `${SALES_API_URL}/reports`;
export const EVENT_DETAILS_URL = `${SALES_API_URL}/graphql`;
export const CERTIFICATE_URL = `${SALES_API_URL}/certificate`;
export const PCI_GRAPHQL_URL = `${SALES_API_URL}/graphql`;
export const POS_GRAPHQL_URL = `${POS_API_URL}/graphql`;
export const TM1_URL = `${process.env.TM1_URL}`;
