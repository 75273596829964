import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { PrinterService } from '../../../PrinterService';
import DropdownPrinterDrivers from '../Inputs/DropdownPrinterDrivers';
import DropdownPrinterList from '../Inputs/DropdownPrinterList';
import InputRemoteServer from '../Inputs/InputRemoteServer';
import '../EditPrinterConfiguration.scss';
export const RemoteQzForm = ({ printers, defaultPrinter, onRefreshPrinter, onChangePrinter, connectionError, loading, printerServiceRefreshed, readOnly, showValidationMessage, }) => {
    const [remoteHost, setRemoteHost] = useState();
    const [printer, setPrinter] = useState();
    useEffect(() => {
        if (defaultPrinter && printerServiceRefreshed) {
            setPrinter(defaultPrinter);
            setRemoteHost(defaultPrinter.remoteHost);
            onChangePrinter(defaultPrinter);
        }
    }, [defaultPrinter, printerServiceRefreshed, setPrinter, onChangePrinter, setRemoteHost]);
    const onRemoteServerChange = (newRemoteHost) => {
        setRemoteHost(newRemoteHost);
        setPrinter(null);
        onChangePrinter(null);
        onRefreshPrinter(PrinterService.QzTrayRemote, newRemoteHost ? { ...newRemoteHost } : undefined);
    };
    const handlePrinterChange = useCallback((newPrinter) => {
        setPrinter(newPrinter);
        onChangePrinter(null);
    }, [onChangePrinter]);
    const handlePrinterDriverChange = (newDriver) => {
        if (printer && remoteHost) {
            const newPrinter = {
                ...printer,
                ...newDriver,
                printerService: PrinterService.QzTrayRemote,
                remoteHost,
            };
            setPrinter(newPrinter);
            onChangePrinter(newPrinter);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { children: _jsx(InputRemoteServer, { defaultRemoteServer: remoteHost !== null && remoteHost !== void 0 ? remoteHost : undefined, onChange: onRemoteServerChange, connectionError: connectionError, readOnly: readOnly, loading: loading, showValidationMessage: showValidationMessage }) }), _jsx("div", { children: _jsx(DropdownPrinterList, { hasConnectionError: !!connectionError, disabled: loading || !!connectionError || !remoteHost, defaultPrinter: printer, printers: printers, onChange: handlePrinterChange, onRefresh: () => onRefreshPrinter(PrinterService.QzTrayRemote, remoteHost ? { ...remoteHost } : undefined), readOnly: readOnly, showValidationMessage: showValidationMessage }) }), _jsx("div", { children: _jsx(DropdownPrinterDrivers, { defaultDriver: { method: printer === null || printer === void 0 ? void 0 : printer.method, dpi: printer === null || printer === void 0 ? void 0 : printer.dpi }, disabled: !(printer === null || printer === void 0 ? void 0 : printer.name), onChange: handlePrinterDriverChange, readOnly: readOnly, showValidationMessage: showValidationMessage }) })] }));
};
export default RemoteQzForm;
