import { call } from 'redux-saga/effects';
import { POS_GRAPHQL_URL } from '../../constants';
import faultTolerance from '../../utils/faultTolerance';
import { cancelPaymentMutation, PAYMENT_DEVICES_QUERY, paymentStatusQuery, startPaymentMutation } from './api-queries';
import { fetchFromSalesApi } from './sales-api';
export function* startPayment(deviceId, eventId, eventCode, hostId, opCode, manualEntry, amount, currency, onError, qrCodeProvider = null) {
    var _a;
    const response = yield call(fetchFromSalesApi, {
        url: POS_GRAPHQL_URL,
        query: startPaymentMutation(deviceId, eventId, eventCode, hostId, opCode, manualEntry, amount, currency, qrCodeProvider),
        onError,
    });
    return ((_a = response.data) === null || _a === void 0 ? void 0 : _a.startPayment) || '';
}
export function* getPaymentDevices() {
    var _a;
    const response = yield call(fetchFromSalesApi, {
        url: POS_GRAPHQL_URL,
        query: PAYMENT_DEVICES_QUERY,
    });
    return ((_a = response.data) === null || _a === void 0 ? void 0 : _a.paymentDevices) || [];
}
export function* cancelPayment(paymentReference, onError) {
    yield call(fetchFromSalesApi, {
        url: POS_GRAPHQL_URL,
        query: cancelPaymentMutation(paymentReference),
        onError,
    });
}
export function* getPaymentStatus(paymentReference, onError) {
    var _a;
    const response = yield call(fetchFromSalesApi, {
        url: POS_GRAPHQL_URL,
        query: paymentStatusQuery(paymentReference),
        onError,
        options: {
            faultToleranceHandler: faultTolerance.retryPaymentIfFaultyConnection,
        },
    });
    return ((_a = response.data) === null || _a === void 0 ? void 0 : _a.paymentStatus) || {};
}
