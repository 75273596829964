import { TransactionType } from '@ticketmaster/tm1pos-tmds-client';
import {
  beginEmvTransaction,
  emvPaymentSuccessful,
  fetchPaymentDevices,
} from '@ticketmaster/tm1pos-web-shared/payment/emvPaymentModule-slice';
import { selectCurrentEventDetails } from '@ticketmaster/tm1pos-web-shared/store/selectors';
import { selectIdentityId, selectOpcodes } from '@ticketmaster/tm1pos-web-shared/store/selectors/user-selector';
import { checkDefined } from '@ticketmaster/tm1pos-web-shared/utils';
import { persistLocalForageUtils } from '@ticketmaster/tm1pos-web-shared/utils/storage/persistLocalForageUtils';
import { actionTypes } from 'redux-form';
import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { call } from 'redux-saga-test-plan/matchers';
import { HOST_EVENT_TYPE } from '../../../EventDetailPage/constants';
import { selectTerminalId } from '../../../EventDetailPage/selectors/main';
import { selectEventType } from '../../../EventDetailPage/selectors/selectEventIds';
import { doDelivery } from './actions';
import { CHECKOUT_NOW, DO_DELIVERY } from './actions-constants';
import { PRINT_RECEIPT_KEY, PRINT_RECEIPT_UPDATE_SETTINGS } from './constants';
import { buildDeliveryRequest } from './delivery-mapper';
import { handleDelivery } from './delivery-saga';
import { paymentMethodToQrCodeProvider } from './qrCodeProvider';
import { handleCheckout, startCheckout } from './saga-checkout';
import { selectFormPrintReceipts, selectFormToggles, selectPayments } from './selectors';

export function* startEMVTransaction() {
  const selectedPayments = yield select(selectPayments);
  if (selectedPayments && selectedPayments.length) {
    const payment = selectedPayments[0];
    const currentEventType = yield select(selectEventType);
    const { manualCardEntry } = yield select(selectFormToggles);

    let systemId;
    let operatorCode;
    let eventCode;

    const eventDetails = yield select(selectCurrentEventDetails);
    const eventId = eventDetails.id;
    if (currentEventType === HOST_EVENT_TYPE) {
      const opCodes = yield select(selectOpcodes);

      systemId = eventDetails?.hostName;
      operatorCode = opCodes && systemId ? opCodes[systemId] : '';
      eventCode = eventDetails.eventCode;
    } else {
      systemId = 'ARCHTICS';
      operatorCode = yield select(selectTerminalId);
      eventCode = undefined;
    }

    const qrCodeProvider = paymentMethodToQrCodeProvider[payment.paymentMethod] ?? null;

    yield put(
      beginEmvTransaction({
        data: {
          amount: payment.amount.amount,
          systemId,
          operatorCode,
          eventId,
          eventCode,
          transactionCurrency: payment.amount.currency,
          isManualEntry: manualCardEntry,
          transactionType: TransactionType.Sale,
          qrCodeProvider,
        },
      }),
    );
  }
}

export function* deliver(
  deliveryMethod,
  orderConfirmation,
  deliveryConfig,
  hostName,
  eventDetails,
  payments,
  items,
  userInformation,
  receiptQuantity,
) {
  const deliveryRequest = yield call(
    buildDeliveryRequest,
    deliveryMethod,
    payments,
    items,
    orderConfirmation,
    userInformation,
    receiptQuantity,
  );

  yield put(
    doDelivery(
      deliveryMethod,
      orderConfirmation,
      deliveryConfig,
      hostName,
      eventDetails,
      payments,
      items,
      deliveryRequest,
    ),
  );
}

export function* storePrintReceiptPref(action) {
  const { field, form } = action.meta;
  if (form === 'checkout' && (field === 'printReceiptToggle' || field === 'printReceiptCount')) {
    const printReceipts = yield select(selectFormPrintReceipts);
    const identity = yield select(selectIdentityId);
    const key = `${PRINT_RECEIPT_KEY}-${identity}`;
    persistLocalForageUtils.setItem(key, printReceipts.toJS());
  }
}

export function* initializeForm(action) {
  yield call(getFormSettingsFromStorage, action);

  yield put(fetchPaymentDevices());
}

export function* getFormSettingsFromStorage(action) {
  if (checkDefined(action, ['meta', 'form']) && action.meta.form === 'checkout') {
    const identity = yield select(selectIdentityId);
    const key = `${PRINT_RECEIPT_KEY}-${identity}`;
    const data = yield persistLocalForageUtils.getItem(key);
    yield put({ type: PRINT_RECEIPT_UPDATE_SETTINGS, data });
  }
}

export function* watchCheckoutNow() {
  yield takeEvery(CHECKOUT_NOW, startCheckout);
}

export function* watchPrintReceiptChange() {
  yield takeLatest(actionTypes.CHANGE, storePrintReceiptPref);
}

export function* watchFormInitialize() {
  yield takeLatest(actionTypes.INITIALIZE, initializeForm);
}

export function* watchEMVSuccess() {
  yield takeLatest(emvPaymentSuccessful.type, handleCheckout);
}

export function* watchDoDelivery() {
  yield takeEvery(DO_DELIVERY, handleDelivery);
}

export default [watchCheckoutNow, watchPrintReceiptChange, watchFormInitialize, watchEMVSuccess, watchDoDelivery];
