import { useRef, useState } from 'react';
export const usePrinterConfiguration = () => {
    const [validPrinter, setValidPrinter] = useState(false);
    const [showValidationMessage, setShowValidationMessage] = useState(false);
    const editPrinterConfigurationRef = useRef(null);
    const cancelEditPrinterConfiguration = () => {
        var _a;
        (_a = editPrinterConfigurationRef.current) === null || _a === void 0 ? void 0 : _a.cancel();
    };
    const savePrinterConfiguration = () => {
        var _a;
        (_a = editPrinterConfigurationRef.current) === null || _a === void 0 ? void 0 : _a.save();
    };
    return {
        editPrinterConfigurationRef,
        cancelEditPrinterConfiguration,
        savePrinterConfiguration,
        setValidPrinter,
        validPrinter,
        showValidationMessage,
        setShowValidationMessage,
    };
};
