import { createSelector } from '@reduxjs/toolkit';
import { selectSelectedInventoryType } from '@ticketmaster/tm1pos-web-shared/store/selectors';
import { selectArchticsPermissions } from '@ticketmaster/tm1pos-web-shared/store/selectors/permissions-selector';
import { sortInventory } from '@ticketmaster/tm1pos-web-shared/utils';
import { List } from 'immutable';
import { ARCHTICS_EVENT_TYPE } from '../constants';
import { selectEventType } from './selectEventIds';

const selectHasAdaSeats = (state) => state.event.get('hasAdaSeats');
const selectShowAdaFilter = (state) => state.event.get('adaFilterToggle');

const selectAdaTypesSelected = createSelector(
  (state) => state.event.get('adaTypesSelected'),
  (adaTypesSelected) => adaTypesSelected || [],
);

const selectArchticsAdaTypes = createSelector(
  (state) => state.event.get('adaTypes'),
  (adaTypes = []) => (adaTypes instanceof List ? adaTypes.toJS() : adaTypes),
);

export const selectArchticsAdaTypesIds = createSelector(selectArchticsAdaTypes, (adaTypes) =>
  adaTypes.flatMap((adaType) => adaType.id),
);

const selectHostAdaTypes = createSelector(
  (state) => state.event.get('adaTypes'),
  (adaTypes = []) => (adaTypes instanceof List ? adaTypes.toJS() : adaTypes),
);

export const selectArchticsSellClasses = createSelector(
  selectArchticsPermissions,
  (permissions) => permissions?.sellClasses || [],
);

export const selectSortedArchticsInventoryTypes = createSelector(
  selectArchticsSellClasses,
  selectArchticsAdaTypesIds,
  (sellClasses, adaTypesIds) =>
    sellClasses instanceof List
      ? []
      : sortInventory(sellClasses.filter(({ id }) => adaTypesIds.indexOf(Number(id)) < 0)),
);

export const selectAdaTypes = createSelector(
  selectEventType,
  selectArchticsAdaTypes,
  selectHostAdaTypes,
  (eventType, selectedArchticsAdaTypes, selectedHostAdaTypes) => {
    const selectedAdaTypes = eventType === ARCHTICS_EVENT_TYPE ? selectedArchticsAdaTypes : selectedHostAdaTypes;
    return selectedAdaTypes || [];
  },
);

const selectAdaTypesSelection = createSelector(selectAdaTypesSelected, selectAdaTypes, (adaTypesSelected, adaTypes) =>
  adaTypesSelected.length > 0 ? adaTypes.filter((type) => adaTypesSelected.includes(type.description)) : adaTypes,
);

export const selectAdaTypesByDescription = createSelector(
  selectAdaTypes,
  selectAdaTypesSelected,
  (adaTypes, adaTypesSelected) => {
    const adaTypesByDescription = adaTypes.reduce((accumulator, adaType) => {
      accumulator[adaType.description] = accumulator[adaType.description] || {
        id: adaType.id,
        name: adaType.name,
        label: adaType.description || adaType.name,
        checked: adaTypesSelected.includes(adaType.description),
      };
      return accumulator;
    }, {});

    return Object.values(adaTypesByDescription);
  },
);

export const selectAdaInfo = createSelector(
  selectHasAdaSeats,
  selectShowAdaFilter,
  selectAdaTypesByDescription,
  selectAdaTypesSelection,
  (hasAda, showAdaFilters, adaTypesByDescription, adaTypesSelection) => ({
    available: hasAda && adaTypesByDescription.length > 0,
    showFilters: hasAda && showAdaFilters,
    adaTypes: adaTypesByDescription,
    adaTypesSelected: adaTypesSelection,
    selectedAdaTypesIds: adaTypesSelection.flatMap(({ id }) => id),
  }),
);

export const selectDisableFindBestAvailable = createSelector(
  selectAdaInfo,
  selectSelectedInventoryType,
  ({ showFilters }, selectedInventoryType) => showFilters || !!selectedInventoryType?.isAccessible,
);
