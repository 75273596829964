import { defineMessages } from 'react-intl';

export default defineMessages({
  introduction: {
    id: 'sales.Checkout.reverse.error.intro',
    defaultMessage:
      'An error occurred while reversing the payment transaction. Contact your Ticketmaster Representative and provide the information below to avoid an overcharge.',
  },
  note: {
    id: 'sales.Checkout.reverse.error.note',
    defaultMessage:
      '<strong>Please note:</strong><ul><li>This payment will need to be refunded manually. </li><li>Let the customer know that manual refund takes 24-48 hours to process once the request has been submitted.</li></ul>',
  },
  errorCode: {
    id: 'sales.Checkout.reverse.error.code',
    defaultMessage: 'Error {code}',
  },
  paymentInformation: {
    id: 'sales.Checkout.reverse.payment.information',
    defaultMessage: 'Payment information',
  },
  tm1Username: {
    id: 'sales.Checkout.reverse.tm1Username',
    defaultMessage: 'TM1 user name:',
  },
  transactionId: {
    id: 'sales.Checkout.reverse.transactionId',
    defaultMessage: 'Transaction id:',
  },
  last4: {
    id: 'sales.Checkout.reverse.last4',
    defaultMessage: 'Last 4 digits of the credit card:',
  },
  amount: {
    id: 'sales.Checkout.reverse.amount',
    defaultMessage: 'Amount:',
  },
  showMore: {
    id: 'shared.copy-box.button.show-more',
    defaultMessage: 'Show more',
  },
  showLess: {
    id: 'shared.copy-box.button.show-less',
    defaultMessage: 'Show less',
  },
});
