import { createAction, createSlice } from '@reduxjs/toolkit';
const actionNamespace = 'featureFlags';
export const initialState = {
    resolvedFlags: {},
};
const featureFlagsSlice = createSlice({
    name: actionNamespace,
    initialState,
    reducers: {
        saveFeatureFlags: (state, { payload }) => ({
            ...state,
            resolvedFlags: Object.fromEntries(payload.map((flag) => [flag, true])),
        }),
    },
});
export const featureFlagsLoaded = createAction(`${actionNamespace}/featureFlagsLoaded`);
export const { saveFeatureFlags } = featureFlagsSlice.actions;
export const featureFlagsReducer = featureFlagsSlice.reducer;
