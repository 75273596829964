import { createSelector } from '@reduxjs/toolkit';
import IconProfile from '@ticketmaster/tm1pos-web-shared/components/icons/IconProfile';
import { TooltipComponent } from '@ticketmaster/tm1pos-web-shared/components/TooltipComponent';
import outsideClick from '@ticketmaster/tm1pos-web-shared/components/utils/OutsideClick';
import { selectOrganizationName } from '@ticketmaster/tm1pos-web-shared/store/selectors/user-selector';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { userSignout } from '../../../App/actions';
import { selectFullName } from '../../../App/selectors';

import './styles.scss';
import messages from '../../messages';

export class UserProfileMenu extends Component {
  static propTypes = {
    fullName: PropTypes.string,
    clickedOutside: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
    organizationName: PropTypes.string,
    openDrop: PropTypes.func,
  };

  constructor() {
    super();
    this.state = { isOpen: false };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.clickedOutside && this.state.isOpen) {
      this.setState(() => {
        this.props.openDrop(false);
        return {
          isOpen: false,
        };
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.isOpen !== this.state.isOpen;
  }

  toggleUserMenuInfo = () => {
    this.setState((prevState) => {
      this.props.openDrop(!prevState.isOpen);
      return {
        isOpen: !prevState.isOpen,
      };
    });
  };

  userSignOut = () => {
    this.props.dispatch(userSignout());
  };

  render() {
    const { fullName } = this.props;
    const { organizationName } = this.props;
    let renderTooltip = (
      <TooltipComponent className="oc-tooltip--top profile__header-tooltip">
        <div className="profile-div-info">
          <div>
            <h2 className="profile-name-tooltip">{fullName}</h2>
            <h3 className="profile-org-name-tooltip">{organizationName}</h3>
          </div>
          <ul className="profile__actions-wrap">
            <li className="profile__action">
              <a onClick={this.userSignOut} tabIndex={0}>
                <FormattedMessage {...messages.signOut} />
              </a>
            </li>
          </ul>
        </div>
      </TooltipComponent>
    );
    if (!this.state.isOpen) {
      renderTooltip = null;
    }

    return (
      <div className="header__profile text-decoration-none">
        <a tabIndex={-1} onClick={this.toggleUserMenuInfo} role="button" className="oc-profile-icon-container">
          <IconProfile />
        </a>
        {renderTooltip}
      </div>
    );
  }
}

const mapStateToProps = createSelector([selectFullName, selectOrganizationName], (fullName, organizationName) => ({
  fullName,
  organizationName,
}));

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(outsideClick(UserProfileMenu));
