import { PRINT } from '@ticketmaster/tm1pos-web-shared/constants';
import MixpanelAnalytics from '@ticketmaster/tm1pos-web-shared/middleware/mixpanel-analytics';
import {
  FINISHED_USER_DATA_RETRIEVAL,
  UPDATE_USER_TRACKING_INFORMATION,
} from '@ticketmaster/tm1pos-web-shared/store/actions-constants';
import { selectSearchQuery } from '@ticketmaster/tm1pos-web-shared/store/events/events-selector';
import {
  fetchAllEventsLoading,
  fetchAllEventsSuccess,
  fetchInitialEventsLoading,
  fetchInitialEventsSuccess,
  selectEvent,
} from '@ticketmaster/tm1pos-web-shared/store/events/events.slice';
import { selectCurrentEventDetails } from '@ticketmaster/tm1pos-web-shared/store/selectors';
import { selectArchticsId, selectUserInformation } from '@ticketmaster/tm1pos-web-shared/store/selectors/user-selector';
import { checkDefined, cleanObjStrict } from '@ticketmaster/tm1pos-web-shared/utils';
import _ from 'lodash';
import { MIXPANEL_TM1_TOKEN, TM1SALES_APP_NAME } from '../constants';
import { CLEAR_CART, TOGGLE_CHECKOUT } from '../containers/App/actions-constants';
import { FETCH_CHECKOUT } from '../containers/Checkout/components/Form/actions-constants';
import { selectFormToggles } from '../containers/Checkout/components/Form/selectors';
import {
  ARCHTICS_EVENT_SEAT_STATUS_FETCH,
  BEST_AVAILABLE_PRICE_MODAL_TOGGLE,
  BEST_AVAILABLE_SEATS,
  FETCH_BEST_AVAILABLE_SEATS,
  FETCH_EVENT_DETAILS,
  INITIAL_ARCHTICS_EVENT_SEAT_STATUS_FETCH,
  PLACE_TO_RESERVE_FETCH,
  PLACE_TOTALS_SET,
  SEAT_DATA_LOAD_FETCH,
  UPDATE_ACTIVE_PRICE_RANGE,
  UPDATE_ACTIVE_TICKET_TYPE,
  UPDATE_TICKETS_QUANTITY,
} from '../containers/EventDetailPage/constants';
import {
  finishLoadingCurrentEventPage,
  startLoadingCurrentEventPage,
} from '../containers/EventDetailPage/event-actions';
import { selectPreviousTicketType } from '../containers/EventDetailPage/selectors/ticketType';
import { selectFirstOpcode } from '../containers/ReportPage/selectors';
import { checkoutToggleLocation, clearCartLocation, mixpanelConsts } from './mixpanel-constants';

const mixpanelTracks = {};
let mixpanelAnalytics;

const eventsList = [
  CLEAR_CART,
  BEST_AVAILABLE_SEATS,
  TOGGLE_CHECKOUT,
  UPDATE_USER_TRACKING_INFORMATION,
  UPDATE_TICKETS_QUANTITY,
  UPDATE_ACTIVE_TICKET_TYPE,
  BEST_AVAILABLE_PRICE_MODAL_TOGGLE,
  UPDATE_ACTIVE_PRICE_RANGE,
  SEAT_DATA_LOAD_FETCH.LOADING,
  SEAT_DATA_LOAD_FETCH.SUCCESS,
  FETCH_EVENT_DETAILS.SUCCESS,
  INITIAL_ARCHTICS_EVENT_SEAT_STATUS_FETCH.LOADING,
  INITIAL_ARCHTICS_EVENT_SEAT_STATUS_FETCH.SUCCESS,
  FETCH_CHECKOUT.LOADING,
  FETCH_CHECKOUT.SUCCESS,
  fetchInitialEventsLoading.type,
  fetchInitialEventsSuccess.type,
  fetchAllEventsLoading.type,
  fetchAllEventsSuccess.type,
  selectEvent.type,
  startLoadingCurrentEventPage.type,
  finishLoadingCurrentEventPage.type,
  FETCH_BEST_AVAILABLE_SEATS.SUCCESS,
  FETCH_BEST_AVAILABLE_SEATS.LOADING,
  PLACE_TO_RESERVE_FETCH.SUCCESS,
  PLACE_TO_RESERVE_FETCH.LOADING,
  ARCHTICS_EVENT_SEAT_STATUS_FETCH.SUCCESS,
  ARCHTICS_EVENT_SEAT_STATUS_FETCH.LOADING,
  PLACE_TOTALS_SET,
  FINISHED_USER_DATA_RETRIEVAL,
];
const ignoreAction = (action) => eventsList.indexOf(action.type) === -1;

export const trackUserType = (userIds) => {
  let userType = '';
  userIds.forEach((user) => {
    if (user.id) {
      if (user.type !== mixpanelConsts.ARCHTICS && !_.isEmpty(user.id)) {
        userType = `${user.type}`;
      } else if (user.type === mixpanelConsts.ARCHTICS && userType && !_.isEmpty(user.id)) {
        userType = `${userType} + ${user.type}`;
      } else if (user.type === mixpanelConsts.ARCHTICS && !userType && !_.isEmpty(user.id)) {
        userType = `${user.type}`;
      }
    }
  });
  return userType;
};

const appInfo = {
  App: TM1SALES_APP_NAME,
};

mixpanelTracks.dispatchLoginToMixpanel = (salesforceData) => {
  mixpanelAnalytics.setUserProperties(salesforceData);
  mixpanelAnalytics.eventTrack(mixpanelConsts.APP_LOAD, { 'App Click': false });
};

mixpanelTracks.dispatchEventSelectionToMixpanel = (eventDetails, state) => {
  const search = selectSearchQuery(state);
  mixpanelAnalytics.eventTrack(
    mixpanelConsts.SELECT_EVENT_FROM_LIST,
    {
      withFilter: !!search.length,
      page_name: mixpanelConsts.EVENT_SEARCH_PAGE,
    },
    eventDetails,
  );
};

mixpanelTracks.dispatchEventLoadTime = (action, eventDetails) => {
  mixpanelAnalytics.eventTrack(
    mixpanelConsts.EVENT_LOAD_TIME,
    {
      '# of Facets:': action.data?.data?.ismAvailability?.facets.length,
      page_name: mixpanelConsts.EVENT_DETAIL_PAGE,
    },
    eventDetails,
  );
};

mixpanelTracks.dispatchTimeToDeliver = (mixpanelAction, eventDetails) => {
  mixpanelAnalytics.eventTrack(
    mixpanelAction,
    {
      page_name: mixpanelConsts.EVENT_DETAIL_PAGE,
    },
    eventDetails,
  );
};

mixpanelTracks.dispatchFindBestAvailableSeatsToMixpanel = (eventDetails) => {
  mixpanelAnalytics.eventTrack(
    mixpanelConsts.FIND_BEST_AVAILABLE_SEATS,
    {
      page_name: mixpanelConsts.EVENT_DETAIL_PAGE,
    },
    eventDetails,
  );
};

mixpanelTracks.dispatchTicketTypeToMixpanel = (data, eventDetails) => {
  mixpanelAnalytics.eventTrack(
    mixpanelConsts.TICKET_TYPE_CHANGE,
    {
      ...data,
      page_name: mixpanelConsts.EVENT_DETAIL_PAGE,
    },
    eventDetails,
  );
};

mixpanelTracks.dispatchTogglePriceRangeModelToMixpanel = (eventDetails) => {
  mixpanelAnalytics.eventTrack(
    mixpanelConsts.PRICE_RANGE_MODEL_TOGGLE,
    {
      toggle: 'toggle',
      page_name: mixpanelConsts.EVENT_DETAIL_PAGE,
    },
    eventDetails,
  );
};

mixpanelTracks.dispatchQuantitySelectorMixpanel = (data, eventDetails) => {
  mixpanelAnalytics.eventTrack(
    mixpanelConsts.QUANTITY_SELECTOR,
    {
      '# of Tickets': data.value,
      page_name: mixpanelConsts.EVENT_DETAIL_PAGE,
    },
    eventDetails,
  );
};

mixpanelTracks.dispatchEventDetailsPage = (eventDetails) => {
  mixpanelAnalytics.eventTrack(
    mixpanelConsts.EVENT_DETAIL_PAGE_PAGE_VISIT,
    {
      page_name: mixpanelConsts.EVENT_DETAIL_PAGE,
    },
    eventDetails,
  );
};

mixpanelTracks.dispatchInitialAvailability = (actionType, eventDetails) => {
  mixpanelAnalytics.eventTrack(
    mixpanelConsts[actionType],
    {
      page_name: mixpanelConsts.EVENT_DETAIL_PAGE,
    },
    eventDetails,
  );
};

mixpanelTracks.dispatchGeometryLoadTime = (action, eventDetails) => {
  mixpanelAnalytics.eventTrack(
    mixpanelConsts.GEOMETRY_LOAD_TIME,
    {
      '# of ISM Seats': action.total,
      page_name: mixpanelConsts.EVENT_DETAIL_PAGE,
    },
    eventDetails,
  );
};

mixpanelTracks.dispatchDeleteInventoryInBagToMixpanel = (eventDetails) => {
  mixpanelAnalytics.eventTrack(
    mixpanelConsts.DELETE_INVENTORY_IN_BAG,
    {
      page_name: mixpanelConsts.EVENT_DETAIL_PAGE,
    },
    eventDetails,
  );
};

mixpanelTracks.dispatchDeleteInventoryInCartToMixpanel = (eventDetails) => {
  mixpanelAnalytics.eventTrack(
    mixpanelConsts.DELETE_INVENTORY_IN_CART,
    {
      page_name: mixpanelConsts.EVENT_DETAIL_PAGE,
    },
    eventDetails,
  );
};

mixpanelTracks.dispatchShoppingBagToggleCheckOutToMixpanel = (eventDetails) => {
  mixpanelAnalytics.eventTrack(
    mixpanelConsts.SHOPPING_BAG_CHECKOUT_TOGGLE,
    {
      page_name: mixpanelConsts.EVENT_DETAIL_PAGE,
    },
    eventDetails,
  );
};

mixpanelTracks.dispatchContinueShoppingAfterCheckoutToMixpanel = () => {
  mixpanelAnalytics.eventTrack(mixpanelConsts.CONTINUE_SHOPPING_AFTER_CHECKOUT, {
    page_name: mixpanelConsts.CHECKOUT_PAGE,
  });
};

mixpanelTracks.dispatchNavBarToggleCheckOutToMixpanel = () => {
  mixpanelAnalytics.eventTrack(mixpanelConsts.NAV_BAR_BAG_CHECKOUT_TOGGLE, {
    page_name: mixpanelConsts.CHECKOUT_PAGE,
  });
};

mixpanelTracks.dispatchPriceRangeToMixpanel = (ranges, eventDetails) => {
  mixpanelAnalytics.eventTrack(
    mixpanelConsts.PRICE_RANGE,
    {
      ...ranges,
      page_name: mixpanelConsts.EVENT_DETAIL_PAGE,
    },
    eventDetails,
  );
};

mixpanelTracks.dispatchCashPaymentMethodToMixpanel = (eventDetails) => {
  mixpanelAnalytics.eventTrack(
    mixpanelConsts.CASH_PAYMENT_PROCESSING,
    {
      page_name: mixpanelConsts.CHECKOUT_PAGE,
    },
    eventDetails,
  );
};

mixpanelTracks.dispatchCardPaymentMethodViaSwipeToMixpanel = (eventDetails) => {
  mixpanelAnalytics.eventTrack(
    mixpanelConsts.CARD_PAYMENT_PROCESSING_VIA_SWIPE,
    {
      page_name: mixpanelConsts.CHECKOUT_PAGE,
    },
    eventDetails,
  );
};

mixpanelTracks.dispatchCardPaymentMethodViaManualInputToMixpanel = (eventDetails) => {
  mixpanelAnalytics.eventTrack(
    mixpanelConsts.CARD_PAYMENT_PROCESSING_VIA_MANUAL_INPUT,
    {
      page_name: mixpanelConsts.CHECKOUT_PAGE,
    },
    eventDetails,
  );
};

mixpanelTracks.dispatchGrossTicketPriceToMixpanel = (data, eventDetails) => {
  mixpanelAnalytics.eventTrack(
    mixpanelConsts.GROSS_PRICE_TICKETS,
    {
      grossPrice: data.ticketsData.totals.total.amount,
      page_name: mixpanelConsts.CONFIRMATION_PAGE,
    },
    eventDetails,
  );
};

mixpanelTracks.dispatchCustomerInfoToMixpanel = ({ phoneNumber, email, enterName: name }) => {
  const customerInfo = cleanObjStrict({
    email,
    name,
    phoneNumber,
  });

  customerInfo['Salesforce ORG ID'] = checkDefined(mixpanelTracks, ['salesforceData', 'currsalesforceOrgIdent'])
    ? mixpanelTracks.salesforceData.salesforceOrgId
    : undefined;
  mixpanelAnalytics.eventTrack(mixpanelConsts.CUSTOMER_INFO, customerInfo);
};

mixpanelTracks.dispatchEventsLoadTimeToMixpanel = (actionType) => {
  mixpanelAnalytics.eventTrack(mixpanelConsts[actionType], {
    page_name: mixpanelConsts.EVENT_SEARCH_PAGE,
  });
};

mixpanelTracks.dispatchCaptureBuyerInfo = (props, eventDetails) => {
  mixpanelAnalytics.eventTrack(
    mixpanelConsts.CAPTURE_BUYER_INFO,
    {
      'Buyer Data': props.buyerData,
    },
    eventDetails,
  );
};

mixpanelTracks.dispatchCompletedCheckoutToMixpanel = (props, eventDetails) => {
  mixpanelAnalytics.eventTrack(
    mixpanelConsts.SUCCESS_COMPLETED_ORDER,
    {
      'Payment Type': props.paymentMethod,
      '# of Tickets': props.quantity,
      '$ Amount': props.amount?.totals?.total?.amount,
      Delivery: props.deliveryMethod,
      'Payment Process': props.paymentProcess,
      'Contains Labeled Holds': props.containsQualifiedHolds,
      'Printer Service': props.deliveryMethod === PRINT ? props.printerService ?? props.printerType : undefined,
    },
    eventDetails,
  );
};

mixpanelTracks.dispatchEdpEventLoadTime = (actionType, eventDetails) => {
  mixpanelAnalytics.eventTrack(
    mixpanelConsts[actionType],
    {
      page_name: mixpanelConsts.EVENT_DETAIL_PAGE,
    },
    eventDetails,
  );
};

mixpanelTracks.dispatchEventDetailsPageLoadTime = (action, eventDetails) => {
  mixpanelAnalytics.eventTrack(
    mixpanelConsts.EVENT_DETAIL_PAGE_LOAD_TIME,
    {
      page_name: mixpanelConsts.EVENT_DETAIL_PAGE,
      '# of ISM Seats': action.payload.seatAmount,
      '# of Price Levels': action.payload.priceLevelAmount,
    },
    eventDetails,
  );
};

let eventLoadFlag = false;

const setBaseData = (state) => {
  const user = selectUserInformation(state);
  const userType = trackUserType([
    {
      id: selectFirstOpcode(state),
      type: mixpanelConsts.HOST,
    },
    {
      id: selectArchticsId(state),
      type: mixpanelConsts.ARCHTICS,
    },
  ]);

  mixpanelAnalytics.setBaseData({
    $user_id: user.profile.profileId, // https://help.mixpanel.com/hc/en-us/articles/360039133851#changes-after-enabling-id-merge (canonical id section)
    'User Type': userType,
    ...appInfo,
  });
};

export default function createMixpanelMiddleware() {
  mixpanelAnalytics = MixpanelAnalytics.create(MIXPANEL_TM1_TOKEN);
  // eslint-disable-next-line sonarjs/cognitive-complexity
  return (store) => (next) => (action) => {
    if (!action || !action.type || ignoreAction(action)) {
      return next(action);
    }

    const state = store.getState();
    const user = selectUserInformation(state);
    const eventDetails = selectCurrentEventDetails(state);

    if (!user) {
      return next(action);
    }

    setBaseData(state);

    const { deliveryMethod } = selectFormToggles(state);
    const isFirstTicketTypeUpdate = selectPreviousTicketType(state)?.id;

    switch (action.type) {
      case UPDATE_USER_TRACKING_INFORMATION:
        mixpanelTracks.dispatchLoginToMixpanel(action.userTrackingInformation);
        return next(action);
      case startLoadingCurrentEventPage.type:
        eventLoadFlag = false;
        mixpanelAnalytics.timeEvent(mixpanelConsts.EVENT_LOAD_TIME);
        mixpanelAnalytics.timeEvent(mixpanelConsts.GEOMETRY_LOAD_TIME);
        mixpanelAnalytics.timeEvent(mixpanelConsts.EVENT_DETAIL_PAGE_LOAD_TIME);
        mixpanelTracks.dispatchEventDetailsPage(eventDetails);
        return next(action);
      case selectEvent.type:
        if (action.payload) {
          mixpanelTracks.dispatchEventSelectionToMixpanel(action.payload, state);
        }
        return next(action);
      case BEST_AVAILABLE_SEATS:
        mixpanelTracks.dispatchFindBestAvailableSeatsToMixpanel(eventDetails);
        return next(action);
      case TOGGLE_CHECKOUT:
        if (action.location === checkoutToggleLocation.NAV && !action.showCart) {
          mixpanelTracks.dispatchNavBarToggleCheckOutToMixpanel();
        } else if (action.location === checkoutToggleLocation.SHOPPING_BAG && !action.showCart) {
          mixpanelTracks.dispatchShoppingBagToggleCheckOutToMixpanel(eventDetails);
        } else if (action.location === checkoutToggleLocation.CONTINUE_SHOPPING) {
          mixpanelTracks.dispatchContinueShoppingAfterCheckoutToMixpanel();
        }
        return next(action);
      case UPDATE_TICKETS_QUANTITY:
        mixpanelTracks.dispatchQuantitySelectorMixpanel(action, eventDetails);
        return next(action);
      case BEST_AVAILABLE_PRICE_MODAL_TOGGLE:
        mixpanelTracks.dispatchTogglePriceRangeModelToMixpanel(eventDetails);
        return next(action);
      case CLEAR_CART:
        if (action.clearPlace === clearCartLocation.SHOPPING_BAG) {
          mixpanelTracks.dispatchDeleteInventoryInBagToMixpanel(eventDetails);
        } else if (action.clearPlace === clearCartLocation.CART) {
          mixpanelTracks.dispatchDeleteInventoryInCartToMixpanel(eventDetails);
        }
        return next(action);
      case UPDATE_ACTIVE_TICKET_TYPE:
        if (isFirstTicketTypeUpdate) {
          mixpanelTracks.dispatchTicketTypeToMixpanel(action.currentTicketType, eventDetails);
        }
        return next(action);
      case UPDATE_ACTIVE_PRICE_RANGE:
        if (!action.deSelect) {
          mixpanelTracks.dispatchPriceRangeToMixpanel(action.ranges, eventDetails);
        }
        return next(action);
      case INITIAL_ARCHTICS_EVENT_SEAT_STATUS_FETCH.LOADING:
        mixpanelAnalytics.timeEvent(mixpanelConsts[INITIAL_ARCHTICS_EVENT_SEAT_STATUS_FETCH.SUCCESS]); // NOT MAPPED
        return next(action);
      case SEAT_DATA_LOAD_FETCH.LOADING:
        mixpanelAnalytics.timeEvent(mixpanelConsts[SEAT_DATA_LOAD_FETCH.SUCCESS]); // NOT MAPPED
        return next(action);
      case FETCH_EVENT_DETAILS.SUCCESS:
      case SEAT_DATA_LOAD_FETCH.SUCCESS:
      case INITIAL_ARCHTICS_EVENT_SEAT_STATUS_FETCH.SUCCESS:
        if (eventLoadFlag) {
          mixpanelTracks.dispatchEventLoadTime(action, eventDetails);
        } else {
          // if ticket types or place data loaded first - change flag and wait for second event;
          eventLoadFlag = true;
        }
        if (action.type !== FETCH_EVENT_DETAILS.SUCCESS) {
          mixpanelTracks.dispatchInitialAvailability(action.type, eventDetails);
        }
        return next(action);
      case FETCH_CHECKOUT.LOADING:
        if (deliveryMethod) {
          mixpanelAnalytics.timeEvent(mixpanelConsts[`${FETCH_CHECKOUT.SUCCESS}_${deliveryMethod.toUpperCase()}`]);
          mixpanelAnalytics.timeEvent(mixpanelConsts[FETCH_CHECKOUT.SUCCESS]);
        }
        return next(action);
      case FETCH_CHECKOUT.SUCCESS:
        mixpanelTracks.dispatchCaptureBuyerInfo(action.mixpanelOptions, eventDetails);
        mixpanelTracks.dispatchCompletedCheckoutToMixpanel(action.mixpanelOptions, eventDetails);
        mixpanelTracks.dispatchGrossTicketPriceToMixpanel(action.data, eventDetails);
        if (deliveryMethod) {
          mixpanelTracks.dispatchTimeToDeliver(
            mixpanelConsts[`${FETCH_CHECKOUT.SUCCESS}_${deliveryMethod.toUpperCase()}`],
            eventDetails,
          );
          mixpanelTracks.dispatchEdpEventLoadTime(FETCH_CHECKOUT.SUCCESS, eventDetails);
        }
        return next(action);
      case fetchInitialEventsLoading.type:
        mixpanelAnalytics.timeEvent(mixpanelConsts[fetchInitialEventsLoading.type]);
        return next(action);
      case fetchAllEventsLoading.type:
        mixpanelAnalytics.timeEvent(mixpanelConsts[fetchAllEventsLoading.type]);
        return next(action);
      case fetchInitialEventsSuccess.type:
      case fetchAllEventsSuccess.type:
        mixpanelTracks.dispatchEventsLoadTimeToMixpanel(action.type);
        return next(action);
      case PLACE_TO_RESERVE_FETCH.LOADING:
        mixpanelAnalytics.timeEvent(mixpanelConsts[PLACE_TO_RESERVE_FETCH.SUCCESS]);
        return next(action);
      case FETCH_BEST_AVAILABLE_SEATS.LOADING:
        mixpanelAnalytics.timeEvent(mixpanelConsts[FETCH_BEST_AVAILABLE_SEATS.SUCCESS]);
        return next(action);
      case ARCHTICS_EVENT_SEAT_STATUS_FETCH.LOADING:
        mixpanelAnalytics.timeEvent(mixpanelConsts[ARCHTICS_EVENT_SEAT_STATUS_FETCH.SUCCESS]);
        return next(action);
      case PLACE_TO_RESERVE_FETCH.SUCCESS:
      case FETCH_BEST_AVAILABLE_SEATS.SUCCESS:
      case ARCHTICS_EVENT_SEAT_STATUS_FETCH.SUCCESS:
        mixpanelTracks.dispatchEdpEventLoadTime(action.type, eventDetails);
        return next(action);
      case PLACE_TOTALS_SET:
        mixpanelTracks.dispatchGeometryLoadTime(action, eventDetails);
        return next(action);
      case finishLoadingCurrentEventPage.type:
        mixpanelTracks.dispatchEventDetailsPageLoadTime(action, eventDetails);
        return next(action);
      default:
        return next(action);
    }
  };
}

export { mixpanelTracks, ignoreAction, eventsList };
