import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
const IconCaution = () => (_jsxs("svg", { className: "oc-icon", viewBox: "0 0 24 23", width: "24px", height: "23px", children: [_jsx("defs", {}), _jsx("g", { id: "S1-No-Seats-Available", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd", transform: "translate(-20.000000, -16.000000)", children: _jsx("g", { id: "Error-Notification", children: _jsx("g", { id: "Icons/Caution/Filled-24", transform: "translate(20.000000, 16.000000)", children: _jsxs("g", { children: [_jsx("rect", { id: "Bounds", x: "0", y: "0", width: "24", height: "24" }), _jsx("path", { d: "M0.815074228,21.9112611 L11.6532534,1.65934362 C11.757491,1.46456817 11.999889,1.39117274 12.1946644,1.49541033 C12.2643468,1.5327021 12.3214202,1.58980074 12.3586811,1.65949957 L23.1853439,21.9114171 C23.2894954,22.1062386 23.2159928,22.3486041 23.0211713,22.4527556 C22.9631521,22.4837726 22.8983781,22.5 22.8325884,22.5 L1.16774637,22.5 C0.946832473,22.5 0.767746373,22.3209139 0.767746373,22.1 C0.767746373,22.0341505 0.784003312,21.9693194 0.815074228,21.9112611 Z", id: "Line-4", stroke: "#EBBA33", fill: "#EBBA33", strokeLinecap: "round" }), _jsx("circle", { id: "Oval-2", fill: "#FFFFFF", cx: "12", cy: "19", r: "1" }), _jsx("path", { d: "M12,16 L12,16 C12.2810488,16 12.5136578,15.7814752 12.5311891,15.5009737 L12.9376217,8.99805258 C12.9699863,8.4802184 12.5764355,8.03419414 12.0586014,8.00182951 C12.0390914,8.00061013 12.0195481,8 12,8 L12,8 C11.4811554,8 11.0605488,8.42060664 11.0605488,8.93945122 C11.0605488,8.95899928 11.0611589,8.97854258 11.0623783,8.99805258 L11.4688109,15.5009737 C11.4863422,15.7814752 11.7189512,16 12,16 Z", id: "exclamation", fill: "#FFFFFF" })] }) }) }) })] }));
export const rawIconCaution = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
<g fill="none" fill-rule="evenodd">
    <path d="M0 0h16v16H0z"/>
    <circle cx="7.778" cy="13.111" r="1" fill="#F2BD2A"/>
    <path fill="#F2BD2A" d="M7.778 11.111a.667.667 0 0 0 .666-.667V6a.667.667 0 0 0-1.333 0v4.444c0 .369.299.667.667.667z"/>
    <path stroke="#F2BD2A" stroke-linecap="round" d="M.573 14.443L7.167 1.857a.722.722 0 0 1 1.27-.015l6.982 12.585a.722.722 0 0 1-.631 1.073H1.213a.722.722 0 0 1-.64-1.057z"/>
</g>
</svg>
`;
export default IconCaution;
