import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../../messages';
import IconAlert from '../icons/IconAlert';
import { InfoTooltipComponent } from '../TooltipComponent';
import outsideClick from '../utils/OutsideClick';
import './styles.scss';
export class Dropdown extends Component {
    constructor(props) {
        super(props);
        this.findAncestorDataset = (event = {}) => {
            let target = event.target;
            while (target && target.parentNode) {
                if (target.parentNode.dataset && target.parentNode.dataset.value) {
                    return target.parentNode.dataset;
                }
                target = target.parentNode;
            }
            return null;
        };
        this.initializeHeightForAnimation = () => {
            if (this.node) {
                this.setState({});
            }
        };
        this.handleSelect = (event) => {
            const selection = this.state.selection;
            const dataSet = this.findAncestorDataset(event);
            if (dataSet) {
                selection.label = dataSet.label;
                selection.value = dataSet.value;
                selection.disabled = dataSet.disabled === true || dataSet.disabled === 'true';
                selection.hasError = dataSet.hasError === true || dataSet.hasError === 'true';
                this.openDrop = false;
                this.setState({ selection });
            }
            if (this.props.onSelect) {
                this.props.onSelect.apply(null, [selection]);
            }
            return true;
        };
        this.handleOpenDropdown = () => {
            this.openDrop = !this.openDrop;
        };
        let stateObj = {
            isOpen: props.defaultOpen,
            selection: { label: undefined, value: undefined, disabled: false, hasError: false },
        };
        if (props.selection) {
            const alternateLabel = typeof props.selection === 'string' ? props.selection : props.selection.name || '';
            stateObj = Object.assign(stateObj, {
                selection: {
                    label: props.selection.label ? props.selection.label : alternateLabel,
                    value: props.selection.value ? props.selection.value : alternateLabel,
                    disabled: props.selection.disabled === 'true' || props.selection.disabled === true,
                    hasError: props.selection.hasError === 'true' || props.selection.hasError === true,
                },
            });
        }
        this.state = stateObj;
        this.node = undefined;
    }
    componentDidMount() {
        setTimeout(this.initializeHeightForAnimation, 1500);
        if (this.props.onSelect) {
            this.props.onSelect();
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (typeof nextProps.selection === 'string') {
            const label = nextProps.selection;
            const value = nextProps.selection;
            this.setState({ selection: { label, value, disabled: false, hasError: false } });
        }
        else if (nextProps.selection !== this.props.selection ||
            (nextProps.selection && this.props.selection && nextProps.selection.hasError !== this.props.selection.hasError)) {
            this.setState({ selection: nextProps.selection });
        }
        if (nextProps.clickedOutside && this.openDrop) {
            this.openDrop = false;
        }
    }
    set openDrop(bool) {
        this.setState({
            isOpen: bool,
        });
        if (this.props.openDrop) {
            this.props.openDrop(bool);
        }
    }
    get openDrop() {
        return this.state.isOpen;
    }
    get options() {
        let options = [];
        if (this.props.dataProvider) {
            options = this.props.dataProvider.map((item) => {
                const dataValue = item.value || item.label || item;
                const dataLabel = item.label || item;
                const selected = dataValue === this.state.selection.value;
                const itemClassName = classNames('oc-dropdown__menu-item', {
                    'oc-dropdown__menu-item--selected': selected,
                    'oc-dropdown__menu-item--disabled': item.disabled,
                });
                const optionsContent = this.props.optionsContentRenderer
                    ? this.props.optionsContentRenderer(dataLabel, item, this.props)
                    : dataLabel;
                return (_jsx("li", { "aria-selected": selected, "data-value": dataValue, "data-label": dataLabel, "data-disabled": item.disabled, "data-has-error": item.hasError, role: "option", tabIndex: "-1", children: _jsx("a", { className: itemClassName, children: optionsContent }) }, dataValue));
            });
            return options;
        }
        return options;
    }
    get label() {
        if (this.props.label) {
            return _jsx("label", { htmlFor: this.props.id, className: this.css.labelClass });
        }
        return null;
    }
    get selection() {
        let newSelection;
        let selected = this.state.selection;
        if (this.props && this.props.dataProvider && selected && selected.value) {
            newSelection = this.props.dataProvider.find((item) => {
                let found = item === selected.value;
                if (item.value) {
                    found = item.value === selected.value;
                }
                return found;
            });
            selected = newSelection || selected;
        }
        if (selected !== Object(selected) || selected instanceof String) {
            const label = selected;
            const value = selected;
            selected = { value, label, hasError: true };
        }
        return selected;
    }
    get tooltip() {
        if (this.errorMessage && this.props.tooltip) {
            return (_jsx(InfoTooltipComponent, { className: "oc-info--left", text: this.errorMessage, tooltipClassName: "oc-tooltip--left", children: _jsx(IconAlert, {}) }));
        }
        return null;
    }
    get errorMessage() {
        return this.props.errorMessage;
    }
    get errorText() {
        if (this.props.invalid && !this.props.tooltip) {
            return _jsx("div", { className: "oc-dropdown__error", children: this.errorMessage });
        }
        return null;
    }
    get css() {
        const labelClass = classNames(['oc-dropdown__label', 'oc-label', this.props.labelClass]);
        const hasError = this.props.invalid;
        const componentClass = classNames(['oc-dropdown', this.props.componentClass], {
            'oc-dropdown--inline': this.props.inline,
            'oc-dropdown--open': this.state.isOpen,
            'oc-dropdown--error': hasError,
            'oc-dropdown--error__tooltip': hasError && this.props.tooltip,
        });
        return { labelClass, componentClass };
    }
    get menuDropdown() {
        if (!this.openDrop) {
            return null;
        }
        return (_jsxs("ul", { "aria-hidden": true, role: "listbox", className: "oc-dropdown__menu", tabIndex: "-1", onClick: this.handleSelect, style: { maxHeight: this.props.height }, children: [this.props.children, this.options] }));
    }
    render() {
        const { id, emptySelection, optionsContentRenderer } = this.props;
        const selectedOption = optionsContentRenderer
            ? optionsContentRenderer(this.state.selection.label, this.state.selection, this.props)
            : this.state.selection.label;
        return (_jsxs("div", { id: id, className: this.css.componentClass, children: [this.label, _jsxs("div", { role: "combobox", "aria-expanded": this.openDrop, className: "oc-dropdown__wrapper", children: [_jsx("a", { role: "button", className: "oc-dropdown__toggle", onClick: this.handleOpenDropdown, tabIndex: "-2", children: selectedOption }), _jsx("span", { className: "oc-dropdown__placeholder", children: emptySelection || _jsx(FormattedMessage, { ...messages.selectOption }) }), this.menuDropdown] }), this.errorText, this.tooltip] }));
    }
}
Dropdown.propTypes = {
    children: PropTypes.arrayOf(PropTypes.node),
    componentClass: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    dataProvider: PropTypes.array,
    defaultOpen: PropTypes.bool,
    emptySelection: PropTypes.string,
    errorMessage: PropTypes.any,
    height: PropTypes.string,
    id: PropTypes.string,
    inline: PropTypes.bool,
    invalid: PropTypes.bool,
    label: PropTypes.string,
    labelClass: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    onSelect: PropTypes.func,
    selection: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    tooltip: PropTypes.bool,
    openDrop: PropTypes.func,
    clickedOutside: PropTypes.bool,
    optionsContentRenderer: PropTypes.func,
};
export const renderDropdownComponent = (field) => {
    const defaultHoc = (comp) => comp;
    const hoc = field && field.hoc ? field.hoc : defaultHoc;
    const OutsideClickDropdown = hoc(outsideClick(Dropdown));
    if (!field) {
        return _jsx(OutsideClickDropdown, {});
    }
    const onValueChange = (selection) => {
        if (selection && field.input && typeof field.input.onChange === 'function') {
            const { label, value, disabled, hasError } = selection;
            field.input.onChange.apply(field.input, [{ id: value, name: label, value, label, disabled, hasError }]);
        }
        return true;
    };
    let initialSelection;
    if (field.selection) {
        initialSelection = field.selection;
    }
    else if (field.input && field.input.value && field.input.value.id) {
        const { id: value = '', name: label = '', disabled, hasError } = field.input.value;
        initialSelection = { label, value, disabled, hasError };
    }
    else if (field.meta && field.meta.initial && field.meta.initial.size) {
        initialSelection = field.meta.initial.toJS();
    }
    return _jsx(OutsideClickDropdown, { ...field, onSelect: onValueChange, selection: initialSelection });
};
export default outsideClick(Dropdown);
