import { call, CallEffect, select } from 'redux-saga/effects';
import { STATUS } from '../../../../constants';
import { IsmrtPlaceData, IsmrtPlaceDataTicketType, IsmrtPlaceUpdate } from '../../model/IsmrtPlaceData';
import { SeatStatusPlace } from '../../model/SeatStatusPlace';
import { selectPriceLevelGroupIdMap } from '../../selectors/main';
import { TicketTypesByOfferSetAndRate } from '../../selectors/ticket-type-by-offerset-and-rate';
import {
  NonExclusiveTicketTypesByRate,
  selectNonExclusiveTicketTypesByRate,
  selectTicketTypesByOfferSetAndRate,
} from '../../selectors/ticket-type-selectors';

export function* mapIsmrtPlacesToSeatStatus(places: IsmrtPlaceData[]) {
  const nonExclusiveTicketTypesByRate: NonExclusiveTicketTypesByRate = yield select(
    selectNonExclusiveTicketTypesByRate,
  );
  const ticketTypesByOfferSetAndRate: TicketTypesByOfferSetAndRate = yield select(selectTicketTypesByOfferSetAndRate);
  const priceLevelIdMap: Map<string, string> = yield select(selectPriceLevelGroupIdMap);

  return places.map(
    ({ id, priceZone, ticketType, version, sectionName, attributes, generalAdmission }): SeatStatusPlace =>
      mapPlaceToSeatStatus(
        ticketType,
        id,
        version,
        priceZone,
        sectionName,
        nonExclusiveTicketTypesByRate,
        ticketTypesByOfferSetAndRate,
        priceLevelIdMap,
        attributes,
        generalAdmission,
      ),
  );
}

export function* mapIsmrtPlaceUpdatesToSeatStatus(
  places: IsmrtPlaceUpdate[],
): Generator<CallEffect, any, SeatStatusPlace[]> {
  return yield call(
    mapIsmrtPlacesToSeatStatus,
    places.map((placeUpdate) => placeUpdate.seat),
  );
}

const mapPlaceToSeatStatus = (
  ticketType: IsmrtPlaceDataTicketType,
  id: string,
  version: number | null,
  priceZone: string,
  sectionName: string,
  nonExclusiveTicketTypesByRate: NonExclusiveTicketTypesByRate,
  ticketTypesByOfferSetAndRate: TicketTypesByOfferSetAndRate,
  priceLevelGroups: Map<string, string>,
  attributes: {
    id: string;
    category: string;
  }[],
  generalAdmission: boolean,
) => {
  let placeInventoryType = '';
  let placeIdNoInventoryType = '';

  if (ticketType.encoded) {
    placeInventoryType = ticketType.encoded.slice(-2);
    placeIdNoInventoryType = ticketType.encoded.slice(0, -2);
  }

  const priceLevelGroupId = priceLevelGroups.get(priceZone);

  const { hasRestrictedOfferSets, offerTicketTypeIds } = mapOffers(
    ticketType,
    priceLevelGroupId,
    nonExclusiveTicketTypesByRate,
    ticketTypesByOfferSetAndRate,
  );

  const available = isSeatAvailable(ticketType, offerTicketTypeIds);

  const accessibility = attributes
    .filter((attribute) => attribute.category === 'ACCESSIBLE' || attribute.category === 'COMPANION')
    .map((attribute) => attribute.id);

  return {
    id,
    version,
    status: ticketType.status,
    priceZone,
    sectionName,
    ticketTypeEncoded: ticketType.encoded,
    placeInventoryType,
    placeIdNoInventoryType,
    restrictedOfferSets: hasRestrictedOfferSets,
    ticketType,
    offerTicketTypeIds,
    priceLevelGroupId,
    available,
    accessibility,
    generalAdmission,
  };
};

const isSeatAvailable = (ticketType: IsmrtPlaceDataTicketType, offerTicketTypeIds: string[]) => {
  if ([STATUS.OPEN].includes(ticketType.status)) {
    return offerTicketTypeIds.length > 0;
  }
  return false;
};
const mapOffers = (
  ticketType: IsmrtPlaceDataTicketType,
  priceLevelGroupId: string | undefined,
  nonExclusiveTicketTypesByRate: NonExclusiveTicketTypesByRate,
  ticketTypesByOfferSetAndRate: TicketTypesByOfferSetAndRate,
) => {
  if (!priceLevelGroupId) {
    return { hasRestrictedOfferSets: false, offerTicketTypeIds: [] };
  }

  let hasRestrictedOfferSets = false;
  const offerTicketTypeIds: string[] = [];

  if (ticketType.offerSets) {
    ticketType.offerSets.forEach((offerSet) => {
      const matchingTicketTypeIds = ticketTypesByOfferSetAndRate.get(offerSet.id, priceLevelGroupId);

      if (matchingTicketTypeIds) {
        offerTicketTypeIds.push(...matchingTicketTypeIds);
      }

      if (offerSet.restricted) {
        hasRestrictedOfferSets = true;
      }
    });
  }

  if (!hasRestrictedOfferSets) {
    offerTicketTypeIds.push(...(nonExclusiveTicketTypesByRate[priceLevelGroupId] || []));
  }

  return { hasRestrictedOfferSets, offerTicketTypeIds };
};
