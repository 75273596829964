import { createSelector } from '@reduxjs/toolkit';
import Fuse from 'fuse.js';
import { List } from 'immutable';
import { uniqBy } from 'lodash';

const selectTicketTypesRaw = (state) => state.event.get('ticketTypes');
const selectTickettypeOfferSearchValue = (state) => state.event.get('ttOfferSearchValue') || '';

// https://confluence.livenation.com/pages/viewpage.action?spaceKey=TMB2C&title=Secure+Entry+Added+Value
const extractTicketTypeId = (ticketTypeIdWithStatus) => ticketTypeIdWithStatus.slice(0, -2);
const selectCurrentTicketType = createSelector(
  (state) => state.event.get('currentTicketType'),
  (ticketType) => {
    if (ticketType && ticketType.id) {
      return { ...ticketType, ticketTypeIdNoStatus: extractTicketTypeId(ticketType.id) };
    }
    return ticketType;
  },
);

const selectPreviousTicketType = (state) => state.event?.get('previousTicketType');

const selectTicketTypes = createSelector(selectTicketTypesRaw, (rawData) => {
  try {
    let mappedData = rawData;
    mappedData = mappedData.filter(
      (item) => (item.rates && item.rates.length) || (item.promoCodes && item.promoCodes.length),
    );
    return uniqBy(mappedData, 'id');
  } catch (err) {
    return rawData;
  }
});

const selectIsLoadingTicketTypes = createSelector(
  (state) => state.event.get('isLoadingTicketTypes'),
  (isLoadingTicketTypes = true) => isLoadingTicketTypes,
);
const selectIsHasNotRate = createSelector(
  selectTicketTypes,
  selectCurrentTicketType,
  (ticketTypes, currentTicketType) => {
    if (ticketTypes && currentTicketType && currentTicketType.id) {
      return !ticketTypes.filter((item) => item.id === currentTicketType.id).pop().rates;
    }
    return false;
  },
);
const selectTicketTypesLoadingState = createSelector(
  (state) => state.event.get('isLoadingTicketTypes'),
  selectTicketTypes,
  (isLoadingTicketTypes, ticketTypes) => {
    if (ticketTypes && ticketTypes.length) {
      return false;
    }
    return isLoadingTicketTypes;
  },
);

const selectCurrentTicketTypeList = createSelector(
  selectTicketTypes,
  selectCurrentTicketType,
  (ticketTypes, currentTicketType) => ticketTypes.filter((item) => item.id === currentTicketType.id).pop(),
);

const selectTicketTypeWithCodeList = createSelector(selectTicketTypes, (ticketTypes) => {
  const hash = {};
  const test1 = ticketTypes.filter((item) => item.name || false);
  test1.forEach((eachItem) => {
    const hashKey = eachItem.name;
    hash[hashKey] = { id: eachItem.id };
  });
  return hash;
});

const selectTicketTypeList = createSelector(selectTicketTypes, (ticketTypes = []) => {
  let tt = ticketTypes;
  if (ticketTypes instanceof List) {
    tt = [];
  }
  return tt.filter((item) => (item.name && item.id) || false).map((item) => item.name);
});
const getEachPrice = (item, hashRates) => {
  const hashRate = hashRates;
  const rates = item.rates;
  const ticketTypeID = item.id;
  rates.forEach((eachRate) => {
    const hashKey = eachRate.id;
    if (hashRate[hashKey]) {
      hashRate[hashKey][ticketTypeID] = { ...eachRate.price, value: eachRate.price.amount, description: item.name };
    } else {
      hashRate[hashKey] = {};
      hashRate[hashKey][ticketTypeID] = { ...eachRate.price, value: eachRate.price.amount, description: item.name };
    }
  });
  return hashRate;
};

const stableEmptyTicketRates = [];
const selectPriceByTicketType = createSelector(
  selectTicketTypes,
  selectCurrentTicketType,
  (ticketType, currentTicketType) => {
    const tt = ticketType.find(({ id }) => id === currentTicketType.id);
    return tt ? tt.rates : stableEmptyTicketRates;
  },
);

export const selectPriceById = createSelector(selectPriceByTicketType, (priceByTicketType) =>
  priceByTicketType.reduce(
    (priceByIdAcc, { id, price: { amount, currency } }) => ({
      ...priceByIdAcc,
      [id]: {
        amount,
        currency,
      },
    }),
    {},
  ),
);
const selectPrice = createSelector(selectTicketTypes, selectCurrentTicketType, (ticketType, currentTicketType) => {
  let hashRates = {};
  if (ticketType.length && currentTicketType.name) {
    ticketType.forEach((eachTicketType) => {
      hashRates = { ...getEachPrice(eachTicketType, hashRates) };
    });
  }
  return hashRates;
});

const searchTicketTypeOffer = (list, searchValue) => {
  const options = {
    threshold: 0.6,
    minMatchCharLength: 1,
    keys: ['name', 'promoCodes'],
  };
  const fuse = new Fuse(list, options);

  return fuse.search(searchValue);
};

const selectTicketTypesWithPromos = createSelector(selectTicketTypes, selectTickettypeOfferSearchValue, (tt, offer) => {
  let searchResult = tt.map((t) => ({
    // fix for some tickettypes that may have no name
    ...t,
    name: t.name || t.id,
  }));
  if (offer) {
    searchResult = searchTicketTypeOffer(searchResult, offer);
  }

  const standardTypes = searchResult.filter((item) => !item.promoCodes?.length && item.rates?.length);
  const promoTypes = searchResult.filter((item) => item.promoCodes.length);

  return {
    standardTypes,
    promoTypes,
  };
});

export {
  selectTicketTypes,
  selectTicketTypesLoadingState,
  selectTicketTypeList,
  selectPrice,
  selectPriceByTicketType,
  selectTicketTypeWithCodeList,
  selectCurrentTicketType,
  selectPreviousTicketType,
  selectCurrentTicketTypeList,
  selectTicketTypesWithPromos,
  selectTickettypeOfferSearchValue,
  searchTicketTypeOffer,
  selectIsLoadingTicketTypes,
  selectIsHasNotRate,
};
