import { Tm1Button, Tm1Dialog, Tm1DialogFooter, Tm1Icon } from '@ticketmaster/tm1pos-web-shared/components/v2';
import { FormattedMessage, InjectedIntl, injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectShowCartExpiredErrorModal } from '../../app-selectors';
import messages from './messages';
import './styles.scss';

type InjectedProps = {
  intl: InjectedIntl;
};

const CartExpiredErrorModal = ({ intl }: InjectedProps) => {
  const isModalOpen = useSelector(selectShowCartExpiredErrorModal);

  const handleReload = () => {
    window.location.reload();
  };

  return isModalOpen ? (
    <div role="dialog">
      <Tm1Dialog
        className="cart-expired-error-modal"
        open
        size="small"
        backgroundOverlay
        data-testid="cart-expired-error-dialog"
      >
        <div className="cart-expired-error-modal__header" slot="dialog-header">
          <Tm1Icon size="regular" icon="errorSolid" />
          <FormattedMessage {...messages.cartExpired} />
        </div>
        <div slot="dialog-content" data-testId="cart-expired-error-modal__content">
          <FormattedMessage {...messages.cartExpiredErrorMessage} />
        </div>
      </Tm1Dialog>
      <Tm1DialogFooter slot="footer">
        <Tm1Button onClick={handleReload}>{intl.formatMessage(messages.reload)}</Tm1Button>
      </Tm1DialogFooter>
    </div>
  ) : null;
};

export default injectIntl(CartExpiredErrorModal);
