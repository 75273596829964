import { configureStore, PreloadedState } from '@reduxjs/toolkit';
import { TM1_ORDERS_CUSTOM_EVENT, TM1_ORDERS_DISPATCHED_EVENT } from '@ticketmaster/tm1pos-web-shared/constants';
import { LogComponent } from '@ticketmaster/tm1pos-web-shared/errorHandling/constants';
import { actionHistory } from '@ticketmaster/tm1pos-web-shared/middleware/actionHistory/debugging';
import { createUnhandledErrorsMiddleware } from '@ticketmaster/tm1pos-web-shared/middleware/errorsHandling/unhandledError.middleware';
import createTimeoutMiddleware from '@ticketmaster/tm1pos-web-shared/middleware/timeout';
import paymentModuleSagas from '@ticketmaster/tm1pos-web-shared/payment/emvPaymentModule-saga';
import printingModuleSagas from '@ticketmaster/tm1pos-web-shared/printing/printingModule-saga';
import { dispatchError } from '@ticketmaster/tm1pos-web-shared/services/errors/error-dispatcher';
import eventsSagas from '@ticketmaster/tm1pos-web-shared/store/events/sagas';
import { AppStoreFactory, enhancers, initLazyLoadingStructure } from '@ticketmaster/tm1pos-web-shared/store/store';
import { translationsSagaWatchers } from '@ticketmaster/tm1pos-web-shared/translations';
import { routerMiddleware } from 'connected-react-router';
import { browserHistory } from 'react-router';
import { Action } from 'redux';
import createOidcMiddleware from 'redux-oidc';
import createSagaMiddleware from 'redux-saga';
import { reduxTimeout } from 'redux-timeout';
import { USER_TIMEOUT } from './containers/App/actions-constants';
import { eventFeesSaga } from './containers/App/event-fees-saga';
import appSagas from './containers/App/sagas';
import commonCheckoutSagas from './containers/Checkout/components/Form/sagas';
import { updateIcePlaces } from './containers/EventDetailPage/event-actions';
import { eventDetailSagas } from './containers/EventDetailPage/event-detail-sagas';
import { eventSubscriptionSagas } from './containers/EventDetailPage/event-subscription-sagas';
import edpSagas from './containers/EventDetailPage/sagas';
import { createErrorNotificationMiddleware } from './middleware/errorsHandling/errorNotification.middleware';
import createMixpanelMiddleware from './middleware/mixpanel';
import createTracingMiddleware from './middleware/tracing-middleware';
import createReducer from './reducers';
import userManager from './utils/userManager';

export type AppDispatch = AppStore['dispatch'];
export type AppStore = AppStoreFactory<RootState>;

export type RootState = ReturnType<typeof rootReducer>;

const errorNotificationMiddleware = createErrorNotificationMiddleware();
const mixpanelMiddleware = createMixpanelMiddleware();
const tracingMiddleware = createTracingMiddleware();
const oidcMiddleware = createOidcMiddleware(userManager);
const sagaMiddleware = createSagaMiddleware({
  onError: (error) => {
    dispatchError(error, LogComponent.UNHANDLED_SAGA_ERROR, store);
  },
});
const timeoutMiddleware = createTimeoutMiddleware();
const unhandledErrorsMiddleware = createUnhandledErrorsMiddleware();

const generateMiddlewares = () =>
  [
    unhandledErrorsMiddleware, // should be the first one, so it can catch all errors
    timeoutMiddleware,
    oidcMiddleware,
    sagaMiddleware,
    routerMiddleware(browserHistory),
    mixpanelMiddleware,
    tracingMiddleware,
    errorNotificationMiddleware,
    reduxTimeout(),
    actionHistory.middleware(),
  ] as const;

const rootReducer = createReducer();

const ACTIONS_TO_SANITIZE = [updateIcePlaces.type];
const actionSanitizer = <A extends Action>(action: A): A => {
  if (!ACTIONS_TO_SANITIZE.includes(action.type)) {
    return action;
  }

  return { type: action.type, data: '<<SANITIZED PAYLOAD>>' } as any as A;
};

export const setupStore = (preloadedState?: PreloadedState<RootState>): AppStore => {
  const baseStore = configureStore({
    devTools: {
      name: 'TM1 Sales',
      actionSanitizer,
    },
    middleware: generateMiddlewares(),
    preloadedState,
    reducer: rootReducer,
    enhancers: enhancers(),
  }) as unknown as AppStore;

  const store = initLazyLoadingStructure(baseStore, createReducer, sagaMiddleware.run);

  store.injectSagas(appSagas, 'appSagas');
  store.injectSagas(eventsSagas, 'eventsSagas');
  store.injectSagas(commonCheckoutSagas, 'commonCheckoutSagas');
  store.injectSagas(edpSagas, 'edpSagas');
  store.injectSagas(eventSubscriptionSagas, 'eventSubscriptionSagas');
  store.injectSagas(eventDetailSagas, 'eventDetailSagas');
  store.injectSagas(eventFeesSaga, 'eventFeesSaga');
  store.injectSagas(translationsSagaWatchers, 'translationsSagaWatchers');
  store.injectSagas(printingModuleSagas, 'printingModuleSagas');
  store.injectSagas(paymentModuleSagas, 'paymentModuleSagas');

  // listen for event from tm1-Orders and dispatch corresponding event to store of tm1-Sales
  document.addEventListener(TM1_ORDERS_CUSTOM_EVENT, (e) => {
    if ((e as CustomEvent).detail.type === TM1_ORDERS_DISPATCHED_EVENT.USER_LOGOUT) {
      store.dispatch({ type: USER_TIMEOUT });
    }
  });

  return store;
};

export const store = setupStore();
