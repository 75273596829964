import { Tm1DataGrid } from '@tm1/design-system-react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectShowEditOtherChargesErrorBanner } from '../../../app-selectors';
import { EditOtherChargesErrorBanner } from './EditOtherChargesErrorBanner';
import EditOtherChargesGridFooter from './EditOtherChargesGridFooter';
import EditOtherChargesGridHeader from './EditOtherChargesGridHeader';
import EditOtherChargesGridItem from './EditOtherChargesGridItem';
import { OtherCharge } from './model/other-charge';
import './styles.scss';

type Props = {
  otherCharges: OtherCharge[];
  onOtherChargesChange: (otherCharges: OtherCharge[]) => void;
};

type InjectedProps = {
  intl: InjectedIntl;
};

const EditOtherChargesGrid = ({ otherCharges, onOtherChargesChange, intl }: Props & InjectedProps) => {
  const setOtherCharge = (updatedCharge: OtherCharge) => {
    const newCharges = otherCharges.map((currentCharge) =>
      currentCharge.id === updatedCharge.id ? updatedCharge : currentCharge,
    );

    onOtherChargesChange(newCharges);
  };

  const displayErrorBanner = useSelector(selectShowEditOtherChargesErrorBanner);
  const haveSameMax = new Set(otherCharges.map((charge) => charge.maxQuantity)).size === 1;
  const shouldHideTooltip = !haveSameMax;
  const tooltipMaxValue = otherCharges[0].maxQuantity;

  return (
    <>
      {displayErrorBanner ? <EditOtherChargesErrorBanner intl={intl} /> : null}

      <Tm1DataGrid gridTemplateColumns="40px 2fr 1fr 1fr 1fr" className="edit-other-charges-grid">
        <EditOtherChargesGridHeader maxValue={tooltipMaxValue} hideTooltip={shouldHideTooltip} />

        {otherCharges.map((otherCharge) => (
          <EditOtherChargesGridItem key={otherCharge.id} item={otherCharge} onOtherChargeChange={setOtherCharge} />
        ))}

        <EditOtherChargesGridFooter otherCharges={otherCharges} />
      </Tm1DataGrid>
    </>
  );
};

export default injectIntl(EditOtherChargesGrid);
