import { nullIfUndefined } from '../null-utils';
class StorageService {
    constructor(storage) {
        this.storage = storage;
    }
    getItem(name) {
        const storageItem = this.storage.getItem(name);
        let item;
        try {
            item = JSON.parse(storageItem);
        }
        catch (e) {
            item = storageItem;
        }
        return item;
    }
    setItem(name, value) {
        let transformedValue = nullIfUndefined(value);
        if (typeof value === 'object') {
            transformedValue = JSON.stringify(value);
        }
        this.storage.setItem(name, transformedValue);
    }
    removeItem(name) {
        this.storage.removeItem(name);
    }
    updateItem(name, key, value) {
        const item = this.getItem(name) || {};
        item[key] = value;
        this.setItem(name, item);
    }
    clearStorage() {
        this.storage.clear();
    }
}
export const storageService = new StorageService(window.sessionStorage);
export default storageService;
