import { GraphQLResult } from '@ticketmaster/tm1pos-web-shared/model/graphql';
import { fetchFromSalesApi } from '@ticketmaster/tm1pos-web-shared/services/sales-api-client/sales-api';
import { call, CallEffect } from 'redux-saga/effects';
import { CERTIFICATE_URL } from '../../constants';
import { CertificateResult } from '../../models/certificate';
import { PAYMENT_CERTIFICATE_QUERY } from './api-queries';

export function* getCertificate(): Generator<
  CallEffect,
  GraphQLResult<CertificateResult>,
  GraphQLResult<CertificateResult>
> {
  return yield call(fetchFromSalesApi, {
    url: CERTIFICATE_URL,
    query: PAYMENT_CERTIFICATE_QUERY,
  });
}
