import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';
import outsideClick from '../../components/utils/OutsideClick';
import './styles.scss';
const LONG_COUNTRY_NAME_THRESHOLD = 26;
export class Dropdown extends Component {
    constructor(props) {
        var _a, _b;
        super(props);
        this.findAncestorDataset = (event = {}) => {
            let target = event.target;
            while (target && target.parentNode) {
                if (target.parentNode.dataset && target.parentNode.dataset.value) {
                    return target.parentNode.dataset;
                }
                target = target.parentNode;
            }
            return null;
        };
        this.handleSelect = (event) => {
            const selection = this.state.selection;
            const dataSet = this.findAncestorDataset(event);
            if (dataSet) {
                selection.label = dataSet.label;
                selection.value = dataSet.value;
                this.openDrop = false;
                this.setState({ selection });
            }
            if (this.props.onSelect) {
                this.props.onSelect.apply(null, [selection]);
            }
            return true;
        };
        this.handleOpenDropdown = () => {
            this.openDrop = !this.openDrop;
        };
        let defaultCountry = props.defaultValue;
        if (defaultCountry === undefined || (defaultCountry === null || defaultCountry === void 0 ? void 0 : defaultCountry.code) === undefined || (defaultCountry === null || defaultCountry === void 0 ? void 0 : defaultCountry.key) === undefined) {
            defaultCountry = props.dataProvider.length > 0 ? props.dataProvider[0] : undefined;
        }
        const firstDataProviderValueCode = (defaultCountry === null || defaultCountry === void 0 ? void 0 : defaultCountry.code) ? `+${defaultCountry === null || defaultCountry === void 0 ? void 0 : defaultCountry.code}` : undefined;
        const firstDataProviderValueValue = defaultCountry === null || defaultCountry === void 0 ? void 0 : defaultCountry.key;
        this.state = {
            isOpen: props.defaultOpen,
            selection: {
                label: ((_a = props.selection) === null || _a === void 0 ? void 0 : _a.label) ? props.selection.label : firstDataProviderValueCode,
                value: ((_b = props.selection) === null || _b === void 0 ? void 0 : _b.value) ? props.selection.value : firstDataProviderValueValue,
            },
        };
        this.handleSelect = this.handleSelect.bind(this);
        this.handleOpenDropdown = this.handleOpenDropdown.bind(this);
    }
    componentDidMount() {
        if (this.props.onSelect) {
            this.props.onSelect();
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.clickedOutside && this.openDrop) {
            this.openDrop = false;
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (!prevState.isOpen && this.state.isOpen) {
            this[this.state.selection.value].scrollIntoView({ block: 'nearest', inline: 'start' });
        }
    }
    set openDrop(bool) {
        this.setState({
            isOpen: bool,
        });
        if (this.props.openDrop) {
            this.props.openDrop(bool);
        }
    }
    get openDrop() {
        return this.state.isOpen;
    }
    get options() {
        return this.props.dataProvider.map((item) => {
            const countryCode = `+${item.code}`;
            const dataValue = item.key;
            const dataLabel = countryCode;
            const selected = dataValue === this.state.selection.value;
            const itemClassName = classNames('oc-dropdown__menu-item', 'countries--format', {
                'oc-dropdown__menu-item--selected': selected,
            });
            const extendedCountriesClassName = classNames('countries', 'countries-extended');
            const codeHideOnHoverClassName = classNames('code', 'code-hide-hover');
            const flagClassName = `flag ${item.key}`;
            const isLongCountryName = item.name.length > LONG_COUNTRY_NAME_THRESHOLD;
            return (_jsx("li", { "aria-selected": selected, "data-value": dataValue, "data-label": dataLabel, role: "option", tabIndex: "-1", ref: (c) => {
                    this[dataValue] = c;
                }, children: _jsxs("div", { className: itemClassName, children: [_jsx("div", { className: flagClassName }), _jsx("div", { className: isLongCountryName ? extendedCountriesClassName : 'countries', children: item.name }), _jsx("div", { className: isLongCountryName ? codeHideOnHoverClassName : 'code', children: `+${item.code}` })] }) }, dataValue));
        });
    }
    get label() {
        if (this.props.label) {
            return _jsx("label", { htmlFor: this.props.id, className: this.css.labelClass });
        }
        return null;
    }
    get selection() {
        let newSelection;
        let selected = this.state.selection;
        if (this.props && this.props.dataProvider && selected && selected.value) {
            newSelection = this.props.dataProvider.find((item) => {
                let found = item === selected.value;
                if (item.value) {
                    found = item.value === selected.value;
                }
                return found;
            });
            selected = newSelection || selected;
        }
        if (selected !== Object(selected) || selected instanceof String) {
            const label = selected;
            const value = selected;
            selected = { value, label };
        }
        return selected;
    }
    get css() {
        const labelClass = classNames(['oc-dropdown__label', 'oc-label', this.props.labelClass]);
        const componentClass = classNames(['country-dropdown', 'oc-dropdown', this.props.componentClass], {
            'oc-dropdown--inline': this.props.inline,
            'oc-dropdown--open': this.state.isOpen,
        });
        return { labelClass, componentClass };
    }
    get menuDropdown() {
        if (!this.openDrop) {
            return null;
        }
        return (_jsx("ul", { "aria-hidden": true, role: "listbox", className: "oc-dropdown__menu", tabIndex: "-1", onClick: this.handleSelect, style: { maxHeight: this.props.height, minWidth: this.props.width }, children: this.options }));
    }
    render() {
        const { id } = this.props;
        const value = this.state.selection ? this.state.selection.value : 'US';
        const label = this.state.selection ? this.state.selection.label : '+1';
        const flagClassName = `flag flag-header ${value}`;
        const classWrapper = classNames('oc-dropdown__toggle', 'oc-dropdown__toggle-country', {
            'countries--error': !this.props.meta.valid,
        });
        const selectedOption = (_jsxs("div", { children: [_jsx("div", { className: flagClassName }), _jsx("div", { className: "code", "aria-readonly": true, children: `${label}` })] }));
        return (_jsxs("div", { id: id, className: this.css.componentClass, children: [this.label, _jsxs("div", { role: "combobox", "aria-expanded": this.openDrop, className: "oc-dropdown__wrapper", children: [_jsx("a", { role: "button", className: classWrapper, onClick: this.handleOpenDropdown, tabIndex: "-2", children: selectedOption }), this.menuDropdown] })] }));
    }
}
Dropdown.propTypes = {
    meta: PropTypes.object,
    componentClass: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    defaultValue: PropTypes.object,
    dataProvider: PropTypes.array,
    defaultOpen: PropTypes.bool,
    height: PropTypes.string,
    width: PropTypes.number,
    id: PropTypes.string,
    inline: PropTypes.bool,
    label: PropTypes.string,
    labelClass: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    selection: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onSelect: PropTypes.func,
    openDrop: PropTypes.func,
    clickedOutside: PropTypes.bool,
};
export const renderCountriesSelectionComponent = (field) => {
    const hoc = (comp) => comp;
    const OutsideClickDropdown = hoc(outsideClick(Dropdown));
    if (!field) {
        return _jsx(OutsideClickDropdown, { dataProvider: [{}], meta: {} });
    }
    const onValueChange = (selection) => {
        if (selection && field.input && typeof field.input.onChange === 'function') {
            const { label, value } = selection;
            field.input.onChange.apply(field.input, [{ id: value, name: label, value, label }]);
        }
    };
    let initialSelection;
    if (field.selection) {
        initialSelection = field.selection;
    }
    else if (field.input && field.input.value) {
        const { id: value = '', name: label = '', disabled, hasError, open } = field.input.value;
        initialSelection = { label, value, disabled, hasError, open };
    }
    else if (field.meta && field.meta.initial && field.meta.initial.size) {
        initialSelection = field.meta.initial.toJS();
    }
    return (_jsx(OutsideClickDropdown, { ...field, onSelect: onValueChange, selection: initialSelection, defaultOpen: initialSelection ? initialSelection.open : false }));
};
export default outsideClick(Dropdown);
