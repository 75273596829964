import { createSelector } from '@reduxjs/toolkit';
import ButtonComponent from '@ticketmaster/tm1pos-web-shared/components/ButtonComponent';
import { PricingDetails } from '@ticketmaster/tm1pos-web-shared/components/v2/Financial/PricingDetails/PricingDetails';
import { PricingDetailsItem } from '@ticketmaster/tm1pos-web-shared/components/v2/Financial/PricingDetailsItem/PricingDetailsItem';
import { selectFeatureFlags } from '@ticketmaster/tm1pos-web-shared/feature-flags';
import mainMessages from '@ticketmaster/tm1pos-web-shared/messages';
import { intlMessageShape } from '@ticketmaster/tm1pos-web-shared/proptypes-constants';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { toggleEditOtherChargesModal } from '../../../App/event-fees-actions';
import ShoppingBagItem from '../../../EventDetailPage/components/ShoppingBagItem';
import { selectCurrentEventFees } from '../../../EventDetailPage/selectors/main';
import EventInfo from './components/EventInfo';
import messages from './messages';

import './styles.scss';

/* eslint-disable jsx-a11y/no-static-element-interactions */

export class CheckoutSummary extends Component {
  shouldComponentUpdate(nextProps) {
    try {
      // update if seats number or total amount  or order status have changed;
      if (
        nextProps.isOrderProcessed !== this.props.isOrderProcessed ||
        nextProps.totals.total.amount !== this.props.totals.total.amount ||
        nextProps.totals.feesAndTaxes.amount !== this.props.totals.feesAndTaxes.amount ||
        nextProps.totals.feesAndTaxesBreakdown.length !== this.props.totals.feesAndTaxesBreakdown.length ||
        nextProps.totals.eventFees?.amount !== this.props.totals.eventFees?.amount ||
        nextProps.totals.eventFeesBreakdown?.length !== this.props.totals.eventFeesBreakdown?.length ||
        nextProps.isOrderUnpaid !== this.props.isOrderUnpaid ||
        nextProps.removingSeatPlacedId !== this.props.removingSeatPlacedId ||
        nextProps.isDeselectingSeat !== this.props.isDeselectingSeat
      ) {
        return true;
      }

      const items = nextProps.items;
      let shouldUpdate = false;
      Object.keys(items).forEach((key) => {
        if (items[key].seatsCount !== this.props.items[key].seatsCount) {
          shouldUpdate = true;
        }
      });
      return shouldUpdate;
    } catch (err) {
      return true;
    }
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  render() {
    const {
      items,
      isOrderProcessed,
      isOrderUnpaid,
      eventSeatBlocks = {},
      checkoutSeatBlocks = {},
      onDeselectSeat,
      removingSeatPlacedId,
      isDeselectingSeat,
      totals,
    } = this.props;

    const toggleEditChargesModal = () => {
      this.props.dispatch(toggleEditOtherChargesModal());
    };

    const checkoutSummaryClassName = classNames({
      'sdr-checkout__summary': true,
      'sdr-checkout__summary--gray': !isOrderProcessed,
    });

    const showFeesTitle = !!totals.feesAndTaxesBreakdown.length || !!totals.feesAndTaxes.amount;
    const showEventFeesBlock = this.props.currentEventFees.length > 0;

    const totalSummary = (
      <div className="checkout-pricing-details">
        <PricingDetails>
          <PricingDetailsItem title={mainMessages.subtotalAmount} price={totals.faceValue} data-testid="face-value" />
          {showFeesTitle ? (
            <PricingDetailsItem
              title={mainMessages.feesAndTaxes}
              charges={totals.feesAndTaxesBreakdown}
              price={totals.feesAndTaxes}
            />
          ) : null}
          {showEventFeesBlock ? (
            <PricingDetailsItem
              title={messages.otherCharges}
              charges={totals.eventFeesBreakdown}
              price={totals.eventFees}
              editFn={isOrderProcessed ? null : toggleEditChargesModal}
              tooltipMessage={messages.editOtherCharges}
            />
          ) : null}
          <PricingDetailsItem
            title={mainMessages.grandTotalAmount}
            price={totals.total}
            variant="large"
            data-testid="grand-total"
          />
        </PricingDetails>
      </div>
    );
    const eventLists = Object.keys(items).map((eventId) => {
      const event = items[eventId].details;
      const eventDetailsMarkup = (
        <>
          <EventInfo
            header="sdr-checkout"
            name={event.name}
            weekday={event.weekday}
            time={event.time}
            fullDate={event.fullDateShortMonth}
          />
          <div className="sdr-checkout__summary__event--location">{event.location}</div>
        </>
      );
      const sections = isOrderProcessed ? checkoutSeatBlocks[eventId] : eventSeatBlocks[eventId];
      return (
        <div className="checkout-summary-wrap" key={eventId}>
          <div className="checkout-summary">
            {eventDetailsMarkup}
            <div className="checkout__line-break" />
            <div className="sdr-shopping-bag__items">
              {sections.map((section) =>
                section.seats.map((seat) => (
                  <ShoppingBagItem
                    key={`${seat.name}_${seat.row}_${seat.section}`}
                    section={seat.section}
                    row={seat.row}
                    seat={seat.name}
                    deSelectSeatAction={onDeselectSeat}
                    placeId={seat.generalAdmission ? seat.gaID : seat.placeId}
                    tickettype={seat.type.current.name}
                    promotion={seat.type.current.promotion}
                    price={seat.price.amount}
                    GA={seat.GA}
                    sellClass={seat.sellClass}
                    adaType={seat.adaType}
                    pricing={seat.pricing}
                    isDeselectingSeat={isDeselectingSeat}
                    removingSeatPlacedId={removingSeatPlacedId}
                    allowDelete={!isOrderProcessed}
                    isOrderUnpaid={isOrderUnpaid}
                  />
                )),
              )}
            </div>
          </div>
        </div>
      );
    });

    const clearCartBtn =
      isOrderProcessed || isOrderUnpaid ? null : (
        <ButtonComponent
          onButtonClick={this.props.onClearCart}
          className="oc-unbutton outline-none sdr-checkout__summary-clear-cart "
        >
          <FormattedMessage {...messages.clearCart} />
        </ButtonComponent>
      );

    return (
      <div className={checkoutSummaryClassName}>
        <div className="sdr-checkout__summary__header">
          <div className="sdr-checkout__summary__header-title">
            <FormattedMessage {...this.props.headerMsg} />
          </div>
          <div>{clearCartBtn}</div>
        </div>
        {eventLists}
        {totalSummary}
      </div>
    );
  }
}

CheckoutSummary.propTypes = {
  dispatch: PropTypes.func,
  items: PropTypes.object,
  totals: PropTypes.object,
  onClearCart: PropTypes.func,
  isOrderProcessed: PropTypes.bool,
  isOrderUnpaid: PropTypes.bool,
  checkoutSeatBlocks: PropTypes.object,
  onDeselectSeat: PropTypes.func,
  removingSeatPlacedId: PropTypes.string,
  isDeselectingSeat: PropTypes.bool,
  eventSeatBlocks: PropTypes.object,
  headerMsg: intlMessageShape,
  featureFlags: PropTypes.object,
  currentEventFees: PropTypes.array,
};

const mapStateToProps = createSelector(
  selectFeatureFlags,
  selectCurrentEventFees,
  (featureFlags, currentEventFees) => ({
    featureFlags,
    currentEventFees,
  }),
);

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutSummary);
