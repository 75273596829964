import { jsx as _jsx } from "react/jsx-runtime";
import { AdaptiveModal, MODAL_SIZE_MEDIUM } from '@ticketmaster/aurora';
import classNames from 'classnames';
import './styles.scss';
const wrapModal = (Wrapped, { bottomActionBarProps = {}, containerProps = {}, contentProps = {}, size = MODAL_SIZE_MEDIUM, ...modalProps } = {}) => (props) => {
    const { className: containerPropsClassName, ...otherContainerProps } = containerProps;
    const { className: contentPropsClassName, ...otherContentProps } = contentProps;
    const { className: bottomActionBarPropsClassName, ...otherBottomActionBarProps } = bottomActionBarProps;
    return (_jsx(AdaptiveModal, { className: "sdr-modal-wrapper", size: size, containerProps: {
            className: classNames('sdr-modal-container', containerPropsClassName),
            ...otherContainerProps,
        }, contentProps: {
            className: classNames('sdr-modal-content', contentPropsClassName),
            ...otherContentProps,
        }, bottomActionBarProps: {
            className: classNames('sdr-modal-bottom-action-bar', bottomActionBarPropsClassName),
            ...otherBottomActionBarProps,
        }, ...modalProps, children: _jsx(Wrapped, { ...props }) }));
};
export default wrapModal;
