import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@ticketmaster/aurora';
import { useState } from 'react';
import { useBeforeunload } from 'react-beforeunload';
import { FormattedMessage } from 'react-intl';
import { useNavigationBlocking } from '../../utils/block-navigation-hook';
import LoadingComponent from '../LoadingComponent';
import messages from './messages';
import './styles.scss';
const EmvTerminal = ({ onCancel }) => {
    const [cancelPaymentClicked, setCancelPaymentClicked] = useState(false);
    useBeforeunload((e) => {
        e.preventDefault();
    });
    useNavigationBlocking();
    const onCancelClick = () => {
        if (cancelPaymentClicked) {
            return;
        }
        setCancelPaymentClicked(true);
        onCancel();
    };
    if (cancelPaymentClicked) {
        return (_jsx(LoadingComponent, { emv: true, children: _jsx(FormattedMessage, { ...messages.cancelPaymentInProgress }) }));
    }
    return (_jsxs(LoadingComponent, { emv: true, children: [_jsx(FormattedMessage, { ...messages.emvWaitingForCustomerPayment }), _jsx("p", { children: _jsx(FormattedMessage, { ...messages.emvDoNotLeaveWarning }) }), _jsx(Button, { className: "emv-terminal__cancel-btn", variant: "transparent", onClick: onCancelClick, children: _jsx(FormattedMessage, { ...messages.emvCancel }) })] }));
};
EmvTerminal.displayName = 'EmvTerminal';
export default EmvTerminal;
