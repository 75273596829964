import { createSelector } from '@reduxjs/toolkit';
import { selectActivePriceLevels } from './priceRange';
import { selectPriceLevelsWithAvailability } from './seatStatus';

export const selectFbaPriceLevelGroups = createSelector(
  selectActivePriceLevels,
  selectPriceLevelsWithAvailability,
  (activeIds: string[], priceLevels: any) =>
    activeIds.length
      ? activeIds
      : priceLevels.filter((priceLevel: any) => !priceLevel.disabled).map((priceLevel: any) => priceLevel.id),
);
