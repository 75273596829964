import TmdsClient from '@ticketmaster/tm1pos-tmds-client';
import { call, put, select } from 'redux-saga/effects';
import { printerToTmdsPrinter } from './mapper';
import { PrinterService } from './PrinterService';
import { selectHasTmdsPrintingEnabled, selectPrinters } from './printingModule-selectors';
import { printingFailed, setActivePrinter, setLoading, setPrinters } from './printingModule-slice';
import { isTmdsPrinter, PrinterType } from './printingModule-types';
class TmdsPrintingModule {
    *initialize(printer, isOnlyPrintingServiceAvailable) {
        const hasTmdsEnabled = yield select(selectHasTmdsPrintingEnabled);
        if (printer && isTmdsPrinter(printer) && hasTmdsEnabled) {
            yield put(setActivePrinter(printer));
        }
        else if (!printer && isOnlyPrintingServiceAvailable) {
            yield call(selectDefaultPrinterWhenPossible);
        }
    }
    *print(printer, filesToPrint) {
        if (isTmdsPrinter(printer)) {
            yield call(TmdsClient.selectPrinter, printerToTmdsPrinter(printer));
            yield call(TmdsClient.print, filesToPrint);
            if (TmdsClient.localPrinterError) {
                yield put(printingFailed(TmdsClient.localPrinterError));
            }
        }
    }
    *refreshPrinters() {
        yield call(refreshPrinters);
    }
}
export function* refreshPrinters() {
    yield put(setLoading(true));
    yield call(TmdsClient.getTmdsDeviceDetails);
    const printers = TmdsClient.printerList.map((tmdsPrinter) => ({
        type: PrinterType.Tmds,
        ...tmdsPrinter,
    }));
    yield put(setPrinters(printers));
    yield put(setLoading(false));
}
export function* selectDefaultPrinterWhenPossible() {
    yield call(refreshPrinters);
    const availablePrinters = yield select(selectPrinters);
    if (availablePrinters.length === 1) {
        yield put(setActivePrinter({
            ...availablePrinters.at(0),
            printerService: PrinterService.Tmds,
        }));
    }
}
export default new TmdsPrintingModule();
