/*
 * Event Detail Page Messages
 *
 * This contains all the text for the EDP component.
 */
import { mapADATypeFromString } from '@ticketmaster/tm1pos-web-shared/ADA/mapper';
import { ADAType } from '@ticketmaster/tm1pos-web-shared/model/ADAType';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  unavailable: {
    id: 'sales.EventDetailPage.unavailable',
    defaultMessage: 'Unavailable',
  },
  findBestAvailable: {
    id: 'sales.EventDetailPage.findBestAvailable',
    defaultMessage: 'Find Best Available',
  },
  priceRangeDetail: {
    id: 'sales.EventDetailPage.priceRangeDetail',
    defaultMessage: 'Price Range',
  },
  qualifiedHoldsLabel: {
    id: 'sales.EventDetailPage.qualifiedHoldsLabel',
    defaultMessage: 'Labeled Holds',
  },
  qualifiedHoldsTooltipText: {
    id: 'sales.EventDetailPage.qualifiedHoldsTooltipText',
    defaultMessage:
      'Combinations of labeled holds and ticket types need to be enabled in advance in order for them to work.',
  },
  inventoryTypeQualifiedHoldLabelAccessible: {
    id: 'sales.EventDetailPage.inventoryTypeQualifiedHoldLabel',
    defaultMessage: '{name} (accessible only)',
  },
  inventoryType: {
    id: 'sales.EventDetailPage.inventoryType',
    defaultMessage: 'Inventory Type',
  },
  ticketType: {
    id: 'sales.EventDetailPage.ticketType',
    defaultMessage: 'Ticket Type',
  },
  seatsAvailableDetail: {
    id: 'sales.EventDetailPage.seatsAvailableDetail',
    defaultMessage: 'Seats Available',
  },
  priceLevelChoose: {
    id: 'sales.EventDetailPage.priceLevelChoose',
    defaultMessage: 'Choose a Price Level',
  },
  priceLevelAbbreviation: {
    id: 'sales.EventDetailPage.priceLevelAbbrevation',
    defaultMessage: 'PL',
  },
  priceLevel: {
    id: 'sales.EventDetailPage.priceLevel',
    defaultMessage: 'Price level',
  },
  priceLevels: {
    id: 'sales.EventDetailPage.priceLevels',
    defaultMessage: 'Price levels',
  },
  selectAll: {
    id: 'sales.EventDetailPage.checkbox',
    defaultMessage: 'Select All',
  },
  clearSelection: {
    id: 'sales.EventDetailPage.clearSelection',
    defaultMessage: 'Clear All',
  },
  cancel: {
    id: 'sales.EventDetailPage.cancel',
    defaultMessage: 'Cancel',
  },
  cancelCart: {
    id: 'sales.EventDetailPage.cancelCart',
    defaultMessage: 'Clear cart',
  },
  cart: {
    id: 'sales.EventDetailPage.cart',
    defaultMessage: 'Cart ({cartItemsLength})',
  },
  filters: {
    id: 'sales.EventDetailPage.filters',
    defaultMessage: 'Filters',
  },
  seatsSelected: {
    id: 'sales.EventDetailPage.seatsSelected',
    defaultMessage:
      '{cartItemsLength, plural, one {Seat Selected <span>({cartItemsLength})</span>} other {Seats Selected <span>({cartItemsLength})</span>}}',
  },
  clearSeatsSelected: {
    id: 'sales.EventDetailPage.clearSeatsSelected',
    defaultMessage: 'Clear',
  },
  bagItemSection: {
    id: 'sales.EventDetailPage.bagItemSection',
    defaultMessage: 'Sec {sectionName}',
  },
  bagItemGA: {
    id: 'sales.EventDetailPage.bagItemGA',
    defaultMessage: 'General admission floor ',
  },
  bagItemRow: {
    id: 'sales.EventDetailPage.bagItemRow',
    defaultMessage: 'Row {rowName}',
  },
  bagItemSeat: {
    id: 'sales.EventDetailPage.bagItemSeat',
    defaultMessage: 'Seat {seatName}',
  },
  bagSubtotal: {
    id: 'sales.EventDetailPage.bagSubtotal',
    defaultMessage: 'Subtotal',
  },
  bagTotal: {
    id: 'sales.EventDetailPage.bagTotal',
    defaultMessage: 'Total',
  },
  grandTotal: {
    id: 'sales.EventDetailPage.grandTotal',
    defaultMessage: 'Grand Total',
  },
  bagFees: {
    id: 'sales.EventDetailPage.bagFees',
    defaultMessage: 'Fees',
  },
  checkout: {
    id: 'sales.EventDetailPage.checkout',
    defaultMessage: 'Checkout',
  },
  noSeatsAvailable: {
    id: 'sales.EventDetailPage.noSeatsAvailable',
    defaultMessage: 'No Availability',
  },
  noRateAvailable: {
    id: 'sales.EventDetailPage.noRateAvailable',
    defaultMessage: 'Unable to load inventory. Try selecting another ticket type or offer.',
  },
  noEnoughSeatsAvailable: {
    id: 'sales.EventDetailPage.noEnoughSeatsAvailable',
    defaultMessage: 'Not available in selected quantity',
  },
  noSeatMapDefined: {
    id: 'sales.EventDetailPage.noSeatMapDefined',
    defaultMessage: 'No seat map is defined for this event.',
  },
  noSeatMapSecondDefined: {
    id: 'sales.EventDetailPage.noSeatMapSecondDefined',
    defaultMessage: 'Use the Find Best Available option.',
  },
  couldNotLoadMap: {
    id: 'sales.EventDetailPage.couldNotLoadMap',
    defaultMessage: 'Could not load the seat map.',
  },
  accessibleSeating: {
    id: 'sales.EventDetailPage.accessibleSeating',
    defaultMessage: 'Accessible Seating',
  },
  companion: {
    id: 'sales.EventDetailPage.companion',
    defaultMessage: 'Companion',
  },
  mobility: {
    id: 'sales.EventDetailPage.mobility',
    defaultMessage: 'Limited Mobility',
  },
  'sight/hearing': {
    id: 'sales.EventDetailPage.sight/hearing',
    defaultMessage: 'Sight Hearing Impaired',
  },
  wheelchair: {
    id: 'sales.EventDetailPage.wheelchair',
    defaultMessage: 'Wheelchair Accessible',
  },
  sight: {
    id: 'sales.EventDetailPage.sight',
    defaultMessage: 'Sight Impaired',
  },
  hearing: {
    id: 'sales.EventDetailPage.hearing',
    defaultMessage: 'Hearing Impaired',
  },
  'not-specified': {
    id: 'sales.EventDetailPage.notSpecified',
    defaultMessage: 'Not Specified',
  },
  ticketTypesDropdownLabel: {
    id: 'sales.EventDetailPage.ticketTypesDropdownLabel',
    defaultMessage: 'Select an option',
  },
  standardTypes: {
    id: 'sales.EventDetailPage.standardTypes',
    defaultMessage: 'Standard',
  },
  promoTypes: {
    id: 'sales.EventDetailPage.promoTypes',
    defaultMessage: 'Promos',
  },
  searchOffer: {
    id: 'sales.EventDetailPage.searchOffer',
    defaultMessage: 'Search Offer',
  },
  failToDeselect: {
    id: 'sales.EventDetailPage.failToDeselect',
    defaultMessage: 'No server connection. Please try again.',
  },
  noOffersFound: {
    id: 'sales.EventDetailPage.noOffersFound',
    defaultMessage: 'No offers found containing {offerName}',
  },
  fees: {
    id: 'sales.EventDetailPage.fees',
    defaultMessage: 'Fees',
  },
  feesAndTaxes: {
    id: 'sales.EventDetailPage.feesAndTaxes',
    defaultMessage: 'Fees & Taxes',
  },
  otherCharges: {
    id: 'sales.EventDetailPage.otherCharges',
    defaultMessage: 'Other Charges',
  },
  editOtherCharges: {
    id: 'sales.EventDetailPage.editOtherCharges',
    defaultMessage: 'Edit Other Charges',
  },
  selectInventoryType: {
    id: 'sales.EventDetailPage.selectInventoryType',
    defaultMessage: 'Select an Inventory Type',
  },
  noPriceForSeat: {
    id: 'sales.EventDetailPage.noPriceForSeat',
    defaultMessage: 'N/A',
  },
  iceCompanionWarning: {
    id: 'sales.EventDetailPage.iceCompanionWarning',
    defaultMessage: 'Please select another type of Accessible seat before selecting a companion seat.',
  },
  iceSection: {
    id: 'sales.EventDetailPage.iceSection',
    defaultMessage: 'SECTION {sectionName}',
  },
  iceAvailableSeats: {
    id: 'sales.EventDetailPage.iceAvailableSeats',
    defaultMessage: '<strong>{available} seats available</strong><br />of {total}',
  },
  icePlaceTemplateHeaderSection: {
    id: 'sales.EventDetailPage.icePlaceTemplateHeaderSection',
    defaultMessage: 'SEC {name}',
  },
  icePlaceTemplateHeaderRow: {
    id: 'sales.EventDetailPage.icePlaceTemplateHeaderRow',
    defaultMessage: 'ROW {name}',
  },
  icePlaceTemplateHeaderSeat: {
    id: 'sales.EventDetailPage.icePlaceTemplateHeaderSeat',
    defaultMessage: 'SEAT {name}',
  },
  ambulant: {
    id: 'sales.EventDetailPage.ambulant',
    defaultMessage: 'Ambulant Disabled',
  },
});

export const getAdaTypeMessageDescriptor = (key) => {
  switch (mapADATypeFromString(key)) {
    case ADAType.COMPANION:
      return messages.companion;
    case ADAType.MOBILITY:
      return messages.mobility;
    case ADAType.SIGHT_HEARING:
      return messages['sight/hearing'];
    case ADAType.SIGHT:
      return messages.sight;
    case ADAType.HEARING:
      return messages.hearing;
    case ADAType.WHEELCHAIR:
      return messages.wheelchair;
    case ADAType.UNSPECIFIED:
      return messages['not-specified'];
    case ADAType.AMBULANT:
      return messages.ambulant;
    default:
  }

  return messages[key] ? messages[key] : null;
};
export default messages;
