import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { v4 as uuidV4 } from 'uuid';
import { Tm1Option, Tm1Select, Tm1Typography } from '../../../../components/v2';
import { PrinterService } from '../../../PrinterService';
import messages, { getLabelForPrinterService } from '../../../printing-message';
import '../EditPrinterConfiguration.scss';
const workaroundForTm1SelectDisabledBug = (callback) => setTimeout(() => callback(), 0);
export const DropdownPrintingService = ({ allowedServices, connectionError, defaultService, onChange, intl, readOnly = false, disabled, showValidationMessage, }) => {
    const [selectedPrinterService, setSelectedPrinterService] = useState();
    const [uniqueId] = useState(() => uuidV4());
    const onPrintingServiceChange = (event) => {
        const newValue = (event.target.value || '').trim() || undefined;
        if (newValue) {
            setSelectedPrinterService(newValue);
            workaroundForTm1SelectDisabledBug(() => onChange(newValue));
        }
    };
    useEffect(() => {
        if (defaultService) {
            if (allowedServices.includes(defaultService)) {
                setSelectedPrinterService(defaultService);
            }
            else {
                workaroundForTm1SelectDisabledBug(() => onChange(null));
            }
        }
    }, [defaultService, allowedServices, setSelectedPrinterService, onChange]);
    const showErrorMessage = connectionError && selectedPrinterService !== PrinterService.QzTrayRemote;
    const defaultPrinterServiceName = defaultService ? intl.formatMessage(getLabelForPrinterService(defaultService)) : '';
    const getErrorMessage = () => {
        let errorMessage = '';
        if (showErrorMessage) {
            errorMessage = intl.formatMessage(connectionError);
        }
        else if (showValidationMessage && !selectedPrinterService) {
            errorMessage = intl.formatMessage(messages.aSelectionIsRequired);
        }
        return errorMessage;
    };
    return (_jsxs("div", { className: "dropdown-printing-service", children: [_jsx("label", { className: "dropdown-printing-service__label", htmlFor: uniqueId, children: _jsx(Tm1Typography, { type: "label-helpertext-regular", children: intl.formatMessage(messages.printingServiceLabel) }) }), readOnly ? (_jsx(Tm1Typography, { type: "body-m", children: defaultPrinterServiceName })) : (_jsx(Tm1Select, { disabled: disabled, error: getErrorMessage(), id: uniqueId, onChange: onPrintingServiceChange, placeholder: intl.formatMessage(messages.printingServicePlaceholder), value: selectedPrinterService, children: allowedServices.map((printerService) => {
                    const label = getLabelForPrinterService(printerService);
                    return (_jsx(Tm1Option, { value: printerService, children: intl.formatMessage(label) }, printerService));
                }) }))] }));
};
export default injectIntl(DropdownPrintingService);
