import { defineMessages } from 'react-intl';
const feeMessages = defineMessages({
    orderProcessingFee: {
        id: 'sales.App.feeName.orderProcessingFee',
        defaultMessage: 'Order Processing Fee',
    },
    serviceChargeSingular: {
        id: 'sales.App.feeName.serviceChargeSingular',
        defaultMessage: 'Service Charge',
    },
    serviceChargePlural: {
        id: 'sales.App.feeName.serviceChargePlural',
        defaultMessage: 'Service Charges',
    },
    serviceChargeTaxSingular: {
        id: 'sales.App.feeName.serviceChargeTaxSingular',
        defaultMessage: 'Service Charge Tax',
    },
    serviceChargeTaxPlural: {
        id: 'sales.App.feeName.serviceChargeTaxPlural',
        defaultMessage: 'Service Charge Taxes',
    },
    facilityFeeSingular: {
        id: 'sales.App.feeName.facilityFeeSingular',
        defaultMessage: 'Facility Fee',
    },
    facilityFeePlural: {
        id: 'sales.App.feeName.facilityFeePlural',
        defaultMessage: 'Facility Fees',
    },
    taxSingular: {
        id: 'sales.App.feeName.taxSingular',
        defaultMessage: 'Tax',
    },
    taxPlural: {
        id: 'sales.App.feeName.taxPlural',
        defaultMessage: 'Taxes',
    },
    distanceChargeSingular: {
        id: 'sales.App.feeName.distanceChargeSingular',
        defaultMessage: 'Distance Charge',
    },
    distanceChargePlural: {
        id: 'sales.App.feeName.distanceChargePlural',
        defaultMessage: 'Distance Charges',
    },
    ticketsFaceValue: {
        id: 'sales.App.feeName.tickets',
        defaultMessage: 'Tickets',
    },
    feesAndTaxes: {
        id: 'sales.App.feeName.feeAndTaxes',
        defaultMessage: 'Fees and Taxes',
    },
});
const isValidKey = (key) => key in feeMessages;
export const formatFeeName = (feeName) => {
    const key = feeName.replace(/^message\./, '');
    return isValidKey(key) ? feeMessages[key] : { id: 'untranslated fee', defaultMessage: feeName };
};
export const formatFeeQuantity = (quantity = 0) => {
    if (quantity > 1) {
        return `x ${quantity}`;
    }
    return null;
};
