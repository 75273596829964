const MAX_ITEMS = 20;
export function createActionHistory(options = {}) {
    const { limit = MAX_ITEMS, exclude = null } = options;
    const actionsQueue = [];
    const middleware = () => () => (next) => (action) => {
        if ((action === null || action === void 0 ? void 0 : action.type) && (!exclude || !action.type.match(exclude))) {
            addToQueue(action);
        }
        return next(action);
    };
    const addToQueue = (action) => {
        actionsQueue.unshift(action === null || action === void 0 ? void 0 : action.type);
        if (actionsQueue.length > limit) {
            actionsQueue.pop();
        }
    };
    return {
        middleware,
        getLastActions: () => [].concat(...actionsQueue),
        reset: () => {
            actionsQueue.splice(0, limit);
        },
    };
}
