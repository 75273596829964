import { TransactionType } from '@ticketmaster/tm1pos-tmds-client';
import { enumFromStringValue } from '../../utils/enumUtils';
import { ReverseTransactionStatus, TransactionStatus, } from '../emvPaymentModule-types';
export const mapTransactionDataToTMDS = (data) => {
    var _a;
    return ({
        eventCode: data.eventCode,
        transactionCurrency: data.transactionCurrency,
        operatorCode: data.operatorCode,
        hostId: data.systemId,
        manualEntry: data.isManualEntry,
        amount: data.amount * 100,
        preferredMopTypes: (_a = data.preferredMopTypes) === null || _a === void 0 ? void 0 : _a.toString(),
        transactionType: enumFromStringValue(TransactionType, data.transactionType),
    });
};
export const mapPaymentDataFromTMDS = (transaction) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    return ({
        paymentReference: null,
        paymentDetails: {
            cardType: (_a = transaction === null || transaction === void 0 ? void 0 : transaction.fundingMethod) !== null && _a !== void 0 ? _a : null,
            lastFourDigits: (_b = transaction === null || transaction === void 0 ? void 0 : transaction.last4) !== null && _b !== void 0 ? _b : null,
            authorizedAmount: (_c = transaction === null || transaction === void 0 ? void 0 : transaction.authorizedAmount) !== null && _c !== void 0 ? _c : null,
        },
        status: TransactionStatus.SUCCESS,
        reverseStatus: ReverseTransactionStatus.NONE,
        error: null,
        expirationMonth: (_d = transaction === null || transaction === void 0 ? void 0 : transaction.expirationMonth) !== null && _d !== void 0 ? _d : null,
        expirationYear: (_e = transaction === null || transaction === void 0 ? void 0 : transaction.expirationYear) !== null && _e !== void 0 ? _e : null,
        hostToken: (_f = transaction === null || transaction === void 0 ? void 0 : transaction.hostToken) !== null && _f !== void 0 ? _f : null,
        token: (_g = transaction === null || transaction === void 0 ? void 0 : transaction.token) !== null && _g !== void 0 ? _g : null,
        response: (_h = transaction === null || transaction === void 0 ? void 0 : transaction.response) !== null && _h !== void 0 ? _h : null,
        merchantReceiptData: (_j = transaction === null || transaction === void 0 ? void 0 : transaction.merchantReceiptData) !== null && _j !== void 0 ? _j : null,
        aid: (_l = (_k = transaction === null || transaction === void 0 ? void 0 : transaction.attributes) === null || _k === void 0 ? void 0 : _k.aid) !== null && _l !== void 0 ? _l : null,
        bin: (_m = transaction === null || transaction === void 0 ? void 0 : transaction.bin) !== null && _m !== void 0 ? _m : null,
        nameOnCard: (_o = transaction === null || transaction === void 0 ? void 0 : transaction.nameOnCard) !== null && _o !== void 0 ? _o : null,
        merchantDetails: mapMerchantDetailsFromTMDS(transaction),
        transactionId: (_p = transaction === null || transaction === void 0 ? void 0 : transaction.paymentTransactionId) !== null && _p !== void 0 ? _p : null,
    });
};
const mapMerchantDetailsFromTMDS = (transaction) => {
    var _a, _b, _c, _d, _e;
    return ({
        address: (_a = transaction === null || transaction === void 0 ? void 0 : transaction.merchantDetails.address) !== null && _a !== void 0 ? _a : null,
        city: (_b = transaction === null || transaction === void 0 ? void 0 : transaction.merchantDetails.city) !== null && _b !== void 0 ? _b : null,
        state: (_c = transaction === null || transaction === void 0 ? void 0 : transaction.merchantDetails.state) !== null && _c !== void 0 ? _c : null,
        country: (_d = transaction === null || transaction === void 0 ? void 0 : transaction.merchantDetails.country) !== null && _d !== void 0 ? _d : null,
        phoneNumber: (_e = transaction === null || transaction === void 0 ? void 0 : transaction.merchantDetails.phoneNumber) !== null && _e !== void 0 ? _e : null,
    });
};
