import { defineMessages } from 'react-intl';

export default defineMessages({
  clearCart: {
    id: 'sales.CheckoutSummary.clearCart',
    defaultMessage: 'Clear',
  },
  otherCharges: {
    id: 'sales.CheckoutSummary.otherCharges',
    defaultMessage: 'Other Charges',
  },
  editOtherCharges: {
    id: 'sales.CheckoutSummary.editOtherCharges',
    defaultMessage: 'Edit Other Charges',
  },
});
