/*
 * HomePage Messages
 *
 * This contains all the text for the HomePage component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  appName: {
    id: 'sales.HomePage.appName',
    defaultMessage: 'Sales',
  },
  redirectMessage: {
    id: 'sales.HomePage.redirectMessage',
    defaultMessage: 'Redirecting...',
  },
  signOut: {
    id: 'sales.HomePage.signOut',
    defaultMessage: 'Sign Out',
  },
});
