import { jsx as _jsx } from "react/jsx-runtime";
import { useDispatch, useSelector } from 'react-redux';
import { selectPrintingModuleLoading } from '../../printingModule-selectors';
import { clearErrorQzConnect } from '../../printingModule-slice';
import EditPrinterModal from './EditPrinterModal';
const EditPrinterModalConnected = ({ onClose }) => {
    const loading = useSelector(selectPrintingModuleLoading);
    const dispatch = useDispatch();
    const onCloseWithDispatch = () => {
        dispatch(clearErrorQzConnect());
        onClose();
    };
    return _jsx(EditPrinterModal, { onClose: onCloseWithDispatch, loading: loading });
};
EditPrinterModalConnected.displayName = 'EditPrinterModalConnected';
export default EditPrinterModalConnected;
