import { Dropdown } from '@ticketmaster/tm1pos-web-shared/components/Dropdown';
import SearchFilterComponent from '@ticketmaster/tm1pos-web-shared/components/SearchFilterComponent';
import outsideClick from '@ticketmaster/tm1pos-web-shared/components/utils/OutsideClick';
import { intlMessageShape } from '@ticketmaster/tm1pos-web-shared/proptypes-constants';
import Fuse from 'fuse.js';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import './styles.scss';

export class SearchFilterDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: '',
      searchResults: null,
    };
    let stateObj = {
      isOpen: props.defaultOpen,
      selection: { label: undefined, value: undefined, disabled: false, hasError: false },
    };

    if (props.selection) {
      const alternateLabel = typeof props.selection === 'string' ? props.selection : props.selection.name || '';
      stateObj = Object.assign(stateObj, {
        selection: {
          label: props.selection.label ? props.selection.label : alternateLabel,
          value: props.selection.value ? props.selection.value : alternateLabel,
          disabled: props.selection.disabled === 'true' || props.selection.disabled === true,
          hasError: props.selection.hasError === 'true' || props.selection.hasError === true,
        },
      });
    }
    this.state = stateObj;
  }

  componentDidMount() {
    if (this.props.onSelect) {
      this.props.onSelect();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (typeof nextProps.selection === 'string') {
      const label = nextProps.selection;
      const value = nextProps.selection;
      this.setState({ selection: { label, value, disabled: false, hasError: false } });
    } else if (
      nextProps.selection !== this.props.selection ||
      (nextProps.selection && this.props.selection && nextProps.selection.hasError !== this.props.selection.hasError)
    ) {
      this.setState({ selection: nextProps.selection });
    }
    if (nextProps.clickedOutside && this.openDrop) {
      this.openDrop = false;
    }
  }

  onSearchChange = (value) => {
    if (value) {
      const options = {
        threshold: 0.4,
        minMatchCharLength: 1,
        keys: ['label'],
      };
      const fuse = new Fuse(this.props.dataProvider, options);
      const results = fuse.search(value);
      this.setState({
        searchValue: value,
        searchResults: results,
      });
    } else {
      this.setState({
        searchValue: '',
        searchResults: null,
      });
    }
  };

  get searchComponent() {
    return (
      <SearchFilterComponent
        id="search-dropdown"
        onInputChange={this.onSearchChange}
        placeholder={this.props.searchPlaceholder}
        value={this.state.searchValue}
      />
    );
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  get selection() {
    let newSelection;
    let selected = this.state.selection;
    if (this.props && this.props.dataProvider && selected && selected.value) {
      newSelection = this.props.dataProvider.find((item) => {
        let found = item === selected.value;
        if (item.value) {
          found = item.value === selected.value;
        }
        return found;
      });
      selected = newSelection || selected;
    }
    if (selected !== Object(selected) || selected instanceof String) {
      const label = selected;
      const value = selected;
      selected = { value, label, hasError: true };
    }
    return selected;
  }

  get emptySearchBlock() {
    return (
      <div className="oc-dropdown__menu-item__empty-block">
        <div>
          <FormattedMessage {...this.props.emptySearchMessage} values={{ searchValue: this.state.searchValue }} />
        </div>
      </div>
    );
  }

  render() {
    return (
      <Dropdown
        onSelect={this.props.onSelect}
        selection={this.state.selection}
        emptySelection={this.props.emptySelection}
        dataProvider={this.state.searchValue ? this.state.searchResults : this.props.dataProvider}
      >
        {this.props.dataProvider && this.props.dataProvider.length > this.props.minimumOptionsToDisplaySearch
          ? this.searchComponent
          : null}
        {this.state.searchValue && !this.state.searchResults.length ? this.emptySearchBlock : null}
      </Dropdown>
    );
  }
}

SearchFilterDropdown.propTypes = {
  minimumOptionsToDisplaySearch: PropTypes.number,
  dataProvider: PropTypes.array,
  defaultOpen: PropTypes.bool,
  selection: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onSelect: PropTypes.func,
  clickedOutside: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  emptySelection: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  emptySearchMessage: intlMessageShape,
};

export const renderDropdownComponent = (field) => {
  const defaultHoc = (comp) => comp;
  const hoc = field && field.hoc ? field.hoc : defaultHoc;
  const OutsideClickDropdown = hoc(outsideClick(SearchFilterDropdown));

  if (!field) {
    return <OutsideClickDropdown />;
  }

  const onValueChange = (selection) => {
    if (selection && field.input && typeof field.input.onChange === 'function') {
      const { label, value, disabled, hasError } = selection;
      field.input.onChange.apply(field.input, [{ id: value, name: label, value, label, disabled, hasError }]);
    }
    return true;
  };

  let initialSelection;
  if (field.selection) {
    initialSelection = field.selection;
  } else if (field.input && field.input.value && field.input.value.id) {
    const { id: value = '', name: label = '', disabled, hasError } = field.input.value;
    initialSelection = { label, value, disabled, hasError };
  } else if (field.meta && field.meta.initial && field.meta.initial.size) {
    initialSelection = field.meta.initial.toJS();
  }

  return <OutsideClickDropdown {...field} onSelect={onValueChange} selection={initialSelection} />;
};
