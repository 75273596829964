import { defineMessages } from 'react-intl';

export default defineMessages({
  editOtherCharges: {
    id: 'sales.editOtherChargesModal.editOtherCharges',
    defaultMessage: 'Edit Other Charges',
  },
  chargeDescription: {
    id: 'sales.editOtherChargesModal.chargeDescription',
    defaultMessage: 'Charge Description',
  },
  chargePrice: {
    id: 'sales.editOtherChargesModal.chargePrice',
    defaultMessage: 'Price',
  },
  chargeQuantity: {
    id: 'sales.editOtherChargesModal.chargeQuantity',
    defaultMessage: 'Quantity',
  },
  chargeMaxQuantity: {
    id: 'sales.editOtherChargesModal.chargeMaxQuantity',
    defaultMessage: 'Each charge can be added up to {maxValue} times.',
  },
  chargeTotal: {
    id: 'sales.editOtherChargesModal.chargeTotal',
    defaultMessage: 'Total',
  },
  otherChargesTotal: {
    id: 'sales.editOtherChargesModal.otherChargesTotal',
    defaultMessage: 'Other Charges Total',
  },
  otherChargesErrorBannerTitle: {
    id: 'sales.editOtherChargesModal.otherChargesErrorBannerTitle',
    defaultMessage: 'Unable to apply charges',
  },
  otherChargesErrorBanner: {
    id: 'sales.editOtherChargesModal.otherChargesErrorBanner',
    defaultMessage:
      'Our system encountered an error while processing the request. Refresh the page and try again or, if the problem persists, contact your Ticketmaster representative for assistance.',
  },
});
