import { SpanKind } from '@opentelemetry/api';
import { getIsmAttributes } from '@ticketmaster/tm1pos-web-shared/middleware/OpenTelemetry/tracing-attribute-utils';
import tracerService, { TracedOperations } from '@ticketmaster/tm1pos-web-shared/services/tracer/tracer.service';
import * as _ from 'lodash';
import {
  finishLoadingCurrentEventPage,
  startLoadingCurrentEventPage,
  updateIcePlaces,
} from '../containers/EventDetailPage/event-actions';
import { iceGeometryLoadStarted, iceMetricsEventReport } from './tracing-actions';

function onStartLoadingCurrentEventPage() {
  tracerService.startSpan(TracedOperations.EventLoading, { root: true });
}

function onFinishLoadingCurrentEventPage(action) {
  tracerService.finishSpan(TracedOperations.IcePlaceDataUpdating);
  tracerService.finishSpan(
    TracedOperations.EventLoading,
    getIsmAttributes(action.payload.seatAmount, action.payload.priceLevelAmount),
  );
}

function onIceGeometryLoadStarted() {
  tracerService.startSpanAsChild(TracedOperations.GeometryLoading, TracedOperations.EventLoading);
}

function onIceMetricsEventReport(action) {
  const eventType = `ice-${action.payload.loadType}`;
  const spanName = `event.${_.camelCase(eventType)}`;
  tracerService.startSpanAsChild(spanName, TracedOperations.GeometryLoading, {
    startTime: new Date(action.payload.startTime),
    kind: spanName === 'event.iceRequestGeometry' ? SpanKind.SERVER : SpanKind.INTERNAL,
  });
  tracerService.finishSpan(spanName);
  if (spanName === 'event.iceCoreStartAnimation') {
    tracerService.finishSpan(TracedOperations.GeometryLoading);
  }
}

function onUpdateIcePlaces() {
  tracerService.startSpanAsChild(TracedOperations.IcePlaceDataUpdating, TracedOperations.EventLoading);
}

const actionHandlersMap = {
  [startLoadingCurrentEventPage.type]: onStartLoadingCurrentEventPage,
  [finishLoadingCurrentEventPage.type]: onFinishLoadingCurrentEventPage,

  [iceGeometryLoadStarted.type]: onIceGeometryLoadStarted,

  [iceMetricsEventReport.type]: onIceMetricsEventReport,

  [updateIcePlaces.type]: onUpdateIcePlaces,
};

export default function createTracingMiddleware() {
  return () => (next) => (action) => {
    if (actionHandlersMap[action?.type]) {
      actionHandlersMap[action.type](action);
    }

    return next(action);
  };
}
