import { formatIncompletePhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js/max';
export const US_COUNTRY_ID = 'US';
export const US_COUNTRY = { key: US_COUNTRY_ID, code: '1' };
export const DEFAULT_COUNTRY = { id: US_COUNTRY_ID, name: '+1', value: 'US', label: '+1' };
export const validatePhoneNumber = (phoneNumber, countryCode = DEFAULT_COUNTRY.id) => {
    if (!phoneNumber) {
        return false;
    }
    const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber, countryCode);
    return !!parsedPhoneNumber && parsedPhoneNumber.isValid();
};
export const normalizePhone = (value) => {
    if (!value) {
        return value;
    }
    if (/.*%$/.test(value)) {
        document.activeElement.blur();
    }
    const onlyNums = value.replace(/[^\d]/g, '');
    if (/^\(\d{3}$/.test(value)) {
        return onlyNums.substr(0, 2);
    }
    return onlyNums;
};
export const formatPhone = (value) => {
    if (value) {
        if (value.length === 10) {
            return formatIncompletePhoneNumber(value, 'US');
        }
        const phoneNumber = `+${value.toString().replace('+', '')}`;
        const data = value ? parsePhoneNumberFromString(phoneNumber) : null;
        if (!data) {
            return null;
        }
        const nationPhoneNumber = data.format('NATIONAL')[0] === '0' ? data.format('NATIONAL').replace('0', ' ') : data.format('NATIONAL');
        return `+${data.countryCallingCode}${nationPhoneNumber}`;
    }
    return null;
};
