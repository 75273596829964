import { createAction, createSlice } from '@reduxjs/toolkit';
export const initialState = {
    userInformation: null,
    isLoadingUserInformation: false,
    isUserUnauthorized: false,
};
const actionNamespace = 'user';
const userSlice = createSlice({
    name: actionNamespace,
    initialState,
    reducers: {
        fetchUserInformationLoading: (state) => ({
            ...state,
            userInformation: null,
            isLoadingUserInformation: true,
            isUserUnauthorized: false,
        }),
        fetchUserInformationSuccess: (state, { payload }) => ({
            ...state,
            userInformation: payload,
            isLoadingUserInformation: false,
            isUserUnauthorized: false,
        }),
        fetchUserInformationFailed: (state, { payload }) => ({
            ...state,
            userInformation: null,
            isLoadingUserInformation: false,
            isUserUnauthorized: payload.isUserUnauthorized,
        }),
    },
});
export const fetchUserArchticsIdFailed = createAction(`${actionNamespace}/fetchUserArchticsIdFailed`);
export const fetchHostPermissionsError = createAction(`${actionNamespace}/fetchHostPermissionsError`);
export const { fetchUserInformationSuccess, fetchUserInformationLoading, fetchUserInformationFailed } = userSlice.actions;
export const userReducer = userSlice.reducer;
