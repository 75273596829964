import { createSelector } from '@reduxjs/toolkit';
import {
  selectArchticsId,
  selectCanAccessSalesReport,
  selectOpcodes,
} from '@ticketmaster/tm1pos-web-shared/store/selectors/user-selector';
import { formValueSelector } from 'redux-form/immutable';
import { CARD_REPORT_ORDER } from '../../constants';

const reportFormSelector = formValueSelector('reportForm');

export const selectReportFormEmail = (state) => reportFormSelector(state, 'reportEmail');
export const selectIsLoadingReport = (state) => state.report.get('isLoadingReportData');
export const selectIsLoadingUpdatedReport = (state) => state.report.get('isLoadingReportUpdated');
export const selectIsSendingReportByEmail = (state) => state.report.get('isSendingEmailReport');
export const selectWasReportSent = (state) => state.report.get('wasReportSent');
export const selectReportEmailSentSuccessStatus = (state) => state.report.get('reportSentSuccessStatus');
export const selectReportStartDateTime = (state) => state.report.get('reportStartDateTime');
export const selectReportEndDateTime = (state) => state.report.get('reportEndDateTime');

export const selectReportRawData = (state) => state.report.get('reportData');
export const selectIsReportFailed = (state) => state.report.get('reportDataLoadFailed');

export const selectOperatorInventory = createSelector([selectReportRawData], (reportData) =>
  reportData && reportData.operator ? reportData.operator.id : '',
);

export const selectFirstOpcode = createSelector([selectOpcodes], (opcodes) => {
  if (opcodes) {
    return Object.values(opcodes)[0] || null;
  }
  return null;
});

export const selectInventoryForReport = createSelector(
  [selectFirstOpcode, selectArchticsId, selectOperatorInventory],
  (firstOpcode, archticsId, operatorInventory) => operatorInventory || archticsId || firstOpcode,
);

export const selectNoPermissionsForReport = createSelector(
  selectCanAccessSalesReport,
  (state) => state.report.get('noPermissionsForReport'),
  (canAccessSalesReport, noPermissionsForReport) => !canAccessSalesReport || noPermissionsForReport,
);

export const selectReportData = createSelector(
  selectReportRawData,
  selectNoPermissionsForReport,
  (data, noPermissions) => {
    if (!data) {
      return {
        grandTotal: null,
        cashTotal: null,
        card: null,
        other: null,
        noPermissions,
      };
    }
    const { grandTotal, paymentGroups } = data;
    const reportIsEmpty =
      grandTotal.total.amount === 0 && grandTotal.sale.amount === 0 && grandTotal.refund.amount === 0;

    const cashGroup = paymentGroups.find((g) => g.type === 'CASH');
    const cardGroup = paymentGroups.find((g) => g.type === 'CARD');
    const checkGroup = paymentGroups.find((g) => g.type === 'CHECK');
    const otherGroup = paymentGroups.find((g) => g.type === 'OTHER');

    cardGroup?.operations?.sort(
      (a, b) => CARD_REPORT_ORDER[a.type.toLowerCase()] - CARD_REPORT_ORDER[b.type.toLowerCase()],
    );

    return {
      grandTotal,
      cashTotal: cashGroup?.groupTotal,
      cardGroup,
      otherGroup,
      checkTotal: checkGroup?.groupTotal,
      reportIsEmpty,
      noPermissions,
    };
  },
);
