import { createSelector } from '@reduxjs/toolkit';
import { checkDefined } from '@ticketmaster/tm1pos-web-shared/utils';
import { isNotANumber } from '../../../utils/isNotANumber';
import { HOST_EVENT_TYPE } from '../constants';
import { DEFAULT_PRICE_LEVEL_OBJ, LEVELS_MAX_QUANTITY } from '../containers/PriceLevels/constants';
import { RANGES_MAX_QUANTITY } from '../containers/PriceRange/constants';
import { selectEventType } from './selectEventIds';
import {
  selectCurrentTicketType,
  selectCurrentTicketTypeList,
  selectPriceByTicketType,
  selectTicketTypes,
} from './ticketType';

export const selectActivePriceRanges = (state) => state.event.get('activePriceRanges');
export const selectActivePriceLevels = (state) => state.event.get('activePriceLevels');
export const selectFlatActivePriceRanges = createSelector(selectActivePriceRanges, (activePriceRanges) => {
  if (activePriceRanges && activePriceRanges.length) {
    return activePriceRanges.reduce((arr, curr) => arr.concat(curr), []);
  }
  return [];
});
export const selectCurrentTicketTypeListRates = createSelector(selectCurrentTicketTypeList, (currentTicketTypeList) => {
  if (checkDefined(currentTicketTypeList, ['rates', 'sort'])) {
    return currentTicketTypeList.rates.sort((a, b) => b.price.amount - a.price.amount);
  }
  return [];
});
export const selectCurrentTicketTypeListRateIdByAuditPriceLevel = createSelector(
  selectCurrentTicketTypeListRates,
  (currentTicketTypeRates) => {
    const auditPriceLevels = {};
    currentTicketTypeRates.forEach((item) => {
      if (checkDefined(item, ['priceLevels'])) {
        item.priceLevels.forEach((priceLevel) => {
          auditPriceLevels[priceLevel] = item.id;
        });
      }
    });
    return auditPriceLevels;
  },
);
export const selectBestAvailableModalPriceList = createSelector(
  selectCurrentTicketTypeListRates,
  selectActivePriceRanges,
  (rates, activePriceRanges) => {
    if (!activePriceRanges.length) {
      return rates;
    }
    let priceList = [];
    if (rates && rates.length) {
      activePriceRanges.forEach((ids) => {
        priceList = priceList.concat(ids.map((id) => rates.find((rate) => rate.id === id)));
      });
      priceList = priceList.sort((a, b) => b.price.amount - a.price.amount);
    }
    return priceList;
  },
);
export const priceRangeList = createSelector(selectCurrentTicketTypeListRates, (rates) => {
  let priceRange = [];
  const size = rates.length;
  if (size <= RANGES_MAX_QUANTITY) {
    priceRange = rates.map((item) => [item]);
  } else {
    const priceRates = rates.concat().reverse();
    const numberPerGroup = Math.floor(rates.length / RANGES_MAX_QUANTITY);
    for (let i = 0; i < RANGES_MAX_QUANTITY; i += 1) {
      const additionalAdd = priceRates.length % (RANGES_MAX_QUANTITY - i) > 0 ? 1 : 0;
      const endSlice = numberPerGroup + additionalAdd;
      priceRange[i] = priceRates.splice(0, endSlice);
    }
    priceRange.reverse();
  }
  return priceRange;
});

export const extendWithNumericId = (rate) => ({ ...rate, numericId: parseInt(rate.id, 10) });

export const selectUniqueRatesFromTicketTypes = (ticketTypes) =>
  ticketTypes
    .reduce((arr, tt) => arr.concat(tt.rates), [])
    .map(extendWithNumericId)
    .filter((value, index, self) => self.map((r) => r.id).indexOf(value.id) === index);

export const selectAllPriceRatesWithNumericIds = createSelector(selectTicketTypes, (ttList) => {
  if (ttList && ttList.length > 0) {
    return selectUniqueRatesFromTicketTypes(ttList);
  }
  return [];
});

export const selectEventPriceLevelList = createSelector(
  selectTicketTypes,
  selectCurrentTicketType,
  selectPriceByTicketType,
  (tt, currentTickettype, currentRates) => {
    if (currentRates.length === 0) {
      return [];
    }
    const current = currentRates.map(extendWithNumericId);
    const currentIds = currentRates.map((r) => r.id);

    const restTT = tt.filter((t) => t.id !== currentTickettype.id);
    const restRates = selectUniqueRatesFromTicketTypes(restTT)
      .filter((value) => currentIds.indexOf(value.id) === -1)
      .map((r) => ({
        ...r,
        price: DEFAULT_PRICE_LEVEL_OBJ.price,
        isNotAvailableInCurrentTicketType: true,
      }));
    const rates = current.concat(restRates).sort((a, b) => (a.numericId > b.numericId ? 1 : -1));
    // .map(generatePriceLevelDescription);

    if (rates.find((r) => isNotANumber(r.numericId))) {
      return rates.concat().sort((a, b) => (a.id > b.id ? 1 : -1));
    }

    return rates;
  },
);

export const selectPriceRangeDisplayMode = createSelector(
  selectAllPriceRatesWithNumericIds,
  selectEventType,
  (allRates, type) => {
    if (allRates.length === 0) {
      return null;
    }

    if (type === HOST_EVENT_TYPE) {
      return allRates.some((rate) => rate.priceLevels.some((pl) => parseInt(pl, 10) > LEVELS_MAX_QUANTITY));
    }

    const uniqRates = allRates.filter((value, index, self) => self.map((r) => r.id).indexOf(value.id) === index);
    return uniqRates.length > LEVELS_MAX_QUANTITY;
  },
);
