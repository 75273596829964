import { defineMessages, FormattedMessage } from 'react-intl';

const messages = defineMessages({
  checkoutCredit: {
    id: 'sales.Checkout.checkoutCredit',
    defaultMessage: 'Card',
  },
  checkoutCash: {
    id: 'sales.Checkout.checkoutCash',
    defaultMessage: 'Cash',
  },
  checkoutOther: {
    id: 'sales.Checkout.checkoutOther',
    defaultMessage: 'Other',
  },
  checkoutNow: {
    id: 'sales.Checkout.checkoutNow',
    defaultMessage: 'Checkout Now',
  },
  cancel: {
    id: 'sales.Checkout.cancel',
    defaultMessage: 'Cancel',
  },
  swipeCardNow: {
    id: 'sales.Checkout.swipeCardNow',
    defaultMessage: 'Swipe card to place order',
  },
  fillInfo: {
    id: 'sales.Checkout.fillInfo',
    defaultMessage: 'Complete required field before swiping',
  },
  authCode: {
    id: 'sales.Checkout.authCode',
    defaultMessage: 'Authorization Code',
  },
  orderConfirmationHeader: {
    id: 'sales.Checkout.orderConfirmationHeader',
    defaultMessage: 'Order Confirmation',
  },
  orderSummaryHeader: {
    id: 'sales.Checkout.orderSummaryHeader',
    defaultMessage: 'Order Summary',
  },
  requiredTag: {
    id: 'sales.Checkout.requiredTag',
    defaultMessage: '(required)',
  },
  fullNamePlaceholder: {
    id: 'sales.Checkout.fullNamePlaceholder',
    defaultMessage: 'Full Name',
  },
  phoneNumberPlaceholder: {
    id: 'sales.Checkout.phoneNumberPlaceholder',
    defaultMessage: 'Phone Number',
  },
  emailPlaceholder: {
    id: 'sales.Checkout.emailPlaceholder',
    defaultMessage: 'Email',
  },
  notePlaceholder: {
    id: 'sales.Checkout.notePlaceholder',
    defaultMessage: 'Notes',
  },
  otherPaymentsPlaceholder: {
    id: 'sales.Checkout.otherPaymentsPlaceholder',
    defaultMessage: 'Select a payment method',
  },
  otherPaymentsSearchPlaceholder: {
    id: 'sales.Checkout.otherPaymentsSearchPlaceholder',
    defaultMessage: 'Search for Payment Method',
  },
  paymentInfoPlaceholder: {
    id: 'sales.Checkout.paymentInfoPlaceholder',
    defaultMessage: 'Payment Info',
  },
  printTicketsPlaceholder: {
    id: 'sales.Checkout.printTicketsPlaceholder',
    defaultMessage: 'Print',
  },
  smsDeliveryPlaceholder: {
    id: 'sales.Checkout.smsDeliveryPlaceholder',
    defaultMessage: 'Text/SMS',
  },
  cardHolderNamePlaceholder: {
    id: 'sales.Checkout.cardHolderNamePlaceholder',
    defaultMessage: 'Name on Card',
  },
  cardNumberPlaceholder: {
    id: 'sales.Checkout.cardNumberPlaceholder',
    defaultMessage: 'Card Number',
  },
  cardPaymentNotConfigured: {
    id: 'sales.Checkout.cardPaymentNotConfigured',
    defaultMessage: 'Credit Card processing through Sales has not been configured. Contact TM Client Account Manager',
  },
  cardExpDatePlaceholder: {
    id: 'sales.Checkout.cardExpDatePlaceholder',
    defaultMessage: 'Exp. Date (mm/yy)',
  },
  manualEntryTogglePlaceholder: {
    id: 'sales.Checkout.manualEntryTogglePlaceholder',
    defaultMessage: 'Enter credit card info manually',
  },
  emvManualEntryPlaceholder: {
    id: 'sales.Checkout.emvManualEntryPlaceholder',
    defaultMessage: 'Enter credit card info manually on the payment device',
  },
  cartEmpty: {
    id: 'sales.Checkout.cartEmpty',
    defaultMessage: 'Cart is empty',
  },
  backToEvents: {
    id: 'sales.Checkout.backToEvents',
    defaultMessage: 'Back To Events',
  },
  processingOrder: {
    id: 'sales.Checkout.processingOrder',
    defaultMessage: 'Processing Order...',
  },
  cancelingOrder: {
    id: 'sales.Checkout.cancelingOrder',
    defaultMessage: 'Canceling Order...',
  },
  cancelingOrderDescription: {
    id: 'sales.Checkout.cancelingOrderDescription',
    defaultMessage: 'Please do not leave this page until the cancellation is complete.',
  },
  revertingTransaction: {
    id: 'sales.Checkout.revertingTransaction',
    defaultMessage: 'Reverting Transaction...',
  },
  revertingTransactionDescription: {
    id: 'sales.Checkout.revertingTransactionDescription',
    defaultMessage:
      'Our system encountered an error while processing the request.\nThe transaction needs to be reverted.\nDo not leave this page.',
  },
  processingPaymentDoNotClose: {
    id: 'sales.Checkout.processingPaymentDoNotClose',
    defaultMessage: 'Please do not leave this page until the transaction is complete.',
  },
  printReceipts: {
    id: 'sales.Checkout.printReceipts',
    defaultMessage: 'Print receipt',
  },
  accountNumberValidationError: {
    id: 'sales.Checkout.accountNumberValidationError',
    defaultMessage: 'Please enter a valid credit card number.',
  },
  expDateValidationError: {
    id: 'sales.Checkout.expDateValidationError',
    defaultMessage: 'Please enter a valid exp. date (mm/yy).',
  },
  phoneNumberValidationError: {
    id: 'sales.Checkout.phoneNumberValidationError',
    defaultMessage: 'Please enter a valid 10-digit phone number',
  },
  phoneNumberValidationErrorNonUS: {
    id: 'sales.Checkout.phoneNumberValidationErrorNonUS',
    defaultMessage: 'Please enter a valid phone number',
  },
  notEnoughCashError: {
    id: 'sales.Checkout.notEnoughCashError',
    defaultMessage: 'Please enter an amount above the total cost',
  },
  checkoutPaymentErrMsg: {
    id: 'sales.Checkout.checkoutPaymentErrMsg',
    defaultMessage: 'Payment has failed for order #{orderId}. Try again to complete this order.',
  },
  paymentInfoLimitMsg: {
    id: 'sales.Checkout.paymentInfoLimitMsg',
    defaultMessage: 'Maximum character limit reached',
  },
  paymentInfoMax10Chars: {
    id: 'sales.Checkout.paymentInfoMax10Chars',
    defaultMessage: 'Please provide up to 10 alphanumeric characters.',
  },
  paymentInfo12Digits: {
    id: 'sales.Checkout.paymentInfo12Digits',
    defaultMessage: 'Please provide 12 digits',
  },
  paymentInfoMaxValue: {
    id: 'sales.Checkout.paymentInfoMaxValue',
    defaultMessage: 'Max. {maxValue}',
  },
  paymentInfoRequired: {
    id: 'sales.Checkout.paymentInfoRequired',
    defaultMessage: 'Required',
  },
  noPaymentMethodsFound: {
    id: 'sales.Checkout.noPaymentMethodsFound',
    defaultMessage: 'No payment methods found for {searchValue}',
  },
  payment: {
    id: 'sales.Checkout.payment',
    defaultMessage: 'Payment',
  },
  ticketDeliveryAndReceipt: {
    id: 'sales.Checkout.ticketDeliveryAndReceipt',
    defaultMessage: 'Delivery',
  },
  addAnotherPayment: {
    id: 'sales.Checkout.addAnotherPayment',
    defaultMessage: '+ Add Another Payment',
  },
  remainingBalance: {
    id: 'sales.Checkout.remainingBalance',
    defaultMessage: 'Remaining Balance',
  },
  savePayment: {
    id: 'sales.Checkout.savePayment',
    defaultMessage: 'Save Payment',
  },
  swipeCardAcceptPayment: {
    id: 'sales.Checkout.swipeCardAcceptPayment',
    defaultMessage: 'Swipe Card for Payment',
  },
  completeCheckout: {
    id: 'sales.Checkout.completeCheckout',
    defaultMessage: 'Complete Checkout',
  },
  cardTypeSelectorPlaceholder: {
    id: 'sales.Checkout.cardTypeSelectorPlaceholder',
    defaultMessage: 'Select a credit card type',
  },
  failed: {
    id: 'sales.Checkout.failed',
    defaultMessage: 'Failed',
  },
  cancellingPayment: {
    id: 'sales.Checkout.cancellingPayment',
    defaultMessage: 'Cancelling Payment...',
  },
  cancellingCart: {
    id: 'sales.Checkout.cancellingCart',
    defaultMessage: 'cancelling your cart',
  },
  backToEvent: {
    id: 'sales.Checkout.backToEvent',
    defaultMessage: 'backing to event',
  },
  reservingYourSeats: {
    id: 'sales.Checkout.reservingYourSeats',
    defaultMessage: 'Loading Checkout',
  },
  selectPrinter: {
    id: 'sales.Checkout.selectPrinter',
    defaultMessage: 'Select a printer (required)',
  },
  incompleteCheckoutUnknownError: {
    id: 'sales.Checkout.error.incompleteCheckout.unknownError',
    defaultMessage: 'Something went wrong.',
  },
  authorizationCodeDigitsOnly: {
    id: 'sales.Checkout.error.authorizationCode.digitsOnly',
    defaultMessage: 'Please provide 9 digits',
  },
  authorizationCodeMaximumLength: {
    id: 'sales.Checkout.error.authorizationCode.maximumLength',
    defaultMessage: 'Maximum character limit reached',
  },
  authorizationCodeMaximumNine: {
    id: 'sales.Checkout.error.authorizationCode.maximumNine',
    defaultMessage: 'Max.9',
  },
  emailNotValid: {
    id: 'sales.Checkout.error.email.notValild',
    defaultMessage: 'Please enter a valid email address.',
  },
  required: {
    id: 'sales.Checkout.required',
    defaultMessage: 'Required',
  },
  createGlobalAccount: {
    id: 'sales.GlobalAccount.createGlobalAccount',
    defaultMessage: 'Create a Ticketmaster Account',
  },
  checkoutPix: {
    id: 'sales.Checkout.checkoutPix',
    defaultMessage: 'Pix',
  },
});

const isValidKey = (key: string): key is keyof typeof messages => key in messages;

export const getMessageByKey = (key: string): FormattedMessage.MessageDescriptor | undefined =>
  isValidKey(key) ? messages[key] : undefined;

export default messages;
