import { getUserAttributes } from './tracing-attribute-utils';
export class UserInformationSpanProcessor {
    constructor() {
        this.userInformation = undefined;
    }
    forceFlush() {
        return Promise.resolve(undefined);
    }
    onStart(span, parentContext) {
        if (this.userInformation) {
            span.setAttributes({
                ...getUserAttributes(this.userInformation),
            });
        }
    }
    onEnd(span) { }
    shutdown() {
        return Promise.resolve(undefined);
    }
    setUserInformation(userInformation) {
        this.userInformation = userInformation;
    }
}
