import { defineMessages } from 'react-intl';
export default defineMessages({
    emvWaitingForCustomerPayment: {
        id: 'shared.CustomersPage.emvWaitingForCustomerPayment',
        defaultMessage: 'Waiting for customer payment...',
    },
    emvDoNotLeaveWarning: {
        id: 'shared.CustomersPage.emvDoNotLeaveWarning',
        defaultMessage: 'Please do not leave this page until the transaction is complete.',
    },
    cancelPaymentInProgress: {
        id: 'shared.CustomersPage.cancelPaymentInProgress',
        defaultMessage: 'Cancelling...',
    },
    emvCancel: {
        id: 'shared.CustomersPage.cancelPayment',
        defaultMessage: 'Cancel Payment',
    },
});
