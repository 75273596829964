export class TicketTypesByOfferSetAndRate {
  map: Record<`${string},${string}`, string[]> = {};

  add(offerSet: string, rate: string, ticketTypeId: string) {
    let ticketTypeIds = this.get(offerSet, rate);

    if (!ticketTypeIds) {
      ticketTypeIds = [];
      this.set(offerSet, rate, ticketTypeIds);
    }

    ticketTypeIds.push(ticketTypeId);
  }

  set(offerSet: string, rate: string, ticketTypes: string[]) {
    this.map[`${offerSet},${rate}`] = ticketTypes;
  }

  get(offerSet: string, rate: string) {
    return this.map[`${offerSet},${rate}`];
  }
}
