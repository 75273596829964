import { CARD } from '../../constants';

function isEMVReady(isPaymentDeviceConnected, emvSetupError) {
  return isPaymentDeviceConnected && !emvSetupError;
}

export function isSwipeable(
  paymentMethod,
  manualCardEntry,
  isPaymentDeviceConnected,
  emvSetupError,
  cardPaymentEnabled,
) {
  return (
    paymentMethod === CARD &&
    cardPaymentEnabled &&
    !manualCardEntry &&
    !isEMVReady(isPaymentDeviceConnected, emvSetupError)
  );
}
