import { OPEN_ERROR_MODAL, SHOW_ERROR_NOTIFICATION } from '@ticketmaster/tm1pos-web-shared/errorHandling/actions';
import {
  fetchHostPermissionsError,
  fetchUserArchticsIdFailed,
} from '@ticketmaster/tm1pos-web-shared/store/user-information/user.slice';
import { MULTIPLE_DATASOURCE_FROM_OPCODES_ERROR } from '../../containers/App/actions-constants';
import { TMDS_PRINTER_ERROR_NOTIFICATION } from '../../containers/App/constants';
import {
  CREDIT_CARD_SWIPE_ERROR,
  DELIVERY_ERROR,
  DELIVERY_ERROR_TYPES,
} from '../../containers/Checkout/components/Form/actions-constants';
import {
  ARCHTICS_EVENT_SEAT_STATUS_FETCH,
  CART_HAS_EXPIRED_INVENTORY_MASK,
  CART_IN_WRONG_STATE_ERROR,
  CART_SPLIT_ORDER_NOT_SUPPORTED_ERROR,
  INITIAL_ARCHTICS_EVENT_SEAT_STATUS_FETCH,
  NO_SEATS_AVAILABLE,
  NO_TICKETTYPES_AVAILABLE,
  PLACE_TO_RESERVE_FETCH,
  TICKET_TYPE_NOT_AVAILABLE_ERROR,
  UNSUPPORTED_EVENT,
  UNSUPPORTED_EVENT_ERRORS,
} from '../../containers/EventDetailPage/constants';

import {
  cartSplitOrderNotSupportedErrorContent,
  cartWrongStateErrorContent,
  companionSeatAlone,
  edpErrorContent,
  emailErrorContent,
  fetchRefundPermissionFailedContent,
  multipleDataSourcesPermissionFailedContent,
  noBarcodeDeliveryFailErrorContent,
  noTicketTypesAvailableErrorContent,
  printingErrorContent,
  smsDeliveryFailErrorContent,
  swipeCardFailContent,
  ticketTypeNotAvailableErrorContent,
  unsupportedEventErrorContent,
} from './errorModalContentConfig';
import messages from './messages';

/**
  errorActionCreator constructs error action depending on what failed event it received.
  Its the only place where we know how should display every error from backend on the ui.
*/
// eslint-disable-next-line sonarjs/cognitive-complexity
const errorActionCreator = (failedAction) => {
  let errorAction = { type: '' };
  const timestamp = Date.now(); // need timestamp to distinguish series of error events
  const showEdpModal = failedAction.type === INITIAL_ARCHTICS_EVENT_SEAT_STATUS_FETCH.ERROR;
  if (failedAction.type === PLACE_TO_RESERVE_FETCH.ERROR) {
    if (failedAction.errorType === 'COMPANION_SEAT_CANNOT_BE_RESERVED_ALONE') {
      errorAction = {
        type: OPEN_ERROR_MODAL,
        content: companionSeatAlone,
      };
    } else if (failedAction.errorType === CART_HAS_EXPIRED_INVENTORY_MASK) {
      errorAction = {
        type: SHOW_ERROR_NOTIFICATION,
        notification: {
          failedActionName: `${failedAction.type}_${timestamp}`,
          message: messages.addSeatToCartExpired,
          isEdp: true,
        },
      };
    } else {
      errorAction = {
        type: SHOW_ERROR_NOTIFICATION,
        notification: {
          failedActionName: `${failedAction.type}_${timestamp}`,
          message: messages.addSeatToCartErr,
          isEdp: true,
        },
      };
    }
  } else if (failedAction.type === CREDIT_CARD_SWIPE_ERROR) {
    errorAction = {
      type: OPEN_ERROR_MODAL,
      content: swipeCardFailContent,
    };
  } else if (failedAction.type === ARCHTICS_EVENT_SEAT_STATUS_FETCH.ERROR) {
    errorAction = {
      type: SHOW_ERROR_NOTIFICATION,
      notification: {
        failedActionName: `${failedAction.type}_${timestamp}`,
        message: messages.ismMapUpdateFail,
        isEdp: true,
      },
    };
  } else if (fetchUserArchticsIdFailed.match(failedAction)) {
    errorAction = {
      type: SHOW_ERROR_NOTIFICATION,
      notification: {
        failedActionName: `${failedAction.type}`,
        message: messages.archticsIdFailMessage,
        tip: messages.contactAdministratorTip,
      },
      isRootNotice: true,
    };
  } else if (failedAction.type === NO_SEATS_AVAILABLE) {
    errorAction = {
      type: SHOW_ERROR_NOTIFICATION,
      notification: {
        failedActionName: `${failedAction.type}`,
        message: messages.noSeatsAvailableMessage,
        tip: messages.noSeatsAvailableTip,
      },
    };
  } else if (failedAction.type === UNSUPPORTED_EVENT) {
    errorAction = getErrorActionForUnsupportedEvent(failedAction);
  } else if (failedAction.type === NO_TICKETTYPES_AVAILABLE) {
    errorAction = {
      type: OPEN_ERROR_MODAL,
      content: noTicketTypesAvailableErrorContent,
    };
  } else if (failedAction.type === TICKET_TYPE_NOT_AVAILABLE_ERROR) {
    errorAction = {
      type: OPEN_ERROR_MODAL,
      content: ticketTypeNotAvailableErrorContent,
    };
  } else if (failedAction.type === CART_IN_WRONG_STATE_ERROR) {
    errorAction = {
      type: OPEN_ERROR_MODAL,
      content: cartWrongStateErrorContent,
    };
  } else if (failedAction.type === CART_SPLIT_ORDER_NOT_SUPPORTED_ERROR) {
    return {
      type: OPEN_ERROR_MODAL,
      content: cartSplitOrderNotSupportedErrorContent(failedAction.ticketQuantity, failedAction.reservedTicketQuantity),
    };
  } else if (failedAction.type === TMDS_PRINTER_ERROR_NOTIFICATION) {
    errorAction = {
      type: SHOW_ERROR_NOTIFICATION,
      notification: {
        failedActionName: `${failedAction.type}`,
        message: failedAction.title,
        tip: failedAction.suggestion,
      },
      isRootNotice: true,
    };
  } else if (failedAction.type === DELIVERY_ERROR) {
    errorAction = getErrorActionForDeliveryError(failedAction);
  } else if (failedAction.type === fetchHostPermissionsError.type) {
    errorAction = {
      type: OPEN_ERROR_MODAL,
      content: fetchRefundPermissionFailedContent,
    };
  } else if (failedAction.type === MULTIPLE_DATASOURCE_FROM_OPCODES_ERROR) {
    errorAction = {
      type: OPEN_ERROR_MODAL,
      content: multipleDataSourcesPermissionFailedContent,
    };
  } else if (showEdpModal) {
    errorAction = {
      type: OPEN_ERROR_MODAL,
      content: edpErrorContent,
    };
  }

  return errorAction;
};

const getErrorActionForUnsupportedEvent = (failedAction) => {
  let tipMessage;
  switch (failedAction.errorType) {
    case UNSUPPORTED_EVENT_ERRORS.DUPLICATE_STATE:
      tipMessage = messages.unsupportedEventDuplicateQualifierTipDetails;
      break;
    case UNSUPPORTED_EVENT_ERRORS.GRANULAR_PRICING:
      tipMessage = messages.unsupportedEventGranularPricingTipDetails;
      break;
    case UNSUPPORTED_EVENT_ERRORS.TRACKING_EVENT:
      tipMessage = messages.unsupportedEventTrackingEventTypeTipDetails;
      break;
    default:
      tipMessage = {
        id: 'sales.errors.unsupportedEventDefaultTip',
        defaultMessage: failedAction.errorType,
      };
  }
  return {
    type: OPEN_ERROR_MODAL,
    content: unsupportedEventErrorContent(tipMessage),
  };
};

const getErrorActionForDeliveryError = (failedAction) => {
  let content;
  switch (failedAction.errorType) {
    case DELIVERY_ERROR_TYPES.NO_BARCODE_TICKETS_ERROR:
      content = noBarcodeDeliveryFailErrorContent(failedAction.deliveryMethod, failedAction.cid);
      break;
    case DELIVERY_ERROR_TYPES.SMS_ERROR:
      content = smsDeliveryFailErrorContent(failedAction.cid);
      break;
    case DELIVERY_ERROR_TYPES.EMAIL_ERROR:
      content = emailErrorContent(failedAction.cid);
      break;
    case DELIVERY_ERROR_TYPES.PRINT_ERROR:
    default:
      content = printingErrorContent(failedAction.cid);
  }
  return {
    type: OPEN_ERROR_MODAL,
    content,
  };
};

export default errorActionCreator;
