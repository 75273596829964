import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { IconCaution, IconClose } from '../icons';
import './styles.scss';
class ErrorNotification extends Component {
    constructor() {
        super();
        this.handleSetTimer = () => {
            if (this.props.clickHandler) {
                return this.props.clickHandler();
            }
            return null;
        };
        this.state = {
            timeout: null,
        };
        this.setTimer = this.setTimer.bind(this);
    }
    componentDidMount() {
        this.setTimer();
    }
    componentWillUnmount() {
        clearTimeout(this.state.timeout);
    }
    setTimer() {
        const timeout = setTimeout(this.handleSetTimer, this.props.delay || 3000);
        this.setState({ timeout });
        return timeout;
    }
    render() {
        if (!this.props.message) {
            return null;
        }
        const notificationClass = classNames('sdr-error-notification', this.props.className);
        return (_jsxs("div", { className: notificationClass, children: [_jsx("div", { className: "sdr-error-notification__icon", children: _jsx(IconCaution, {}) }), _jsxs("div", { className: "sdr-error-notification__content", children: [_jsx("div", { className: "sdr-error-notification__message", children: _jsx(FormattedMessage, { ...this.props.message }) }), this.props.tip ? (_jsx("div", { className: "sdr-error-notification__tip", children: _jsx(FormattedMessage, { ...this.props.tip }) })) : null] }), _jsx("div", { className: "sdr-error-notification__action", children: _jsx("a", { role: "button", onClick: this.props.clickHandler, tabIndex: -1, className: "sdr-error-notification__close", children: _jsx(IconClose, {}) }) })] }));
    }
}
ErrorNotification.propTypes = {
    clickHandler: PropTypes.func.isRequired,
    message: PropTypes.object.isRequired,
    tip: PropTypes.object,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    delay: PropTypes.number.isRequired,
};
export default ErrorNotification;
