import { sortInventory } from '@ticketmaster/tm1pos-web-shared/utils';
import { InjectedIntl } from 'react-intl';
import messages from './messages';
import { InventoryType } from './selectors/main';

export type InventoryTypeDisplay = {
  id: string;
  name: string;
  sellType: InventoryType;
};

export const formatInventoryType =
  (intl: InjectedIntl) =>
  (inventoryTypeList: InventoryType[] | null): InventoryTypeDisplay[] => {
    if (!inventoryTypeList) {
      return [];
    }

    const formattedInventoryType = inventoryTypeList.map((it) => {
      let name;
      if (it.parent) {
        if (it.isAccessible) {
          name = intl.formatMessage(messages.inventoryTypeQualifiedHoldLabelAccessible, {
            name: `${it.parent} - ${it.name}`,
          });
        } else {
          name = `${it.parent} - ${it.name}`;
        }
      } else {
        name = it.name;
      }

      return {
        id: it.id,
        name,
        sellType: it,
      };
    });

    return sortInventory(formattedInventoryType);
  };

export const getSellTypeFromDisplayName = (
  inventoryType: InventoryTypeDisplay[],
  displayName: string | null,
): InventoryType | null => {
  const maybeInventoryType = inventoryType.find((it) => it.name === displayName);

  return maybeInventoryType ? maybeInventoryType.sellType : null;
};

export const getSelectedDisplayName = (
  inventoryType: InventoryTypeDisplay[],
  selectedInventoryType: InventoryType | null,
): string | null => {
  if (!selectedInventoryType) {
    return null;
  }

  const maybeSelectedInventoryTypeDisplay = inventoryType.find((it) => it.id === selectedInventoryType?.id);

  return maybeSelectedInventoryTypeDisplay ? maybeSelectedInventoryTypeDisplay.name : null;
};
