import CopyBox from '@ticketmaster/tm1pos-web-shared/components/CopyBox';
import { Tm1Button, Tm1Divider } from '@ticketmaster/tm1pos-web-shared/components/v2';
import _ from 'lodash';
import { useState } from 'react';
import { FormattedHTMLMessage, FormattedMessage, InjectedIntl, injectIntl } from 'react-intl';
import messages from './reverse-transaction-messages';
import './styles.scss';

type InjectedProps = {
  intl: InjectedIntl;
};

type Status = 'SUCCESS' | 'FAILURE';

type Error = {
  code: string;
  message?: string;
};

export type Props = {
  status: Status;
  error?: Error;
  payment: {
    tm1Username?: string;
    transactionId?: string;
    last4?: string;
    amount?: string;
  };
};

export const ReverseTransactionResult = ({ status, error, payment, intl }: Props & InjectedProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const safeLine = (message: FormattedMessage.MessageDescriptor, value: any) =>
    _.isNil(value) || (
      <div className="copy-box__line">
        <FormattedMessage {...message} /> {value}
      </div>
    );
  const safePush = (lines: string[], message: FormattedMessage.MessageDescriptor, value: any) => {
    if (!_.isNil(value)) {
      lines.push(`${intl.formatMessage(message)} ${value}`);
    }
  };

  const isFailure = (_status: Status, _error?: Error): _error is Error => _status === 'FAILURE';

  if (isFailure(status, error)) {
    const { code, message } = error;

    const copyLines: string[] = [];
    safePush(copyLines, messages.tm1Username, payment.tm1Username);
    safePush(copyLines, messages.transactionId, payment.transactionId);
    safePush(copyLines, messages.last4, payment.last4);
    safePush(copyLines, messages.amount, payment.amount);
    const textToCopy = copyLines.reduce(
      (previous, current) => `${previous}\n${current}`,
      `${intl.formatMessage(messages.errorCode, { code })} ${message ?? ''}`,
    );

    return (
      <div>
        <div className="divider">
          <Tm1Divider orientation="horizontal" />
        </div>
        <div className="error-message__content">
          <FormattedHTMLMessage {...messages.introduction} />
          <FormattedHTMLMessage {...messages.note} />
          <CopyBox {...{ textToCopy }}>
            <div className="copy-box__header">
              <FormattedMessage {...messages.errorCode} values={{ code }} />
            </div>
            {_.isNil(message) || <span>{message}</span>}
            {expanded && (
              <>
                <br />
                <div className="copy-box__header">
                  <FormattedMessage {...messages.paymentInformation} />
                </div>
                {safeLine(messages.tm1Username, payment.tm1Username)}
                {safeLine(messages.transactionId, payment.transactionId)}
                {safeLine(messages.last4, payment.last4)}
                {safeLine(messages.amount, payment.amount)}
              </>
            )}
            <div>
              <Tm1Button variant="lightweight" onClick={() => setExpanded(!expanded)}>
                <FormattedMessage {...(expanded ? messages.showLess : messages.showMore)} />
              </Tm1Button>
            </div>
          </CopyBox>
        </div>
      </div>
    );
  }
  return null;
};

export default injectIntl(ReverseTransactionResult);
