import { gql } from '@apollo/client';
import { asString } from '@ticketmaster/tm1pos-web-shared/utils/graphQL/graphql-utils';

const placeFragment = asString(gql`
  fragment PlaceParts on Place {
    id
    version
    ticketType {
      status
      hold
      statusName
      encoded
      offerSets {
        id
        restricted
      }
    }
    priceZone
    sectionName
    generalAdmission
    attributes {
      id
      category
    }
  }
`);

export const HOST_ISM_SNAPSHOT = asString(gql`
  ${placeFragment}
  query Places($eventId: String!, $token: String!) {
    places(eventId: $eventId, token: $token) {
      ...PlaceParts
    }
  }
`);

export const ISMRT_PLACE_UPDATE_QUERY = gql`
  ${placeFragment}
  subscription onPlaceUpdate($eventId: String!) {
    update(eventId: $eventId) {
      eventId
      seat {
        ...PlaceParts
      }
    }
  }
`;
