import { useSelector } from 'react-redux';
import { selectLinkGlobalAccount } from '../../selectors';
import GlobalAccount from './GlobalAccount';

const GlobalAccountConnected = () => {
  const linkGlobalAccount = useSelector(selectLinkGlobalAccount);
  return <GlobalAccount linkGlobalAccount={linkGlobalAccount} />;
};
GlobalAccountConnected.displayName = 'GlobalAccountConnected';
export default GlobalAccountConnected;
