/* eslint-disable react/jsx-no-useless-fragment */

import LoadingComponent from '@ticketmaster/tm1pos-web-shared/components/LoadingComponent';
import { LogComponent, LogStatus } from '@ticketmaster/tm1pos-web-shared/errorHandling/constants';
import { logClientToCloud } from '@ticketmaster/tm1pos-web-shared/store/actions';
import { PropsWithChildren, useEffect, useState } from 'react';
import { installImportMap } from '../../services/tm1Api/install-importmap';
import { useAppDispatch } from '../../store/hooks';

export type Tm1ImportMapLoaderProps = PropsWithChildren<unknown>;

export const Tm1ImportMapLoader = ({ children }: Tm1ImportMapLoaderProps) => {
  const dispatch = useAppDispatch();

  const [isImportMapInstalled, setIsImportMapInstalled] = useState(false);
  const [errorCaughtLoadingImportMap, setErrorCaughtLoadingImportMap] = useState<Error | null>(null);

  useEffect(() => {
    installImportMap()
      .then(() => {
        setIsImportMapInstalled(true);
      })
      .catch((e) => {
        setErrorCaughtLoadingImportMap(e);
      });
  }, []);

  if (isImportMapInstalled) {
    return <>{children}</>;
  }

  if (errorCaughtLoadingImportMap) {
    // TODO: We'll begin with monitoring the importmap load errors and won't block the app loading process.
    console.error('Could not load TM1 ImportMap');
    dispatch(
      logClientToCloud({
        data: { error: { message: 'Tm1ImportMapLoader: Could not load TM1 ImportMap' } },
        type: LogComponent.APP,
        status: LogStatus.ERROR,
      }),
    );

    return <>{children}</>;
  }

  return <LoadingComponent largeGear />;
};
