import { put } from 'redux-saga/effects';
import { setEmvMode, setEmvSupported } from './emvPaymentModule-slice';
import { EMVService } from './EMVService';
class NoopPaymentModule {
    *isAvailable() { }
    *initialize() {
        yield put(setEmvMode(EMVService.None));
        yield put(setEmvSupported(false));
    }
    *processTransaction() { }
    *acknowledgeTransaction() { }
    *reverseTransaction() { }
    *cancelTransaction() { }
    *fetchPaymentDevices() { }
    *pollTransactionStatus() { }
    *clearTransaction() { }
}
export default new NoopPaymentModule();
