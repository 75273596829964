import { call, select } from 'redux-saga/effects';
import { EVENTS_SERVICE_URL } from '../../constants';
import { dateEntriesModifier } from '../../store/events/utils';
import { selectLanguage } from '../../translations';
import { SEARCH_EVENTS_QUERY } from './api-queries';
import { fetchFromSalesApi } from './sales-api';
export function* findEvents(startDate, endDate) {
    const result = yield call(fetchFromSalesApi, {
        url: EVENTS_SERVICE_URL,
        query: SEARCH_EVENTS_QUERY,
        options: {
            variables: {
                startDateTime: startDate,
                endDateTime: endDate,
            },
        },
    });
    if (result.data) {
        const locale = yield select(selectLanguage);
        return result.data.eventsV2.map((event) => dateEntriesModifier(event, locale));
    }
    throw new Error('Could not fetch events list.');
}
