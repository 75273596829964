import { TIMEOUT_DELAY } from '../constants';
import { START_TIMEOUT, USER_TIMEOUT } from '../store/actions-constants';
let timeout = 0;
const isNoAction = (action) => !action || !action.type;
const isStartTimeoutAction = (action) => action.type === START_TIMEOUT;
const isUserTimeoutAction = (action) => action.type === USER_TIMEOUT;
const isTimeoutActive = () => timeout !== 0;
export { timeout };
export default function createTimeoutMiddleware() {
    return (store) => (next) => (action) => {
        if (isNoAction(action)) {
        }
        else if (isStartTimeoutAction(action)) {
            timeout = action.time;
        }
        else if (isUserTimeoutAction(action)) {
            timeout = 0;
        }
        else if (isTimeoutActive()) {
            const now = Date.now();
            const timedOut = now - timeout >= TIMEOUT_DELAY;
            if (timedOut) {
                return store.dispatch({ type: USER_TIMEOUT });
            }
            timeout = now;
        }
        return next(action);
    };
}
