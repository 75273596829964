import { createSelector } from '@reduxjs/toolkit';
import { renderCheckboxComponent } from '@ticketmaster/tm1pos-web-shared/components/Checkbox';
import { renderDropdownComponent } from '@ticketmaster/tm1pos-web-shared/components/Dropdown';
import { PRINT } from '@ticketmaster/tm1pos-web-shared/constants';
import EditPrinterModal from '@ticketmaster/tm1pos-web-shared/printing/Configuration/Modal';
import { selectPrintingModuleEnabled } from '@ticketmaster/tm1pos-web-shared/printing/printingModule-selectors';
import { initializePrintingModule } from '@ticketmaster/tm1pos-web-shared/printing/printingModule-slice';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { Field } from 'redux-form/immutable';
import printerDropdownStatus from '../../../../../App/components/PrinterDropdownStatus';
import messages from '../../../../messages';
import './styles.scss';
import { normalizeFn } from '../../utils';
import PrintControl from '../PrintControl';

export class PrintReceipt extends Component {
  static propTypes = {
    active: PropTypes.bool,
    quantity: PropTypes.object,
    deliveryMethod: PropTypes.string,
    printerList: PropTypes.array,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    intl: intlShape.isRequired,
    dispatch: PropTypes.func,
    printingModuleEnabled: PropTypes.bool,
  };

  static printerQuantityProvider = [
    {
      label: '0',
      value: '0',
    },
    {
      label: '1',
      value: '1',
    },
    {
      label: '2',
      value: '2',
    },
  ];

  constructor(props) {
    super(props);
    this.state = {
      showEdit: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(initializePrintingModule());
  }

  valueIsNotEmptyAndNoErrors = (value) => {
    const val = value && value.toJS ? value.toJS() : value;
    if (val && val.hasError) {
      return true;
    }
    return val ? undefined : this.props.intl.formatMessage(messages.required);
  };

  render() {
    const { deliveryMethod, active, quantity, printerList, printingModuleEnabled } = this.props;
    const classes = classnames({
      'sdr-checkout__print-receipt': true,
      'sdr-checkout__print-receipt--active': active,
    });
    const showPrinterField = deliveryMethod === PRINT || (active && parseInt(quantity.value, 10) > 0);
    const receiptControl = (
      <div className={classes} key="receipt-control">
        <Field
          className="oc-toggle-switch--small"
          checked={this.props.active}
          component={renderCheckboxComponent}
          id="printReceiptToggle"
          name="printReceiptToggle"
          normalize={normalizeFn}
          text={this.props.label}
        />
        {this.props.active ? (
          <Field
            component={renderDropdownComponent}
            name="printReceiptCount"
            selection={quantity || PrintReceipt.printerQuantityProvider[1]}
            dataProvider={PrintReceipt.printerQuantityProvider}
            emptySelection={PrintReceipt.printerQuantityProvider[1].label}
          />
        ) : null}
      </div>
    );
    const editModal = this.state.showEdit ? (
      <EditPrinterModal onClose={() => this.setState({ showEdit: false })} />
    ) : null;

    let printerControl;
    if (showPrinterField) {
      printerControl = printingModuleEnabled ? (
        <PrintControl />
      ) : (
        <Field
          key="print-control"
          component={renderDropdownComponent}
          hoc={printerDropdownStatus}
          name="printer"
          dataProvider={printerList}
          emptySelection={this.props.intl.formatMessage(messages.selectPrinter)}
          validate={this.valueIsNotEmptyAndNoErrors}
        />
      );
    } else {
      printerControl = null;
    }

    return [receiptControl, printerControl, editModal];
  }
}

const mapStateToProps = createSelector(selectPrintingModuleEnabled, (printingModuleEnabled) => ({
  printingModuleEnabled,
}));
const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PrintReceipt));
