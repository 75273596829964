import { context, trace } from '@opentelemetry/api';
export const TracedOperations = {
    EventLoading: 'event.eventDetailsLoading',
    GeometryLoading: 'event.geometryLoading',
    IcePlaceDataUpdating: 'event.icePlaceDataUpdating',
};
const QueryToTracedOperations = new Map([
    ['ismAvailability', [TracedOperations.EventLoading]],
    ['Places', [TracedOperations.EventLoading]],
    ['getEventDetails', [TracedOperations.EventLoading]],
    ['adaTypes', [TracedOperations.EventLoading]],
    ['aapiAvailability', [TracedOperations.EventLoading]],
]);
export class TracerService {
    constructor() {
        this.spans = new Map();
        this.tracer = trace.getTracer('sales_ui');
    }
    startSpan(name, options, spanContext) {
        const span = this.tracer.startSpan(name, options, spanContext);
        this.spans.set(name, span);
        return span;
    }
    finishSpan(name, attributes) {
        if (!this.spans.has(name)) {
            console.debug(`%cCould not close span "${name}": NOT FOUND`, 'color: orange');
            return;
        }
        const span = this.spans.get(name);
        if (attributes) {
            span === null || span === void 0 ? void 0 : span.setAttributes(attributes);
        }
        span === null || span === void 0 ? void 0 : span.end();
        this.spans.delete(name);
    }
    getSpan(name) {
        return this.spans.has(name) ? this.spans.get(name) : undefined;
    }
    getSpanForQuery(query) {
        const keys = [...QueryToTracedOperations.keys()];
        const allSpans = QueryToTracedOperations.get(keys.filter((key) => query.includes(key))[0]);
        const currentSpan = allSpans === null || allSpans === void 0 ? void 0 : allSpans.filter((span) => this.spans.get(span));
        if (currentSpan === null || currentSpan === void 0 ? void 0 : currentSpan.length) {
            return this.spans.get(currentSpan[0]);
        }
        return undefined;
    }
    getContextForTracedOperation(tracedOperation) {
        const span = this.getSpan(tracedOperation);
        if (span) {
            return trace.setSpan(context.active(), span);
        }
        return undefined;
    }
    startSpanAsChild(tracedOperation, parentOperation, options) {
        const traceOperationContext = this.getContextForTracedOperation(parentOperation);
        return this.startSpan(tracedOperation, options, traceOperationContext);
    }
}
export const tracerService = new TracerService();
export default tracerService;
