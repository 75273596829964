import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../../messages';
import './styles.scss';
export default class TextareaComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value || '',
        };
        this.onChange = this.onChange.bind(this);
    }
    UNSAFE_componentWillReceiveProps(props) {
        if (this.state.value !== props.value) {
            this.setState({ value: props.value });
        }
    }
    onChange(event) {
        let value = event.target.value;
        if (this.props.onChange) {
            value = this.props.onChange(value);
        }
        if (this.props.onInputChange) {
            value = this.props.onInputChange(value) || value;
        }
        if (!this.props.input || !this.props.input.onChange) {
            this.setState({ value });
        }
        return true;
    }
    get limited() {
        let limited = null;
        if (this.props.noteLimited) {
            const remaining = this.props.LimitedNumber - this.state.value.length;
            if (remaining >= 0) {
                limited = (_jsx("div", { className: "sdr-textarea--tips", children: _jsx(FormattedMessage, { ...messages.remaining, values: { remaining } }) }));
            }
            else {
                limited = (_jsxs("div", { className: "sdr-textarea--error-tips_container", children: [_jsxs("div", { className: "sdr-textarea--error-tips_left", children: [_jsx(FormattedMessage, { ...messages.limitedReached }), ' '] }), _jsx("div", { className: "sdr-textarea--error-tips_right", children: _jsx(FormattedMessage, { id: "shared.TextareaComponent.MaxNumber", defaultMessage: `Max.
               {LimitedNumber}`, values: { LimitedNumber: this.props.LimitedNumber } }) })] }));
            }
        }
        return limited;
    }
    render() {
        const styleClasses = classNames('sdr-textarea', this.props.className, {
            'sdr-textarea--error-border': this.props.noteLimited && this.props.LimitedNumber - this.state.value.length < 0,
        });
        return (_jsxs("div", { className: "sdr-textarea-wrap", children: [_jsx("textarea", { className: styleClasses, value: this.props.value, onChange: this.onChange, placeholder: this.props.placeholder }), this.limited] }));
    }
}
TextareaComponent.propTypes = {
    LimitedNumber: PropTypes.number,
    onChange: PropTypes.func,
    input: PropTypes.object,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    placeholder: PropTypes.string,
    noteLimited: PropTypes.bool,
    value: PropTypes.string,
    onInputChange: PropTypes.func,
};
export const onBlockSwipeInput = ({ input }) => (event) => {
    let fchange = event;
    if (/%/g.test(event)) {
        fchange = input.value;
        document.activeElement.blur();
    }
    if (input && input.onChange) {
        input.onChange.apply(input, [fchange]);
    }
    return fchange;
};
export const renderTextAreaComponent = (field) => (_jsx(TextareaComponent, { ...field, value: field.input.value, onInputChange: onBlockSwipeInput(field) }));
