import { PrintingDpi, PrintingMethod } from './printingModule-types';
export var PrinterDriver;
(function (PrinterDriver) {
    PrinterDriver["Boca200"] = "Boca200";
    PrinterDriver["Boca300"] = "Boca300";
    PrinterDriver["Png200"] = "Png200";
    PrinterDriver["Png300"] = "Png300";
    PrinterDriver["Pdf"] = "Pdf";
    PrinterDriver["Postscript"] = "Postscript";
})(PrinterDriver || (PrinterDriver = {}));
export const getPrinterDrivers = () => Object.values(PrinterDriver);
export const getPrintingMethodAndDpi = (printingDriver) => {
    switch (printingDriver) {
        case PrinterDriver.Png200:
            return {
                method: PrintingMethod.PNG,
                dpi: PrintingDpi.Dpi200,
            };
        case PrinterDriver.Png300:
            return {
                method: PrintingMethod.PNG,
                dpi: PrintingDpi.Dpi300,
            };
        case PrinterDriver.Postscript:
            return {
                method: PrintingMethod.PS,
            };
        case PrinterDriver.Boca200:
            return {
                method: PrintingMethod.BOCA,
                dpi: PrintingDpi.Dpi200,
            };
        case PrinterDriver.Boca300:
            return {
                method: PrintingMethod.BOCA,
                dpi: PrintingDpi.Dpi300,
            };
        case PrinterDriver.Pdf:
            return {
                method: PrintingMethod.PDF,
            };
        default:
            return {};
    }
};
export const getPrinterDriverFromMethodAndDpi = (printingMethodAndDpi) => {
    if (!printingMethodAndDpi) {
        return null;
    }
    const { method, dpi } = printingMethodAndDpi;
    switch (method) {
        case PrintingMethod.PNG:
            return dpi === PrintingDpi.Dpi200 ? PrinterDriver.Png200 : PrinterDriver.Png300;
        case PrintingMethod.PS:
            return PrinterDriver.Postscript;
        case PrintingMethod.BOCA:
            return dpi === PrintingDpi.Dpi200 ? PrinterDriver.Boca200 : PrinterDriver.Boca300;
        case PrintingMethod.PDF:
            return PrinterDriver.Pdf;
        case PrintingMethod.FGL:
        default:
            return null;
    }
};
