import { LOCATION_CHANGE } from 'connected-react-router';
import { fromJS, List } from 'immutable';
import { JWT_TOKEN_EXPIRED } from '../store/actions-constants';
import { CLOSE_ERROR_MODAL, HIDE_ERROR_NOTIFICATION, OPEN_ERROR_MODAL, SHOW_ERROR_NOTIFICATION } from './actions';
const initialState = fromJS({
    modalContent: null,
    isModalActive: false,
    errorNotifications: [],
});
let isErrActive;
const errorsReducer = (state = initialState, action) => {
    const isModalActive = state.get('isModalActive');
    switch (action.type) {
        case LOCATION_CHANGE:
            isErrActive = isModalActive || state.get('errorNotifications').size;
            if (isErrActive && action.payload.pathname === '/') {
                return initialState;
            }
            return state;
        case JWT_TOKEN_EXPIRED:
        case OPEN_ERROR_MODAL:
            if (isModalActive) {
                return state;
            }
            return state.set('isModalActive', true).set('modalContent', action.content).set('errorNotifications', List());
        case CLOSE_ERROR_MODAL:
            return state.set('isModalActive', false).set('modalContent', null);
        case SHOW_ERROR_NOTIFICATION:
            if (isModalActive) {
                return state.set('errorNotifications', List());
            }
            return state.set('errorNotifications', state.get('errorNotifications').concat(action.notification));
        case HIDE_ERROR_NOTIFICATION:
            return state.set('errorNotifications', state
                .get('errorNotifications')
                .filter((notification) => notification.failedActionName !== action.notification.failedActionName));
        default:
            return state;
    }
};
export default errorsReducer;
