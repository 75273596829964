import moment from 'moment';
export const formatDatesAndTimes = (date, utcOffset, locale) => {
    var _a;
    const offset = !utcOffset ? 0 : utcOffset;
    if (!date) {
        return {
            time: '',
            dateNumb: '',
            dateMonth: '',
            weekday: '',
            fullDateShortMonth: '',
            fullDateLongMonth: '',
        };
    }
    const momentDate = moment.utc(date).utcOffset(offset);
    const localeDate = momentDate.toDate();
    const currentTimezoneOffset = localeDate.getTimezoneOffset();
    const dateToFormat = new Date(localeDate.getTime() + (currentTimezoneOffset + offset) * 60 * 1000);
    const timeFormat = new Intl.DateTimeFormat(locale, { hour: 'numeric', minute: '2-digit' });
    const parts = timeFormat.formatToParts(dateToFormat);
    const timeSeparator = ((_a = parts.find((part) => part.type === 'literal')) === null || _a === void 0 ? void 0 : _a.value) || ':';
    return {
        date,
        utcOffset: offset,
        localTime: momentDate.format(),
        dateNumb: new Intl.DateTimeFormat(locale, { day: 'numeric' }).format(dateToFormat),
        dateMonth: new Intl.DateTimeFormat(locale, { year: 'numeric', month: 'short' }).format(dateToFormat),
        monthDate: new Intl.DateTimeFormat(locale, { month: 'short', day: 'numeric' }).format(dateToFormat),
        year: new Intl.DateTimeFormat(locale, { year: 'numeric' }).format(dateToFormat),
        weekday: new Intl.DateTimeFormat(locale, { weekday: 'short' }).format(dateToFormat),
        fullDateShortMonth: new Intl.DateTimeFormat(locale, { year: 'numeric', month: 'short', day: 'numeric' }).format(dateToFormat),
        fullDateLongMonth: new Intl.DateTimeFormat(locale, { year: 'numeric', month: 'long', day: 'numeric' }).format(dateToFormat),
        time: timeFormat.format(dateToFormat).replace(timeSeparator, ':'),
    };
};
