import { jsx as _jsx } from "react/jsx-runtime";
import { createSelector } from '@reduxjs/toolkit';
import { Children, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import IntlProviderWrapper from './IntlProviderWrapper';
import { selectLanguage, selectMessages } from './translations-selectors';
import { loadLocale } from './utils';
export const LanguageProvider = ({ language, children, messages }) => {
    const [latestLocale, setLatestLocale] = useState('');
    const lang = language.split('-')[0];
    useEffect(() => {
        loadLocale(lang).then((locale) => {
            setLatestLocale(locale);
        });
    }, [lang, latestLocale]);
    return (_jsx(IntlProviderWrapper, { textComponent: "span", locale: language, messages: messages, onError: () => { }, children: Children.only(children) }, latestLocale));
};
const mapStateToProps = createSelector([selectLanguage, selectMessages], (language, messages) => ({
    language,
    messages,
}));
const ConnectedLanguageProvider = connect(mapStateToProps)(LanguageProvider);
ConnectedLanguageProvider.displayName = 'ConnectedLanguageProvider';
export default ConnectedLanguageProvider;
