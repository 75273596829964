import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';
import './styles.scss';
import { IconAlert24, IconCautionWhiteBg, IconInfo } from '../icons';
export const WARNING = 'WARNING';
export const ERROR = 'ERROR';
export const INFO = 'INFO';
export class Card extends Component {
    get body() {
        if (!this.props.children) {
            return null;
        }
        return _jsx("div", { className: "sdr-card__body", children: this.props.children });
    }
    get header() {
        if (!this.props.header) {
            return null;
        }
        return _jsx("div", { className: "sdr-card__header", children: this.props.header });
    }
    get icon() {
        return _jsx("div", { className: "sdr-card__icon", children: Card.TYPES[this.props.type].icon });
    }
    render() {
        const styleClasses = classNames(['sdr-card', Card.TYPES[this.props.type].style, this.props.className]);
        return (_jsxs("div", { id: this.props.id, className: styleClasses, children: [this.icon, _jsxs("div", { children: [this.header, this.body] })] }));
    }
}
Card.TYPES = {
    [ERROR]: {
        icon: _jsx(IconAlert24, {}),
        style: 'sdr-card--error',
    },
    [INFO]: {
        icon: _jsx(IconInfo, {}),
        style: 'sdr-card--info',
    },
    [WARNING]: {
        icon: _jsx(IconCautionWhiteBg, {}),
        style: 'sdr-card--warning',
    },
};
Card.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    id: PropTypes.string,
    header: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    type: PropTypes.oneOf([ERROR, INFO, WARNING]).isRequired,
};
export default Card;
