import { EMAIL, SMS } from '@ticketmaster/tm1pos-web-shared/constants';
import { call, put } from 'redux-saga/effects';
import { deliverTickets } from '../../../../services/sales-api-client/sales-api-shopping';
import { DELIVERY_ERROR, DELIVERY_ERROR_TYPES, DO_DELIVERY_END } from './actions-constants';
import { ERROR_NO_BARCODE_TICKETS } from './constants';

export const getDeliveryConfirmation = (action, failed, response) => {
  let delivery;
  const orderConfirmation = action.order;
  const method = action.deliveryMethod;

  if (!failed) {
    delivery = action.deliveryRequest.recipient;
    orderConfirmation.filesToPrint = response.data.deliver;
  }

  return {
    deliverySuccess: !failed,
    delivery,
    deliveryMethod: method,
    ...orderConfirmation,
  };
};

export function* handleDelivery(action) {
  let deliveryHasFailed;
  let response;

  try {
    response = yield call(deliverTickets, { ...action });
    deliveryHasFailed = yield call(processError, response, action.deliveryMethod);
  } catch (error) {
    deliveryHasFailed = true;
  }

  const deliveryConfirmation = yield call(getDeliveryConfirmation, action, deliveryHasFailed, response);
  yield put({ type: DO_DELIVERY_END, data: deliveryConfirmation });
}

export function* processError(response, deliveryMethod) {
  const hasErrors = !!response?.errors?.length;
  if (hasErrors) {
    const allErrors = response.errors;
    let errorType;

    // todo remove ERROR_NO_BARCODE_TICKETS === error.code when cleaning up posDeliveryEnabled (TM1POS-2379)
    if (
      allErrors.some(
        (error) => ERROR_NO_BARCODE_TICKETS === error.code || ERROR_NO_BARCODE_TICKETS === error?.extensions?.errorCode,
      )
    ) {
      errorType = DELIVERY_ERROR_TYPES.NO_BARCODE_TICKETS_ERROR;
    } else if (deliveryMethod === SMS) {
      errorType = DELIVERY_ERROR_TYPES.SMS_ERROR;
    } else if (deliveryMethod === EMAIL) {
      errorType = DELIVERY_ERROR_TYPES.EMAIL_ERROR;
    } else {
      errorType = DELIVERY_ERROR_TYPES.PRINT_ERROR;
    }

    const cid = response.errors.map((error) => error.cid).find((x) => x !== undefined);

    yield put({ type: DELIVERY_ERROR, errorType, deliveryMethod, cid });
    return true;
  }
  return false;
}
