import PropTypes from 'prop-types';

export const amountShape = PropTypes.shape({
  amount: PropTypes.number,
  currency: PropTypes.string,
});

export const paymentAmountShape = PropTypes.shape({
  sale: amountShape,
  refund: amountShape,
  total: amountShape,
  type: PropTypes.string,
});

export const paymentGroupShape = PropTypes.shape({
  groupTotal: paymentAmountShape,
  operations: PropTypes.arrayOf(paymentAmountShape),
  type: PropTypes.string,
});

export const feePricing = PropTypes.shape({
  faceValue: amountShape,
  feesAndTaxes: amountShape,
  feesAndTaxesBreakdown: PropTypes.arrayOf(
    PropTypes.shape({
      amount: amountShape,
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  eventFeesBreakdown: PropTypes.arrayOf(
    PropTypes.shape({
      amount: amountShape,
      id: PropTypes.string,
      name: PropTypes.string,
      quantity: PropTypes.number,
    }),
  ),
  total: amountShape,
});
