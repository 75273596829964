import LoadingComponent from '@ticketmaster/tm1pos-web-shared/components/LoadingComponent';
import { useNavigationBlocking } from '@ticketmaster/tm1pos-web-shared/utils/block-navigation-hook';
import { useBeforeunload } from 'react-beforeunload';
import { FormattedMessage } from 'react-intl';
import messages from '../../messages';

type Props = {
  message: FormattedMessage.MessageDescriptor;
  description?: FormattedMessage.MessageDescriptor | undefined;
};
export const CheckoutLoadingGear = ({ message, description }: Props) => {
  useBeforeunload((e) => {
    e.preventDefault();
  });

  useNavigationBlocking();

  return (
    <LoadingComponent>
      <FormattedMessage {...message} />
      <p>
        <FormattedMessage {...(description || messages.processingPaymentDoNotClose)} />
      </p>
    </LoadingComponent>
  );
};
