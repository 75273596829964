import { isTmdsPrinter, PrinterType } from './printingModule-types';
export const printerIdMapper = (printer) => (isTmdsPrinter(printer) ? printer.id : printer.name);
export const printerNameMapper = (printer) => (isTmdsPrinter(printer) ? printer.label : printer.name);
export const tmdsPrinterToPrinter = (tmdsPrinter) => {
    if (!tmdsPrinter) {
        return null;
    }
    const { hasError, status, ...printer } = tmdsPrinter;
    return {
        type: PrinterType.Tmds,
        ...printer,
    };
};
export const printerToTmdsPrinter = (printer) => {
    if (!printer || !isTmdsPrinter(printer)) {
        return null;
    }
    return {
        hasError: false,
        status: 'ready',
        ...printer,
    };
};
