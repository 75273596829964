import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LoaderRing } from '@ticketmaster/aurora';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import animationData from './emv.json';
import './styles.scss';
const LoadingGearComponent = (props) => {
    const loadingGearClass = classNames('sdr-loadingGear', props.additionalClass);
    if (props.emv) {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
            },
        };
        return (_jsx("div", { className: loadingGearClass, children: _jsxs("div", { className: "sdr-loadingGear__spinnerWrap", children: [_jsx("div", { className: "sdr-loadingGear__spinner--emv", children: _jsx(Lottie, { options: defaultOptions, height: 140, width: 140 }) }), _jsx("div", { className: "sdr-loadingGear__loadingMessage", children: props.children })] }) }));
    }
    if (props.largeGear) {
        return (_jsx("div", { className: loadingGearClass, children: _jsxs("div", { className: "sdr-loadingGear__spinnerWrap", children: [_jsx("div", { className: "sdr-loadingGear__spinner", children: _jsx(LoaderRing, {}) }), _jsx("div", { className: "sdr-loadingGear__loadingMessage", children: props.children })] }) }));
    }
    return (_jsx("div", { className: loadingGearClass, children: _jsxs("div", { className: "sdr-loadingGear__spinnerWrap", children: [_jsx("div", { className: "sdr-loadingGear__spinner", children: _jsx(LoaderRing, {}) }), _jsx("div", { className: "sdr-loadingGear__loadingMessage", children: props.children })] }) }));
};
LoadingGearComponent.propTypes = {
    children: PropTypes.node,
    additionalClass: PropTypes.string,
    largeGear: PropTypes.bool,
    emv: PropTypes.bool,
};
export default LoadingGearComponent;
