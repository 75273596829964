export function withMatcher(actionCreator) {
    var _a;
    const type = (_a = actionCreator.type) !== null && _a !== void 0 ? _a : actionCreator().type;
    return Object.assign(actionCreator, {
        type,
        match(action) {
            return action.type === type;
        },
    });
}
