import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Children } from 'react';
import './styles.scss';
const RadioControl = (props) => {
    const labelClass = classNames('oc-radio', props.className, { 'oc-radio--disabled': props.disabled });
    return (_jsxs("label", { className: labelClass, htmlFor: props.id, children: [_jsx("input", { disabled: props.disabled, defaultChecked: props.checked, type: "radio", onClick: props.onClick, name: props.name, id: props.id, value: props.value }), _jsx("span", { className: "oc-radio__button" }), _jsx("span", { className: "oc-radio__label-text", children: props.text }), props.children || null] }));
};
const RadioList = (props) => {
    const listClass = classNames('oc-radio-list', props.className);
    const items = Children.map(props.children, (item) => (_jsx("li", { className: "oc-radio-list__item", children: item }, item.props.value)));
    return _jsx("ul", { className: listClass, children: items });
};
RadioList.propTypes = {
    children: PropTypes.array,
    className: PropTypes.string,
};
RadioControl.propTypes = {
    children: PropTypes.node,
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    disabled: PropTypes.bool,
    checked: PropTypes.bool,
    onClick: PropTypes.func,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};
const renderRadioComponent = (field) => (_jsx(RadioControl, { ...field, value: field.input.value, name: field.input.name, onClick: field.input.onChange }));
export { RadioControl, RadioList, renderRadioComponent };
