import { createSelector } from '@reduxjs/toolkit';
import { Link } from '@ticketmaster/aurora';
import IconShoppingCart from '@ticketmaster/tm1pos-web-shared/components/icons/IconShoppingCart';
import { selectFeatureFlags } from '@ticketmaster/tm1pos-web-shared/feature-flags';
import { selectCanAccessSalesReport } from '@ticketmaster/tm1pos-web-shared/store/selectors/user-selector';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { TAB_MENU_PATHS } from '../../../../constants';
import { checkoutToggleLocation } from '../../../../middleware/mixpanel-constants';
import UserProfileMenu from '../../../HomePage/containers/UserProfileMenu';
import messages from '../../../HomePage/messages';
import { toggleCheckout } from '../../actions';
import TabMenu from '../../components/TabMenuComponent';
import { selectCartItemsLength, selectShowCart } from '../../selectors';
import Feedback from './components/Feedback';
import './styles.scss';

export class Header extends Component {
  static propTypes = {
    canAccessSalesReport: PropTypes.bool,
    cartItemsLength: PropTypes.number,
    featureFlags: PropTypes.object,
    handleClickCart: PropTypes.func,
    locationPath: PropTypes.string,
    showCart: PropTypes.bool,
  };

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.cartItemsLength !== this.props.cartItemsLength || nextProps.locationPath !== this.props.locationPath
    );
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  handleClickCart() {
    const location = checkoutToggleLocation.NAV;
    const showCart = this.props.showCart;
    this.props.dispatch(toggleCheckout({ location, showCart }));
  }

  generateTabMenuPaths() {
    let tabMenuPaths = TAB_MENU_PATHS;

    if (!this.props.canAccessSalesReport) {
      const { REPORTS, ...tabMenuPathsWithoutReports } = tabMenuPaths;
      tabMenuPaths = tabMenuPathsWithoutReports;
    }

    if (!this.props.featureFlags.settingsPage || !this.props.featureFlags.printingConfiguration) {
      const { SETTINGS, ...tabMenuPathsWithoutSettings } = tabMenuPaths;
      tabMenuPaths = tabMenuPathsWithoutSettings;
    }

    return tabMenuPaths;
  }

  render() {
    const { cartItemsLength, handleClickCart } = this.props;

    const tabMenuPaths = this.generateTabMenuPaths();

    return (
      <div className="header">
        <div className="header-wrap">
          <div className="header__tabs">
            <Link to="/" className="header__logo text-decoration-none ">
              <FormattedMessage {...messages.appName} />
            </Link>
            <TabMenu paths={tabMenuPaths} currentLocation={this.props.locationPath} />
            <div className="header_tab--empty" />
          </div>
          <Feedback />
          <UserProfileMenu className="header__profile text-decoration-none" />
          <a role="button" onClick={handleClickCart} tabIndex={-1} className="header__cart">
            <div className="header__cart__icon">
              <IconShoppingCart />
            </div>
            <div className="header__cart__count">{cartItemsLength}</div>
          </a>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  selectCartItemsLength,
  selectShowCart,
  selectCanAccessSalesReport,
  selectFeatureFlags,
  (cartItemsLength, showCart, canAccessSalesReport, featureFlags) => ({
    cartItemsLength,
    canAccessSalesReport,
    featureFlags,
    showCart,
  }),
);

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(Header);
