import { Banner } from '@ticketmaster/aurora';
import Button from '@ticketmaster/tm1pos-web-shared/components/ButtonComponent';
import EditPrinterModal from '@ticketmaster/tm1pos-web-shared/printing/Configuration/Modal';
import { PrinterName } from '@ticketmaster/tm1pos-web-shared/printing/Configuration/Read';
import printMessages from '@ticketmaster/tm1pos-web-shared/printing/printing-message';
import { useState } from 'react';
import { FormattedMessage, InjectedIntl, injectIntl } from 'react-intl';
import './PrintControl.scss';

type InjectedProps = {
  intl: InjectedIntl;
  hasActivePrinter: boolean;
};
export type Props = InjectedProps;
export const PrintControl = ({ hasActivePrinter, intl }: Props) => {
  const [showEdit, setShowEdit] = useState<boolean>(false);

  if (showEdit) {
    return <EditPrinterModal onClose={() => setShowEdit(false)} />;
  }

  return (
    <div className="print-control">
      {!hasActivePrinter && (
        <div className="print-control__alert" data-testid="print-control-alert">
          <Banner heading={intl.formatMessage(printMessages.noPrinterConfiguredMessage)} isOpen variant="error" />
        </div>
      )}
      <div className="print-control__body">
        <PrinterName />
        <Button className="oc-unbutton print-control__button" onButtonClick={() => setShowEdit(true)}>
          <FormattedMessage {...printMessages.editPrinterConfigurationButton} />
        </Button>
      </div>
    </div>
  );
};
const PrintControlWithIntl = injectIntl(PrintControl);
PrintControlWithIntl.displayName = 'PrintControl';
export default PrintControlWithIntl;
