import { createSelector } from '@reduxjs/toolkit';
import { Banner } from '@ticketmaster/aurora';
import { selectEmvPaymentModuleState } from '@ticketmaster/tm1pos-web-shared/payment/emvPaymentModule-selectors';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import messages from '../../../../messages';
import {
  selectCardPaymentEnabled,
  selectCurrentEventPaymentPermissions,
  selectManualCardEntryAllowed,
  selectManualCardEntryDisabled,
} from '../../checkout-selectors';
import OtherForm from '../../components/OtherForm';
import { CARD, OTHER, PIX } from '../../constants';
import EMVPaymentForm from '../EMVPaymentForm';
import AuthorizationOffForm from './AuthorizationOffForm';
import { authorizationOffValidator } from './AuthorizationOffValidator';
import StandardAuthorizationOnForm from './StandardAuthorizationOnForm';
import './styles.scss';

// eslint-disable-next-line react/prefer-stateless-function
export class RenderPaymentForm extends Component {
  authorizationOffValidator = authorizationOffValidator(this.props.intl);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  render() {
    const {
      authCodePlaceholder,
      paymentMethod,
      manualCardEntry,
      cardFormLabels,
      otherFormLabels,
      fieldNames,
      emvSupported,
      manualCardEntryDisabled,
      manualCardEntryAllowed,
      cardPaymentDisabled,
      isAuthorizationOn,
    } = this.props;

    const manualCardEntryProps = {
      checked: manualCardEntry,
      disabled: manualCardEntryDisabled,
      allowed: manualCardEntryAllowed,
      fieldNames,
      emvSupported,
    };

    if (paymentMethod === CARD) {
      if (cardPaymentDisabled) {
        return (
          <div role="alert">
            <Banner heading={this.props.intl.formatMessage(messages.cardPaymentNotConfigured)} isOpen variant="error" />
          </div>
        );
      }

      if (isAuthorizationOn) {
        if (emvSupported) {
          return <EMVPaymentForm manualCardEntry={manualCardEntryProps} showManualCardEntry />;
        }
        return (
          <StandardAuthorizationOnForm
            fieldNames={fieldNames}
            cardFormLabels={cardFormLabels}
            manualCardEntry={manualCardEntryProps}
          />
        );
      }
      return (
        <AuthorizationOffForm
          fieldNames={fieldNames}
          cardFormLabels={cardFormLabels}
          manualCardEntry={manualCardEntryProps}
          placeholder={authCodePlaceholder}
          validator={this.authorizationOffValidator}
        />
      );
    }

    if (paymentMethod === PIX) {
      return <EMVPaymentForm manualCardEntry={manualCardEntryProps} showManualCardEntry={false} />;
    }

    if (paymentMethod === OTHER) {
      return (
        <OtherForm
          {...otherFormLabels}
          fieldNames={fieldNames}
          cashToTender={this.props.cashToTender}
          shouldInitFocusOnCashTendered={this.props.cashFormAutoSelect}
        />
      );
    }
    return null;
  }
}

RenderPaymentForm.propTypes = {
  manualCardEntry: PropTypes.bool,
  paymentMethod: PropTypes.string,
  cardFormLabels: PropTypes.object,
  otherFormLabels: PropTypes.object,
  fieldNames: PropTypes.object,
  authCodePlaceholder: PropTypes.string,
  hostPermissions: PropTypes.object,
  hostName: PropTypes.string,
  cashToTender: PropTypes.object,
  cashFormAutoSelect: PropTypes.bool,
  emvSupported: PropTypes.bool,
  manualCardEntryDisabled: PropTypes.bool,
  manualCardEntryAllowed: PropTypes.bool,
  isEmvSupported: PropTypes.bool,
  cardPaymentDisabled: PropTypes.bool,
  isAuthorizationOn: PropTypes.bool,
  intl: intlShape.isRequired,
};
const mapDispatchToProps = (dispatch) => ({ dispatch });
const mapStateToProps = createSelector(
  [
    selectCurrentEventPaymentPermissions,
    selectManualCardEntryDisabled,
    selectManualCardEntryAllowed,
    selectEmvPaymentModuleState,
    selectCardPaymentEnabled,
  ],
  (paymentPermissions, manualCardEntryDisabled, manualCardEntryAllowed, { isEmvSupported }, cardPaymentEnabled) => ({
    isAuthorizationOn: paymentPermissions?.mustAuthorizeCards || false,
    manualCardEntryDisabled,
    manualCardEntryAllowed,
    isEmvSupported,
    cardPaymentDisabled: !cardPaymentEnabled,
  }),
);
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(RenderPaymentForm));
