import { createSelector } from '@reduxjs/toolkit';
import SellTypesDropdown from '@ticketmaster/tm1pos-web-shared/components/SellTypesDropdown';
import { setInventoryType } from '@ticketmaster/tm1pos-web-shared/store/actions';
import { selectSelectedInventoryType } from '@ticketmaster/tm1pos-web-shared/store/selectors';
import { intlShape } from '@ticketmaster/tm1pos-web-shared/typings/react-intl-types';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { formatInventoryType, getSelectedDisplayName, getSellTypeFromDisplayName } from '../../inventory-type';
import messages from '../../messages';
import { selectDefaultInventoryType, selectInventoryTypesWithoutQualifiedHolds } from '../../selectors/main';
import './styles.scss';

export class CancelOrderContainer extends Component {
  componentDidMount() {
    if (this.props.sellTypes.length === 1) {
      this.props.dispatch(setInventoryType(this.props.sellTypes[0]));
    } else {
      this.props.dispatch(setInventoryType(this.props.defaultSellType));
    }
  }

  getFormattedInventoryTypes = () => formatInventoryType(this.props.intl)(this.props.sellTypes);

  onOptionChange = (valueArray) => {
    const newSellTypeName = valueArray[0];
    const sellType = getSellTypeFromDisplayName(this.getFormattedInventoryTypes(), newSellTypeName);

    return this.props.dispatch(setInventoryType(sellType));
  };

  render() {
    const onlyOneTypeAvailable = this.props.sellTypes.length === 1;
    const formattedInventoryTypes = this.getFormattedInventoryTypes();
    return (
      <div className="sdr-cancel-order">
        <div className="sdr-cancel-order__title">
          <div className="sdr-cancel-order__message">
            <FormattedMessage {...messages.selectInventoryType} />
          </div>
          <div className="sdr-cancel-order__control">
            <SellTypesDropdown
              sellTypes={formattedInventoryTypes}
              selectedValue={getSelectedDisplayName(formattedInventoryTypes, this.props.selectedValue)}
              onOptionChange={this.onOptionChange}
              shouldDisableOption={() => false}
              onlyOneTypeAvailable={onlyOneTypeAvailable}
              size="large"
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({ dispatch });

const mapStateToProps = createSelector(
  [selectInventoryTypesWithoutQualifiedHolds, selectDefaultInventoryType, selectSelectedInventoryType],
  (sellTypes, defaultSellType, selectedValue) => ({
    sellTypes,
    defaultSellType,
    selectedValue,
  }),
);

CancelOrderContainer.propTypes = {
  sellTypes: PropTypes.array,
  defaultSellType: PropTypes.string,
  selectedValue: PropTypes.object,
  intl: intlShape.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CancelOrderContainer));
