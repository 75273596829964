import { createSelector } from '@reduxjs/toolkit';
import { selectAllErrorNotifications } from '@ticketmaster/tm1pos-web-shared/errorHandling/selectors';

export const selectEdpErrorNotifications = createSelector([selectAllErrorNotifications], (notifications) => {
  if (notifications.toArray && notifications.toArray().length) {
    return notifications.toArray().filter((notification) => notification.isEdp);
  }
  return [];
});

export const selectMainErrorNotifications = createSelector([selectAllErrorNotifications], (notifications) => {
  if (notifications.toArray().length) {
    return notifications.toArray().filter((notification) => !notification.isEdp);
  }
  return [];
});
