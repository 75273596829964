import localForage from 'localforage';
import { ForageUtilsBase } from './ForageUtilsBase';
class SessionLocalForage extends ForageUtilsBase {
    constructor() {
        const store = localForage.createInstance({
            name: 'TM1 Sales',
        });
        super(store);
    }
}
export const localForageUtils = new SessionLocalForage();
