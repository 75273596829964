/**
 *
 * App.react.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import { LOCATION_CHANGE } from 'connected-react-router';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { appLoaded } from './actions';
import { ComponentsDependenciesLoader } from './components/ComponentsDependenciesLoader/components-dependencies-loader';
import { SessionCheck } from './components/SessionCheck/session-check';
import { MainContainer } from './containers/MainContainer/main-container';

export class App extends Component {
  static propTypes = {
    children: PropTypes.node,
    location: PropTypes.object,
  };

  componentDidMount() {
    this.props.dispatch(appLoaded());
    this.props.dispatch({ type: LOCATION_CHANGE, payload: { pathname: this.props.location.pathname } });
  }

  shouldComponentUpdate(newProps) {
    return this.props.location.pathname !== newProps.location.pathname;
  }

  componentDidUpdate() {
    this.props.dispatch({ type: LOCATION_CHANGE, payload: { pathname: this.props.location.pathname } });
  }

  render() {
    return (
      <div className="sdr-app">
        <SessionCheck
          location={this.props.location}
          unknownUserElement={this.props.children}
          authenticatedUserElement={
            <ComponentsDependenciesLoader>
              <MainContainer location={this.props.location}>{this.props.children}</MainContainer>
            </ComponentsDependenciesLoader>
          }
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(null, mapDispatchToProps)(App);
