import { createSelector } from 'reselect';
export const selectUserInformation = (state) => state.user.userInformation;
export const selectIsLoadingUserInformation = (state) => state.user.isLoadingUserInformation;
export const selectIsUserUnauthorized = (state) => state.user.isUserUnauthorized;
export const selectArchticsId = createSelector(selectUserInformation, (userInformation) => { var _a; return ((_a = userInformation === null || userInformation === void 0 ? void 0 : userInformation.archtics) === null || _a === void 0 ? void 0 : _a.userId) || null; });
export const selectFirstDsnName = createSelector(selectUserInformation, (userInformation) => { var _a; return ((_a = userInformation === null || userInformation === void 0 ? void 0 : userInformation.archtics) === null || _a === void 0 ? void 0 : _a.dsn) || null; });
export const selectIdentityId = createSelector(selectUserInformation, (user) => { var _a; return (_a = user === null || user === void 0 ? void 0 : user.profile) === null || _a === void 0 ? void 0 : _a.profileId; });
export const selectOrganizationId = createSelector(selectUserInformation, (userInformation) => { var _a; return (_a = userInformation === null || userInformation === void 0 ? void 0 : userInformation.profile) === null || _a === void 0 ? void 0 : _a.organizationId; });
export const selectOrganizationName = createSelector(selectUserInformation, (userInformation) => { var _a; return (_a = userInformation === null || userInformation === void 0 ? void 0 : userInformation.profile) === null || _a === void 0 ? void 0 : _a.organizationName; });
export const selectOpcodes = createSelector(selectUserInformation, (userInformation) => { var _a, _b; return (_b = (_a = userInformation === null || userInformation === void 0 ? void 0 : userInformation.host) === null || _a === void 0 ? void 0 : _a.opCodes) !== null && _b !== void 0 ? _b : {}; });
export const selectFirstOpcode = createSelector([selectOpcodes], (opcodes) => Object.values(opcodes)[0] || null);
export const selectUserCountryCode = createSelector(selectUserInformation, (userInformation) => { var _a; return (_a = userInformation === null || userInformation === void 0 ? void 0 : userInformation.profile) === null || _a === void 0 ? void 0 : _a.countryCode; });
export const selectAuthToken = createSelector(selectUserInformation, (userInformation) => userInformation === null || userInformation === void 0 ? void 0 : userInformation.tm1posAuthToken);
export const selectUserIdentityData = createSelector(selectUserInformation, (userInformation) => {
    var _a, _b, _c;
    return ({
        archticsDsn: (_a = userInformation === null || userInformation === void 0 ? void 0 : userInformation.archtics) === null || _a === void 0 ? void 0 : _a.dsn,
        archticsUserId: (_b = userInformation === null || userInformation === void 0 ? void 0 : userInformation.archtics) === null || _b === void 0 ? void 0 : _b.userId,
        hostDetails: (_c = userInformation === null || userInformation === void 0 ? void 0 : userInformation.host) === null || _c === void 0 ? void 0 : _c.opCodes,
    });
});
export const selectUserRoles = (state) => { var _a, _b; return ((_b = (_a = state.user.userInformation) === null || _a === void 0 ? void 0 : _a.profile) === null || _b === void 0 ? void 0 : _b.roles) || []; };
export const selectCanAccessSalesReport = createSelector(selectUserRoles, (userRoles) => userRoles.includes('canAccessSalesReport'));
