import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { PrinterService } from '../../../PrinterService';
import DropdownPrinterList from '../Inputs/DropdownPrinterList';
import '../EditPrinterConfiguration.scss';
export const TmdsForm = ({ printers, defaultPrinter, onRefreshPrinter, onChangePrinter, connectionError, loading, printerServiceRefreshed, readOnly, showValidationMessage, }) => {
    const [printer, setPrinter] = useState();
    useEffect(() => {
        if (defaultPrinter && printerServiceRefreshed) {
            setPrinter(defaultPrinter);
            onChangePrinter(defaultPrinter);
        }
    }, [defaultPrinter, printerServiceRefreshed, setPrinter, onChangePrinter]);
    const handlePrinterChange = useCallback((newPrinter) => {
        if (newPrinter) {
            const printerWithPrinterService = {
                ...newPrinter,
                printerService: PrinterService.Tmds,
            };
            setPrinter(printerWithPrinterService);
            onChangePrinter(printerWithPrinterService);
        }
        else {
            setPrinter(null);
            onChangePrinter(null);
        }
    }, [onChangePrinter]);
    return (_jsx("div", { children: _jsx(DropdownPrinterList, { hasConnectionError: !!connectionError, disabled: loading || !!connectionError, defaultPrinter: printer, printers: printers, onChange: handlePrinterChange, onRefresh: () => onRefreshPrinter(PrinterService.Tmds), readOnly: readOnly, showValidationMessage: showValidationMessage }) }));
};
export default TmdsForm;
