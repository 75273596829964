import { defineMessages } from 'react-intl';

export default defineMessages({
  eventsMenuTabName: {
    id: 'sales.App.eventsMenuTabName',
    defaultMessage: 'Events',
  },
  customersMenuTabName: {
    id: 'sales.App.customersMenuTabName',
    defaultMessage: 'Customers',
  },
  reportsMenuTabName: {
    id: 'sales.App.reportsMenuTabName',
    defaultMessage: 'Reports',
  },
  settingsMenuTabName: {
    id: 'sales.App.settingsMenuTabName',
    defaultMessage: 'Settings',
  },
  feedbackModalTitle: {
    id: 'sales.App.feedbackModalTitle',
    defaultMessage: 'Get in Touch',
  },
  feedbackModalMsg: {
    id: 'sales.App.feedbackModalMsg',
    defaultMessage: 'Thanks for reaching out. We’d love to hear from you. Select an option to get in touch with us.',
  },
  feedbackModalTab1: {
    id: 'sales.App.feedbackModalTab1',
    defaultMessage: 'Get Help',
  },
  feedbackModalTab2: {
    id: 'sales.App.feedbackModalTab2',
    defaultMessage: 'Send Feedback',
  },
  supportHelpText: {
    id: 'sales.App.supportHelpText',
    defaultMessage: 'To access knowledge articles and product documentation, or to submit a request for help:',
  },
  supportCenterName: {
    id: 'sales.App.supportCenterName',
    defaultMessage: 'Support Center',
  },
  supportHelpLink: {
    id: 'sales.App.supportHelpLink',
    defaultMessage: 'Access the {supportCenterName}',
  },
  supportHelpPhone: {
    id: 'sales.App.supportHelpPhone',
    defaultMessage: 'Call us at {phoneNumber}',
  },
  supportHelpEmail: {
    id: 'sales.App.supportHelpEmail',
    defaultMessage: 'Email us at {email}',
  },
  sendFeedbackPlaceholder: {
    id: 'sales.App.sendFeedbackPlaceholder',
    defaultMessage: "What's on your mind?",
  },
  sendEmailPlaceholder: {
    id: 'sales.App.sendEmailPlaceholder',
    defaultMessage: 'Email address',
  },
  sendFeedbackBtnSend: {
    id: 'sales.App.sendFeedbackBtnSend',
    defaultMessage: 'Send',
  },
  feedbackMsgReceived: {
    id: 'sales.App.feedbackMsgReceived',
    defaultMessage: 'Message received.',
  },
  feedbackMsgReceivedTip: {
    id: 'sales.App.feedbackMsgReceivedTip',
    defaultMessage: 'We take your feedback to heart, and will use it to create a better experience.',
  },
  feedbackOk: {
    id: 'sales.App.feedbackOk',
    defaultMessage: 'OK',
  },
  feedbackMsgErrorEmail: {
    id: 'sales.App.feedbackMsgErrorEmail',
    defaultMessage: 'Please enter a valid email address.',
  },
  feedbackMsgErrorText: {
    id: 'sales.App.feedbackMsgErrorText',
    defaultMessage: 'Unable to send your feedback. Try again.',
  },
  EMVTerminalIdMissingMsg: {
    id: 'sales.App.EMVTerminalIdMissingMsg',
    defaultMessage: 'Your payment terminal is not correctly configured. Your terminal id is missing.',
  },
  terminalMsg: {
    id: 'sales.App.terminalMsg',
    defaultMessage: 'Terminal Message: {terminalMessage}',
  },
});
