import { Banner } from '@ticketmaster/aurora';
import TmdsClient from '@ticketmaster/tm1pos-tmds-client';
import IconAlert from '@ticketmaster/tm1pos-web-shared/components/icons/IconAlert24';
import { EMAIL, SMS } from '@ticketmaster/tm1pos-web-shared/constants';
import {
  selectEMVCheckoutPageError,
  selectEmvPaymentModuleState,
  selectTransactionIsInError,
} from '@ticketmaster/tm1pos-web-shared/payment/emvPaymentModule-selectors';
import { selectHasActivePrinter } from '@ticketmaster/tm1pos-web-shared/printing/printingModule-selectors';
import { intlMessageShape } from '@ticketmaster/tm1pos-web-shared/proptypes-constants';
import { intlShape } from '@ticketmaster/tm1pos-web-shared/typings/react-intl-types';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form/immutable';
import emvmessages from '../../../App/messages';
import { selectCartId, selectCartItemsPrice } from '../../../App/selectors';
import { selectCurrentEvent, selectOrderIdToRetry } from '../../../EventDetailPage/selectors/main';
import messages from '../../messages';
import { selectCardPaymentEnabled, selectLinkGlobalAccountEnabled } from './checkout-selectors';
import { isCheckoutFormComplete } from './checkout-utils';
import DeliveryForm from './components/DeliveryForm';
import DeliveryTypeControls from './components/DeliveryTypeControls';
import GlobalAccount from './components/GlobalAccount';
import PaymentTypeControls from './components/PaymentTypeControls';
import PrintReceipt from './components/PrintReceipt';
import SubmitButton from './components/SubmitButton';
import { CARD, CASH, CHECKOUT_FORM_TITLE } from './constants';
import RenderPaymentForm from './containers/RenderPaymentForm';
import { handleTmdsPrinterChange } from './handle-tmds-printer-change';
import {
  selectCheckoutInProgress,
  selectCreditCardFormData,
  selectFormToggles,
  selectHasPrintReceipt,
  selectLinkGlobalAccount,
  selectPhonePre,
  selectPrintReceiptData,
  selectUseEmailAsDelivery,
  selectUserInfoState,
} from './selectors';
import './styles.scss';

export class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFocusOn: {
        email: false,
        phoneNumber: false,
      },
    };
    this.handleOnBlur = this.handleOnBlur.bind(this);
    this.handleOnFocus = this.handleOnFocus.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const shouldUpdateFullName = !this.props.fullName || this.props.fullName === this.props.cardHolderName;
    const cardHolderNameHasChanged = nextProps.cardHolderName !== this.props.cardHolderName;
    if (shouldUpdateFullName && cardHolderNameHasChanged) {
      this.props.dispatch(change(CHECKOUT_FORM_TITLE, 'fullName', nextProps.cardHolderName));
    }

    const isOrderToRetryChanged = nextProps.orderIdToRetry && !this.props.orderIdToRetry;
    const checkoutLoadingFinished = !nextProps.checkoutInProgress && this.props.checkoutInProgress;
    if (this.errRetryMsg && (isOrderToRetryChanged || (checkoutLoadingFinished && nextProps.orderIdToRetry))) {
      this.errRetryMsg.scrollIntoView();
    }
  }

  handleOnBlur(event) {
    this.setState((prevState) => {
      const currentState = prevState;
      currentState.isFocusOn[event.target.name] = false;
      return {
        isFocusOn: currentState.isFocusOn,
      };
    });
  }

  handleOnFocus(event) {
    this.setState((prevState) => {
      const currentState = prevState;
      currentState.isFocusOn[event.target.name] = true;
      return {
        isFocusOn: currentState.isFocusOn,
      };
    });
  }

  getFormLabels(deliveryMethod, respectRotEntryToken) {
    const isFullNameRequired =
      (respectRotEntryToken && this.props.currentEvent.rotatingEntryTokenEnabled) ||
      (this.props.isEmvSupported &&
        this.props.paymentMethod === CARD &&
        (this.props.manualCardEntry || this.props.shouldRequireFullName));
    const isCardHolderNameRequired =
      (respectRotEntryToken && this.props.currentEvent.rotatingEntryTokenEnabled) ||
      (this.props.paymentMethod === CARD && this.props.manualCardEntry);
    const isEmailRequired = deliveryMethod === EMAIL || this.props.linkGlobalAccount;
    const fullNamePlaceholder = `${this.props.intl.formatMessage(messages.fullNamePlaceholder)} ${
      isFullNameRequired ? this.props.intl.formatMessage(messages.requiredTag) : ''
    }`;
    const phoneNumberPlaceholder = `${this.props.intl.formatMessage(messages.phoneNumberPlaceholder)} ${
      deliveryMethod === SMS ? this.props.intl.formatMessage(messages.requiredTag) : ''
    }`;
    const emailPlaceholder = `${this.props.intl.formatMessage(messages.emailPlaceholder)} ${
      isEmailRequired ? this.props.intl.formatMessage(messages.requiredTag) : ''
    }`;
    const notePlaceholder = this.props.intl.formatMessage(messages.notePlaceholder);
    const authCodePlaceholder = this.props.intl.formatMessage(messages.authCode);
    const printReceiptToggleLabel = this.props.intl.formatMessage(messages.printReceipts);
    const cardFormLabels = {
      cardHolderLabel: `${this.props.intl.formatMessage(messages.cardHolderNamePlaceholder)} ${
        isCardHolderNameRequired ? this.props.intl.formatMessage(messages.requiredTag) : ''
      }`,
      cardNumberLabel: this.props.intl.formatMessage(messages.cardNumberPlaceholder),
      cardExpDateLabel: this.props.intl.formatMessage(messages.cardExpDatePlaceholder),
    };
    const otherFormLabels = {
      paymentInfoLabel: this.props.intl.formatMessage(messages.paymentInfoPlaceholder), //  cardNumberPlaceholder
      otherPaymentsLabel: `${this.props.intl.formatMessage(
        messages.otherPaymentsPlaceholder,
      )} ${this.props.intl.formatMessage(messages.requiredTag)}`,
    };
    return {
      fullNamePlaceholder,
      phoneNumberPlaceholder,
      emailPlaceholder,
      cardFormLabels,
      notePlaceholder,
      otherFormLabels,
      printReceiptToggleLabel,
      authCodePlaceholder,
    };
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  render() {
    const {
      intl,
      paymentMethod,
      deliveryMethod,
      handleSubmit,
      manualCardEntry,
      invalid,
      cartEmpty,
      checkoutInProgress,
      printerList,
      printReceiptData,
      currentEvent,
      cashToTender,
      isEmvSupported,
      emvCheckoutPageError,
      useEmailAsDelivery,
    } = this.props;

    const respectRotEntryToken = paymentMethod === CASH || (paymentMethod === CARD && manualCardEntry);
    const { cardFormLabels, otherFormLabels, printReceiptToggleLabel, authCodePlaceholder } = this.getFormLabels(
      deliveryMethod,
      respectRotEntryToken,
    );

    const fieldNames = {
      cardHolderName: 'cardHolderName',
      accountNumber: 'accountNumber',
      expDate: 'expDate',
      creditCardTypes: 'creditCardTypes',
      manualCardEntry: 'manualCardEntry',
      authCode: 'authCode',
      paymentMethod: 'paymentMethod',
      otherPaymentMethod: 'otherPaymentMethod',
      paymentInfo: 'paymentInfo',
      cashTendered: 'cashTendered',
      changeDue: 'changeDue',
    };

    return (
      <form className="sdr-checkout__form" onSubmit={handleSubmit} autoComplete="off">
        <div className="sdr-checkout__form-inner-wrap">
          {this.props.orderIdToRetry ? (
            <div
              className="sdr-checkout__payment-fail-wrap"
              ref={(el) => {
                this.errRetryMsg = el;
              }}
            >
              <div className="sdr-checkout__payment-fail">
                <IconAlert />
                <FormattedMessage {...messages.checkoutPaymentErrMsg} values={{ orderId: this.props.orderIdToRetry }} />
              </div>
            </div>
          ) : null}
          {this.props.hasEMVError && emvCheckoutPageError ? (
            <div className="sdr-checkout__payment-emv-fail">
              <Banner
                heading={intl.formatMessage(emvCheckoutPageError.message)}
                isOpen
                variant={emvCheckoutPageError.isWarning ? 'alert' : 'error'}
                {...(emvCheckoutPageError.terminalMessage
                  ? {
                      content: intl.formatMessage(emvmessages.terminalMsg, {
                        terminalMessage: emvCheckoutPageError.terminalMessage,
                      }),
                      isExpanded: true,
                    }
                  : {})}
              />
            </div>
          ) : null}
          <div className="sdr-checkout__payment-title-wrap">
            <div className="sdr-checkout__section-title">
              <FormattedMessage {...messages.payment} />
            </div>
          </div>
          {[
            <PaymentTypeControls fieldName={fieldNames.paymentMethod} key="payment-type-controls" />,
            <RenderPaymentForm
              emvSupported={isEmvSupported}
              manualCardEntry={manualCardEntry}
              paymentMethod={paymentMethod}
              authCodePlaceholder={authCodePlaceholder}
              cardFormLabels={cardFormLabels}
              otherFormLabels={otherFormLabels}
              fieldNames={fieldNames}
              cashToTender={cashToTender}
              cashFormAutoSelect
              key="payment-form"
            />,
          ]}
          <DeliveryTypeControls emailAsDelivery={useEmailAsDelivery} />
          <PrintReceipt
            deliveryMethod={deliveryMethod}
            paymentMethod={paymentMethod}
            label={printReceiptToggleLabel}
            active={printReceiptData.active}
            quantity={printReceiptData.quantity}
            printerList={printerList}
          />
          {this.props.linkGlobalAccountEnabled && <GlobalAccount />}
          <DeliveryForm
            deliveryMethod={deliveryMethod}
            currentEvent={currentEvent}
            respectRotEntryToken={respectRotEntryToken}
            {...this.getFormLabels(deliveryMethod, respectRotEntryToken)}
            handleOnBlur={this.handleOnBlur}
            handleOnFocus={this.handleOnFocus}
            isFocusOn={this.state.isFocusOn}
            phonePre={this.props.phonePre}
            isEmvSupported={this.props.isEmvSupported}
            isManualEntry={manualCardEntry}
            shouldRequireFullName={this.props.shouldRequireFullName}
            paymentMethod={this.props.paymentMethod}
          />
        </div>
        <SubmitButton
          isOrderUnpaid={!!this.props.orderIdToRetry}
          ifConnectPaymentDevice={isEmvSupported}
          checkoutInProgress={checkoutInProgress}
          invalid={
            cartEmpty ||
            !isCheckoutFormComplete(
              isEmvSupported,
              manualCardEntry,
              !invalid,
              this.props.fullName,
              deliveryMethod,
              this.props.hasPrintReceipt,
              this.props.hasActivePrinter,
              this.props.hasActivePaymentDevice,
              paymentMethod,
              this.props.cardPaymentEnabled,
            )
          }
        />
      </form>
    );
  }
}

CheckoutForm.propTypes = {
  checkoutInProgress: PropTypes.bool,
  manualCardEntry: PropTypes.bool,
  paymentMethod: PropTypes.string,
  deliveryMethod: PropTypes.string,
  fullName: PropTypes.string,
  cardHolderName: PropTypes.string,
  invalid: PropTypes.bool,
  cartEmpty: PropTypes.bool,
  orderIdToRetry: PropTypes.string,
  handleSubmit: PropTypes.func,
  intl: intlShape.isRequired,
  printerList: PropTypes.array,
  currentEvent: PropTypes.object,
  cashToTender: PropTypes.object,
  printReceiptData: PropTypes.object,
  phonePre: PropTypes.object,
  isEmvSupported: PropTypes.bool,
  emvCheckoutPageError: intlMessageShape,
  hasEMVError: PropTypes.bool,
  useEmailAsDelivery: PropTypes.bool,
  isEMVOn: PropTypes.bool,
  hasActivePrinter: PropTypes.bool,
  hasPrintReceipt: PropTypes.bool,
  shouldRequireFullName: PropTypes.bool,
  hasActivePaymentDevice: PropTypes.bool,
  cardPaymentEnabled: PropTypes.bool,
  linkGlobalAccountEnabled: PropTypes.bool,
  linkGlobalAccount: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { printerList } = TmdsClient;

  const checkoutInProgress = selectCheckoutInProgress(state);
  const formToggles = selectFormToggles(state);
  const cart = selectCartId(state);
  const creditCard = selectCreditCardFormData(state);
  const userInfo = selectUserInfoState(state);
  const currentEvent = selectCurrentEvent(state);
  const { total } = selectCartItemsPrice(state);
  const orderIdToRetry = selectOrderIdToRetry(state);
  const printReceiptData = selectPrintReceiptData(state);
  const phonePre = selectPhonePre(state);
  const emvCheckoutPageError = selectEMVCheckoutPageError(state);
  const transactionIsInError = selectTransactionIsInError(state);
  const useEmailAsDelivery = selectUseEmailAsDelivery(state);
  const { isEmvSupported, rules, deviceId: activePaymentDeviceId } = selectEmvPaymentModuleState(state);
  const hasActivePrinter = selectHasActivePrinter(state);
  const hasPrintReceipt = selectHasPrintReceipt(state);
  const cardPaymentEnabled = selectCardPaymentEnabled(state);
  const linkGlobalAccountEnabled = selectLinkGlobalAccountEnabled(state);
  const linkGlobalAccount = selectLinkGlobalAccount(state);

  return {
    checkoutInProgress,
    ...formToggles,
    cartEmpty: !cart,
    ...creditCard,
    ...userInfo,
    printerList,
    currentEvent,
    cashToTender: total,
    orderIdToRetry,
    printReceiptData,
    phonePre,
    isEmvSupported,
    emvCheckoutPageError,
    hasEMVError: transactionIsInError,
    useEmailAsDelivery,
    hasActivePrinter,
    hasPrintReceipt,
    shouldRequireFullName: rules.shouldRequireFullName,
    hasActivePaymentDevice: activePaymentDeviceId !== null || !rules.shouldSelectDeviceFromList,
    cardPaymentEnabled,
    linkGlobalAccountEnabled,
    linkGlobalAccount,
  };
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

const connectedForm = connect(mapStateToProps, mapDispatchToProps)(injectIntl(CheckoutForm));

const connectedReduxForm = reduxForm({
  form: CHECKOUT_FORM_TITLE,
  validate: () => ({}),
  onChange: (values, dispatch, props, previousValues) => {
    handleTmdsPrinterChange(values, previousValues);
  },
})(connectedForm);

export const connectedReduxFormInitial = connect((state) => ({
  initialValues: {
    ...state.form.getIn(['checkout', 'values']).toJS(),
    printer: TmdsClient.selectedPrinterData,
    printReceiptCount: selectPrintReceiptData(state),
  },
}))(connectedReduxForm);

export default connectedReduxFormInitial;
