import { createSelector } from 'reselect';
import { DEFAULT_HOST_LANGUAGE_NAME, DEFAULT_INVENTORY_TYPE_SELECTED } from '../../constants';
import { sortInventory } from '../../utils';
import { selectHostPermissions } from './permissions-selector';
export const selectCurrentEventCountryCode = (state) => { var _a; return (_a = state.event.get('location')) === null || _a === void 0 ? void 0 : _a.countryCode; };
export const selectCurrentEventDetails = (state) => state.events.currentEvent;
export const selectCurrentHostEventPermissions = createSelector(selectHostPermissions, selectCurrentEventDetails, (permissions, currentEventDetails) => {
    let currentPermissions;
    if (permissions) {
        if (currentEventDetails === null || currentEventDetails === void 0 ? void 0 : currentEventDetails.hostName) {
            currentPermissions = permissions[currentEventDetails.hostName];
        }
        else {
            currentPermissions = Object.values(permissions)[0];
        }
    }
    if (!currentPermissions) {
        const defaultSellClass = {
            id: '000000000000',
            name: DEFAULT_INVENTORY_TYPE_SELECTED,
        };
        currentPermissions = {
            canRefund: false,
            canRefundPrinted: false,
            canUseTmdsPayment: false,
            canUsePdg: false,
            canUseCcManualEntryWithoutEmv: false,
            canLinkGlobalAccount: false,
            mustAuthorizeCards: true,
            paymentMethods: [],
            defaultSellClass,
            sellClasses: [defaultSellClass],
            languageName: DEFAULT_HOST_LANGUAGE_NAME,
        };
    }
    return currentPermissions;
});
export const selectSortedHostInventoryTypes = createSelector(selectCurrentHostEventPermissions, (currentPermissions) => sortInventory(currentPermissions.sellClasses));
