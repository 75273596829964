import classNames from 'classnames';
import { useAppSelector } from '../../../../store/hooks';
import { selectCheckoutInProgress } from '../../../Checkout/components/Form/selectors';
import { selectIsOrderDeleteInProgress } from '../../../EventDetailPage/selectors/main';

export type Props = {
  showCart: boolean;
  handleClick: () => void;
};
export const CartCover = ({ showCart = false, handleClick }: Props) => {
  const isCheckoutInProgress = useAppSelector(selectCheckoutInProgress);
  const isOrderDeleteInProgress = useAppSelector(selectIsOrderDeleteInProgress);

  const isCheckoutWindowNonCloseable = isCheckoutInProgress || isOrderDeleteInProgress;

  if (isCheckoutWindowNonCloseable) {
    return <div className="sdr-checkout-bgclose" data-testid="cart-cover" />;
  }

  const outsideClass = classNames('sdr-checkout-bgclose', 'fastest', {
    'sdr-checkout--hidden': !showCart,
    animated: true,
    fadeIn: showCart,
    fadeOut: !showCart,
  });
  return (
    <a className={outsideClass} role="button" onClick={handleClick} tabIndex={-1} data-testid="cart-cover">
      &nbsp;
    </a>
  );
};
export default CartCover;
