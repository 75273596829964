import { SellClass } from '@ticketmaster/tm1pos-web-shared/model/graphql';
import { setInventoryType } from '@ticketmaster/tm1pos-web-shared/store/actions';
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';
import { EVENT_ID_SET, FETCH_EVENT_DETAILS } from './constants';
import { mapIsmrtPlacesToSeatStatus } from './containers/SearchMap/mapper';
import { ismrtSnapshotDataSuccess, setSeatStatuses, subscribeToIsmrtPlaceUpdates } from './event-actions';
import { SeatStatusPlace } from './model/SeatStatusPlace';
import { SellTypeAvailabityCounts } from './selectors/event-detail-selector-models';
import { selectInventoryTypes } from './selectors/main';
import { selectSellTypeAvailabilityCounts } from './selectors/seatStatus';

function canSellInventoryType(allowedInventoryTypes: SellClass[], inventoryType: string) {
  return allowedInventoryTypes.some((type) => type.id === inventoryType);
}

export function* checkInventoryTypesAvailabilityCounts() {
  const counts: SellTypeAvailabityCounts = yield select(selectSellTypeAvailabilityCounts);
  const availableInventoryTypes = Object.keys(counts.sellTypeAvailability);
  const allowedInventoryTypes: SellClass[] = yield select(selectInventoryTypes);
  const allowedAndAvailable = availableInventoryTypes.filter((type) =>
    canSellInventoryType(allowedInventoryTypes, type),
  );
  if (allowedAndAvailable.length === 1) {
    const inventoryType = allowedInventoryTypes.find((it) => it.id === allowedAndAvailable[0]);
    yield put(setInventoryType(inventoryType));
  }
}

export function* watchSetSeatStatusesOnEventLoad() {
  yield takeLatest(EVENT_ID_SET, setSeatStatusesOnEventLoad);
}

export function* setSeatStatusesOnEventLoad() {
  const { ismrtSnapshot } = yield all({
    eventDetails: take(FETCH_EVENT_DETAILS.SUCCESS),
    ismrtSnapshot: take(ismrtSnapshotDataSuccess.type),
  });

  const seatStatusPlaces: SeatStatusPlace[] = yield call(mapIsmrtPlacesToSeatStatus, ismrtSnapshot.payload.places);

  yield put(setSeatStatuses({ places: seatStatusPlaces }));

  yield put(subscribeToIsmrtPlaceUpdates());
}

export const eventDetailSagas = [watchSetSeatStatusesOnEventLoad];
