import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormattedMessage, injectIntl } from 'react-intl';
import wrapModal from '../../../components/utils/ModalWrap';
import messages from '../../printing-message';
import EditPrinterConfiguration from '../Edit';
import './EditPrinterModal.scss';
import { EditPrinterConfigurationActions } from '../Edit/EditPrinterConfigurationActions';
import { usePrinterConfiguration } from '../use-printer-configuration';
const EditPrinterModal = ({ onClose, loading }) => {
    const { cancelEditPrinterConfiguration, editPrinterConfigurationRef, savePrinterConfiguration, setShowValidationMessage, setValidPrinter, showValidationMessage, validPrinter, } = usePrinterConfiguration();
    const onCancel = () => {
        cancelEditPrinterConfiguration();
        setShowValidationMessage(false);
        onClose();
    };
    const onSave = () => {
        if (validPrinter) {
            savePrinterConfiguration();
            setShowValidationMessage(false);
            onClose();
        }
        else {
            setShowValidationMessage(true);
        }
    };
    return (_jsxs("div", { className: "edit-printer-modal", children: [_jsx("div", { className: "edit-printer-modal__header", children: _jsx("p", { className: "edit-printer-modal__header__title", children: _jsx(FormattedMessage, { ...messages.printerConfigurationModalTitle }) }) }), _jsx("div", { className: "edit-printer-modal__body", children: _jsx(EditPrinterConfiguration, { onPrinterValidChange: setValidPrinter, showValidationMessage: showValidationMessage, ref: editPrinterConfigurationRef }) }), _jsx("div", { className: "edit-printer-modal__footer", children: _jsx(EditPrinterConfigurationActions, { onCancel: onCancel, onSave: onSave, saveDisabled: loading }) })] }));
};
export default wrapModal(injectIntl(EditPrinterModal), { contentProps: { className: 'edit-printer-modal__content' } });
