export var LogStatus;
(function (LogStatus) {
    LogStatus["SUCCESS"] = "SUCCESS";
    LogStatus["ERROR"] = "ERROR";
    LogStatus["INFO"] = "INFO";
    LogStatus["UNKNOWN"] = "UNKNOWN";
})(LogStatus || (LogStatus = {}));
export var LogComponent;
(function (LogComponent) {
    LogComponent["EMV"] = "emv";
    LogComponent["PDG"] = "pdg";
    LogComponent["PRINT"] = "print";
    LogComponent["CHECKOUT"] = "checkout";
    LogComponent["ENHANCED_CHECKOUT_LOGS"] = "enhancedCheckoutLogs";
    LogComponent["DELIVERY"] = "delivery";
    LogComponent["QUERY_PARAMS"] = "queryParams";
    LogComponent["APP"] = "app";
    LogComponent["EVENT_DETAILS"] = "eventDetails";
    LogComponent["QZ_PRINTING"] = "qzPrintingModuleLogs";
    LogComponent["ISMRT_SUBSCRIPTION"] = "ismrtSubscription";
    LogComponent["UNHANDLED_SAGA_ERROR"] = "unhandledSagaError";
    LogComponent["UNHANDLED_MIDDLEWARE_ERROR"] = "unhandledMiddlewareError";
    LogComponent["UNHANDLED_COMPONENT_ERROR"] = "unhandledComponentError";
    LogComponent["UNHANDLED_WINDOW_ERROR"] = "unhandledWindowError";
    LogComponent["UNHANDLED_REJECTION_ERROR"] = "unhandledRejectionError";
})(LogComponent || (LogComponent = {}));
