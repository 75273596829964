import { createSelector } from '@reduxjs/toolkit';
import { renderRadioComponent } from '@ticketmaster/tm1pos-web-shared/components/RadioComponent';
import { PaymentType } from '@ticketmaster/tm1pos-web-shared/model/payment';
import { intlShape } from '@ticketmaster/tm1pos-web-shared/typings/react-intl-types';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Field } from 'redux-form/lib/immutable';
import messages from '../../../../messages';
import { CARD, OTHER, PIX } from '../../constants';
import { selectOtherPaymentMethods } from '../../selectors';

export class PaymentTypeControls extends PureComponent {
  isPixAvailable = this.props.otherPaymentMethods.some((item) => item.type === PaymentType.PIX);

  render() {
    const { intl } = this.props;
    return (
      <ul className="oc-radio-list oc-radio-list--toggle checkout-payment-options">
        <li className="oc-radio-list__item">
          <Field
            name={this.props.fieldName}
            component={renderRadioComponent}
            id="creditCardRadio"
            value={CARD}
            text={intl.formatMessage(messages.checkoutCredit)}
            type="radio"
            checked
            disabled={this.props.disableCard}
          />
        </li>
        {this.isPixAvailable && (
          <li className="oc-radio-list__item">
            <Field
              name={this.props.fieldName}
              component={renderRadioComponent}
              id="pixRadio"
              value={PIX}
              text={intl.formatMessage(messages.checkoutPix)}
              type="radio"
            />
          </li>
        )}
        {this.props.otherPaymentMethods &&
        this.props.otherPaymentMethods.filter((method) => method.type !== PaymentType.PIX).length > 0 ? (
          <li className="oc-radio-list__item">
            <Field
              name={this.props.fieldName}
              component={renderRadioComponent}
              id="otherRadio"
              value={OTHER}
              text={intl.formatMessage(messages.checkoutOther)}
              type="radio"
            />
          </li>
        ) : null}
      </ul>
    );
  }
}

PaymentTypeControls.propTypes = {
  intl: intlShape.isRequired,
  disableCard: PropTypes.bool,
  fieldName: PropTypes.string,
  otherPaymentMethods: PropTypes.array,
};
const mapDispatchToProps = (dispatch) => ({ dispatch });
const mapStateToProps = createSelector([selectOtherPaymentMethods], (otherPaymentMethods) => ({
  otherPaymentMethods,
}));

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PaymentTypeControls));
