import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
const IconAda = () => (_jsxs("svg", { fill: "none", height: "16", viewBox: "0 0 16 16", width: "16", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", children: [_jsx("clipPath", { id: "a", children: _jsx("path", { d: "m0 0h16v16h-16z" }) }), _jsx("g", { clipPath: "url(#a)", children: _jsx("path", { clipRule: "evenodd", d: "m3.00005 2.5c0-1.38071 1.11929-2.5 2.5-2.5 1.38072 0 2.5 1.11929 2.5 2.5 0 1.17542-.81118 2.16137-1.90427 2.42858l.21431 1.07142h3.18996c.27615 0 .50005.22386.50005.5 0 .24546-.17692.44961-.41017.49194l-.08988.00806h-2.98994l.40004 2h4.59005c.1752 0 .3353.09145.4253.23712l.0389.07718 1.8738 4.6857h1.662c.2454 0 .4496.1769.4919.4101l.008.0899c0 .2455-.1768.4496-.4101.4919l-.0898.0081h-2c-.1753 0-.3354-.0915-.4254-.2371l-.0389-.0772-1.8747-4.6857h-4.66105c-.20855 0-.39173-.1288-.46558-.31757l-.02471-.08438-.92653-4.63262c-1.18235-.19839-2.08328-1.2267-2.08328-2.46543zm2.49185 1.49998.01725-.00001c.82424-.00489 1.4909-.67458 1.4909-1.49997 0-.82843-.67157-1.5-1.5-1.5-.82842 0-1.5.67157-1.5 1.5 0 .82571.66718 1.49559 1.49185 1.49998zm-5.4919 6.12812c0 3.2217 2.61167 5.8334 5.83333 5.8334 2.12401 0 4.04827-1.1453 5.07717-2.9591.1362-.2402.0519-.5453-.1883-.6816-.2401-.1362-.5453-.052-.6816.1882-.85295 1.5038-2.44691 2.4525-4.20727 2.4525-2.66937 0-4.83333-2.164-4.83333-4.8334 0-1.52052.70619-2.92322 1.88879-3.83316.21886-.16839.25977-.48232.09137-.70117-.16839-.21886-.48232-.25977-.70118-.09137-1.426062 1.09726-2.27898 2.79141-2.27898 4.6257z", fill: "#262626", fillOpacity: ".65", fillRule: "evenodd" }) })] }));
export const rawAdaSvg = `<svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <clipPath id="a">
        <path d="m0 0h16v16h-16z"/>
    </clipPath>
    <g clip-path="url(#a)">
        <path clip-rule="evenodd"
              d="m3.00005 2.5c0-1.38071 1.11929-2.5 2.5-2.5 1.38072 0 2.5 1.11929 2.5 2.5 0 1.17542-.81118 2.16137-1.90427 2.42858l.21431 1.07142h3.18996c.27615 0 .50005.22386.50005.5 0 .24546-.17692.44961-.41017.49194l-.08988.00806h-2.98994l.40004 2h4.59005c.1752 0 .3353.09145.4253.23712l.0389.07718 1.8738 4.6857h1.662c.2454 0 .4496.1769.4919.4101l.008.0899c0 .2455-.1768.4496-.4101.4919l-.0898.0081h-2c-.1753 0-.3354-.0915-.4254-.2371l-.0389-.0772-1.8747-4.6857h-4.66105c-.20855 0-.39173-.1288-.46558-.31757l-.02471-.08438-.92653-4.63262c-1.18235-.19839-2.08328-1.2267-2.08328-2.46543zm2.49185 1.49998.01725-.00001c.82424-.00489 1.4909-.67458 1.4909-1.49997 0-.82843-.67157-1.5-1.5-1.5-.82842 0-1.5.67157-1.5 1.5 0 .82571.66718 1.49559 1.49185 1.49998zm-5.4919 6.12812c0 3.2217 2.61167 5.8334 5.83333 5.8334 2.12401 0 4.04827-1.1453 5.07717-2.9591.1362-.2402.0519-.5453-.1883-.6816-.2401-.1362-.5453-.052-.6816.1882-.85295 1.5038-2.44691 2.4525-4.20727 2.4525-2.66937 0-4.83333-2.164-4.83333-4.8334 0-1.52052.70619-2.92322 1.88879-3.83316.21886-.16839.25977-.48232.09137-.70117-.16839-.21886-.48232-.25977-.70118-.09137-1.426062 1.09726-2.27898 2.79141-2.27898 4.6257z"
              fill="#262626" fill-opacity=".65" fill-rule="evenodd"/>
    </g>
</svg>`;
export default IconAda;
