import { defineMessages } from 'react-intl';

export default defineMessages({
  cartExpired: {
    id: 'sales.cartExpiredErrorModal.cartExpired',
    defaultMessage: 'Cart Expired',
  },
  cartExpiredErrorMessage: {
    id: 'sales.cartExpiredErrorModal.cartExpiredErrorMessage',
    defaultMessage: 'Your cart has reached the time limit and has expired. Please reload and start again.',
  },
  reload: {
    id: 'sales.cartExpiredErrorModal.reload',
    defaultMessage: 'Reload',
  },
});
