import { jsx as _jsx } from "react/jsx-runtime";
import { injectIntl } from 'react-intl';
import { Tm1Button, Tm1Icon } from '../../v2';
import messages from './messages';
const IconRefresh = injectIntl(({ disabled, onClick, intl }) => (_jsx(Tm1Button, { "aria-label": intl.formatMessage(messages.refresh), disabled: !!disabled, onClick: () => {
        if (!disabled) {
            onClick === null || onClick === void 0 ? void 0 : onClick();
        }
    }, size: "small", variant: "outline", children: _jsx(Tm1Icon, { icon: "refresh" }) })));
export default IconRefresh;
