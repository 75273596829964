import CopyCorrelationId from '@ticketmaster/tm1pos-web-shared/components/CopyCorrelationId/CopyCorrelationId';
import { Tm1Banner, Tm1Button, Tm1Dialog, Tm1DialogFooter } from '@ticketmaster/tm1pos-web-shared/components/v2';
import appMessages from '@ticketmaster/tm1pos-web-shared/messages';
import { FormattedMessage, InjectedIntl, injectIntl } from 'react-intl';
import ReverseTransactionResult from 'containers/Checkout/components/ReverseTransactionResult';
import { checkoutErrorMessages, DEFAULT_CHECKOUT_ERROR_CODE, messages } from '../checkout-error-messages';
import './styles.scss';

type InjectedProps = {
  intl: InjectedIntl;
};

export type Props = {
  paymentFailed: boolean;
  reversePaymentFailed: boolean;
  errorCode: string;
  orderId: string;
  close: () => void;
  cancel?: () => void;
  notes: FormattedMessage.MessageDescriptor[];
  cid?: string;
};

export const CheckoutErrorModal = ({
  errorCode,
  paymentFailed,
  reversePaymentFailed,
  orderId,
  close,
  cancel,
  notes,
  intl,
  cid,
}: Props & InjectedProps) => {
  const getTitle = () => {
    if (paymentFailed) {
      return messages.paymentNotCompleteTitle;
    }
    if (reversePaymentFailed) {
      return messages.refundFailed;
    }
    return messages.checkoutFailed;
  };

  const getNotes = () => {
    if (notes && notes.length) {
      const list = notes.map((note) => (
        <li>
          <FormattedMessage {...note} />
        </li>
      ));
      return (
        <>
          <FormattedMessage {...messages.pleaseNote} />
          <ul>{list}</ul>
        </>
      );
    }
    return null;
  };

  const getContent = () => {
    const note = getNotes();
    const checkoutErrorMessage = (
      <>
        <FormattedMessage
          {...(checkoutErrorMessages[errorCode] ?? checkoutErrorMessages[DEFAULT_CHECKOUT_ERROR_CODE])}
        />
        {note}
      </>
    );
    if (paymentFailed) {
      return (
        <>
          <FormattedMessage {...messages.paymentNotCompleteMessage} values={{ orderId }} />
          <div className="checkout-error__detail">
            <strong>
              <FormattedMessage {...messages.paymentNotCompleteError} />
            </strong>
            {checkoutErrorMessage}
          </div>
          <FormattedMessage {...messages.paymentNotCompleteTip} />
          <Tm1Banner variant="caution" expanded open expandable={false}>
            <FormattedMessage {...messages.paymentNotCompleteWarning} values={{ orderId }} />
          </Tm1Banner>
        </>
      );
    }
    return checkoutErrorMessage;
  };

  const title = getTitle();
  return (
    <div role="dialog" title={intl.formatMessage(title)}>
      <Tm1Dialog open type="error" size="medium" backgroundOverlay>
        <div slot="dialog-header">
          <FormattedMessage {...title} />
        </div>
        <div slot="dialog-content">
          <div className="checkout-error__content">{getContent()}</div>
          <ReverseTransactionResult />
          {cid ? <CopyCorrelationId cid={cid} /> : undefined}
        </div>
        <Tm1DialogFooter slot="footer">
          {cancel ? (
            <>
              <Tm1Button slot="actions" variant="lightweight" onClick={cancel}>
                {intl.formatMessage(messages.cancelOrder)}
              </Tm1Button>
              <Tm1Button slot="actions" onClick={close}>
                {intl.formatMessage(messages.retryPayment)}
              </Tm1Button>
            </>
          ) : (
            <Tm1Button slot="actions" onClick={close}>
              {intl.formatMessage(appMessages.Ok)}
            </Tm1Button>
          )}
        </Tm1DialogFooter>
      </Tm1Dialog>
    </div>
  );
};

export default injectIntl(CheckoutErrorModal);
