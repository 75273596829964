export var ADAType;
(function (ADAType) {
    ADAType["COMPANION"] = "COMPANION";
    ADAType["MOBILITY"] = "MOBILITY";
    ADAType["SIGHT_HEARING"] = "SIGHT_HEARING";
    ADAType["WHEELCHAIR"] = "WHEELCHAIR";
    ADAType["SIGHT"] = "SIGHT";
    ADAType["HEARING"] = "HEARING";
    ADAType["AMBULANT"] = "AMBULANT";
    ADAType["UNSPECIFIED"] = "UNSPECIFIED";
})(ADAType || (ADAType = {}));
