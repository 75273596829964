import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Tm1Icon, Tm1Tooltip } from '@tm1/design-system-react';
import classNames from 'classnames';
import { useState } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { v1 as uuid } from 'uuid';
import { currencyFormatOptions } from '../../../../constants';
import { formatFeeName, formatFeeQuantity } from '../../../../fees/fees-taxes-messages';
import { ToggleArrow } from '../../ToggleArrow/ToggleArrow';
import './styles.scss';
export const PricingDetailsItem = (props) => {
    const { title, price, charges, editFn, tooltipMessage, variant, color, icon, children } = props;
    const dataTestId = props['data-testid'];
    const [isOpen, setIsOpen] = useState(false);
    const hasCharges = !!(charges === null || charges === void 0 ? void 0 : charges.length);
    const handleToggle = () => {
        if (hasCharges) {
            setIsOpen(!isOpen);
        }
    };
    const toggleTitleClass = classNames({
        'pricing-details-item__title': true,
        'pricing-details-item__title--pointer': hasCharges,
    });
    const noToggleTitleClass = classNames({
        'pricing-details-item__title': true,
        'pricing-details-item__title--no-pointer': !hasCharges,
    });
    const variantClass = classNames({
        'pricing-details-item__header': true,
        'variant-small': variant !== 'large',
        'variant-large': variant === 'large',
        'color-red': color === 'red',
    });
    const priceClass = classNames({
        'pricing-details-item__price': true,
        'pricing-details-item__price-negative': price.amount < 0,
    });
    const tooltipAnchorId = uuid();
    const getTitle = () => (isString(title) ? _jsx("span", { children: title }) : _jsx(FormattedMessage, { ...title }));
    const renderSummary = () => (_jsxs("div", { className: variantClass, children: [hasCharges ? (_jsxs("a", { role: "button", tabIndex: -1, className: toggleTitleClass, onClick: handleToggle, children: [_jsx(ToggleArrow, { open: isOpen, "data-testid": "toggle-arrow" }), getTitle()] })) : (_jsxs("span", { className: noToggleTitleClass, children: [icon, getTitle()] })), _jsxs("div", { className: priceClass, children: [editFn && tooltipMessage && (_jsxs(_Fragment, { children: [_jsx(Tm1Icon, { id: tooltipAnchorId, size: "small", icon: "edit", onClick: editFn, "data-testid": "edit-icon" }), _jsx(Tm1Tooltip, { anchor: tooltipAnchorId, position: "top", children: _jsx(FormattedMessage, { ...tooltipMessage }) })] })), _jsx(FormattedNumber, { value: (price === null || price === void 0 ? void 0 : price.amount) || 0, currency: price === null || price === void 0 ? void 0 : price.currency, ...currencyFormatOptions })] })] }));
    const renderDetails = () => (_jsx("div", { className: "pricing-details-item__details-wrap", children: charges === null || charges === void 0 ? void 0 : charges.map((charge) => (_jsxs("div", { className: "pricing-details-item__details", children: [_jsxs("div", { children: [_jsx(FormattedMessage, { ...formatFeeName(charge.name) }), charge.quantity ? (_jsx("span", { className: "pricing-details-item__details__quantity", children: formatFeeQuantity(charge.quantity) })) : null] }), _jsx("div", { className: "pricing-details-item__details__price", children: _jsx(FormattedNumber, { value: charge.amount.amount, currency: charge.amount.currency, ...currencyFormatOptions }) })] }, charge.id))) }));
    return (_jsxs("div", { className: "pricing-details-item", "data-testid": dataTestId, children: [renderSummary(), isOpen && hasCharges ? renderDetails() : null, children ? _jsx("div", { className: "pricing-details-item__details-wrap", children: children }) : null] }));
};
const isString = (value) => typeof value === 'string';
