import { getCurrentEventAttributes } from './tracing-attribute-utils';
export class EventDetailsSpanProcessor {
    constructor() {
        this.eventDetails = undefined;
    }
    forceFlush() {
        return Promise.resolve(undefined);
    }
    onStart(span, parentContext) {
        if (this.eventDetails) {
            span.setAttributes({
                ...getCurrentEventAttributes(this.eventDetails),
            });
        }
    }
    onEnd(span) { }
    shutdown() {
        return Promise.resolve(undefined);
    }
    setEventDetails(eventDetails) {
        this.eventDetails = eventDetails;
    }
}
