import Oidc from 'oidc-client';
import { createUserManager } from 'redux-oidc';
import { userOauth } from '../constants';

/* Identity config */
const userManagerConfig = {
  authority: userOauth.AUTHORITY,
  userinfo_endpoint: userOauth.USERINFO_ENDPOINT,
  authorization_endpoint: userOauth.AUTHORIZATION_ENDPOINT,
  token_endpoint: userOauth.TOKEN_ENDPOINT,
  client_id: userOauth.CLIENT_ID,
  scope: userOauth.SCOPE,
  redirect_uri: userOauth.REDIRECT_URI,
  response_type: userOauth.RESPONSE_TYPE,
  jwks_uri: userOauth.JWKS_URI,
  silent_redirect_uri: userOauth.SILENT_REDIRECT_URI,
  automaticSilentRenew: true,
  revokeAccessTokenOnSignout: true,
  metadata: userOauth.METADATA,
};
const userManager = createUserManager(userManagerConfig);

Oidc.Log.logger = console;
Oidc.Log.level = Oidc.Log.INFO;

export default userManager;
