import { call, put, takeEvery } from 'redux-saga/effects';
import { getTranslations } from './translations-service';
import { loadMessages, setMessages } from './translations-slice';
export function* watchForSetLanguage() {
    yield takeEvery(loadMessages.type, loadTranslations);
}
export function* loadTranslations(action) {
    if (!loadMessages.match(action)) {
        return;
    }
    const { url, language, namespace } = action.payload;
    const messages = yield call(getTranslations, url, language, namespace);
    yield put(setMessages(messages));
}
export const translationsSagaWatchers = [watchForSetLanguage];
