import { createSelector } from '@reduxjs/toolkit';
import ButtonComponent from '@ticketmaster/tm1pos-web-shared/components/ButtonComponent';
import IconClose from '@ticketmaster/tm1pos-web-shared/components/icons/IconClose';
import IconFeedback from '@ticketmaster/tm1pos-web-shared/components/icons/IconFeedback';
import IconGreenCheckmark from '@ticketmaster/tm1pos-web-shared/components/icons/IconGreenCheckmark';
import IconQuestion from '@ticketmaster/tm1pos-web-shared/components/icons/IconQuestion';
import SmallSpinnerComponent from '@ticketmaster/tm1pos-web-shared/components/SmallSpinnerComponent';
import TextareaComponent from '@ticketmaster/tm1pos-web-shared/components/TextareaComponent';
import wrapModal from '@ticketmaster/tm1pos-web-shared/components/utils/ModalWrap';
import sharedMessages from '@ticketmaster/tm1pos-web-shared/messages';
import { intlShape } from '@ticketmaster/tm1pos-web-shared/typings/react-intl-types';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { closeFeedbackModal, submitFeedback } from '../../../../../../actions';
import messages from '../../../../../../messages';
import { selectIsFeedbackSubmitted, selectLoadingFeedbackSubmit } from '../../../../../../selectors';
import Tab from '../Tab';

import './styles.scss';

const feedbackLabel = 'feedback';
const helpLabel = 'help';
const defaultState = {
  feedbackText: '',
};

export class FeedbackModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: helpLabel,
      showTextErr: false,
      ...defaultState,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.isLoading && this.props.isLoading && !nextProps.isSubmitted) {
      this.setState({ ...defaultState, showTextErr: true });
    }
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  get helpContent() {
    const { phone, email, link } = this.props;
    const supportCenterName = <FormattedMessage {...messages.supportCenterName} />;

    const nameBlock = supportCenterName ? (
      <div className="get-help-item">
        <FormattedMessage {...messages.supportHelpText} />
        &nbsp;
        <span className="get-help-item--name">
          <a href={link} target="_blank" className="get-help-item--name">
            <FormattedMessage {...messages.supportHelpLink} values={{ supportCenterName }} />
          </a>
        </span>
      </div>
    ) : null;

    const phoneBlock = phone ? (
      <div className="get-help-item">
        <FormattedMessage
          {...messages.supportHelpPhone}
          values={{
            phoneNumber: (
              <a href={`tel:${phone}`} className="get-help-item--phone">
                {phone}
              </a>
            ),
          }}
        />
      </div>
    ) : null;
    const emailBlock = email ? (
      <div className="get-help-item">
        <FormattedMessage
          {...messages.supportHelpEmail}
          values={{
            email: (
              <a href={`mailto:${email}`} className="get-help-item--email">
                {email}
              </a>
            ),
          }}
        />
      </div>
    ) : null;

    return (
      <div className="get-help-content">
        {nameBlock}
        {phoneBlock}
        {emailBlock}
      </div>
    );
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  get feedbackContent() {
    const { feedbackText, showTextErr } = this.state;
    const textareaPlaceholder = this.props.intl.formatMessage(messages.sendFeedbackPlaceholder);
    return (
      <div className="send-feedback-content">
        <div className="send-feedback__input-wrap">
          <div className="send-feedback__wrapper-text">
            <TextareaComponent
              className="send-feedback__textarea"
              placeholder={textareaPlaceholder}
              onChange={this.onFeedbackTextChange}
              value={this.state.feedbackText}
            />
            {this.state.showTextErr ? (
              <div className="send-feedback__error">
                <FormattedMessage {...messages.feedbackMsgErrorText} />
              </div>
            ) : null}
          </div>
        </div>

        <div className="send-feedback__btn-wrap">
          <ButtonComponent className="oc-unbutton" onButtonClick={this.props.onClose}>
            <FormattedMessage {...sharedMessages.Cancel} />
          </ButtonComponent>
          <ButtonComponent className="oc-btn" onButtonClick={this.sendFeedback} disabled={!feedbackText || showTextErr}>
            {this.props.isLoading ? <SmallSpinnerComponent /> : <FormattedMessage {...messages.sendFeedbackBtnSend} />}
          </ButtonComponent>
        </div>
      </div>
    );
  }

  get tabContent() {
    return this[`${this.state.activeTab}Content`];
  }

  get approveModalContent() {
    return (
      <div className="feedback-modal feedback-approve">
        <div className="feedback-modal__content">
          <div className="feedback-modal__title-wrap feedback-approve__title">
            <div className="feedback-modal__title">
              <IconGreenCheckmark />
              <FormattedMessage {...messages.feedbackMsgReceived} />
            </div>
          </div>
          <div className="feedback-modal__msg feedback-approve__msg">
            <FormattedMessage {...messages.feedbackMsgReceivedTip} />
          </div>
          <div className="feedback-approve__btn">
            <ButtonComponent className="oc-btn" onButtonClick={this.closeApproveModal}>
              <FormattedMessage {...messages.feedbackOk} />
            </ButtonComponent>
          </div>
        </div>
      </div>
    );
  }

  onTabClick = (activeTab) => {
    this.setState({ activeTab });
  };

  onFeedbackTextChange = (feedbackText) => {
    this.setState({ feedbackText, showTextErr: false });
  };

  sendFeedback = () => {
    this.props.dispatch(
      submitFeedback({
        feedbackText: this.state.feedbackText,
      }),
    );
  };

  closeApproveModal = () => {
    this.props.onClose();
    this.props.dispatch(closeFeedbackModal());
  };

  render() {
    if (this.props.isSubmitted) {
      return this.approveModalContent;
    }
    return (
      <div className="feedback-modal">
        <div className="feedback-modal__content">
          <div className="feedback-modal__title-wrap">
            <div className="feedback-modal__title">
              <FormattedMessage {...messages.feedbackModalTitle} />
            </div>
            <a role="button" onClick={this.props.onClose} tabIndex={-1} className="feedback-modal__close">
              <IconClose />
            </a>
          </div>
          <div className="feedback-modal__msg">
            <FormattedMessage {...messages.feedbackModalMsg} />
          </div>
          <div className="feedback-modal__tabs-content">
            <div className="feedback-modal__tabs-wrap">
              <ul className="feedback-modal-tabs">
                <Tab
                  activeTabLabel={this.state.activeTab}
                  label={helpLabel}
                  icon={IconQuestion}
                  onClick={this.onTabClick}
                  message={messages.feedbackModalTab1}
                />
                <Tab
                  activeTabLabel={this.state.activeTab}
                  label={feedbackLabel}
                  icon={IconFeedback}
                  onClick={this.onTabClick}
                  message={messages.feedbackModalTab2}
                />
              </ul>
              <div className="feedback-modal__active-content">{this.tabContent}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

FeedbackModal.propTypes = {
  onClose: PropTypes.func,
  intl: intlShape.isRequired,
  // props with contacts info
  email: PropTypes.string,
  phone: PropTypes.string,
  link: PropTypes.string,
  isLoading: PropTypes.bool,
  isSubmitted: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({ dispatch });
const mapStateToProps = createSelector(
  selectLoadingFeedbackSubmit,
  selectIsFeedbackSubmitted,
  (isLoading, isSubmitted) => ({ isLoading, isSubmitted }),
);

export default connect(mapStateToProps, mapDispatchToProps)(wrapModal(injectIntl(FeedbackModal)));
