import { ApolloClientFactory as ApolloClientFactoryV3Beta1 } from '@tm1/core/lib/dependencies/apollo-client/v3beta1';
import { ApolloClientFactory as ApolloClientFactoryV3Beta2 } from '@tm1/core/lib/dependencies/apollo-client/v3beta2';
import { v1 as FeatureFlagsV1 } from '@tm1/core/lib/dependencies/feature-flags';
import { TM1Navigator } from '@tm1/core/lib/dependencies/navigator/v1beta1';
import { type DependenciesFactories, DependenciesProvider } from '@tm1/core/lib/dependencies/v2/provider';
import { RegisterEventHandler } from '@tm1/core/lib/events/register-event/handler';
import { PropsWithChildren } from 'react';
import type {
  ActivatedRoute,
  BreadcrumbService,
  CommandQueue,
  Dispatcher,
  Logger,
  Translator,
} from '@ticketmaster/tm1-core';
import type { AnalyticsProvider } from '@tm1/types/_/dependencies/analytics/v1beta1';
import type { ComponentInfo } from '@tm1/types/_/dependencies/component-info/v1beta1';
import type { PermissionsEvaluator } from '@tm1/types/_/dependencies/permissions/v1';
import type { UserInfo } from '@tm1/types/_/dependencies/user-info/v1beta1';

type RegisterEventListerProps = PropsWithChildren<unknown> & {
  activatedRoute?: ActivatedRoute;
  analytics?: AnalyticsProvider;
  apolloClientV3Beta1?: ApolloClientFactoryV3Beta1;
  apolloClientV3Beta2?: ApolloClientFactoryV3Beta2;
  breadcrumb?: BreadcrumbService;
  commandQueue?: CommandQueue;
  componentInfo?: ComponentInfo;
  dispatcher?: Dispatcher;
  featureFlagsService?: FeatureFlagsV1.FeatureFlagsService;
  logger?: Logger;
  navigator?: TM1Navigator;
  permissions?: PermissionsEvaluator;
  translator?: Translator;
  userInfo?: UserInfo;
};

/**
 * @example
 * (arg1?: any, ...params: any[]): boolean => {
 *   if (typeof arg1 === 'undefined') {
 *     // Check if the current log level is enabled (noop operation)
 *   } else if (arg1 instanceof Error) {
 *     // Log an Error instance
 *     console.warn({ err: arg1, msg: arg1.message, ...params });
 *   } else if (typeof arg1 === 'object' && !Array.isArray(arg1)) {
 *     // Add some logging fields to the payload
 *     console.log({ ...arg1, ...params });
 *   } else if (typeof arg1 === 'string') {
 *     // Log any payload
 *     console.log(arg1, ...params);
 *   }
 *   return true;
 * },
 */
const defaultLogFunction = (arg1?: any, ...params: any[]): boolean => true; // eslint-disable-line @typescript-eslint/no-unused-vars

const defaultLogger: Logger = {
  debug: defaultLogFunction,
  error: defaultLogFunction,
  fatal: defaultLogFunction,
  info: defaultLogFunction,
  trace: defaultLogFunction,
  warn: defaultLogFunction,
};

const missingDependencyMessage = (dependencyName: string) => `Dependency "${dependencyName}" is missing`;

const noOpDispatcher = { dispatch: () => {} } as unknown as Dispatcher;
const noOpActivatedRoute: ActivatedRoute = { route: '' };
const noOpTranslator = { language: 'en-US' } as Translator;

const throwDependencyNotImplementedError = (dependency: string) => {
  throw new Error(`Dependency "${dependency}" is not implemented`);
};

export const RegisterEventV2Listener = ({
  activatedRoute = noOpActivatedRoute,
  analytics,
  apolloClientV3Beta1,
  apolloClientV3Beta2,
  breadcrumb,
  children,
  commandQueue,
  componentInfo,
  dispatcher = noOpDispatcher,
  featureFlagsService: featureFlags,
  logger = defaultLogger,
  navigator,
  permissions,
  translator = noOpTranslator,
  userInfo,
}: RegisterEventListerProps) => {
  /**
   * This is the `Sales` custom implementation of the `DependencyProvider`
   *
   *   It is based on https://git.tmaws.io/tm1/tm1/-/blob/master/packages/app/src/injection-tokens/providers/tm1-dependencies.provider.ts
   *
   * The goal is to mimic what is done for UFO so we can use the same dependency injection pattern in both Sales and UFO.
   *
   *
   * 🚨 Important 🚨
   *
   * Whenever a dependency is not implemented, we throw an error. This also means at the moment, there's a lot of unreachable code.
   * It was made this way to avoid fighting with the typings and make it obvious the dependency was NOT implemented.
   *
   * There are 2 ways to provide a dependency:
   * 1. Pass it as a `prop` from the parent component
   * 2. Implement a custom one in the `DependencyProvider`
   *
   * In both cases, you will need to remove the `throwDependencyNotImplementedError()` call at that point.
   */
  const dependenciesProvider = new DependenciesProvider(
    {
      activatedRoute: {
        v1: () => {
          if (!activatedRoute) {
            throw new Error(missingDependencyMessage('activatedRoute'));
          }
          return activatedRoute;
        },
      },
      additionalDependencies: {
        v1: ({ collectedDependencies }) => collectedDependencies,
      },
      analytics: {
        v1beta1: () => {
          throwDependencyNotImplementedError('analytics');

          if (!analytics) {
            throw new Error(missingDependencyMessage('analytics'));
          }
          return analytics;
        },
      },
      apolloClient: {
        v3beta1: () => {
          throwDependencyNotImplementedError('apolloClientV3Beta1');

          if (!apolloClientV3Beta1) {
            throw new Error(missingDependencyMessage('apolloClientV3Beta1'));
          }
          return apolloClientV3Beta1;
        },
        v3beta2: () => {
          throwDependencyNotImplementedError('apolloClientV3Beta2');

          if (!apolloClientV3Beta2) {
            throw new Error(missingDependencyMessage('apolloClientV3Beta2'));
          }
          return apolloClientV3Beta2;
        },
      },
      breadcrumb: {
        v1beta1: () => {
          throwDependencyNotImplementedError('breadcrumb');

          if (!breadcrumb) {
            throw new Error(missingDependencyMessage('breadcrumb'));
          }
          return breadcrumb;
        },
      },
      commandQueue: {
        v1: () => {
          throwDependencyNotImplementedError('commandQueue');

          if (!commandQueue) {
            throw new Error(missingDependencyMessage('commandQueue'));
          }
          return commandQueue;
        },
      },
      componentInfo: {
        v1beta1: () => {
          throwDependencyNotImplementedError('componentInfo');

          if (!componentInfo) {
            throw new Error(missingDependencyMessage('componentInfo'));
          }
          return componentInfo;
        },
      },
      dispatcher: {
        v1: () => {
          if (!dispatcher) {
            throw new Error(missingDependencyMessage('dispatcher'));
          }
          return dispatcher;
        },
      },
      featureFlags: {
        v1: () => {
          throwDependencyNotImplementedError('featureFlags');

          if (!featureFlags) {
            throw new Error(missingDependencyMessage('featureFlags'));
          }
          return featureFlags;
        },
      },
      logger: {
        v1: () => {
          throwDependencyNotImplementedError('logger');

          if (!logger) {
            throw new Error(missingDependencyMessage('logger'));
          }
          return logger;
        },
      },
      navigator: {
        v1beta1: () => {
          throwDependencyNotImplementedError('navigator');

          if (!navigator) {
            throw new Error(missingDependencyMessage('navigator'));
          }
          return navigator;
        },
      },
      permissions: {
        v1: () => {
          throwDependencyNotImplementedError('permissions');

          if (!permissions) {
            throw new Error(missingDependencyMessage('permissions'));
          }
          return permissions;
        },
      },
      translator: {
        v1: () => {
          if (!translator) {
            throw new Error(missingDependencyMessage('translator'));
          }
          return translator;
        },
      },
      userInfo: {
        v1beta1: () => {
          throwDependencyNotImplementedError('userInfo');

          if (!userInfo) {
            throw new Error(missingDependencyMessage('userInfo'));
          }
          return userInfo;
        },
      },
    } satisfies DependenciesFactories,
    logger,
  );

  const registerEventHandler = new RegisterEventHandler(dependenciesProvider);
  registerEventHandler.addEventListener(document); // Using `document` here is fine as long as `Sales` is not a Web Component

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
