import {
  fetchHostPermissionsError,
  fetchUserArchticsIdFailed,
} from '@ticketmaster/tm1pos-web-shared/store/user-information/user.slice';
import { List } from 'immutable';

import { MULTIPLE_DATASOURCE_FROM_OPCODES_ERROR, PROMPT_ORDER_DELETE } from '../../containers/App/actions-constants';

import { CREDIT_CARD_SWIPE_ERROR, DELIVERY_ERROR } from '../../containers/Checkout/components/Form/actions-constants';
import {
  ARCHTICS_EVENT_SEAT_STATUS_FETCH,
  CART_IN_WRONG_STATE_ERROR,
  CART_SPLIT_ORDER_NOT_SUPPORTED_ERROR,
  INITIAL_ARCHTICS_EVENT_SEAT_STATUS_FETCH,
  NO_SEATS_AVAILABLE,
  NO_TICKETTYPES_AVAILABLE,
  PLACE_TO_RESERVE_FETCH,
  SEAT_DATA_LOAD_FETCH,
  TICKET_TYPE_NOT_AVAILABLE_ERROR,
  UNSUPPORTED_EVENT,
} from '../../containers/EventDetailPage/constants';
import factory from './actionCreator';

// list of failed events that need to be handled
const eventsList = [
  PLACE_TO_RESERVE_FETCH.ERROR,
  INITIAL_ARCHTICS_EVENT_SEAT_STATUS_FETCH.ERROR,
  SEAT_DATA_LOAD_FETCH.ERROR,
  ARCHTICS_EVENT_SEAT_STATUS_FETCH.ERROR,
  CREDIT_CARD_SWIPE_ERROR,
  NO_SEATS_AVAILABLE,
  NO_TICKETTYPES_AVAILABLE,
  TICKET_TYPE_NOT_AVAILABLE_ERROR,
  CART_IN_WRONG_STATE_ERROR,
  CART_SPLIT_ORDER_NOT_SUPPORTED_ERROR,
  DELIVERY_ERROR,
  fetchUserArchticsIdFailed.type,
  fetchHostPermissionsError.type,
  PROMPT_ORDER_DELETE,
  UNSUPPORTED_EVENT,
  MULTIPLE_DATASOURCE_FROM_OPCODES_ERROR,
];
const ignoreAction = (action, errorNotifications = List()) => {
  const notOnList = eventsList.indexOf(action.type) === -1;
  if (!notOnList && action) {
    // make sure dupe notifications do not occur
    return Boolean(errorNotifications.find((item) => item.failedActionName && item.failedActionName === action.type));
  }
  return notOnList;
};
export function createErrorNotificationMiddleware() {
  return (store) => (next) => (action) => {
    const state = store.getState();
    let errorNotifications = List();
    if (state) {
      errorNotifications = state.errors.getIn(['errorNotifications'], List());
    }
    if (!action || !action.type || ignoreAction(action, errorNotifications)) {
      return next(action);
    }
    const errorAction = factory(action);
    if (errorAction.type) {
      store.dispatch(errorAction);
    }

    return next(action);
  };
}

export { eventsList };
