import { defineMessages, FormattedMessage } from 'react-intl';

export const messages = defineMessages({
  checkoutFailed: {
    id: 'sales.Checkout.error.title.checkout-failed',
    defaultMessage: 'Unable to complete checkout',
  },
  refundFailed: {
    id: 'sales.Checkout.error.title.refund-failed',
    defaultMessage: 'Unable to refund the customer',
  },
  paymentNotCompleteTitle: {
    id: 'sales.errors.paymentNotCompleteTitle',
    defaultMessage: 'Payment not completed',
  },
  paymentNotCompleteMessage: {
    id: 'sales.errors.paymentNotCompleteMessage',
    defaultMessage:
      'The items in order #{orderId} have been SOLD but the system is unable to complete the payment due to the following element:',
  },
  paymentNotCompleteTip: {
    id: 'sales.errors.paymentNotCompleteTip',
    defaultMessage: 'Please try another payment or click "Cancel Order" to release the seats.',
  },
  paymentNotCompleteError: {
    id: 'sales.errors.paymentNotCompleteError',
    defaultMessage: 'Error:',
  },
  paymentNotCompleteWarning: {
    id: 'sales.errors.paymentNotCompleteWarning',
    defaultMessage:
      'If you leave TM1 Sales or open a new TM1 Sales page, you will need to fix order #{orderId} manually.',
  },
  retryPayment: {
    id: 'sales.errors.retryPayment',
    defaultMessage: 'Retry Payment',
  },
  cancelOrder: {
    id: 'sales.errors.cancelOrder',
    defaultMessage: 'Cancel Order',
  },
  pleaseNote: {
    id: 'sales.warning.pleaseNote',
    defaultMessage: 'Please note:',
  },
  pendingTransactionWarning: {
    id: 'sales.warning.pendingTransaction',
    defaultMessage:
      'The sale may appear in the card holders account as a pending transaction, and reduce their available balance temporarily.',
  },
  chargeReleaseWarning: {
    id: 'sales.warning.chargeRelease',
    defaultMessage:
      'Let the customer know any pending charges related to this card activity will usually be released within 24-28 hours.',
  },
});

// messages exported from version "export 1 to sales UI", sheet Backend Errors https://docs.google.com/spreadsheets/d/1hnMGxCqYMwdPURV5oawGBdiI8YE5RKCMrapM9mJ_EzY/edit?usp=sharing
export const checkoutErrorMessages: { [key: string]: FormattedMessage.MessageDescriptor } = defineMessages({
  CARD_REJECT_INTERNAL_ERROR: {
    id: 'sales.Checkout.error.CARD_REJECT_INTERNAL_ERROR',
    defaultMessage: 'An unknown system error occurred. Try using a different card or payment method.',
  },
  CARD_REJECT_UNKNOWN_RESPONSE: {
    id: 'sales.Checkout.error.CARD_REJECT_UNKNOWN_RESPONSE',
    defaultMessage: 'An unknown system error was returned by the bank. Try using a different card or payment method.',
  },
  CARD_REJECT_FRAUD: {
    id: 'sales.Checkout.error.CARD_REJECT_FRAUD',
    defaultMessage: 'Card authorization failure. Try using a different card or payment method.',
  },
  CARD_REJECT_BAD_DATA: {
    id: 'sales.Checkout.error.CARD_REJECT_BAD_DATA',
    defaultMessage: 'Card swipe unsuccessful. Try again or use a different payment method.',
  },
  EMAIL_INVALID: {
    id: 'sales.Checkout.error.EMAIL_INVALID',
    defaultMessage: 'Invalid email address. Try again with a different email address.',
  },
  CART_ID_INVALID_OR_EXPIRED: {
    id: 'sales.Checkout.error.CART_ID_INVALID_OR_EXPIRED',
    defaultMessage:
      'Our system encountered an error while creating the order. The cart was not found or has expired. Please reserve new seats.',
  },
  EMPTY_SHOPPING_CART: {
    id: 'sales.Checkout.error.EMPTY_SHOPPING_CART',
    defaultMessage:
      'Our system encountered an error while creating the order. The cart was not found or has expired. Please reserve new seats.',
  },
  SERVICE_UNKNOWN_ERROR: {
    id: 'sales.Checkout.error.SERVICE_UNKNOWN_ERROR',
    defaultMessage:
      'Our system encountered an unexpected error. Try again or if the problem persists, contact your Ticketmaster representative for assistance.',
  },
  UNREACHABLE_ENDPOINT_ERROR: {
    id: 'sales.Checkout.error.UNREACHABLE_ENDPOINT_ERROR',
    defaultMessage:
      'Our system timed out. Try again or if the problem persists, contact your Ticketmaster representative for assistance.',
  },
  SERVICE_TIMEOUT: {
    id: 'sales.Checkout.error.SERVICE_TIMEOUT',
    defaultMessage:
      'Our system timed out. Try again or if the problem persists, contact your Ticketmaster representative for assistance.',
  },
  CARD_REJECT_LOST_OR_STOLEN: {
    id: 'sales.Checkout.error.CARD_REJECT_LOST_OR_STOLEN',
    defaultMessage:
      'Payment declined. The card has been reported lost or stolen by its owner. Try using a different card or payment method.',
  },
  CARD_REJECT_GENERAL_DECLINE: {
    id: 'sales.Checkout.error.CARD_REJECT_GENERAL_DECLINE',
    defaultMessage:
      'Payment declined. The card has insufficient funds or exceeded its limit. Try using a different card or payment method.',
  },
  CARD_REJECT_CALL_FOR_AUTH: {
    id: 'sales.Checkout.error.CARD_REJECT_CALL_FOR_AUTH',
    defaultMessage:
      'Payment declined. The transaction has not been authorized by the card issuer. Try using another card or payment method.',
  },
  USER_MISSING_REQUIRED_MOP_PERMISSION: {
    id: 'sales.Checkout.error.USER_MISSING_REQUIRED_MOP_PERMISSION',
    defaultMessage:
      "System authorization failure. There's a problem with your user configuration, contact your Ticketmaster representative for assistance.",
  },
  CARD_REJECT_UNKNOWN_BANK: {
    id: 'sales.Checkout.error.CARD_REJECT_UNKNOWN_BANK',
    defaultMessage:
      'The bank configured for the merchant ID is not set up to process transactions. Please contact your Ticketmaster representative for assistance.',
  },
  CARD_REJECT_INVALID_TRANSACTION_TYPE: {
    id: 'sales.Checkout.error.CARD_REJECT_INVALID_TRANSACTION_TYPE',
    defaultMessage: 'Checkout Error',
  },
  CARD_EXPIRED: {
    id: 'sales.Checkout.error.CARD_EXPIRED',
    defaultMessage: 'The card is expired. Try again with a different card or use a different payment method.',
  },
  CARD_EXPIRED_OR_INVALID: {
    id: 'sales.Checkout.error.CARD_EXPIRED_OR_INVALID',
    defaultMessage: 'The card expiration date is invalid or expired. Try using a different card or payment method.',
  },
  CARD_EXPIRATION_INVALID: {
    id: 'sales.Checkout.error.CARD_EXPIRATION_INVALID',
    defaultMessage: 'The card expiration date is invalid. Try again or use a different payment method.',
  },
  CARD_INVALID_PAN: {
    id: 'sales.Checkout.error.CARD_INVALID_PAN',
    defaultMessage: 'The card number is invalid. Try again or use a different payment method.',
  },
  CARD_SWIPE_ERROR_OR_MISSING_PERMISSION: {
    id: 'sales.Checkout.error.CARD_SWIPE_ERROR_OR_MISSING_PERMISSION',
    defaultMessage: 'The magnetic stripe caused a system error. Try again or use a different payment method.',
  },
  CARD_REJECT_INVALID_MERCHANT_NUMBER: {
    id: 'sales.Checkout.error.CARD_REJECT_INVALID_MERCHANT_NUMBER',
    defaultMessage:
      'The merchant ID was not found. Contact your Ticketmaster representative for assistance or use a different payment method.',
  },
  PAYMENT_WRONG_AMOUNT: {
    id: 'sales.Checkout.error.PAYMENT_WRONG_AMOUNT',
    defaultMessage:
      'The order must be paid with the exact amount. Try again or use a different payment method. If the problem persists contact your Ticketmaster representative for assistance.',
  },
  PAYMENT_SYSTEM_UNKNOWN_ERROR: {
    id: 'sales.Checkout.error.PAYMENT_SYSTEM_UNKNOWN_ERROR',
    defaultMessage:
      'The payment system encountered an error while creating the order. Try again or if the problem persists, contact your Ticketmaster representative for assistance.',
  },
  PAYMENT_TOKEN_INVALID: {
    id: 'sales.Checkout.error.PAYMENT_TOKEN_INVALID',
    defaultMessage: 'The payment token is invalid. Try again or use a different payment method.',
  },
  PAYMENT_REJECTED: {
    id: 'sales.Checkout.error.PAYMENT_REJECTED',
    defaultMessage:
      'The payment was declined by the ticketing system. Try again with a different card or payment method.',
  },
  PAYMENT_PROCESSING_ERROR: {
    id: 'sales.Checkout.error.PAYMENT_PROCESSING_ERROR',
    defaultMessage:
      'The system encountered an error while processing the payment. Try again or use a different payment method.',
  },
  ORDER_CREATION_ERROR: {
    id: 'sales.Checkout.error.ORDER_CREATION_ERROR',
    defaultMessage:
      'The ticketing system encountered an error while creating the order. Try again or if the problem persists, contact your Ticketmaster representative for assistance.',
  },
  TICKETING_SYSTEM_FORMAT_ERROR: {
    id: 'sales.Checkout.error.TICKETING_SYSTEM_FORMAT_ERROR',
    defaultMessage:
      "The ticketing system received a request that wasn't properly formatted. This may be caused by a bad character (such as a special accent) or by a system error. Try again or if the problem persists, contact your Ticketmaster representative for assistance.",
  },
  TICKETING_SYSTEM_UNKNOWN_ERROR: {
    id: 'sales.Checkout.error.TICKETING_SYSTEM_UNKNOWN_ERROR',
    defaultMessage:
      'The ticketing system encountered an error while creating the order. Try again or if the problem persists, contact your Ticketmaster representative for assistance.',
  },
  TICKETING_SYSTEM_MAINTENANCE: {
    id: 'sales.Checkout.error.TICKETING_SYSTEM_MAINTENANCE',
    defaultMessage: 'The ticketing system is under maintenance. Try again later.',
  },
  CARD_REJECT_AMOUNT_NOT_ALLOWED: {
    id: 'sales.Checkout.error.CARD_REJECT_AMOUNT_NOT_ALLOWED',
    defaultMessage:
      'The transaction amount is invalid. The amount should be greater than zero and should not exceed the capacity or limit of the card. Try again or use a different payment method.',
  },
  TICKETING_SYSTEM_TIMEOUT: {
    id: 'sales.Checkout.error.TICKETING_SYSTEM_TIMEOUT',
    defaultMessage: 'The transaction timed out on the ticketing system. Try again or use a different payment method.',
  },
  PAYMENT_SYSTEM_TIMEOUT_ERROR: {
    id: 'sales.Checkout.error.PAYMENT_SYSTEM_TIMEOUT_ERROR',
    defaultMessage:
      'The transaction timed out while waiting for authorization. Try again or use a different payment method.',
  },
  PAYMENT_TYPE_NOT_PERMITTED: {
    id: 'sales.Checkout.error.PAYMENT_TYPE_NOT_PERMITTED',
    defaultMessage:
      "There's a problem with your user configuration: the Archtics Payment type privilege is missing. Please contact your Ticketmaster representative for assistance.",
  },
  CARD_REJECT_INVALID_MERCHANT_MOP: {
    id: 'sales.Checkout.error.CARD_REJECT_INVALID_MERCHANT_MOP',
    defaultMessage: 'Card Authorization Failure. Invalid Method of Payment for merchant',
  },
  CARD_UNSUPPORTED_TYPE: {
    id: 'sales.Checkout.error.CARD_UNSUPPORTED_TYPE',
    defaultMessage:
      'This payment method is not accepted by the ticketing system. Try using a different payment method.',
  },
  TRANSACTION_MISSING_PAN: {
    id: 'sales.Checkout.error.TRANSACTION_MISSING_PAN',
    defaultMessage: 'This type of transaction requires a card number. Try again or use a different method of payment.',
  },
  CARD_REJECT_MOP_NOT_ALLOWED: {
    id: 'sales.Checkout.error.CARD_REJECT_MOP_NOT_ALLOWED',
    defaultMessage:
      'This user is not configured to process this method of payment. Try using a different card or payment method.',
  },
  TICKET_TYPE_NOT_AVAILABLE: {
    id: 'sales.Checkout.error.TICKET_TYPE_NOT_AVAILABLE',
    defaultMessage:
      'This combination of the inventory and ticket type is currently not available. Change your selection or enable the relevant combination on the ticketing system.',
  },
  OPTION_MAYBE_RESTRICTED: {
    id: 'sales.Checkout.error.OPTION_MAYBE_RESTRICTED',
    defaultMessage:
      'Update of the account options was denied by the ticketing system.  Try again or if the problem persists, contact your Ticketmaster representative for assistance.',
  },
  TICKETLIMIT_BELOW_MINIMUM: {
    id: 'sales.Checkout.error.TICKETLIMIT_BELOW_MINIMUM',
    defaultMessage:
      'The ticketing system encountered an error while creating the order. One of the selected Ticket Types is below the ticket minimum.',
  },
  TICKETLIMIT_EXCEED_MAXIMUM: {
    id: 'sales.Checkout.error.TICKETLIMIT_EXCEED_MAXIMUM',
    defaultMessage:
      'The ticketing system encountered an error while creating the order. One of the selected Ticket Types exceeds the maximum ticket limit.',
  },
  TICKETLIMIT_VIOLATE_MULTIPLIER: {
    id: 'sales.Checkout.error.TICKETLIMIT_VIOLATE_MULTIPLIER',
    defaultMessage:
      'The ticketing system encountered an error while creating the order. One of the selected Ticket Types does not comply with the ticket multiplier value (example: tickets required to be sold in pairs).',
  },
  COSELL_BELOW_MINIMUM: {
    id: 'sales.Checkout.error.COSELL_BELOW_MINIMUM',
    defaultMessage:
      'The ticketing system encountered an error while creating the order. One of the selected Ticket Types is below the required minimum quantity.',
  },
  COSELL_EXCEED_MAXIMUM: {
    id: 'sales.Checkout.error.COSELL_EXCEED_MAXIMUM',
    defaultMessage:
      'The ticketing system encountered an error while creating the order. One of the selected Ticket Types exceeds the maximum quantity.',
  },
  COSELL_VIOLATE_MULTIPLIER: {
    id: 'sales.Checkout.error.COSELL_VIOLATE_MULTIPLIER',
    defaultMessage:
      'The ticketing system encountered an error while creating the order. One of the selected Ticket Types does not comply with the multiplier value (example: ticket type required to be sold in pairs).',
  },
  COSELL_VIOLATE_EXACT: {
    id: 'sales.Checkout.error.COSELL_VIOLATE_EXACT',
    defaultMessage:
      'The ticketing system encountered an error while creating the order. One of the selected Ticket Types requires to be sold with an exact quantity of tickets.',
  },
  COSELL_VIOLATE_RATIO: {
    id: 'sales.Checkout.error.COSELL_VIOLATE_RATIO',
    defaultMessage:
      'The ticketing system encountered an error while creating the order. One of the selected Ticket Types does not comply with the ratio value.',
  },
  COSELL_MASK_BELOW_MINIMUM: {
    id: 'sales.Checkout.error.COSELL_MASK_BELOW_MINIMUM',
    defaultMessage:
      'The ticketing system encountered an error while creating the order. One of the selected Ticket Types was sold below the required minimum quantity.',
  },
  COSELL_MASK_EXCEED_MAXIMUM: {
    id: 'sales.Checkout.error.COSELL_MASK_EXCEED_MAXIMUM',
    defaultMessage:
      'The ticketing system encountered an error while creating the order. One of the selected Ticket Types was sold above the maximum quantity.',
  },
  COSELL_MASK_VIOLATE_MULTIPLIER: {
    id: 'sales.Checkout.error.COSELL_MASK_VIOLATE_MULTIPLIER',
    defaultMessage:
      'The ticketing system encountered an error while creating the order. One of the selected Ticket Types does not comply with the multiplier value (example: ticket type required to be sold in pairs).',
  },
  COSELL_MASK_VIOLATE_EXACT: {
    id: 'sales.Checkout.error.COSELL_MASK_VIOLATE_EXACT',
    defaultMessage:
      'The ticketing system encountered an error while creating the order. One of the selected Ticket Types requires to be sold with an exact quantity of tickets.',
  },
  COSELL_MASK_VIOLATE_ASSOCIATION: {
    id: 'sales.Checkout.error.COSELL_MASK_VIOLATE_ASSOCIATION',
    defaultMessage:
      'The ticketing system encountered an error while creating the order. One of the selected Ticket Types must be sold with another type of ticket.',
  },
  ORDER_EXCEEDS_EVENT_MAXIMUM: {
    id: 'sales.Checkout.error.ORDER_EXCEEDS_EVENT_MAXIMUM',
    defaultMessage:
      'The ticketing system encountered an error while creating the order. One of the selected Ticket Types exceeds the maximum event limit.',
  },
});

export const DEFAULT_CHECKOUT_ERROR_CODE = 'SERVICE_UNKNOWN_ERROR';

checkoutErrorMessages[DEFAULT_CHECKOUT_ERROR_CODE] = {
  id: 'sales.Checkout.error.SERVICE_UNKNOWN_ERROR',
  defaultMessage:
    'Our system encountered an unexpected error. Try again or if the problem persists, contact your Ticketmaster representative for assistance.',
};

checkoutErrorMessages.GENERIC_ERROR = checkoutErrorMessages.SERVICE_UNKNOWN_ERROR;
