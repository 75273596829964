import { createAction } from '@reduxjs/toolkit';
import { InjectedIntl } from 'react-intl';
import { ICE } from '../../modules/window';
import { IsmrtPlaceData } from './model/IsmrtPlaceData';
import { SeatStatusPlace } from './model/SeatStatusPlace';

export type FinishLoadingCurrentEventPagePayload = {
  seatAmount: number;
  priceLevelAmount: number;
};

export type IsmrtSnapshotDataSuccessPayload = {
  places: IsmrtPlaceData[];
};

export type SetSeatStatusesPayload = {
  places: SeatStatusPlace[];
};

export type UpdateSeatStatusesPayload = {
  placeUpdates: SeatStatusPlace[];
};

export type UpdateIcePlacesPayload = {
  ice: ICE;
  data: SeatStatusPlace[];
  intl: InjectedIntl;
};

export const startLoadingCurrentEventPage = createAction('event/startLoadingCurrentEventPage');

export const finishLoadingCurrentEventPage = createAction<FinishLoadingCurrentEventPagePayload>(
  'event/finishLoadingCurrentEventPage',
);

export const updateIcePlaces = createAction<UpdateIcePlacesPayload>('event/updateIcePlaces');

export const subscribeToIsmrtPlaceUpdates = createAction('event/subscribeToIsmrtPlaceUpdates');

export const unsubscribeFromIsmrtPlaceUpdates = createAction('event/unsubscribeFromIsmrtPlaceUpdates');

export const setSeatStatuses = createAction<SetSeatStatusesPayload>('event/setSeatStatuses');

export const updateSeatStatuses = createAction<UpdateSeatStatusesPayload>('event/updateSeatStatuses');

export const ismrtSnapshotDataLoading = createAction('event/ismrtSnapshotDataLoading');

export const ismrtSnapshotDataSuccess = createAction<IsmrtSnapshotDataSuccessPayload>('event/ismrtSnapshotDataSuccess');

export const ismrtSnapshotDataError = createAction('event/ismrtSnapshotDataError');

export const geometryLoaded = createAction('event/geometryLoaded');

export const reloadIsmrtSnapshot = createAction('event/reloadIsmrtSnapshot');
