import { notTranslatedMessage } from '../../translations';
import messages from '../printing-message';
import { PrintingDpi, PrintingMethod } from '../printingModule-types';
export class UnsupportedPrintingMethodError extends Error {
    constructor(method) {
        super();
        this.method = method;
        this.name = 'UnsupportedPrintingMethodError';
    }
}
const isUnsupportedPrintingMethodError = (error) => error.name === 'UnsupportedPrintingMethodError';
export const getPrinterData = (printer, data) => {
    switch (printer.method) {
        case PrintingMethod.PDF:
            return {
                type: 'pixel',
                format: 'pdf',
                flavor: 'base64',
                data,
            };
        case PrintingMethod.BOCA:
            return {
                type: 'raw',
                format: 'command',
                flavor: 'base64',
                options: {
                    dotDensity: printer.dpi === PrintingDpi.Dpi200 ? 200 : 300,
                },
                data,
            };
        case PrintingMethod.PS:
            return {
                type: 'raw',
                format: 'command',
                flavor: 'base64',
                data,
            };
        case PrintingMethod.PNG:
            return {
                type: 'pixel',
                format: 'image',
                flavor: 'base64',
                options: {
                    dotDensity: printer.dpi === PrintingDpi.Dpi200 ? 200 : 300,
                },
                data,
            };
        case PrintingMethod.FGL:
        default:
            throw new UnsupportedPrintingMethodError(printer.method);
    }
};
export const getPrinterError = (error) => {
    if (isUnsupportedPrintingMethodError(error)) {
        return {
            title: messages.notSupportedMethod,
        };
    }
    return {
        title: notTranslatedMessage(error.message),
    };
};
