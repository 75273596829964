import { GraphQLResult } from '@ticketmaster/tm1pos-web-shared/model/graphql';
import { fetchFromSalesApi } from '@ticketmaster/tm1pos-web-shared/services/sales-api-client/sales-api';
import { call, Effect } from 'redux-saga/effects';
import { POS_GRAPHQL_URL } from '../../constants';
import { SET_CART_EVENT_FEES_MUTATION } from './api-queries';
import { CartResponse } from './model/cart-response';
import { CartEventFeesInput } from './model/set-cart-event-fees-input';

export function* setCartEventFees(
  params: CartEventFeesInput,
): Generator<Effect, GraphQLResult<CartResponse>, GraphQLResult<CartResponse>> {
  return yield call(fetchFromSalesApi, {
    url: POS_GRAPHQL_URL,
    query: SET_CART_EVENT_FEES_MUTATION,
    options: {
      variables: {
        input: params,
      },
    },
  });
}
