import { defineMessages } from 'react-intl';
export const PDG_REQUEST_UNREACHABLE = 'pdg.request.unreachable';
const PDG_TIMEOUT = 'pdg.error.timeout';
const PDG_INVALID_JWT = 'pdg.error.jwt.invalid';
const PDG_INVALID_FIELD = 'pdg.error.field.invalid';
const PDG_TRANSACTION_NOT_AUTHORIZED = 'pdg.error.transaction.not.authorized';
const PDG_TRANSACTION_NOT_FOUND = 'pdg.error.transaction.not.found';
const PDG_REFERENCE_NOT_FOUND = 'pdg.error.reference.not.found';
const PDG_TRANSACTION_DUPLICATE = 'pdg.error.transaction.duplicate';
const PDG_DEVICE_INVALID = 'pdg.error.device.invalid';
const PDG_MERCHANT_NOT_FOUND = 'pdg.error.merchant.not.found';
const PDG_MERCHANT_INVALID = 'pdg.error.merchant.invalid';
const PDG_GENERAL_DECLINE = 'pdg.error.declined';
const PDG_EXPIRED_CARD = 'pdg.error.expired-card';
const PDG_REFERRAL = 'pdg.error.referral';
const PDG_KEEP_CARD = 'pdg.error.keep-card';
const PDG_INVALID_CARD_DATA = 'pdg.error.invalid-card-data';
const PDG_MISSING_SECURITY_CODE = 'pdg.error.missing-security-code';
const PDG_INVALID_CIN = 'pdg.error.invalid-cin';
const PDG_MAG_STRIPE_ERROR = 'pdg.error.mag-stripe-error';
const PDG_CVV2_ERROR = 'pdg.error.cvv2-error';
const PDG_SYSTEM_ERROR = 'pdg.error.system-error';
const PDG_DEVICE_IN_USE_ERROR = 'pdg.error.device-in-use';
const PDG_BANK_INTERNAL_ERROR = 'pdg.error.bank-internal-error';
const PDG_INVALID_MERCHANT = 'pdg.error.invalid-merchant';
const PDG_INVALID_MOP = 'pdg.error.invalid-mop';
const PDG_DUPLICATE = 'pdg.error.duplicate';
const PDG_COMMUNICATION_ERROR = 'pdg.error.communication-error';
const PDG_REFUND_DENIED = 'pdg.error.refund-denied';
const messages = defineMessages({
    paymentDeviceListLabel: {
        id: 'sales.Checkout.paymentDeviceListLabel',
        defaultMessage: 'Select payment device',
    },
    paymentDeviceListPlaceholder: {
        id: 'sales.Checkout.paymentDeviceListPlaceholder',
        defaultMessage: 'Select a Device',
    },
    paymentDeviceListEmptyError: {
        id: 'sales.Checkout.paymentDeviceListEmptyError',
        defaultMessage: 'No payment device was detected. Refresh the payment device list or use another payment method. If the problem persists, contact your Ticketmaster representative for assistance.',
    },
    manualEntryInPaymentDevice: {
        id: 'sales.Checkout.manualEntryInPaymentDevice',
        defaultMessage: 'Enter credit card info manually on the payment device',
    },
    paymentDeviceGatewayGeneralFailure: {
        id: 'sales.Checkout.paymentProcessError',
        defaultMessage: 'Unable to process payment. Try again, or use a different card or payment method.',
    },
    paymentDeviceGatewayInvalidDevice: {
        id: 'sales.Checkout.deviceConnectionError',
        defaultMessage: 'Unable to connect to the payment device. Make sure the device is powered on and connected to a network or try another payment device or payment method. If the problem persists, contact your Ticketmaster representative for assistance.',
    },
    paymentDeviceGatewayInvalidMerchant: {
        id: 'sales.Checkout.merchantConfigurationError',
        defaultMessage: 'Merchant configuration is invalid. Make sure your organisation is properly configured or use another payment method. If the problem persists, contact your Ticketmaster representative for assistance.',
    },
    cancelEMVRefundWarningMsg: {
        id: 'orders.App.cancelEMVRefundWarningMsg',
        defaultMessage: 'Unable to process refund. Transaction has been cancelled.',
    },
    refundEMVRefundErrMsg: {
        id: 'orders.App.refundEMVRefundErrMsg',
        defaultMessage: 'Unable to process refund. Try again or use a different card.',
    },
    cancelEMVPaymentWarningMsg: {
        id: 'sales.App.cancelEMVPaymentWarningMsg',
        defaultMessage: 'Payment has been cancelled. To try again, press "Checkout Now".',
    },
    nakEMVPaymentWarningMsg: {
        id: 'sales.App.nakEMVPaymentWarningMsg',
        defaultMessage: 'There was an error during checkout. Initial payment has been refunded. Please try again.',
    },
    checkoutEMVPaymentBadSwipeErrMsg: {
        id: 'sales.App.checkoutEMVPaymentBadSwipeErrMsg',
        defaultMessage: 'Could not read the credit card. Try swiping the card again, enter the card manually,' +
            ' use another card or payment method.',
    },
    checkoutEMVPaymentErrMsg: {
        id: 'sales.App.checkoutEMVPaymentErrMsg',
        defaultMessage: 'Unable to process payment. Try again, or use a different card or payment method.',
    },
    checkoutEMVPaymentDeviceInUseErrMsg: {
        id: 'sales.App.checkoutEMVPaymentDeviceInUseErrMsg',
        defaultMessage: 'Unable to process payment. The selected payment device is already in use. Try again later or use a different payment device.',
    },
    checkoutPDGPaymentDeclined: {
        id: 'sales.Checkout.cardDeclined',
        defaultMessage: 'Credit card is declined. Try using a different credit card or payment method.',
    },
    checkoutPDGPaymentExpired: {
        id: 'sales.Checkout.cardExpired',
        defaultMessage: 'Credit card is expired. Try using a different credit card or payment method.',
    },
    checkoutPDGPaymentCardError: {
        id: 'sales.Checkout.paymentProcessErrorUseOtherMop',
        defaultMessage: 'Unable to process payment. Try again by using a different card or payment method.',
    },
});
export const mapErrorCodeToErrorMessage = (error) => {
    switch (error) {
        case PDG_TIMEOUT:
        case PDG_REQUEST_UNREACHABLE:
        case PDG_INVALID_JWT:
        case PDG_INVALID_FIELD:
        case PDG_TRANSACTION_NOT_AUTHORIZED:
        case PDG_TRANSACTION_NOT_FOUND:
        case PDG_REFERENCE_NOT_FOUND:
        case PDG_TRANSACTION_DUPLICATE:
            return messages.paymentDeviceGatewayGeneralFailure;
        case PDG_DEVICE_INVALID:
            return messages.paymentDeviceGatewayInvalidDevice;
        case PDG_MERCHANT_NOT_FOUND:
        case PDG_MERCHANT_INVALID:
            return messages.paymentDeviceGatewayInvalidMerchant;
        case PDG_GENERAL_DECLINE:
            return messages.checkoutPDGPaymentDeclined;
        case PDG_EXPIRED_CARD:
            return messages.checkoutPDGPaymentExpired;
        case PDG_REFERRAL:
        case PDG_KEEP_CARD:
        case PDG_INVALID_CARD_DATA:
        case PDG_SYSTEM_ERROR:
        case PDG_BANK_INTERNAL_ERROR:
        case PDG_INVALID_MERCHANT:
        case PDG_INVALID_MOP:
        case PDG_DUPLICATE:
        case PDG_COMMUNICATION_ERROR:
        case PDG_REFUND_DENIED:
            return messages.checkoutPDGPaymentCardError;
        case PDG_MISSING_SECURITY_CODE:
        case PDG_INVALID_CIN:
        case PDG_MAG_STRIPE_ERROR:
        case PDG_CVV2_ERROR:
            return messages.checkoutEMVPaymentBadSwipeErrMsg;
        case PDG_DEVICE_IN_USE_ERROR:
            return messages.checkoutEMVPaymentDeviceInUseErrMsg;
        default:
            return messages.checkoutEMVPaymentErrMsg;
    }
};
export default messages;
