import { renderDropdownComponent } from '@ticketmaster/tm1pos-web-shared/components/Dropdown';
import { renderInputComponent } from '@ticketmaster/tm1pos-web-shared/components/InputComponent';
import { intlShape } from '@ticketmaster/tm1pos-web-shared/typings/react-intl-types';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Field } from 'redux-form/immutable';
import messages from '../../../../messages';
import { CREDIT_CARD_TYPE } from '../../constants';

export class AuthOffCC extends Component {
  validateCardTypeUndefined = (value) => (value ? undefined : this.props.intl.formatMessage(messages.required));

  render() {
    const { cardHolderLabel, fieldNames } = this.props;
    const emptySelectionMsg = this.props.intl.formatMessage(messages.cardTypeSelectorPlaceholder);
    return (
      <div className="card-entry__form">
        <Field
          name={fieldNames.cardHolderName}
          className="sdr-checkout__input"
          component={renderInputComponent}
          placeholder={cardHolderLabel}
        />
        <Field
          component={renderDropdownComponent}
          name={fieldNames.creditCardTypes}
          className="sdr-checkout__input"
          validate={this.validateCardTypeUndefined}
          dataProvider={CREDIT_CARD_TYPE}
          emptySelection={emptySelectionMsg}
        />
      </div>
    );
  }
}

AuthOffCC.propTypes = {
  cardHolderLabel: PropTypes.string,
  fieldNames: PropTypes.object,
  intl: intlShape,
};

export default injectIntl(AuthOffCC);
