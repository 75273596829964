import { getUserFromStorage } from '@ticketmaster/tm1pos-web-shared/utils/getUserFromStorage';
import { TM1_URL } from '../../constants';

export const tm1ApiClient = {
  async fetchImportMap() {
    const baseUrl = encodeURIComponent(TM1_URL);
    return fetchFromTM1(`/api/components/dependencies/import-map?baseURL=${baseUrl}`);
  },
};

const fetchFromTM1 = async (input: string) => {
  const accessToken = getUserFromStorage()?.access_token;
  const response = await fetch(`${TM1_URL}${input}`, {
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  return response.json();
};
