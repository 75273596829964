import TmdsClient from '@ticketmaster/tm1pos-tmds-client';
import { createActor } from 'xstate';
import { clearTransactionState, setReverseTransactionStatus, setStatus } from '../emvPaymentModule-slice';
import { ReverseTransactionStatus, TransactionStatus } from '../emvPaymentModule-types';
import { tmdsPaymentMachine, TmdsPaymentsMachineEvents, TmdsPaymentsMachineStates, } from './tmdsPaymentMachine';
import { mapTransactionDataToTMDS } from './tmdsPaymentMapper';
import { handlePaymentResponse, handleRollbackResponse } from './tmdsPaymentResponseHandler';
export const tmdsPaymentActor = createActor(tmdsPaymentMachine);
export { TmdsPaymentsMachineEvents };
let previousState;
tmdsPaymentActor.subscribe((snapshot) => {
    if (snapshot.value === previousState)
        return;
    previousState = snapshot.value;
    if (snapshot.matches(TmdsPaymentsMachineStates.PAYING)) {
        const { paymentData, isRefund, dispatch } = snapshot.context;
        if (!paymentData || !dispatch)
            return;
        processPayment(paymentData, isRefund, dispatch);
    }
    if (snapshot.matches(TmdsPaymentsMachineStates.ROLLBACKING)) {
        const { dispatch } = snapshot.context;
        if (!dispatch)
            return;
        rollback(dispatch);
    }
    if (snapshot.matches(TmdsPaymentsMachineStates.ACKNOWLEDGING)) {
        TmdsClient.acknowledgeEmvTransaction();
        tmdsPaymentActor.send({ type: TmdsPaymentsMachineEvents.ACKNOWLEDGED });
    }
    if (snapshot.matches(TmdsPaymentsMachineStates.STOPPING)) {
        TmdsClient.stopEmvTransaction();
    }
});
tmdsPaymentActor.start();
const processPayment = (data, isRefund, dispatch) => {
    TmdsClient.clearPaymentData();
    dispatch(clearTransactionState());
    dispatch(setStatus(TransactionStatus.PROCESSING));
    const transaction = mapTransactionDataToTMDS(data);
    TmdsClient.processEmvTransaction(transaction).then((paymentResult) => {
        handlePaymentResponse(paymentResult, isRefund, dispatch, tmdsPaymentActor.send);
    });
};
const rollback = (dispatch) => {
    dispatch(setReverseTransactionStatus(ReverseTransactionStatus.PROCESSING));
    TmdsClient.reverseEmvTransaction().then((rollbackResult) => {
        handleRollbackResponse(rollbackResult, dispatch, tmdsPaymentActor.send);
    });
};
