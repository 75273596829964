export var PaymentType;
(function (PaymentType) {
    PaymentType["CASH"] = "CASH";
    PaymentType["AMERICANEXPRESS"] = "AMERICANEXPRESS";
    PaymentType["MASTERCARD"] = "MASTERCARD";
    PaymentType["DISCOVER"] = "DISCOVER";
    PaymentType["VISA"] = "VISA";
    PaymentType["DEBITCARD"] = "DEBITCARD";
    PaymentType["CHECK"] = "CHECK";
    PaymentType["GIFTCERTIFICATE"] = "GIFTCERTIFICATE";
    PaymentType["DINERSCLUB"] = "DINERSCLUB";
    PaymentType["CREDITCARD"] = "CREDITCARD";
    PaymentType["NYCE"] = "NYCE";
    PaymentType["PULSE"] = "PULSE";
    PaymentType["STAR"] = "STAR";
    PaymentType["INTERAC"] = "INTERAC";
    PaymentType["OTHER"] = "OTHER";
    PaymentType["VOU"] = "VOU";
    PaymentType["PPRT"] = "PPRT";
    PaymentType["CHRG"] = "CHRG";
    PaymentType["DSC"] = "DSC";
    PaymentType["MC"] = "MC";
    PaymentType["COMP"] = "COMP";
    PaymentType["GC"] = "GC";
    PaymentType["PO"] = "PO";
    PaymentType["CONSIN"] = "CONSIN";
    PaymentType["PSS"] = "PSS";
    PaymentType["BB"] = "BB";
    PaymentType["CDNDBT"] = "CDNDBT";
    PaymentType["GAMEX"] = "GAMEX";
    PaymentType["CO"] = "CO";
    PaymentType["GD"] = "GD";
    PaymentType["TD"] = "TD";
    PaymentType["EX"] = "EX";
    PaymentType["GP"] = "GP";
    PaymentType["MO"] = "MO";
    PaymentType["TMGC"] = "TMGC";
    PaymentType["AME"] = "AME";
    PaymentType["AMX"] = "AMX";
    PaymentType["APP"] = "APP";
    PaymentType["BDH"] = "BDH";
    PaymentType["GMCV"] = "GMCV";
    PaymentType["CPSC"] = "CPSC";
    PaymentType["GRP"] = "GRP";
    PaymentType["PEN"] = "PEN";
    PaymentType["TDI"] = "TDI";
    PaymentType["SBD"] = "SBD";
    PaymentType["LOT"] = "LOT";
    PaymentType["LIB"] = "LIB";
    PaymentType["VCH"] = "VCH";
    PaymentType["INV"] = "INV";
    PaymentType["CHEQUE"] = "CHEQUE";
    PaymentType["CK"] = "CK";
    PaymentType["PIX"] = "PIX";
})(PaymentType || (PaymentType = {}));
export const isPaymentTypeCard = (type) => cardPaymentTypes.indexOf(type) > -1;
export const cardPaymentTypes = [
    PaymentType.AMERICANEXPRESS,
    PaymentType.MASTERCARD,
    PaymentType.DISCOVER,
    PaymentType.DSC,
    PaymentType.VISA,
    PaymentType.DEBITCARD,
    PaymentType.CREDITCARD,
];
