export var EmvTransactionType;
(function (EmvTransactionType) {
    EmvTransactionType["Sale"] = "S";
    EmvTransactionType["Refund"] = "C";
    EmvTransactionType["Void"] = "V";
    EmvTransactionType["TokenExchange"] = "X";
    EmvTransactionType["TokenOnly"] = "T";
})(EmvTransactionType || (EmvTransactionType = {}));
export var EmvMopType;
(function (EmvMopType) {
    EmvMopType["Visa"] = "VI";
    EmvMopType["Mastercard"] = "MC";
    EmvMopType["Amex"] = "AX";
    EmvMopType["Discover"] = "DI";
    EmvMopType["JCB"] = "JC";
    EmvMopType["Diners"] = "ED";
    EmvMopType["PrivateLabel"] = "PL";
    EmvMopType["Debit"] = "DE";
    EmvMopType["GiftCard"] = "GC";
    EmvMopType["Insignia"] = "INSIGNIA";
    EmvMopType["Pix"] = "pix";
})(EmvMopType || (EmvMopType = {}));
export var TransactionStatus;
(function (TransactionStatus) {
    TransactionStatus["PROCESSING"] = "PROCESSING";
    TransactionStatus["CANCELLED"] = "CANCELLED";
    TransactionStatus["CANCELLING"] = "CANCELLING";
    TransactionStatus["STARTING"] = "STARTING";
    TransactionStatus["FAILURE"] = "FAILURE";
    TransactionStatus["SUCCESS"] = "SUCCESS";
    TransactionStatus["NONE"] = "NONE";
})(TransactionStatus || (TransactionStatus = {}));
export var ReverseTransactionStatus;
(function (ReverseTransactionStatus) {
    ReverseTransactionStatus["PROCESSING"] = "PROCESSING";
    ReverseTransactionStatus["FAILURE"] = "FAILURE";
    ReverseTransactionStatus["SUCCESS"] = "SUCCESS";
    ReverseTransactionStatus["NONE"] = "NONE";
})(ReverseTransactionStatus || (ReverseTransactionStatus = {}));
export var TMDSError;
(function (TMDSError) {
    TMDSError["CANCEL"] = "1004";
    TMDSError["BAD_SWIPE"] = "1007";
    TMDSError["NAK"] = "2020";
})(TMDSError || (TMDSError = {}));
