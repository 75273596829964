import { createSelector } from 'reselect';
import { selectFeatureFlags } from '../feature-flags';
import { PrinterService } from './PrinterService';
export const selectPrintingModuleState = (state) => state.printingModule;
export const selectPrintingModuleEnabled = createSelector(selectFeatureFlags, (featureFlags) => !!(featureFlags === null || featureFlags === void 0 ? void 0 : featureFlags.printingConfiguration));
export const selectHasQzTrayEnabled = createSelector(selectFeatureFlags, (featureFlags) => !!(featureFlags === null || featureFlags === void 0 ? void 0 : featureFlags.printWithQZTray));
export const selectHasTmdsPrintingEnabled = createSelector(selectFeatureFlags, (featureFlags) => !!(featureFlags === null || featureFlags === void 0 ? void 0 : featureFlags.printWithTMDS));
export const selectIsQzConnecting = (state) => state.printingModule.isQzConnecting;
export const selectPrinters = (state) => state.printingModule.printers;
export const selectPrinterServices = createSelector(selectHasQzTrayEnabled, selectHasTmdsPrintingEnabled, (hasQzTrayEnabled, hasTmdsEnabled) => {
    const services = [];
    if (hasQzTrayEnabled) {
        services.push(PrinterService.QzTrayLocal, PrinterService.QzTrayRemote);
    }
    if (hasTmdsEnabled) {
        services.push(PrinterService.Tmds);
    }
    return services;
});
export const selectActivePrinter = (state) => state.printingModule.activePrinter;
export const selectHasActivePrinter = createSelector([selectActivePrinter, selectPrintingModuleEnabled], (activePrinter, isPrintingModuleEnabled) => !!activePrinter || !isPrintingModuleEnabled);
export const selectPrinterError = (state) => state.printingModule.printerError;
export const selectPrintingModuleInitialized = (state) => state.printingModule.printingModuleInitialized;
export const selectPrintingModuleLoading = (state) => state.printingModule.loading;
export const selectPrintTestTicketState = (state) => state.printingModule.printTestTicketState;
export const selectConnectionError = (state) => state.printingModule.connectionError;
