import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { IconRemove, IconSearch, IconSearchAlt } from '../icons';
import './styles.scss';
export default class SearchFilterComponent extends Component {
    constructor() {
        super(...arguments);
        this.onSubmit = (event) => {
            if (this.props.onInputSubmit) {
                this.props.onInputSubmit(event.target.value);
            }
        };
        this.handleBlur = (event) => {
            if (this.props.onInputBlur) {
                this.props.onInputBlur(event.target.value);
            }
        };
        this.handleChange = (event) => {
            const value = event.target.value;
            if (this.props.onInputChange) {
                this.props.onInputChange(value);
            }
        };
        this.handleClear = () => {
            if (this.props.onInputChange) {
                this.props.onInputChange('');
                this.forceFocus();
            }
        };
        this.handleKeyPress = (ev) => {
            if (ev.key === 'Enter') {
                this.onSubmit(ev);
            }
        };
        this.forceFocus = () => {
            if (this.searchInputField) {
                this.searchInputField.focus();
            }
        };
    }
    componentDidMount() {
        if (this.props.focusOnMount) {
            this.forceFocus();
        }
    }
    render() {
        const styleClasses = classNames('oc-search-filter', this.props.className, {
            'oc-search-filter--disabled': this.props.disabled,
        });
        const isBorderless = styleClasses.indexOf('oc-search-filter--borderless') > -1;
        const icon = isBorderless ? _jsx(IconSearchAlt, {}) : _jsx(IconSearch, {});
        return (_jsxs("div", { className: styleClasses, children: [_jsx("input", { type: "text", className: "oc-search-filter__input", id: this.props.id, placeholder: this.props.placeholder, disabled: this.props.disabled, onChange: this.handleChange, onKeyPress: this.handleKeyPress, onBlur: this.handleBlur, value: this.props.value, ref: (input) => {
                        this.searchInputField = input;
                    }, autoFocus: this.props.focusOnMount }), _jsx("span", { className: "oc-search-filter__icon", children: icon }), _jsx("button", { className: "oc-search-filter__clear-btn", onClick: this.handleClear, children: _jsx(IconRemove, {}) })] }));
    }
}
SearchFilterComponent.propTypes = {
    id: PropTypes.string,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    value: PropTypes.string,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    onInputChange: PropTypes.func,
    onInputSubmit: PropTypes.func,
    onInputBlur: PropTypes.func,
    focusOnMount: PropTypes.bool,
};
