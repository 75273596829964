import { renderCheckboxComponent } from '@ticketmaster/tm1pos-web-shared/components/Checkbox';
import { useEffect, useState } from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { Field } from 'redux-form/immutable';
import messages from '../../../../messages';
import { normalizeFn } from '../../utils';
import MessageDescriptor = ReactIntl.FormattedMessage.MessageDescriptor;

type InjectedProps = {
  intl: InjectedIntl;
};

export type Props = {
  fieldNames: any;
  emvSupported: boolean;
  checked: boolean;
  allowed: boolean;
  disabled: boolean;
};

const ManualCardEntry = ({ checked, fieldNames, emvSupported, allowed, disabled, intl }: InjectedProps & Props) => {
  const [placeholder, setPlaceholder] = useState<MessageDescriptor>(messages.manualEntryTogglePlaceholder);

  useEffect(() => {
    if (emvSupported) {
      setPlaceholder(messages.emvManualEntryPlaceholder);
    }
  }, [emvSupported, setPlaceholder]);

  return (
    <div className="card-entry__toggle">
      {allowed && (
        <Field
          className="oc-toggle-switch--small"
          checked={checked}
          component={renderCheckboxComponent}
          id={fieldNames.manualCardEntry}
          name={fieldNames.manualCardEntry}
          normalize={normalizeFn}
          disabled={disabled}
          text={intl.formatMessage(placeholder)}
        />
      )}
    </div>
  );
};

export default injectIntl(ManualCardEntry);
